import './app.css';
import './styling-standards.css';
import './view-toggle.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { MultiStateButton } from './MultiStateButton';
import { SquareIcon, GridColums } from './ComponentStandards';
import { urlInfoContext } from './App';
import { themeContext } from './ThemeProvider';
import produce from "immer";


export default function ViewToggle({ theme_, show, options, callbackMultiStateButton }) {

  ///ref
  const multiStateButtonRef = useRef(null)

  ///context
  const { theme } = useContext(themeContext);

  ///const
  const name = "view-toggle center-by-grid"

  const options_ = 
    options.map((option, index) =>
      <div 
        key={option}
        id={index}>
        <GridColums 
          className={"view-toogle"}
          gridAutoFlow={"column"}
          //gridTemplateColumns={`minmax(1.5vw, auto) minmax(8vw, auto)`} ///optimise: width identical to min width of googletranslate - base it on shared variable
          gridTemplateRows={""}
          justifyContent="center"
          justifyItems="center"
          columnGap={"0.5vw"}
    	  >
          {option}
        </GridColums>
      </div>
     )
  
    return (
          <MultiStateButton
            show={show}
            classNameWrapper={`${name}`} 
            classTypes={["classical", "subtle"]}
            fontSize={0}
            theme={!theme_ ? theme.theme : theme_}
            label={name}
            ref={multiStateButtonRef}
            options={options_} 
            value={options_[theme.view]} ///was: [theme.view] ??
            parentCallback={callbackMultiStateButton}
        ></MultiStateButton>
    )
}
