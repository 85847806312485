import './app.css';
import './styling-standards.css';
import './page.css';

export default function Page( { children } ) {
	
  return (
	  <div className={"page-container"}>
      <div className={"page"}>
            { children }
      </div>
    </div>
  );;
}