import './app.css';
import './styling-standards.css';
import './gallery-item.css';
import { useState, useEffect, useContext, useLayoutEffect, memo, useRef, useCallback, createContext, useMemo } from 'react';
import { ItemText } from './ItemText';
import * as generics from './Generics';
import { urlInfoContext, scrollToContext } from './App';
import { themeContext } from './ThemeProvider';
import { itemsRefinedContext } from './ItemsRefinedComponent';
import { refineContext } from './ItemsRefinedComponent';
import { NoMatch, GridColums, Button, Wrapper, AspectRatio, ConditionalElementWrapper } from './ComponentStandards';
import { scrollToName, ScrollBoxPair } from './Scroll';
import { WithLink, ConditionalLinkWrapper } from './Links';
import { UserPresent }from './UserUidProvider';
import produce from "immer";
import { use } from 'i18next';
import { uploadMedia } from './NewArtwork'; ///optimise: move uploadmedia elsewhere?
import { ArrayOfStrings } from './ItemForm';
import * as Gallery_r from './Gallery_r';
import { MediaUpload, templatesSetup } from './ItemForm';
import { Like } from './UserCases';
import { mainColors } from './ItemForm';
// import { templatesDataContext } from './DataProvider';
import { itemsContext } from './ItemsProvider';
import { sessionInfoContext } from './SessionInfoProvider';


export const GalleryItem = memo(({ templatesData, setTemplatesData, userComments, gridColumn, document, to, data, index, parentCallback }) => {
  ///ref
  const contentEditableRef = useRef([])

  ///context
	const
    { itemsRefined } = useContext(itemsRefinedContext),
	  { theme } = useContext(themeContext),
	  { refine } = useContext(refineContext),
    { items, setItems } = useContext(itemsContext),
    // { templatesData, setTemplatesData } = useContext(templatesDataContext),
    { sessionInfo } = useContext(sessionInfoContext)

  ///const via context
  const 
    template = data.template,
    isEditDocument = document === templatesData.update.document,
    isEditMediaDocument = document ===
      templatesData.update.document && 
      (templatesData.update.label === "media" || 
      templatesData.update.label === "mediaText" ||
      templatesData.update.label === "media_is_video"),
    //activeTemplate = templatesData.update.template, ///optimise/risk: it must be correct to discontinue use of template here, and instead use templet, correct
    dataUpdateMedia = templatesData["update"].values[template]["media"],

    showDocumentMoreGridViewExtra = theme.view === 1 && generics.checkRemainder(index + 1, refine.gallery.itemsPrRow + 1, true) ///question: add theme.view === 1? ///note: refine.gallery.itemsPrRow + 1 is equal to period
    //showDocumentMoreGridView = theme.view === 1 && generics.stringInArray(refine.documentMore.documentIndexs, index) ///optimise: consolidate criteria in relation to theme.view, documentmore ... 

  ///state
  const 
    //[editMediaDocument, setEditMediaDocument] = useState(""),
    [videoUrl, setVideoUrl] = useState("")
    //[isUpdating, setIsUpdating] = useState(true)
	
	// const galleryItem = useCallback(() => { 
	const 
    userPresent = UserPresent(),
    stateMedia = isEditMediaDocument ?
      dataUpdateMedia : 
      data.media,
    isUpdated = 
      (!generics.arrayOfObjectsForKeyIsEqual(data.media, dataUpdateMedia, "mediaText") || 
      !generics.arrayOfObjectsForKeyIsEqual(dataUpdateMedia, data.media, "mediaText") ||
      !generics.arrayOfObjectsForKeyIsEqual(data.media, dataUpdateMedia, "nameId") || 
      !generics.arrayOfObjectsForKeyIsEqual(dataUpdateMedia, data.media, "nameId") ||
      !generics.arrayOfObjectsSortIsEqual(data.media, dataUpdateMedia, "id")),
    showDocumentMore = true,
        // theme.view === 0 && ///i.e. listview
        // generics.stringsAreIdentical(document, refine.documentMore.document) ||
        // showDocumentMoreGridViewExtra,
    fadeArtwork = refine.documentMore.directLoad && refine.documentMore.document !== "" && !showDocumentMore,
		mapOnlyBoxId = theme.view === 1 && !showDocumentMoreGridViewExtra ? 1 : "", ///risk: no diff if implemented, why not
		elementStyle = [
			{
				gridTemplateColumns: "0.65fr 0.35fr", ///scroll-box-config: columns: 0.1fr 0.55fr 0.35fr ///view-0 ///was: "0.3fr 2fr 1fr" ///10% 60% 30%
				gridTemplateRows: "1fr minmax(0, fit-content(100%))", // fit-content(100%)", ///was: "1fr 1f",
				// gridTemplateRows: "100%",
				height: "100%",
				columnGap: "1vh",
				display: "grid", ///optimise: only define once
        gridColumn: gridColumn
			},
			{
				gridTemplateColumns: `${(theme.view === 0 || showDocumentMoreGridViewExtra) ? "6fr 1fr" : "1fr"}`, ///view-1
				gridTemplateRows: "1fr",
        gridColumn: gridColumn,
				// gridRowGap: "0vh",
				display: "grid"
			},
		]

  	///more-link cliked =>
	const handleSetDocumentMore = (state) => {
		// setDocumentMore(document);
    
    const document = state.document
		const documentIndex = state.documentIndex
		const value = {document: document, documentIndex: documentIndex, directLoad: false} ///was: const value = {document: "*not-used*", documentIndex: documentIndex, directLoad: false}
		parentCallback("documentMore", "documentMore-update-from-user-interaction", value) //handleSetDocumentMore(document, false)}
	}

  // const dataUpdateMediaX = async(label, value) => { ///optimise: combine with update function in itemtext
  //   await Gallery_r.updateItemText(label, value, document) ///optimise: implement abortcontroller or similar for calls with multistatebuttons - to avoid write at every change, see fx https://stackoverflow.com/questions/67034451/react-cancel-async-functions-in-useeffect-that-depends-on-other-variables or https://javascript.plainenglish.io/how-to-add-a-timeout-limit-to-asynchronous-javascript-functions-3676d89c186d
  //   handleSetItems(label, value) ///optimise/consider: reverse update and set for at more smooth user experience, and get confirmation of update via callback from update
  //   //setUpdatedProperty([document, index]) ///note: for styling usage
  // }

  ///user interaction (edit media) => set document for update
  const handleSetTemplatesDocument = ({ reset }) => { ///note: obsolute, no  need to set af edit, simple set at change or save

   // handleSetTemplatesData({ reset }) 
    // const value = reset ? "" : document
    // setTemplatesData(
    //   produce((draft) => {
    //     draft.update.document = value
    //   })
    // );
  }

  ///change or save media  => set element at template[template]
  const handleSetTemplatesData = ({ trigger, document, label, index, value, startUpload, reset }) => {

    // if (trigger === "item-text--updated") {
    //   handleSetItems({ label, value })
    // }
    //setIsUpdating(true) ///note: setisupdating implemented to ensure that statedata is copied to templatesdata update. if not [] would be copied ///optimise: way to make obsolute?
    const tempObj = { ///optimise: make specification implicit via map
      document: reset ? "" : document, ///optimse: pass document instead? ///note: if reset document is "", else document
      action: "update", 
      template: template, 
      label: label, 
      index: index, 
      value: value, 
      startUpload: startUpload, 
      reset: reset 
    } 
    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  };

  ///useraction change media (edit or save) =>
  const handleChangeMedia = async({ label }) => { ///note: superfluous to pass iseditmediadocument, but done to empatise condition 
    console.log("handleChangeMedia")
    const 
      index = null, ///note: not used, but specified for completeness
      reset = false ///note: superfluous, but specified for completeness
    let trigger, value, startUpload = false
    switch (true) {
      case isEditMediaDocument: ///save/update
        const 
          mediaData = templatesData.update.values[template][label],
          mediaArray = await uploadMedia({ mediaData, template })
        trigger = "media--uploaded"
        value = mediaArray
        startUpload = true
        break;
      case !isEditMediaDocument: ///=> edit mode
        trigger = "media--edit-mode"
        value = stateMedia
        startUpload = false
        break;
      default:
      break;
    };
    handleSetTemplatesData({ trigger, document, label, value, index, startUpload, reset })
  }

  // ///userinteraction save media =>
  // const handleUpdateMedia = async() => {
  //   console.log("update media")
  //   const 
  //     label = "media",
  //     mediaData = templatesData.update.values[template][label],
  //     mediaArray = await uploadMedia({ mediaData, template }),
  //     index = null, ///note: superfluous, but specified for completeness
  //     value = mediaArray,
  //     startUpload = true,
  //     reset = false ///note: superfluous, but specified for completeness
  //   handleSetTemplatesData({ label, value, index, startUpload, reset })
  // }

  ///update media url at backend =>
  useEffect( async() => {

    if (
      !isEditDocument || ///if not the document being edited => abort
      !templatesData.update.startUpload ///if startupload is false => abort
      ) {
      return
    }
   
    const 
      label = templatesData.update.label,
      document = templatesData.update.document,
      // trigger = "item-text--updated",
      // index = null, ///note: not used, but specified for completeness
      value = templatesData.update.values[template][label]

      //reset = true

    await Gallery_r.updateItemText({ label, value, document, template })

    ///reset:
      //setIsUpdating(false)
      //handleSetTemplatesDocument({ reset })
      handleSetItems({ label, value })
      // handleSetTemplatesData({ trigger, index, reset })
  }, [templatesData.update.startUpload]); 

  ///handle setitems - from updated data at templatesdata
  const handleSetItems = useCallback(({ label, value }) => {
    setItems(
      produce((draft) => {

        const item = draft.find((item) => item.document === document);
        item[label] = value
        // switch (true) {
        //   case label === "media":
        //     item["media"] = value
        //     break
        //   default:
        //   break;
        // };
        //item.forceRender = !item.forceRender; //new state ensures render when contentEditableRef.current = "" (at no change in 'items' prop via use of prevText) ///question: needed??
      })
    );
  }, [])

  ///items changed (incl updated) => reset (at templatesdata.update)
  useEffect( async() => {
    const 
      trigger = "item-text--updated",
      document = "", ///note: superfluous, but specified for completeness
      index = null, ///note: not used, but specified for completeness
      reset = true
    handleSetTemplatesData({ trigger, document, index, reset })
  }, [items]); 

  // useEffect(() => {
  //   const stateMedia = isEditMediaDocument ?
  //     dataUpdateMedia :
  //     data.media
  //     console.log("use effect", dataUpdateMedia, isEditMediaDocument, stateMedia)
  //   setStateMedia(stateMedia)
  // }, [editMediaDocument]);

  ///morelink
	const MoreLink = ({ className, to, preTree, state, document, disable, children }) => {
		const 
			name = "more",
			moreLink = disable ? children : WithLink({to, children, preTree, state, disable})
			
		const handleClickedDocumentMore = () => {
				handleSetDocumentMore(state) 
				scrollToName(document, "", false, false, -150) ///optimise: pass as object, and include offset
			}
		return (
			<Button
        show={true}
        wrapperHeight={"auto"}
        wrapperWidth={"auto"}
				key={index} 
				name={name}
				wrapperClassName={`more-wrapper`}
				//wrapperStyle={{}}
				classTypes={["classical", "link"]}
				className={`
					more
          input-padding-3
					${className}
				`}
        wrapperStyle={{margin: "auto"}}
        width={"fit-content"}
				//wrapperStyle={{zIndex: "50"}}
				//positions={["middle", "middle"]}
				// height={"100%"}
				// width={"100%"}
				fontSize={1}
				theme={theme.theme}
				parentCallback={() => handleClickedDocumentMore(document, state)} ///optimise: document and state can be removed here
			>
				{/* <span className={`more-text`}
				> */}
					{moreLink}
				{/* </span> */}
			</Button>
		)
	}

  const Component = useCallback(({  }) => {
    const state={document: document, documentIndex: index}
    const mapItemData = !!data.geoLocation && !!data.geoLocation[0].lat && !!data.geoLocation[1].lng ? 
      [{ url: "", id: "map", mediaType: "map", item: data }] : ///geoLocation: data.geoLocation 
      []

    const handleClickedDocumentMore = () => {

      handleSetDocumentMore(state) 
      scrollToName(document, "", false, false, -30) ///bug: does not always scroll to correct positon at first item in row?? optimise: pass as object, and include offset
    }
   // moreLink = disable ? children : WithLink(to, children, preTree, state, disable)
    
   
   const ConditionalButtonWrapper = ({ isWrap, children }) => {
      const button = 
        <Button
        wrapperHeight={"auto"}
        wrapperWidth={"auto"}
        show={true}
        width={"100%"}
        height={"100%"}
        overflowOff={false}
        classTypes={["classical", "linkSubtle"]}
        //wrapperStyle={{gridColumn: generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, 1, "gridColumn")}}
        //style={{border: `${templatesSetup[template].color} 1px solid`}}
        parentCallback={() => !showDocumentMoreGridViewExtra && handleClickedDocumentMore(document, state)}
        hoverOff
        selectOff={theme.view === 0 || showDocumentMoreGridViewExtra}
        wrapperClassName={`${document}${showDocumentMoreGridViewExtra ? "-extra" : ""}`}
    //    className={`
    //  ${showDocumentMore ? 
    //    `gallery-item-background theme-${theme.theme + 2}` : 
    //    ""
    //  }
    //  `}
        //classTypes={["classical", "min"]}
      >{children}</Button> 
    const component = isWrap ? button : children
   return (
    component
   )
   }
   
   
   return (
      <GridColums
      gridTemplateColumns={"minmax(0, 1fr)"}
      gridTemplateRows={"1fr minmax(0, fit-content(100%))"}
      rowGap={"0.5em"}


            className={`gallery-item-main-grid
     ${showDocumentMore ? 
       `gallery-item-background theme-${theme.theme + 2}` : 
       ""
     }
     ${showDocumentMoreGridViewExtra ? "gallery-item--large" : "gallery-item--small"}
     `}
     wrapperStyle={{gridColumn: gridColumn, border: `${templatesSetup[template].color} 1px solid`}}
     >
      {/* <Button
       show={true}
       width={"100%"}
       height={"100%"}
       overflowOff={false}
       classTypes={["classical", "customborder"]}
       wrapperStyle={{gridColumn: generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, 1, "gridColumn")}}
       //style={{border: `${templatesSetup[template].color} 1px solid`}}
       parentCallback={() => !showDocumentMoreGridViewExtra && handleClickedDocumentMore(document, state)}
       hoverOff
       selectOff={theme.view === 0 || showDocumentMoreGridViewExtra}
       wrapperClassName={`${document}${showDocumentMoreGridViewExtra ? "-extra" : ""}`}
    //    className={`
    //  ${showDocumentMore ? 
    //    `gallery-item-background theme-${theme.theme + 2}` : 
    //    ""
    //  }
    //  `}
       //classTypes={["classical", "min"]}
      > */}
    <AspectRatio
      aspectRatio={theme.view === 0 || showDocumentMoreGridViewExtra ? 52 : 120}
    >
      <ConditionalLinkWrapper
        //key={label}
        wrapIt={false} ///was: theme.view === 1 && !showDocumentMoreGridViewExtra} note: wrapIt false as Link placed on child (and otherwise it will be link within link, not permitted)
        to={to} 
        preTree={`gallery`}
        state={state}
        disable={false}
        underline={false}
      >
      <GridColums ///optimise: make width of itemtext column constant, indepedent on scrollbox or edit media
      gridTemplateColumns={generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, showDocumentMoreGridViewExtra ? 0 : theme.view, "gridTemplateColumns")} ///optimise: make into function once
      gridTemplateRows={generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, showDocumentMoreGridViewExtra ? 0 : theme.view, "gridTemplateRows")} 
      columnGap={generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, showDocumentMoreGridViewExtra ? 0 : theme.view, "columnGap")}
      height={generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, showDocumentMoreGridViewExtra ? 0 : theme.view, "height")}
      maxWidth={"calc(100vw - 24vw)"} ///optimise: get value dynamic 
      name={"gallery-item"}
      //name={`gallery-item`}
      // wrapperStyle={{
      //     border: `${templatesSetup[template]} 1px solid`,
      //     gridColumn: generics.valueByIndexAndKeyInArrayOfObjects(elementStyle, theme.view, "gridColumn")
      //   }}
      wrapperStyle={{height: "100%", overflow: `${theme.view === 0 ? "" : "scroll"}`}} ///note: overflow at view 0 due to editmore and no overflow + scroll at view 1}}
      className={`
        
        input-radius
        ${isEditDocument ? "edit" : ""} 
      ${fadeArtwork ? 
        "fade fadeout-01" : 
        "show fadein-05"}
      `}

      ///wrapperStyle={{gridColumn: theme.view === 1 && showDocumentMore ? `1 / span ${itemsPrRow}` : ""}}
      // name={document}
    > 
      {userPresent && 
      isEditMediaDocument 
        ? 
        // <p></p>
        <>
          {/* <div> */}
          <MediaUpload ///optimise: move media to own component (like itemtext)
            //stateMedia={data.media}
            stateMedia={stateMedia}
            label={"media"} 
            minWidthButton={"50vw"}
            setDataParentCallback={(label, files) => {
              const 
                trigger = "media--change",
                index = null, ///note: not used, but specified for completeness
                value = files,
                startUpload = false,
                reset = false
              handleSetTemplatesData({ trigger, document, label, index, value, startUpload, reset }) ///was: handleSetItems({ label, value })
            }}
            setTextCallback={(label, value, index) => {
              const 
                trigger = "media-text--change",
                startUpload = false,
                reset = false
              handleSetTemplatesData({ trigger, document, label, index, value, startUpload, reset })} ///was: handleSetItems({ action, label, value, index })}
            } 
            //setDataExtraCallback={(parameter, value, action, index) => console.log(parameter, value, action, index)}
          >
            <ArrayOfStrings
              label={"video"} 
              ///stateMedia={data.media}
              stateMedia={stateMedia}
              editableContent={videoUrl} //"https://www.youtube.com/watch?v=GWFToTcoVwI"} ///was: videoUrl
              currentRefs={contentEditableRef.current}
              refIndex={0}
              setStateCallback={(label, value, index) => setVideoUrl(value)} ///optimise: consider use of datatemplate instead of vidiorul, align with upload form...
              submitCallback={(label, value) => {
                const 
                  trigger = "video--added",
                  index = null, ///note: not used, but specified for completeness
                  startUpload = false,
                  reset = false
                handleSetTemplatesData({ trigger, document, label, value, index, startUpload, reset }) ///was: handleSetItems({ action, label, value })}
              }}
          ></ArrayOfStrings>
          </MediaUpload>

        {/* </div> */}
        <div></div> 
        </> ///note: above - dummy for map into grid (to ensure positioning of itemtext)
        :
        <ConditionalButtonWrapper isWrap={theme.view === 1 && !showDocumentMoreGridViewExtra}> 
        {/* <div className={`scroll-box-pair-wrapper`}>  */}
          <ScrollBoxPair
            templatesData={templatesData}
            document={document}
            data={[
              ...mapItemData,
              ...data.media, 
              ...userComments
               .sort((a, b) => generics.sortDate(b, a, "created"))
               .sort((a, b, index) => { ///note: sort user-comments from selected user-cases first
                  if (sessionInfo.userCases?.some(userCase_ => userCase_.document === a.userCaseDocument)) return -1;
                  if (sessionInfo.userCases?.some(userCase_ => userCase_.document === b.userCaseDocument)) return 1;
                  return a - b;
                })
              	// .sort((a, b, index) => {
                //   if (a["isPublic"] === true && a["userCaseDocument"] === openUserCase.document) return -1;
                //   if (b["isPublic"] === true && b["userCaseDocument"] === openUserCase.document) return 1;
                //   return a - b;
                // })
                .map(userComment => { return {...userComment, listingColor: mainColors[data.template]} }),
              {mediaType: "newUserComment"},
            ]}
            state={{document: document, documentIndex: index}}
            mapOnlyBoxId={mapOnlyBoxId}
            groupSize={[4, 1]}
          //	mapOnlyBoxId={0} ///default (undefined or "") => map both scrollbox
            to={to}
            overlayContent={data.highlights}
            //social={data.social}
            //animate={true}
            // hoverParentCallback={}
            //parentCallback={(elementName, groupName, groupSize, containerId, horizontal) => scrollBoxElementClicked(elementName, groupName, groupSize, containerId, horizontal)}
          >
            {/* <Button
              positions={["top", "right"]}
              >Edit Media
            </Button> */}
          </ScrollBoxPair>
        {/* </div>  */}
        {/* scroll-box-config: rows: scroll-box-pair-wrapper */}
        </ConditionalButtonWrapper>
        }
        {/* <div className={`gallery-img-wrapper gallery-element ${userPresent ? "gallery-img-zoom--off" : "gallery-img-zoom--on"}`}>
        {userPresent &&
          <div className="gallery-img-text">{index+1}</div>
        }
          <ImageZoom // optimise: mitigate move-left at drag
            data={data}
            className={`gallery-img`}
          ></ImageZoom>
        </div> */}      
      <>
      <Wrapper
        name={"gallery-text"}
        className={"no-select"}
        show={true} //showDocumentMore ? true : false}
        animate={true}
        overflow={"hidden"}
        height={"100%"}
      >
        {/* <div className={"gallery-text gallery-text-artist"}>  */}
          <ItemText
            templatesData={templatesData}
            data={data}
            document={document}
            documentIndex={state.documentIndex}
            isEditDocument={isEditDocument}
            setTemplatesDataCallback={({ trigger, document, label, index, value, startUpload, reset }) => handleSetTemplatesData({ trigger, document, label, index, value, startUpload, reset })}
          />
        {/* {theme.view === 0 ?
        <MoreLink
          className={`${!showDocumentMore ? "show fadein-05" : "hide fadeout-05"}`}
          preTree={`gallery`}
          to={to} 
          state={{document: document, documentIndex: index}}
          //text={"More"} 
          disable={false}
          document={document}
        >More
        </MoreLink> : null
        } */}
      {/* </div> */}
      </Wrapper>
      {/* <UserComments
          isSocialMediaPreview={true}
          listingDocument={document} 
          listingColor={templatesSetup[template].color}
      /> */}
      {userPresent ? 
          <GridColums
            gridTemplateColumns={`minmax(0, 1fr)`}
            gridTemplateRows={`repeat(${2}, minmax(40vh, fit-content(100%)))`}
            wrapperStyle={{position: "absolute", right: `calc(100% + 0.5vw)`, width: "8vw"}} ///optimise: width is manualle set to less than page margin. make automatic
            rowGap={"2vh"}
            justifyContent={"center"}
            //width={"12vw"}
          > 
            <Button
              wrapperHeight={"auto"}
              wrapperWidth={"auto"}
              show={true} //isEditMediaDocument}
              disabled={isEditMediaDocument && !isUpdated}
              wrapperClassName={"btn-center"}
              position={"relative"}
              classTypes={["classic", "min"]}
              overflowOff={true}
              //width={"12vw"}
              paddingAndMargin={`padding-standard-horizontal`}
              //className={`${userPresent && document !== editMediaDocument ? "show fadein-05" : "fade fadeout-05"}`}
              //positions={["left"]}
              theme={theme.theme}
              parentCallback={() => {
                const label = "media"
                handleChangeMedia({ label })
              //   const 
              //     origin = `edit-media-button-state-${isEditMediaDocument}`,
              //     label = "media",
              //     value = stateMedia,
              //     index = null,
              //     startUpload = false,
              //     reset = false
              //   isEditMediaDocument ?
              //     handleUpdateMedia() :
              //     handleSetTemplatesData({ origin, label, value, index, startUpload, reset })
              //     //handleSetTemplatesDocument({ reset })
              }} ///was: setEditMediaDocument(document)
              > {isEditMediaDocument ? "Save" : "Edit Media" }
            </Button>
            <Button
              wrapperHeight={"auto"}
              wrapperWidth={"auto"}
              show={isEditMediaDocument}
              disabled={false}
              wrapperClassName={"btn-center"}
              classTypes={["classic", "min"]}
              position={"relative"}
              paddingAndMargin={`padding-standard-horizontal`}
              overflowOff={true}
              //className={`${userPresent && document !== editMediaDocument ? "show fadein-05" : "fade fadeout-05"}`}
              //positions={["left"]}
              theme={theme.theme}
              parentCallback={() => { ///optimise: implement reset correctly ..
                const 
                  trigger = "media-cancel-edit",
                  index = null, ///note: not used, but specified for completeness
                  reset = true
                handleSetTemplatesData({ trigger, document, index, reset })
              }} ///was: setEditMediaDocument(document)
              > { "Cancel" }
              
            </Button>
          </GridColums>
          : null 
        }
      {/* {showDocumentMore ?
        <MoreMedia
          imageUrl={imageUrl}
        >
        </MoreMedia>
          : null
      }   */}
      
       <Like document={document}/>
      </>
    </GridColums>
    </ConditionalLinkWrapper>
    </AspectRatio>
    {/* </Button> */}
    {/* {theme.view === 0 ? 
      <UserComments 
        listingDocument={document} 
        listingColor={templatesSetup[template].color}
      ></UserComments>
  : null } */}
    </GridColums>
    )}, [userComments, sessionInfo, items, itemsRefined, isEditDocument, template, theme, videoUrl, isEditMediaDocument, dataUpdateMedia]) ///question: rendering implication of adding videourl?

		return (
		 <Component
      //showDocumentMore={showDocumentMore} ///optimise: superflertous

     ></Component>
    )
	// }, [theme, itemsRefined, refine.documentMore.document])
  // return galleryItem
})