import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import * as Gallery_r from './Gallery_r';
import { sessionInfoContext } from './SessionInfoProvider';
import { collection, query, doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from './Firebase'; 
import produce from "immer";

export const userCommentsContext = createContext([,() => {}])

export const UserCommentsProvider = ({ children }) => {

  ///context
  const { sessionInfo } = useContext(sessionInfoContext)

  ///state
  const [userComments, setUserComments] = useState([])
	const userCommentsValue = useMemo(
    () => ({ userComments, setUserComments }), 
    [userComments]
  );

    ///load user-comments
    useEffect(() => {
      handleGetUserComments({ isPublic: true })
    }, [])

  const handleGetUserComments = async({ isPublic }) => {
    let userComments_ = await Gallery_r.getUserComments({ collectionGroup: "comments", collection: "userCases", isPublic: isPublic, document: sessionInfo.document }) ///get gallery data
    userComments_ = [...userComments, ...userComments_] ///risk: if at !!sessionInfo.document handleListenToComments return setState before getComment setState => unwanted duplicates. ///possible solution: simply remove duplicates before present set-state
    setUserComments(userComments_) 
  }

  useEffect(() => {
    if (!!sessionInfo.document) {
      handleListenToUserComments({ }) ///was: userComments: userComments ??
    }
    // if (!sessionInfo.document) {
    //   handleListenToComments({ isUnsubscribe: true })
    // }
  }, [sessionInfo.document]);

  const handleListenToUserComments = ({ isUnsubscribe }) => {

    const document = sessionInfo.document
    //const q = doc(db, "userCases", document, "userComments")
    
    const q = query(collection(db, "userCases", document, "comments")) //, where("state", "==", "CA"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //const userComments = [];
      // querySnapshot.forEach((doc) => {
      //   userComments.push(doc.data());
      // });
      // console.log("commentscomments", userComments);
      querySnapshot.docChanges().forEach((change) => { 
        const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
        if (source === "Local") {
          //comment_ = { ...comment_, ["created"]: "bib" } //serverTimestamp } ///if local no server timestamp, thus created is undefined => timestamp created to enable sort by crated
          ///return
        }
        // let comment_ = !comment_.created ? 
        //   { ...comment_, ["created"]: serverTimestamp } : ///if local no server timestamp, thus created is undefined => timestamp created to enable sort by crated
        //   comment_
        let comment_
        comment_ = { ...change.doc.data(), document: change.doc.id, isChanged: true } ///Object.assign({ document: change.doc.id }, change.doc.data())
        if (change.type === "added") {

          if (source === "Local") {
            comment_ = { ...comment_, ["created"]: Timestamp.now() } ///if local no server timestamp, thus created is undefined => timestamp created to enable sort by crated
          }
              setUserComments(
                produce((draft) => {
                  const commentExist = draft.some(comment => comment.document === change.doc.id) ///note: can't properly access userComments inside querysnapshot, but can instead access via draft setUserComments
                  if (!commentExist) {
                    draft.push(comment_)
                  }
                })
            ) 
          
        }
        if (change.type === "modified") {
            setUserComments(
              produce((draft) => {
                const index = draft.findIndex(comment => comment.document === change.doc.id) 
    
                if (index !== -1) draft[index] = comment_
              })
            ) 
        }
        if (change.type === "removed") {
            
        }
      });

        //   setUserComments(
        //     produce((draft) => {
        //       const caseExist = draft.some(userCase_ => userCase_.document === document)
        //       if (!caseExist) {
        //         draft.push(userCase)
        //       }
        //       if (caseExist) {
        //         const index = draft.findIndex(userCase_ => userCase_.document === document)
        //         console.log("userCase exist", userCase)
        //         if (index !== -1) draft[index] = userCase
        //       }
        //     })
        // )
    
    });

    // const unsubscribeListenToCase =
    //   onSnapshot(q,
    //     (doc) => {
    //       const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    //       // if (source === "Local") {
    //       //   return
    //       // }
    
    //       const userCase = {...doc.data(), document}

    //       console.log("")
    //     //   setUserComments(
    //     //     produce((draft) => {
    //     //       const caseExist = draft.some(userCase_ => userCase_.document === document)
    //     //       if (!caseExist) {
    //     //         draft.push(userCase)
    //     //       }
    //     //       if (caseExist) {
    //     //         const index = draft.findIndex(userCase_ => userCase_.document === document)
    //     //         console.log("userCase exist", userCase)
    //     //         if (index !== -1) draft[index] = userCase
    //     //       }
    //     //     })
    //     // )
    //     },
    //     (error) => {
    //       // console.log(error)
    //     });

    if (isUnsubscribe) {
      //unsubscribeListenToCase()
    }
          // console.log("xtt", sessionInfo.document)
  // const data = NewArtwork_w.listenToCase({ document: sessionInfo.document })
  // console.log("dataxxx", data)
  }


  const handleSetUserComments = () => {
    console.log("handleSetUserComments")
  }

    return (
      <userCommentsContext.Provider value={userCommentsValue}> 
          {children(userComments, setUserComments, handleSetUserComments)}
      </userCommentsContext.Provider>
    )
}