import './links.css';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useLocation, useNavigate} from 'react-router-dom'; //v6
import React, { useEffect, useState, useContext } from "react";
import * as LoginInput_w from './LoginInput_w';
import { initialStateOptions } from './StateOptions';
import * as generics from './Generics';
import { GridColums } from './ComponentStandards';
import { userUidContext, UserPresent } from './UserUidProvider';
import produce from "immer";
import { sessionInfoContext } from './SessionInfoProvider';

const NavLinkStyled = ({ to, text, onClick, isDisableStyling }) => {
	return (
		<NavLink
			className={({ isActive }) => 
			isActive && !isDisableStyling ? `nav-link nav-link-unselected` : `nav-link nav-link-selected`
			} //optimise: nav-link outside
			to={generics.stringToUrl(to)}
			onClick={onClick}
			>{text}
		</NavLink>
	)
}

export const ConditionalLinkWrapper = ( { underline, wrapIt, to, text, preTree, state, children } ) => 
wrapIt ? WithLink({ underline, to, text, preTree, state, children }) : children;

export const HomeLink = ({ children }) => {
    return (

			// <nav>
				<Link to="/"> { children } </Link>
			// </nav>
    )
}

export const HeaderLinks = ({ className }) => {

	const 
		{ setUserUid } = useContext(userUidContext),
		{ sessionInfo, setSessionInfo } = useContext(sessionInfoContext)

	///const
	const 
		linkText = `New`, // ${activeTemplate}`
		userPresent = UserPresent()

	const handleLogout = async() => { //optimise: move to LoginInput.jsx, and controlled via states?
		await LoginInput_w.SignOut()
		setUserUid(initialStateOptions[0])
	}

	const handleSessionInfo = async() => { 
		setSessionInfo(
			produce((draft) => {
				draft["open"] = !sessionInfo.open;
			})
		)
	}

    return ( ///optimise: map over gridcolumns
			<div className={`header-navlinks-wrapper ${className}`}> 
        	<div className={`header-navlinks`}>
            <nav>
							<GridColums
								//gridTemplateColumns={}
								gridAutoFlow={`column`}
								columnGap={`1.5vw`}
							>
								{/* <NavLinkStyled to="blog" text="Blog"></NavLinkStyled> */}
								{userPresent &&
								<>
								<NavLinkStyled to="//" text={"Logout"}
									onClick={() => handleLogout()}
								></NavLinkStyled> 
								<NavLinkStyled to="new destination" text={linkText}></NavLinkStyled>
								</>
								}
								{/* <NavLinkStyled to="/" text="Explore"></NavLinkStyled> */}
								{/* <NavLinkStyled to="/" text="Open" isDisableStyling={true}
									onClick={() => handleSessionInfo()}
								></NavLinkStyled> */}
								{/* <NavLinkStyled to="about" text="About"></NavLinkStyled>
								<NavLinkStyled to="contact" text="Contact"></NavLinkStyled> */}
							</GridColums>
            </nav>
					</div>
        </div>
    )
}

export const WithLink = ({ underline, to, text, preTree, state, children }) => {
	to = !generics.stringUndefined(preTree) ? `${preTree}/${to}` : to
	return (
		// <nav>
			<Link
				to={generics.stringToUrl(to)} //.toLowerCase()}
				className={`
					link
				`}
				style={{color: "inherit", display: "block", textDecoration: `${underline ? "underline" : " none"}`}}
				state={state}
				underline={"false"}
			> { text } { children }
			</Link> 
		// </nav>
	)
}
