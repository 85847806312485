import './app.css';
import './styling-standards.css';
import './user-cases.css';
import { useState, useEffect, useContext, memo,useRef, useCallback, useMemo } from 'react';
import { mapText, Button, PlusButton } from './ComponentStandards';
import produce from "immer";
import { useLongPress } from 'use-long-press';
import ContentEditable from 'react-contenteditable'
import { weekdays, hoursTypes, OpeningHours, TextualArray, TextualObjects, templates, templatesNames, templatesClassName, templatesVertical, templatesIsFixed, templatesShortcut, templatesSetup, contentEditableKeyDown, contentEditablePaste, templatesMapping, contentRestrictions, options, handleContentEditableChange, multiOptionsValues, multiStateButtons, multiOptions, textualContent } from './ItemForm';
import * as generics from './Generics';
import * as NewArtwork_w from './NewArtwork_w';
// import { templatesDataContext } from './DataProvider';
import { lockIcon, lockOpenIcon, onlyHeartAndUnion } from './Icons';
import ViewToggle from './ViewToggle';
import * as LoginInput_w from './LoginInput_w';
import { SocialMediaPostPreview, socialsList } from './SocialMediaPost';
import * as icons from './Icons';
import { templatesValue } from './ItemForm';
import Dropdown from 'react-dropdown';
import { ScrollBox, MemoScrollBox } from './Scroll';
import PhotoAlbum from "react-photo-album";
import { ShareComponent } from './ShareComponent';
import { Chat } from './Chat';
import { showContext } from './Modal';
import { initialValueShow } from './Modal';
import { itemsContext } from './ItemsProvider';
import { userCasesContext } from './UserCasesProvider';
import { sessionInfoContext } from './SessionInfoProvider';
import { userUidContext } from './UserUidProvider';
import { socialsIcons } from './SocialMediaPost';
import { refineContext } from './ItemsRefinedComponent';


const Editable = ({ className, label, userCase, contentEditableRef, refIndex, editableCallback }) => {

  useEffect(() => {
    contentEditableRef.current[refIndex].innerHTML = userCase[label]
  }, [])

  const handleOnChange = (e) => { ///note: target is undefined, blur blur capture the correct value! (why??)
    if (generics.containsHTML(e.target.value)) {
      contentEditableRef.current[refIndex].innerText = generics.removeHtmlTypeStrings(e.target.value)
    }
  }

  return (
    <ContentEditable ///optimise: implement contenteditable default
    // key={`${"bib"}${s}`}
    //className={`${templateClassName}`}
    //tagName={htmlTag}
    placeholder={`${label}?`}
    className={className}
    html={contentEditableRef.current[refIndex]?.innerHTML}
    innerRef={(el) => (contentEditableRef.current[refIndex] = el)}
    // placeholder={userPresent ? `add ${label}?` : null}
    //style={contentStyle}
    // disabled={!userPresent}
    //innerRef={contentEditableRef} ///(el) => (contentEditableRef.current[refIndex] = el)}
    onChange={(e) => handleOnChange(e)}//handleChange(e)}
    //onClick={() => !userPresent && handleTextClicked(label)} ///reactivate
    // onKeyDown={(e) => {
    //   const gotoNext = false
    //   contentEditableKeyDown(e, label, contentEditableRef.current, refIndex, gotoNext)} ///note: refindex due to nested data like dimensions
    // } 
    // onPaste={(e) => {
    //   const anchorOffset = window.document.getSelection()["anchorOffset"]
    //   const value = contentEditablePaste(e, label, contentEditableRef.current, refIndex, anchorOffset) ///if text is not restricted => return text, else empty string
    //   handleSetItems(label, document, value, s) ///note: handlecontenteditableblur not used: to mitigate upgrade at backend directly after paste. instead re-focus via setscrollto/wasref ensures update at blur
    //   const component = "itemtext"
    //   handleSetScrollTo(contentEditableRef.current[refIndex], refIndex, anchorOffset, component)
    //   }} 
    // ///note: onchange not used => to ensure correct focus/blur at custom keydown events
    onBlur={async() => editableCallback({ label: label, value: contentEditableRef.current[refIndex]?.innerHTML, isAdd: null })}
    //   {
    //   await NewArtwork_w.updateUserCase({ document: sessionInfo.document, label: label, value: contentEditableRef.current[refIndex]?.innerHTML, isAdd: null })
    //   // setUserCases(
    //   //   produce((draft) => {
    //   //     const index = draft.findIndex(userCase_ => userCase_.document === sessionInfo.document)
    //   //     if (index !== -1) draft[index][label] = contentEditableRef.current[refIndex]?.innerHTML
    //   //   })
    //   // )
    // }}
    > 
    </ContentEditable>
  )
}

export const ToggleUnion = ({ type }) => {

  const { sessionInfo, setSessionInfo } = useContext(sessionInfoContext)

  const show = type === "isOnlyHeart" && !!sessionInfo.userCases?.length || type === "isOnlyRedHeart" && !!sessionInfo.document && !!sessionInfo.documents.length > 0

  return (
    <div 
      className={`toggle-union-trips toggle-union-trips-${type} show--${show}`}
    //  style={{position: "absolute", bottom: "0.5em", right: "0.5em", zIndex: 1}}
    >
      <ViewToggle  ///note: rename to Toogle
        theme_={"4"}
        show={show}
        options={[onlyHeartAndUnion({isOnlyHeart: !sessionInfo[type], color: "green"}), onlyHeartAndUnion({isOnlyHeart: sessionInfo[type], color: "green"})]}
        callbackMultiStateButton={( label, value ) => {
            setSessionInfo(
              produce((draft) => {
                draft[type] = !draft[type] 
              })
            )
      }}
    /></div> 
  )
}

const ToggleShowMainComponent = ({ isEdit, type, isShowMain, toggleShowOpenCaseCallback }) => {

  const { sessionInfo } = useContext(sessionInfoContext)

  const [text, setText] = useState(["new trip"])

  const isShow = (!sessionInfo.document || sessionInfo?.type === type) && !isEdit

  const handleSetText = () => {
    let text = ""
    switch (true) {
      case !isShowMain && !!sessionInfo.document:
        text = [`Your ${type}`] 
        break;
      case !isShowMain && !sessionInfo.document:
        text = [`New ${type}`]
        break;
      case isShowMain:
        text = [`Show ${type}`]
        break;
      default:
      break;
    };
    setText(text)
  }

  useEffect(() => {
    setTimeout(handleSetText, 500)
  }, [isShowMain, sessionInfo])

  return (
    <div className={`${isShow ? "show fadein-05" : "hide fadein-05"}`}
     style={{position: "absolute", bottom: "0.5em", left: "0.5em", zIndex: 1}}
    >
      <ViewToggle  ///note: rename to Toogle
        theme_ = "4"
        show={isShow}
        options={text}
        callbackMultiStateButton={( label, value ) => toggleShowOpenCaseCallback({ isShowMain: !isShowMain }) }
    />
    </div> 
  )
}

const prepareUserCases = ({ userCases, items, activeTemplates }) => { ///add templatesFractions and itemData
  const userCases_ = userCases.map((userCase) => {
     let 
      templatesNamesCounts = {},
      imageData = []
     userCase.documents.map((document) => {
      const 
         item = items.find(item => item.document === document),
         template = item?.template,
          isImageExist = item?.media.some(media => media.mediaType === "image") ///isMediaExist = item?.media 
      if (isImageExist) { ///isMediaExist
         const mediaOverlay = item?.title
         let media = { ... item.media[0], mediaOverlay, document: item.document } ///imageSrc = item?.media.find(media => media.mediaType === "image").url
         imageData.push(media) ///imageSrc
       }
       if (templatesNamesCounts[template]) {
         templatesNamesCounts[template]++;
       } else {
         templatesNamesCounts[template] = 1;
       }
     })

     const documentsLenghts = userCase.documents.length
     let templatesFractions = generics.objMap(templatesNamesCounts, (v) => v / documentsLenghts, false)
  
     let prevSumFraction = 0, sumFraction = 0
     const 
       pieColors = (Object.entries(templatesFractions).map(([k, v], i) => {
         const prevTemplate = Object.entries(templatesFractions)[i - 1]?.[0]
         prevSumFraction = sumFraction
         sumFraction = sumFraction + v
           return (
           `${i === 0 ? 
               `${templatesSetup[k]?.["color"]} ${0}%,` : 
               `${templatesSetup[k]?.["color"]} ${100 * prevSumFraction}%,`
           }
           ${templatesSetup[k]?.["color"]} ${100 * sumFraction}%`
           )
       }))
         .join(",")

      const templatesFractionSum = Object.entries(templatesFractions).reduce((t, pair) => {
          const [key, value] = pair;
          return activeTemplates.includes(key) ?
            Number(generics.round(t + value, 2)) :  Number(generics.round(t, 2))
        }, 0)

       const userCase_ = {...userCase, imageData, pieColors, templatesFractionSum }
       return userCase_
     })
     return userCases_
}

export const UserCaseFilters = ({ backgroundColor, disabled, hoverOff, userCaseFiltersMap, userCaseFilters, userCaseFiltersParentCallback }) => { ///was: theme, userCaseFiltersHoverCallback

  const UserCaseFiltersButton = ({ userCaseFilter, isSelected }) => {

    const tripIcon = getUserCaseIcon({ type: userCaseFilter, color: "gold"})

    const substituteText = {
      plan: `trip plans`,
      experiences: "trip logs",
      itinerary: "trip itineraries",
    }
    const prefixs = {
      plan: "ongoing",
      itinerary: "by businesses",
    }
    const suffixs = {
      plan: "",
      experiences: "",
      itinerary: "",
    }

    const
      text = !hoverOff && substituteText?.[userCaseFilter] ? substituteText[userCaseFilter] : userCaseFilter,
      prefix = !hoverOff && prefixs?.[userCaseFilter] ? prefixs[userCaseFilter] : "",
      suffix = !hoverOff && suffixs?.[userCaseFilter] ? suffixs[userCaseFilter] : ""

    return (
      <div
        key={userCaseFilter}
        // onMouseEnter={() => userCaseFiltersHoverCallback({ filter: userCaseFilter, isHover: true })}
        // onMouseLeave={() => userCaseFiltersHoverCallback({ filter: "", isHover: false })}
      >
        <Button
          show={true}
          wrapperHeight={"auto"}
          wrapperWidth={"fit-content"}
          disabled={disabled}
          hoverOff={hoverOff}
          text={tripIcon}
          key={userCaseFilter}
          prefix={prefix}
          suffix={suffix}
          classTypes={["classical", "filter"]} ///bug: remove flickering of filter on select 
          className={`
            filter-box-filter
            ${hoverOff ? "hover-off" : "hover-on"}
          `}
          //wrapperStyle={{margin: "0.5vw"}}
          fontSize={1}
          theme={`${0} theme-filter`} ///optimise: filter "all" not white when theme black??
          isSelected={isSelected}
          innerRef={(el) => { ///note: ref used to enable use of !important
            if (el) {
              if (isSelected) {
              }
              else {
              el.style.setProperty('background-color', backgroundColor) 
              el.style.setProperty('border-color', backgroundColor, 'important') 
              }
            } 
          }}
        parentCallback={() => userCaseFiltersParentCallback({ filter: userCaseFilter })}
        ><span className={`filter-box-filter-text`}>{text}</span></Button>
      </div>
    )
  }

  return (
    <div className={"user-case-filters-container"}>
       <div className={"user-case-filters"}>
          {userCaseFiltersMap.map((userCaseFilter) => {
              return (
                <UserCaseFiltersButton
                  key={userCaseFilter}
                  userCaseFilter={userCaseFilter}
                  isSelected={userCaseFilters.includes(userCaseFilter)}
                />
              )
          }
          )}
      </div>
    </div>
    )
}

export const ChooseTripType = ({ isModal, isMain }) => {

  const { sessionInfo } = useContext(sessionInfoContext)

  if (!(!isMain && !sessionInfo.document)) { ///if not "!isMain" and "no open usercase"
    return null
  } 

  const 
    firstMessage = [
      "usefull tip",
    ],
    secondMessage = [
      "Planning your trip with your friends?",  
    ],
    thirdMessage = [ 
      "Or share your favorite places & experiences with others?",
    ],
    fourthMessage = [ 
      "For businesses",
    ]

  const 
    backgroundComp = isModal ? <div className={`background`}></div> : null,
    headerComp = isModal ? <h1>Start New Trip?</h1> : null

  return (
    <>
      {backgroundComp}
      <div className={`tips-wrapper ${isModal ? "modal" : ""}`}>
        <div className={"tips-container"}>
          <div className={"tips"}>
              {headerComp}
              <div className={"new-user-case-button-container"}>
                {mapText(secondMessage, "block", "", false, "center", "", "", "", "font-size-1")}
                <NewUserCase type={"plan"} text={`start your trip plan`}></NewUserCase>
              </div>
              <div className={"new-user-case-button-container"}>
                {mapText(thirdMessage, "block", "", false, "center", "", "", "", "font-size-1")}
                <NewUserCase type={"experiences"} text={"make a trip log"}>{}</NewUserCase>
              </div>
              <div className={"new-user-case-button-container"}>
                {mapText(fourthMessage, "block", "", false, "center", "", "", "", "font-size-1")}
                <NewUserCase type={"itinerary"} text={"new trip itinerary"}></NewUserCase>
              </div>    
            </div>
          </div>
        </div>
      </>
  )
}

export const NewUserCase = ({ type, text }) => {

  ///context
  const { setSessionInfo } = useContext(sessionInfoContext)

  const createNewUserCase = async({ type }) => {
    
    setSessionInfo( ///note: set sessionInfo => trigger opening of modal (with info) prior to createUser (slow...)
      produce((draft) => {
        draft.document = ""
        draft.password = ""
        draft.documents = []
        draft.type = type
        draft.isModal = true
        })
      );

    const [caseId, password] = await LoginInput_w.createUser()
    const userCase = { 
      caseId: caseId, 
      caseTitle: "", 
      by: "", 
      documents: [],
      color: generics.randomColor(),
      instagram: "",
      instagrams: [],
      facebooks: [],
      tiktoks: [],
      twitters: [],
      facebook: "",
      twitter: "",
      tiktok: "",
      type: type,
    }
    //handleSetItems(caseId) ///q: set only after backend write finished?
    const sessionDocument = await NewArtwork_w.WriteArtwork(userCase, "userCases")
  
    setSessionInfo( ///setSessionInfo({ document: sessionDocument, password: password, documents: [document] })
      produce((draft) => {
        draft.document = sessionDocument
        draft.password = password
        draft.documents = []
        draft.type = type
        })
      );
    }

  return (
    // <div style={{height: "2em", width: "fit-content"}}>
    <Button
      style={{fontWeight: "900"}} ///was: textWrap: "nowrap", 
      wrapperHeight={"auto"}
      wrapperWidth={"auto"}
      show={true}
      fontSize={1}
      text={text}
      classTypes={["classical", "min"]}
      className={`
     
      `}
      theme={4}
      // height={"100%"}
      parentCallback={() => createNewUserCase({ type })}
    />
    // </div>
  )
}

export const getUserCaseIcon = ({ type, color }) => {
    const userCaseIcon = {
      plan: icons.tripIcon({ icon: "p", color }), ///shakaSolidIcon({ color }),
      experiences: icons.tripIcon({ icon: "e", color }), //starSolidIcon({ color }),
      itinerary: icons.tripIcon({ icon: "i", color }) ///starSolidIcon({ color }),
    }
    return userCaseIcon[type]
}

export const SocialsModalChildComponent = ({ template, isChangePeopleLength, userCase, setSocialsCallback, setPeopleLengthCallback }) => {

  ///ref
  const socialsRef = useRef([])

  const [localPeopleLength, setLocalPeopleLength] = useState(1)
  const 
    activeTemplate = template,
    label = "socials"

  useEffect(() => {
    if (!!userCase?.["socials"] && !generics.objectIsEmpty(userCase["socials"])) {
      const localPeopleLength = Object.keys(userCase["socials"]).length
      setLocalPeopleLength(localPeopleLength)
      setPeopleLengthCallback({ peopleLength: localPeopleLength }) ///setPeopleLength(localPeopleLength)
    }
  }, [])

  const textualObjs = Array.from({ length: localPeopleLength }, (_, index) => index + 1).map((socialsObj, index) =>
    <div key={index} className={"socials-container"}>
      <span>{localPeopleLength > 1 ? index + 1 : ""}</span>

      <TextualObjects
          isPredefined={!generics.arrayEmpty(templatesValue[activeTemplate][label])} ///if true => predefined entries/objects, fixed lenght, and non-sortable
          activeTemplate={activeTemplate}
          placeholder={`Type ${label}`}
          requester={"userCase"}
          label={label}
          // value={"bib"}
          className={"socials"}
          currentRefs={socialsRef.current}
          refIndex={index}
          stateData={ ///optimise: include criteria for statedata as templatesData
            !!userCase?.["socials"] && ///socials key exist
            !generics.objectIsEmpty(userCase["socials"]) && ///and not empty object (default locally (here) is {}, but cannot be utilsed as stateData (not mappable))
            index + 1 <= Object.keys(userCase["socials"]).length ? ///stateData up until lenght of userCase.socials (from backend)
              generics.outerObjectToArray(userCase?.["socials"])[index] : 
              socialsList.map((social) => { return {[social]: ""} })}
          setTemplatesDataCallback={({ label, value }) => {
            setSocialsCallback({ index, value, peopleLength: localPeopleLength }) /// handleSetSocials({ index, value, peopleLength: localPeopleLength }) ///optimise: only use peopleLength not localPeopleLength
          }} 
      ></TextualObjects>
    </div>
  )
  return (
    <div className={"modal-socials-container"}>
      <div className={"intro-container"}>
        <span className={"first"}>Add Social Media Profiles</span>
        {isChangePeopleLength ? 
          <div className={"second"}>
            <span>For</span>
            <Dropdown 
              className={`
                theme-${0}
                input-radius
              `}
              controlClassName={`btn-subtle`}
              menuClassName={`btn-like`}
              value={localPeopleLength.toString()}
              options={Array.from({ length: 100 }, (_, index) => index + 1)} 
              placeholder={localPeopleLength}
              onChange={({ value }) => {
                setLocalPeopleLength(value)
                setPeopleLengthCallback({ peopleLength: value }) ///setPeopleLength(value)
              }}
            />
            <span>{localPeopleLength === 1 ? "person" : "persons"}</span>
          </div>
          : null
        }
      </div>
      <div className={"socials-wrapper"}>
        {textualObjs}
      </div>
    </div>
  )
}

const SocialsComponent = ({ userCase }) => { ///was: setSocialsCallback, setPeopleLengthCallback

  const 
    isExistSocials = !!userCase?.["socials"] && !generics.objectIsEmpty(userCase["socials"]),
    socialsMap = isExistSocials ? userCase.socials : { 0: socialsList.map((social) => { return {[social]: ""} }) }

  const socialsComponent = 
    <div className={"user-case-social-wrapper"}>
      <span>Social Media Profiles</span>
      {Object.entries(socialsMap).map(([key, array]) => ( ///was: key had-${index}
        <div key={`${key}`} className={"user-case-social-container"}> 
          {array.map((obj, index) => (
            Object.entries(obj)
              //.filter(([social, value]) => (isOpenUserCase && !isLocked) || !!value)
              .map(([social, value]) => 
                <div key={`${social}-${value}`} className={"user-case-social"}
                  // onClick={() => 
                  //   isOpenUserCase && 
                  //   !isLocked && 
                  //   toggleModalShow({ label: "socials", 
                  //     modalChildComponent: 
                  //       <SocialsModalChildComponent 
                  //         template={"userCase"}
                  //         isChangePeopleLength={true}
                  //         userCase={userCase} 
                  //         setSocialsCallback={setSocialsCallback}
                  //         setPeopleLengthCallback={setPeopleLengthCallback} //{({ peopleLength }) => setPeopleLength(peopleLength)}
                  //       />, 
                  //     modalChildName: "textualObjects"})
                  //   } ///optimise: changed to textualObject and implement in App.js
                >
                  {!!value ?  ///was: {(isOpenUserCase && !isLocked) || 
                    <>
                      <a 
                        //style={{pointerEvents: isLocked ? "all" : "none"}}
                        href={value}
                        target={"_blank"}>
                      </a>
                      {socialsIcons[socialsList.findIndex(social_ => social_ === social)]}
                    </>
                      : <></>
                  }
                  {/* <span className={"questionmark"}>{isOpenUserCase && !value && !isLocked ? "?" : ""}</span> */}
                </div>
              )
            ))}
          </div>
      ))}
    </div> 

  return socialsComponent
}

export const SocialMediaPostsModalChildComponent = ({ setTemplatesData, template, item, document, index }) => {

  ///ref
  const socialMediaPostsRef = useRef([])

  ///context
  ///const { setTemplatesData } = useContext(templatesDataContext)

  ///state 
  //const [socialMediaPosts, setSocialMediaPosts] = useState([])

  ///functions
  const handleSetTemplatesData = ({ template, trigger, document, label, index, value, startUpload, reset }) => {

    console.log("handleSetTemplatesDatax", template, trigger, document, label, index, value, startUpload, reset)
    // if (trigger === "item-text--updated") {
    //   handleSetItems({ label, value })
    // }
    //setIsUpdating(true) ///note: setisupdating implemented to ensure that statedata is copied to templatesdata update. if not [] would be copied ///optimise: way to make obsolute?
    const tempObj = { ///optimise: make specification implicit via map
      document: reset ? "" : document, ///optimse: pass document instead? ///note: if reset document is "", else document
      action: "update", 
      template: template, 
      label: label, 
      index: index, 
      value: value, 
      startUpload: startUpload, 
      reset: reset 
    } 
    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  }

  const 
    activeTemplate = template,
    label = "social media posts"
  return (

    <div className={"modal-socials-container"}>
      <div className={"intro-container"}>
        <span>Add Social Media</span>
      </div>
      {/* <div className={"socials-container"}> */}
      <TextualArray
          isSocialMediaPreview={true}
          activeTemplate={activeTemplate}
          placeholder={`link to social media. supported: instagram (post), tiktok (reel), facebook (video or reel) or youtube (video)`}
          requester={template}
          label={label}
          // value={""}
          className={"socials"}
          currentRefs={socialMediaPostsRef.current}
          refIndex={index}
          stateData={item?.["social media posts"] ? item?.["social media posts"] : [] // templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
           // templatesData.update.label, templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
           
           // !!userCase?.["socials"] && ///socials key exist
            // !generics.objectIsEmpty(userCase["socials"]) && ///and not empty object (default locally (here) is {}, but cannot be utilsed as stateData (not mappable))
            // index + 1 <= Object.keys(userCase["socials"]).length ? ///stateData up until lenght of userCase.socials (from backend)
            //   generics.outerObjectToArray(userCase?.["socials"])[index] : 
            //   socialsList.map((social) => { return {[social]: ""} })
            }
          setTemplatesDataCallback={({ label, value }) => {
                  const
                    // template = "userCase",
                    trigger = "text--change",
                    //document = item.document, ///note: or sessionInfo.document
                    //index = null, ///note: not used, but specified for completeness
                    startUpload = false,
                    reset = false 
                    //setSocialMediaPosts(value)
            
                    //handleSetSocials({ index, value }) ///optimise: only use peopleLength not localPeopleLength
                    handleSetTemplatesData({ template, trigger, document, label, index, value, startUpload, reset }) 
                }} 
      ></TextualArray>
      {/* </div> */}
    </div>
  )
}

const PhotoCollage = ({ caseId, imageData }) => {
  const photos = imageData.map((media, i) => { return { src: media.url, width: 1920, height: 1100, key: `${media.url}-${i}`}}) ///height: 1080
  return (
    <div className={`photo-collegage-wrapper`}>
      <div className={`photo-collegage-container`}>
      <PhotoAlbum key={caseId} layout={`${imageData.length > 3 ? "columns" : "rows"}`} photos={photos} />
      </div>
    </div>
  )
}

const EditUserCase = ({ setTemplatesData, userCase, isLocked, index }) => { ///was: theme

    ///ref
    /// const socialsRef = useRef([]);
    const contentEditableRef = useRef([]);

    ///context
    const 
      { sessionInfo } = useContext(sessionInfoContext),
      { show, setShow } = useContext(showContext)
      ///{ setTemplatesData } = useContext(templatesDataContext)

    ///state
    const 
      [socials, setSocials] = useState({}),
      //[currentImageCounter, setCurrentImageCounter] = useState(0),
      //[isHovered, setIsHovered] = useState(false),
      [peopleLength, setPeopleLength] = useState(1)

    ///const
    const 
      isOpenUserCase = userCase?.document === sessionInfo?.document,
      ///isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId),
      createdDuraction = generics.timeAgo(userCase.created),
      isExistSocialMediaData = userCase?.["social media posts"]?.length > 0

    ///data
    const 
      imageData = !!userCase.imageData ? userCase.imageData : [],
      socialMediaData = isExistSocialMediaData ? userCase["social media posts"].map((post) => { return { url: post, mediaType: "video" } }) : []
    let mediaData = []

    ///OPEN USERCASE:
    useEffect(() => {
      const filteredSocials =  generics.sliceObjectAtIndexs({ obj: socials, indexStart: 0, indexEnd: peopleLength }) ///note: use only socials corresponding to chosen peopleLength
      handleSetTemplatesData({ template: "userCase", trigger: "text--change", document: userCase.document, label: "socials", index, value: filteredSocials, startUpload: false, reset: false }) 
    }, [socials, peopleLength])

    //multioptions via modal action => 
    useEffect(() => {

      if (show.modal.requester === "userCase") {
        if (show.multiOptions.action === "check") {
          handleUpdateUserCase({ document:  show.multiOptions.document, label: show.multiOptions.label, value: show.multiOptions.dataSelected, isAdd: null })
        }
      }	
    }, [show.multiOptions.action]);
  
    const handleUpdateUserCase = async({ document, label, value, isAdd }) => {
      await NewArtwork_w.updateUserCase({ document, label, value, isAdd })
      setShow(initialValueShow)
    }        

  const UserCaseIntro = ({ userCase, isFrontPage, isEditable }) => {
    
    const 
      isSocialMediaPost = !!userCase["social media posts"] && userCase?.["social media posts"].length > 0, ///.some((post) => post.includes("instagram")),
      socialMediaPostUrl = isSocialMediaPost ? userCase["social media posts"][0] : [], ///.find((post) => post.includes("instagram")) : "",
      socialMediaPost = isSocialMediaPost &&
        <SocialMediaPostPreview
          className={"circle-preview"}
          url={socialMediaPostUrl}
        >
        </SocialMediaPostPreview>

    let
      caseTitle, by

    switch (true) {
    case isOpenUserCase && !isLocked:
      caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "add title?"
      by = !!userCase.by ? userCase.by : "by?"
      break;
    default:
      caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "no-title"
      by = !!userCase.by ? userCase.by : "anonymous"
    };

    return (
    <div key={userCase.caseId} className={`user-case-intro-container ${isEditable ? "editable--true" : "editable--false"} ${isOpenUserCase ? "open-case" : ""} ${isLocked ? "locked--true" : "locked--false"}`} ///was: ${isUserCaseSelected? "selected--true" : "selected--false"}
    //style={{backgroundImage: `url(${mediaData?.[isExistSocialMediaData ? socialMediaData.length : 0]?.url})`}}
    >
      <div className={`user-case-intro`}>
        {/* <div className={`user-case-type`}>
          <UserCaseFilters 
            hoverOff={true}
            backgroundColor={"gold"}
            userCaseFiltersMap={[userCase.type]}
            ///theme={theme} 
            userCaseFilters={[]}
          />
        </div> */}
        {isEditable && isOpenUserCase && !isLocked ?
          ["caseTitle", "by"].map((label, i) =>
            <Editable 
              className={"editable"}
              label={label}
              userCase={userCase}
              contentEditableRef={contentEditableRef} 
              refIndex={i}
              editableCallback={async({ label, value, isAdd }) =>
                await NewArtwork_w.updateUserCase({ document: sessionInfo.document, label, value, isAdd })
                // setUserCases(
                //   produce((draft) => {
                //     const index = draft.findIndex(userCase_ => userCase_.document === sessionInfo.document)
                //     if (index !== -1) draft[index][label] = contentEditableRef.current[refIndex]?.innerHTML
                //   })
                // )
              }
            >
            </Editable> 
          )
        :
        <>
          <span>{caseTitle}</span>
          <span>{by}</span>
          <span className={"created-duration"}>{`${createdDuraction} ago`}</span>
        </> }
      </div>
      {isFrontPage ? socialMediaPost : null}
      <PhotoCollage caseId={userCase.caseId} imageData={imageData}/>
      { isOpenUserCase && userCase?.documents?.length === 0 &&
            <span className={`user-case-span addinfo`}>
                Select your first desitnation to add to your trip
            </span>
      }
    </div>
    )
  }

  const shareComponent = [{
    mediaType: "shareComponent",
    url: "", ///not used
    component: 
      <ShareComponent 
        url={`www.siargao.live/${userCase.caseId}`}
        hashtag={`#siargao.live`}
      />
    }]

  const socialsComponent = [{
    mediaType: "socialsComponent",
    url: "", ///not used
    component: 
      <SocialsComponent 
        userCase={userCase}
        isLocked={isLocked}
        setSocialsCallback={({ index, value, peopleLength }) => handleSetSocials({ index, value, peopleLength })}
        setPeopleLengthCallback={({ peopleLength }) => setPeopleLength(peopleLength)}
      />
    }]

  const frontPageComponent = [{
     mediaType: "userCaseIntro",
     url: "", ///not used
     component: <UserCaseIntro userCase={userCase} isFrontPage={false} isEditable={false}/>
    }]

  mediaData = [...shareComponent, ...socialsComponent, ...socialMediaData.reverse(), ...frontPageComponent, ...imageData]

  ///on longpress =>
  const longPressCallback = (event, userCase) => { ///note: callback triggered at longpress true
    //handleUserCaseSelection({ userCase: userCase["context"], isLongPress: true })
  }

  ///on mousedown event => call handlelongpress with click or longpress info
  const longPressEvent = useLongPress(true ? longPressCallback : null, {
    onCancel: (event, userCase) => { ///note: equals onclick event
      //handleUserCaseSelection({ userCase: userCase["context"], isLongPress: false })
    },
    threshold: 150,
  });

    // useEffect(() => {
    //   let timeout
    //   if (isHovered && userCase.documents.length > 1) {
    //   timeout = setTimeout(() => {
    //     setCurrentImageCounter(currentImageCounter + 1);
    //   }, 1000);
  
    //   if (!!sessionInfo.document && !isLocked) { ///note: if !!sessionInfo.document (usercase open) => remove counter to avoid rerending of components (and unwanted abort of edit mode)
    //     clearTimeout(timeout);
    //     return
    //   }
  
    //   return () => {
    //     clearTimeout(timeout);
    //     //setCurrentImageCounter(0);
    //   };
    //   } else { 
    //     const imageCounter = isOpenUserCase ? userCase.documents.length - 1 : 0 ///if not hovered => for open user case show last added item
    //     clearTimeout(timeout)
    //     setCurrentImageCounter(imageCounter);
    //   }
    // }, [currentImageCounter, isHovered]);

  const toggleModalShow = ({ label, modalChildComponent, modalChildName }) => { ///optimise: move most to modal in app
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", 
      childValue = modalChildName ///note/risk: not correct - should be dynamic, based on who calls (textialarray or textualobjecs)
    const 
      modalChild = modalChildName,
      parameter = "label", labelValue = label
      //data = "data", dataValue = { ["bib"]: "hi" }

    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[component]["requester"] = "userCase"
        draft[modalChild][parameter] = labelValue
        //draft[modalChild][data] = dataValue
        draft[modalChild]["child"] = modalChildComponent
        // draft[modalChild]["payloadObjectName"] = payloadObjectName
        // draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
        // draft[modalChild]["preSelectedOptions"] = preSelectedOptions
        draft[modalChild]["document"] = ""
      })
    );
  }

  const handleSetSocials = useCallback(({ index, value, peopleLength }) => {
    setSocials(
      produce((draft) => {
      draft[index] = value
      })
    )
    setPeopleLength(peopleLength)
  }, [])

  const handleSetTemplatesData = ({ template, trigger, document, label, index, value, startUpload, reset }) => {

    // if (trigger === "item-text--updated") {
    //   handleSetItems({ label, value })
    // }
    //setIsUpdating(true) ///note: setisupdating implemented to ensure that statedata is copied to templatesdata update. if not [] would be copied ///optimise: way to make obsolute?
    const tempObj = { ///optimise: make specification implicit via map
      document: reset ? "" : document, ///optimse: pass document instead? ///note: if reset document is "", else document
      action: "update", 
      template: template, 
      label: label, 
      index: index, 
      value: value, 
      startUpload: startUpload, 
      reset: reset 
    } 
    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  }


  const Component = useCallback(({ setTemplatesData, userCase, isLocked, mediaData }) => { ///was: setSocialsCallback, setPeopleLengthCallback
  
    //   const 
    // introTextArray = [
    //   <>{caseTitle}</>, 
    //   "by",
    //   <>{by}</>
    // ],
    // introText = mapText(introTextArray, "block")


  return (
    <div key={userCase.caseId} className={`user-case-wrapper ${userCase.type}`}>
    {/* <div 
      className="background"
      style={{gridTemplateColumns: gridTemplateColumns}}
    >{templatesNamesFractionsComponents}
    </div> */}
      <div key={userCase.document} className={"user-case-container"}>
        { isOpenUserCase && !isLocked &&
          <div className={`user-case-social-media-buttons`}>
            <div className={`social-media-profiles-button`}>
              <Button
                    classTypes={["classical", "min"]}
                    isSelected={false}
                    wrapperHeight={"auto"}
                    wrapperWidth={"auto"}
                    height={"100%"}
                    width={"100%"}
                    fontSize={0}
                    show={true}
                    theme={`${0} theme-social-media`}
                    parentCallback={() => {
                      // isOpenUserCase && 
                      // !isLocked && 
                      toggleModalShow({ 
                        label: "socials", 
                        modalChildComponent: 
                          <SocialsModalChildComponent 
                            template={"userCase"}
                            isChangePeopleLength={true}
                            userCase={userCase} 
                            setSocialsCallback={({ index, value, peopleLength }) => handleSetSocials({ index, value, peopleLength })}
                            setPeopleLengthCallback={({ peopleLength }) => setPeopleLength(peopleLength)}
                          />, 
                        modalChildName: "textualObjects"})
                      } 
                    }
                    >add social media profiles
              </Button>
            </div>

            <div className={`social-media-button`}>
              <Button
                    classTypes={["classical", "min"]}
                    wrapperHeight={"auto"}
                    wrapperWidth={"auto"}
                    isSelected={false}
                    height={"100%"}
                    width={"100%"}
                    fontSize={0}
                    show={true}
                    theme={`${0} theme-social-media`}
                    parentCallback={() => {
                        toggleModalShow({ 
                          label: "social media posts", 
                          modalChildComponent: 
                            <SocialMediaPostsModalChildComponent
                              template={"userCase"}
                              item={userCase}
                              document={userCase.document}
                              setTemplatesData={setTemplatesData}
                            />, 
                          modalChildName: "textualArray"})}
                    }
                    >Add SoMe
              </Button>
            </div>
          </div>
        }

        {/* <PlusButton  
          value={"user-case"}
          isPlus={!isUserCaseSelected}
          show={true}
          color={"gold"}
          parentCallback={({ }) => handleUserCaseSelection({ userCase: userCase, isLongPress: true })}
        /> */}
        <div 
          // {...longPressEvent(userCase)}
          className={`user-case`} ///was: ${isUserCaseSelected? "selected--true" : "selected--false"}
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
        > 
          {/* <Chat/> setTemplatesData={setTemplatesData} */}
          <UserCaseIntro 
            userCase={userCase} 
            isFrontPage={true} 
            isEditable={true}
            setTemplatesData={setTemplatesData}
          />

          <ScrollBox  
                // templatesData={templatesData} ///note: not used in this context
                boxId={1}
                document={userCase.document}
                data={mediaData} ///{data.media}
                mediaCountOffset={isExistSocialMediaData ? socialMediaData.length + 3 : 3} ///note: + 2 due to SocialsComponent and UserCaseIntro
                state={{document: userCase.document, documentIndex: 1}}
                // mapOnlyBoxId={""}
                groupSize={[1, 1]}
              //	mapOnlyBoxId={0} ///default (undefined or "") => map both scrollbox
                to={""}
                // overlayContent={["overlay"]}
                social="" ///{data.social} ///data.social
                setup={
                    [
                      {horizontal: [false, true]},
                      {groupSize: [1, 1]},
                      {scrollSnap: [true, true]},
                      {onHoverPartnerScrollToMe: [true, false]},
                      {cancelOnHover: [true, false]},
                      {withScrollButtons: [false, false]},
                      {animate: [true, false]},
                    ]}
                //animate={true}
                focusItemDocumentCallback={({ document }) => void 0}
                // hoverParentCallback={}
                //parentCallback={(elementName, groupName, groupSize, containerId, horizontal) => scrollBoxElementClicked(elementName, groupName, groupSize, containerId, horizontal)}
              >
          </ScrollBox>

          {/* { isOpenUserCase && userCase?.documents?.length === 0 &&
            <span className={`user-case-span addinfo`}>
                select {getUserCaseIcon({ type: [userCase?.type], color: "black"})} 
                  below to add first destination to your trip
            </span>
          } */}


          {/* <span className={`user-case-span count`}>{mediaData.length}</span>  */}
          {/* <span className={`user-case-span count`}>{`${(currentImageCounter % mediaData.length)+1} of ${mediaData.length}`}</span> */}

          {/* <div className={`bar`}
                  style={{backgroundImage: `linear-gradient(to right, ${userCase.pieColors})`}}
          ></div> */}
          {/* <div className={`background odd ${(currentImageCounter % mediaData.length) % 2 !== 0 ? "show fadein-05" : "hide fadeout-05"}`}
            style={{backgroundImage: `url(${mediaData[currentImageCounter % mediaData.length]})`}}
          ></div>
          <div className={`background even ${(currentImageCounter % mediaData.length) % 2 === 0 ? "show fadein-05" : "hide fadeout-05"}`}
            style={{backgroundImage: `url(${mediaData[currentImageCounter % mediaData.length]})`}}
          ></div> */}
          {/* <span className={`user-case-span count`}>{`${(currentImageCounter % mediaData.length)+1} of ${mediaData.length}`}</span>  */}
        </div>
      </div>

      {/* <div className={"user-case-footer-container"}>
        <div className={`heart-container ${isUserCaseSelected ? "show fadein-05" : "hide fadeout-05"}`}>
          <div className={`heart`}
          style={{position: "absolute"}}
          >
            {getUserCaseIcon({ type: [userCase?.type], color: "white"})}
                <div className={`heart`}
              style={{transform: "scale(0.8)", position: "absolute", left: "0%", top: "0"}}
              >
                {getUserCaseIcon({ type: [userCase?.type], color: userCase?.color})}
              </div>
          </div>
        </div>
      </div> */}
    </div>

  )
  }, [setTemplatesData, userCase, isLocked, mediaData]) ///was: peopleLength, currentImageCounter
  Component.displayName = 'user-case-component';
  return (
        <Component 
          userCase={userCase}
          isLocked={isLocked}
          mediaData={mediaData}
          setTemplatesData={setTemplatesData}
        />
)}

const SocialMediaPost = ({ userCase, isFrontPage }) => {

  const 
   isSocialMediaPost = 
     isFrontPage && 
     !!userCase["social media posts"] && 
     userCase["social media posts"].some((post) => post.includes("instagram")),
   socialMediaPostUrl = isSocialMediaPost ? userCase["social media posts"].find((post) => post.includes("instagram")) : ""
 const component = isSocialMediaPost ?
   <SocialMediaPostPreview
     className={"circle-preview"}
     url={socialMediaPostUrl}
   >
   </SocialMediaPostPreview> : []
   return <>{component}</>
}
const MemoSocialMediaPost = memo(SocialMediaPost)
MemoSocialMediaPost.displayName = 'SocialMediaPost';

const UserCaseIntro = ({ imageData, isLocked, userCase, isFrontPage, isEditable }) => {

  // const socialMediaPost = useCallback(() => {
  //   const 
  //     isSocialMediaPost = 
  //       isFrontPage && 
  //       !!userCase.socialMediaPosts && 
  //       userCase.socialMediaPosts.some((post) => post.includes("instagram")),
  //     socialMediaPostUrl = isSocialMediaPost ? userCase.socialMediaPosts.find((post) => post.includes("instagram")) : ""
  //   const component = isSocialMediaPost ?
  //     <SocialMediaPostPreview
  //       className={"circle-preview"}
  //       url={socialMediaPostUrl}
  //     >
  //     </SocialMediaPostPreview> : null
  //     return <>{component}</>
  // }, [userCase])

  const
    isOpenUserCase = false,
    createdDuraction = generics.timeAgo(userCase.created)

  const memoUserCase = useMemo(() => userCase)

  let caseTitle, by

  switch (true) {
  case isOpenUserCase && !isLocked:
    caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "add title?"
    by = !!userCase.by ? userCase.by : "by?"
    break;
  default:
    caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "no-title"
    by = !!userCase.by ? userCase.by : "anonymous"
  };

  return (
  <div key={`user-case-${userCase.caseId}-is-editable-${isEditable}`} className={`user-case-intro-container ${isEditable ? "editable--true" : "editable--false"} ${isOpenUserCase ? "open-case" : ""} ${isLocked ? "locked--true" : "locked--false"}`} ///was: ${isUserCaseSelected? "selected--true" : "selected--false"}
  //style={{backgroundImage: `url(${mediaData?.[isExistSocialMediaData ? socialMediaData.length : 0]?.url})`}}
  >
    <div className={`user-case-intro`}>
      {/* <div className={`user-case-type`}>
        <UserCaseFilters 
          hoverOff={true}
          backgroundColor={"gold"}
          userCaseFiltersMap={[userCase.type]}
          //theme={theme} 
          userCaseFilters={[]}
        />
      </div> */}
      <>
        <span>{caseTitle}</span>
        <span>{by}</span>
        <span className={"created-duration"}>{`${createdDuraction} ago`}</span>
      </> 
    </div>
    <MemoSocialMediaPost userCase={memoUserCase} isFrontPage={isFrontPage}/>
    <PhotoCollage caseId={userCase.caseId} imageData={imageData}/>
    { isOpenUserCase && userCase?.documents?.length === 0 &&
          <span className={`user-case-span addinfo`}>
              select {getUserCaseIcon({ type: [userCase?.type], color: "black"})} 
              below to add first destination to your trip
          </span>
    }
  </div>
  )
}

const MainUserCase = ({ userCase, setFocusItemDocument }) => { ///note: isLocked excluded and set locally

  // const { refine, setRefine } = useContext(refineContext)

  const Component = useCallback(({ userCase }) => {

  

      ///const
      const 
        // isOpenUserCase = false,
        // createdDuraction = generics.timeAgo(userCase.created),
        isExistSocialMediaData = userCase?.["social media posts"]?.length > 0,
        isLocked = true

      ///data
      const 
        imageData = !!userCase.imageData ? userCase.imageData : [],
        socialMediaData = isExistSocialMediaData ? userCase["social media posts"].map((post) => { return { url: post, mediaType: "video" } }) : []
      let mediaData = []

      ///const memoSocialMediaPosts = useMemo(() => userCase.socialMediaPosts)
  
    // const UserCaseIntro = ({ userCase, isFrontPage, isEditable }) => {

    //   // const socialMediaPost = useCallback(() => {
    //   //   const 
    //   //     isSocialMediaPost = 
    //   //       isFrontPage && 
    //   //       !!userCase.socialMediaPosts && 
    //   //       userCase.socialMediaPosts.some((post) => post.includes("instagram")),
    //   //     socialMediaPostUrl = isSocialMediaPost ? userCase.socialMediaPosts.find((post) => post.includes("instagram")) : ""
    //   //   const component = isSocialMediaPost ?
    //   //     <SocialMediaPostPreview
    //   //       className={"circle-preview"}
    //   //       url={socialMediaPostUrl}
    //   //     >
    //   //     </SocialMediaPostPreview> : null
    //   //     return <>{component}</>
    //   // }, [userCase])

    //   let caseTitle, by
  
    //   switch (true) {
    //   case isOpenUserCase && !isLocked:
    //     caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "add title?"
    //     by = !!userCase.by ? userCase.by : "by?"
    //     break;
    //   default:
    //     caseTitle = !!userCase.caseTitle ? userCase.caseTitle : "no-title"
    //     by = !!userCase.by ? userCase.by : "anonymous"
    //   };
  
    //   return (
    //   <div key={userCase.caseId} className={`user-case-intro-container ${isEditable ? "editable--true" : "editable--false"} ${isOpenUserCase ? "open-case" : ""} ${isLocked ? "locked--true" : "locked--false"}`} ///was: ${isUserCaseSelected? "selected--true" : "selected--false"}
    //   //style={{backgroundImage: `url(${mediaData?.[isExistSocialMediaData ? socialMediaData.length : 0]?.url})`}}
    //   >
    //     <div className={`user-case-intro`}>
    //       <div className={`user-case-type`}>
    //         <UserCaseFilters 
    //           hoverOff={true}
    //           backgroundColor={"gold"}
    //           userCaseFiltersMap={[userCase.type]}
    //           //theme={theme} 
    //           userCaseFilters={[]}
    //         />
    //       </div>
    //       <>
    //         <span>{caseTitle}</span>
    //         <span>{by}</span>
    //         <span className={"created-duration"}>{createdDuraction}</span>
    //       </> 
    //     </div>
    //     <MemoSocialMediaPost userCase={memoSocialMediaPosts} isFrontPage={isFrontPage}/>
    //     <PhotoCollage caseId={userCase.caseId} imageData={imageData}/>
    //     { isOpenUserCase && userCase?.documents?.length === 0 &&
    //           <span className={`user-case-span addinfo`}>
    //               select {getUserCaseIcon({ type: [userCase?.type], color: "black"})} 
    //               below to add first destination to your trip
    //           </span>
    //     }
    //   </div>
    //   )
    // }
  
    const shareComponent = [{
      mediaType: "shareComponent",
      url: "", ///not used
      component: 
        <ShareComponent 
          url={`www.siargao.live/${userCase.caseId}`}
          hashtag={`#siargao.live`}
        />
      }]
  
    const socialsComponent = [{
      mediaType: "socialsComponent",
      url: "", ///not used
      component: 
        <SocialsComponent 
          ///index={index}
          userCase={userCase}
          isLocked={isLocked}
        />
      }]
  
    const frontPageComponent = [{
       mediaType: "userCaseIntro",
       url: "", ///not used
       component: 
        <UserCaseIntro 
          userCase={userCase} 
          isFrontPage={false} 
          isEditable={false}
          imageData={imageData}
          isLocked={isLocked}
        />
      }]
  
    mediaData = [...shareComponent, ...socialsComponent, ...socialMediaData.reverse(), ...frontPageComponent, ...imageData]
  
  const 
    memoDocument = userCase.document,
    memoMediaData = mediaData,
    memoMediaCountOffset = isExistSocialMediaData ? socialMediaData.length + 3 : 3,
    memoState = {document: userCase.document, documentIndex: 1},
    memoGroupSize = [1, 1],
    memoSetup = [
      {horizontal: [false, true]},
      {groupSize: [1, 1]},
      {scrollSnap: [true, true]},
      {onHoverPartnerScrollToMe: [true, false]},
      {cancelOnHover: [true, false]},
      {withScrollButtons: [false, false]},
      {animate: [true, false]},
    ]
    

  const scrollbox =
    <MemoScrollBox
    boxId={1}
    document={memoDocument}
    data={memoMediaData} ///{data.media}
    mediaCountOffset={memoMediaCountOffset} ///note: + 2 due to SocialsComponent and UserCaseIntro
    //geoInfo={""} ///{geoLocation: data.geoLocation}}
    state={memoState}
    // mapOnlyBoxId={""}
    groupSize={memoGroupSize}
  //	mapOnlyBoxId={0} ///default (undefined or "") => map both scrollbox
    to={""}
    // overlayContent={["overlay"]}
    social="" ///{data.social} ///data.social
    setup={memoSetup}
    //animate={true}
    // hoverParentCallback={}
    focusItemDocumentCallback={( data ) => {
      setFocusItemDocument(
          produce((draft) => { 
            Object.entries(data) ///note: set state for each param (passed via function)
              .filter(([param, v]) => param !== "") ///exclude param value from set state
              .map(([param, v], i) => draft[param] = v )
        }))
        // produce((draft) => {
        //   draft.document = document
        //   draft.isMapFocus = true
        // })
    }}
    //parentCallback={(elementName, groupName, groupSize, containerId, horizontal) => scrollBoxElementClicked(elementName, groupName, groupSize, containerId, horizontal)}
  >
    </MemoScrollBox>

  return (
    <div key={userCase.caseId} className={`user-case-wrapper ${userCase.type}`}>
      <div className={"user-case-container"}>
        <div 
          // {...longPressEvent(userCase)}
          className={`user-case`} ///was: ${isUserCaseSelected? "selected--true" : "selected--false"}
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
        > 
          {/* <Chat/> ///note: setTemplatesData must be added...  */} 
          <UserCaseIntro 
            userCase={userCase} 
            isFrontPage={true} 
            isEditable={true}
            imageData={imageData}
            isLocked={isLocked}
            
          />
          {scrollbox}

          {/* { isOpenUserCase && userCase?.documents?.length === 0 &&
            <span className={`user-case-span addinfo`}>
                select {getUserCaseIcon({ type: [userCase?.type], color: "black"})} 
                  below to add first destination to your trip
            </span>
          } */}


          {/* <span className={`user-case-span count`}>{mediaData.length}</span>  */}
          {/* <span className={`user-case-span count`}>{`${(currentImageCounter % mediaData.length)+1} of ${mediaData.length}`}</span> */}

          {/* <div className={`bar`}
                  style={{backgroundImage: `linear-gradient(to right, ${userCase.pieColors})`}}
          ></div> */}
          {/* <div className={`background odd ${(currentImageCounter % mediaData.length) % 2 !== 0 ? "show fadein-05" : "hide fadeout-05"}`}
            style={{backgroundImage: `url(${mediaData[currentImageCounter % mediaData.length]})`}}
          ></div>
          <div className={`background even ${(currentImageCounter % mediaData.length) % 2 === 0 ? "show fadein-05" : "hide fadeout-05"}`}
            style={{backgroundImage: `url(${mediaData[currentImageCounter % mediaData.length]})`}}
          ></div> */}
          {/* <span className={`user-case-span count`}>{`${(currentImageCounter % mediaData.length)+1} of ${mediaData.length}`}</span>  */}
        </div>
      </div>

      {/* <div className={"user-case-footer-container"}>
        <div className={`heart-container ${isUserCaseSelected ? "show fadein-05" : "hide fadeout-05"}`}>
          <div className={`heart`}
          style={{position: "absolute"}}
          >
            {getUserCaseIcon({ type: [userCase?.type], color: "white"})}
                <div className={`heart`}
              style={{transform: "scale(0.8)", position: "absolute", left: "0%", top: "0"}}
              >
                {getUserCaseIcon({ type: [userCase?.type], color: userCase?.color})}
              </div>
          </div>
        </div>
      </div> */}
    </div>
  )
  }, [userCase]) ///was: peopleLength, currentImageCounter
  Component.displayName = 'user-case-component';

  return (
        <Component userCase={userCase}/>
)}
const MemoMainUserCase = memo(MainUserCase)

const UserCaseFooter = ({ userCase, children }) => {

  const { sessionInfo } = useContext(sessionInfoContext)

  const isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId)
  return (
    <div className={`user-case-footer-container ${isUserCaseSelected ? "selected--true" : "selected--false"}`}>
      {/* <div className={"user-case-trip-icon-container"}> */}
      { children }
        {getUserCaseIcon({ type: [userCase?.type], color: isUserCaseSelected ? userCase?.color : "gold"})}
 
      {/* </div> */}
      {/* <div className={`heart-container ${isUserCaseSelected ? "show fadein-05" : "hide fadeout-05"}`}>
        <div className={`heart`}
        style={{position: "absolute"}}
        >
          {getUserCaseIcon({ type: [userCase?.type], color: "white"})}
              <div className={`heart`}
            style={{transform: "scale(0.8)", position: "absolute", left: "0%", top: "0"}}
            >
              {getUserCaseIcon({ type: [userCase?.type], color: userCase?.color})}
            </div>
        </div>
      </div> */}
    </div>
  )
}

const UserCasePlusButton = ({ userCase }) => {

  const { sessionInfo, setSessionInfo } = useContext(sessionInfoContext)

  const isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId)

  const handleUserCaseSelection = ({ userCase, isLongPress }) => {
    const isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId) 
    if (isLongPress) {
      setSessionInfo(
        produce((draft) => {
          if (!isUserCaseSelected) {
          draft.hasOwnProperty("userCases") ?
            draft["userCases"].push(userCase) :
            draft["userCases"] = [userCase]
          }
          if (isUserCaseSelected) {
            const index = draft.userCases.findIndex(userCase_ => userCase_.caseId === userCase.caseId)
            if (index !== -1) draft.userCases.splice(index, 1)
          }
        })
      )
    }
    
    if (!isLongPress) {
      setSessionInfo(
        produce((draft) => {
          //const isUserCaseSelected = draft.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId)
          if (!isUserCaseSelected) {
            draft["userCases"] = [userCase] 
          }
          if (isUserCaseSelected) {
            const index = draft.userCases.findIndex(userCase_ => userCase_.caseId === userCase.caseId)
            if (index !== -1) draft.userCases.splice(index, 1)
          }
        })
      )
    }
  }

  return (
    <PlusButton  
      value={"user-case"}
      isPlus={!isUserCaseSelected}
      show={true}
      color={"gold"}
      parentCallback={({ }) => handleUserCaseSelection({ userCase: userCase, isLongPress: true })}
    />
  )
}

const UserCaseWrapper = ({ setTemplatesData, userCase, isMain, isLocked, setFocusItemDocument }) => {

  const { sessionInfo } = useContext(sessionInfoContext)

  const isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.caseId === userCase.caseId)
  
  const
    { templatesFractionSum, ...userCase_ } = userCase, ///note: userCase_ without templatesFractionSum, as templatesFractionSum not used in UserCase components
    memoUserCase = useMemo(() => userCase_),
    memoSetFocusItemDocument = useMemo(() => setFocusItemDocument),
    bar = 
      <div className={`bar`}
        style={{backgroundImage: `linear-gradient(to right, ${userCase.pieColors})`}}
      ></div>

  const MainUserCaseComponent = useCallback(({ userCase, isLocked, setFocusItemDocument }) => {
    return (
      <MemoMainUserCase 
        userCase={userCase}
        isLocked={isLocked}
        setFocusItemDocument={setFocusItemDocument}
      />
    )
  },[])

  const component = 
    <div className={`
      user-case-wrapper-outer 
      ${isUserCaseSelected? "selected--true" : "selected--false"}
    `}>  
      {bar}
      {isMain ?
        <MainUserCaseComponent
          userCase={memoUserCase}
          isLocked={isLocked}
          setFocusItemDocument={memoSetFocusItemDocument}
        /> 
        :
        <EditUserCase
          userCase={userCase_}
          isLocked={isLocked}
          setTemplatesData={setTemplatesData}
        /> 
      }
      <UserCaseFooter
        userCase={userCase}
      >
        <UserCasePlusButton
          userCase={userCase} 
        />
      </UserCaseFooter>

    </div> 

  return (
    <>{component}</>
  )
}

const EditUserCaseCollectionComponent = ({ templatesData, setTemplatesData, isMapUserCases, isLocked, isMain, userCaseFilters }) => { ///was: theme

    const 
      { userCases } = useContext(userCasesContext),
      { sessionInfo } = useContext(sessionInfoContext),
      { items } = useContext(itemsContext)
      ///{ templatesData } = useContext(templatesDataContext)
  
    const 
      activeTemplates = templatesData.show.activeTemplates,
      openUserCase =
        // userCases.length > 0 && 
        !!sessionInfo.document &&
        userCases.some(userCase_ => userCase_.document === sessionInfo.document) ?
          [userCases.find(userCase_ => userCase_.document === sessionInfo.document)] :
          []

    const userCase_ = prepareUserCases({ userCases: openUserCase, items, activeTemplates })

    const userCaseCollection = userCase_

  return userCaseCollection
    //.filter(userComment => userComment.document === document)
    //.filter((userCase) => !isMain || (isMain && userCaseFilters.length > 0 ? userCaseFilters.includes(userCase?.type) : true))
    .map((userCase) => 
        <UserCaseWrapper
          key={`user-case-wrapper-${userCase.caseId}`}
          userCase={userCase}
          isMain={isMain}
          isLocked={isLocked}
          setTemplatesData={setTemplatesData}
        />
    )
}

const MainUserCaseCollectionComponent = ({ templatesData, type, isMapUserCases, isLocked, isMain, userCaseFilters, setFocusItemDocument }) => { ///was: theme
    
  const 
  { userCases } = useContext(userCasesContext),
  { items } = useContext(itemsContext)
  ///{ templatesData } = useContext(templatesDataContext)
  
  const activeTemplates = templatesData.show.activeTemplates

  const getUserCasesCollections = useCallback(() => {
    if (!isMapUserCases) { return []} 
    const userCases_ = prepareUserCases({ userCases, items, activeTemplates }) ///add templatesFractions and itemData
    const userCasesRest =  [
      ...userCases_
        /// .filter(userCase_ => userCase_.document !== sessionInfo.document) ///note: decision to include open usercase in userCasesRest: 1) user with open case, even at logged in, will be able to experience their open-case as other users experience it, its position in the list 2) make dependency on sessionInfo obsolute (as open case is part of userCases, the ability to filter out open case currently depend on sessionInfo)
        .filter(userCase_ => userCase_.type === type)
        .filter(userCase_ => userCase_.documents.length > 0)
        .sort(( a, b ) => generics.sortDate(b, a, "created"))
        .sort(( a, b ) => b.templatesFractionSum - a.templatesFractionSum)
      ] 
    return userCasesRest /// was: [userCasesRest, openUserCase] ///config-1
  }, [userCases, items, isMapUserCases, activeTemplates])  

  const userCaseCollection_ = getUserCasesCollections()

  //const ChildWrapper = useCallback(({ children }) => <>{children}</>, [userCases, userCaseFilters])

  return userCaseCollection_
    ///.filter(userComment => userComment.document === document)
    .filter((userCase) => !isMain || (isMain && userCaseFilters.length > 0 ? userCaseFilters.includes(userCase?.type) : true))
    .map((userCase) => {
        return (
          //<ChildWrapper>
            <UserCaseWrapper
              key={`user-case-collection-${userCase.caseId}`}
              userCase={userCase}
              isMain={isMain}
              isLocked={isLocked}
              setFocusItemDocument={setFocusItemDocument}
            />
          //</ChildWrapper>
        )
    })
}

const LockComponent = ({ isMain, isLocked, setIsLocked }) => {

    ///context
    const { sessionInfo } = useContext(sessionInfoContext)

    const lockComponent = !isMain && !!sessionInfo?.document ?
        <div className={`lock-container`}>
          <div className={`lock`}
            onClick={(() => setIsLocked(!isLocked))}
          >
            {isLocked ? lockIcon : lockOpenIcon }
          </div> 
        </div> 
        : null
    
    return lockComponent
}

const UserCaseCollectionWrapperComponent = ({ templatesData, setTemplatesData, isEdit, type, isMapUserCases, isShowMain, isMain, setIsShowMain, setFocusItemDocument }) => {

  ///state
  const 
    [isLocked, setIsLocked] = useState(true),
    [userCaseFilters, setUserCaseFilters] = useState([])

  const handleUserCaseFilters = ({ filter }) => {
    const isSelected = userCaseFilters.includes(filter)
    setUserCaseFilters(
      produce((draft) => {
        if (!isSelected) {
          draft.push(filter)
        }
        if (isSelected) {
          const index = draft.findIndex(filter_ => filter_ === filter) 
          if (index !== -1) draft.splice(index, 1)
        }
      })
    );
  }

  const 
    headerText = isMain ? "Trips Explorer" : "Your Trip",
    subHeaderText = `${type}` 
    
  const component =
      <div className={`${isMain ? "flip-card-front" : "flip-card-back"}`}>
      <div className={`user-cases-container ${isMain ? "main" : "open-case"}`}>             
        <div className={`user-cases-container-header-wrapper theme-${0}`}>
          {/* {i === 0 && */}
          <>
            <div className={`user-cases-container-header-container`}>
              {/* <span className={`header`}>{headerText}</span> */}
              <div className={`sub-header-container`}>
                <span className={`sub-header select ${isMapUserCases ? "show fadein-05" : "hide fadeout-05"}`}>{subHeaderText}</span>
                <span className={`sub-header ${!isMapUserCases ? "show fadein-05" : "hide fadeout-05"}`}>Loading...</span>
              </div>
            </div>
            {/* <div className={`filters`}> ///note: userCaseTypes seperated into seperate panels, thus filter redundant
              {isMain &&
                <UserCaseFilters 
                  backgroundColor={"gold"}
                  userCaseFiltersMap={["plan", "experiences", "itinerary"]}
                  ///theme={theme} 
                  userCaseFilters={userCaseFilters}
                  userCaseFiltersParentCallback={({ filter }) => handleUserCaseFilters({ filter })}
                /> 
              }
            </div> */}
          </>
          {/* } */}
        </div>
        {/* <ToggleUnion isOnlyHeart={isMain }/> */}
        <ToggleShowMainComponent isEdit={isEdit} type={type} isShowMain={isShowMain} toggleShowOpenCaseCallback={({ isShowMain }) => setIsShowMain(isShowMain)}/>
        <div className={`
          user-cases ${isMain ? "main" : "open-case"}
          ${isMapUserCases ? "show fadein-05" : "hide fadeout-05"}
          `}
          style={{overflowX: isMain ? "scroll" : "visible"}}
        >
          <ChooseTripType 
            isModal={false}
            isMain={isMain}
          />
          { isMain ? 
            <MainUserCaseCollectionComponent
              key={`user-case-collection-${isMain ? "main": "open-case"}`}
              type={type}
              isLocked={isLocked}
              isMapUserCases={isMapUserCases}
              ///userCaseCollection={userCaseCollection}
              isMain={isMain}
              userCaseFilters={userCaseFilters}
              templatesData={templatesData}
              setFocusItemDocument={setFocusItemDocument}
            /> 
            :
            <EditUserCaseCollectionComponent
              key={`user-case-collection-${isMain ? "main": "open-case"}`}
              isLocked={isLocked}
              isMapUserCases={isMapUserCases}
              ///userCaseCollection={userCaseCollection} 
              isMain={isMain}
              userCaseFilters={userCaseFilters}
              templatesData={templatesData} setTemplatesData={setTemplatesData}
              setFocusItemDocument={setFocusItemDocument}
          /> 
          }
        </div>
        <LockComponent
          isMain={isMain}
          isLocked={isLocked}
          setIsLocked={setIsLocked}
        />
      </div> 
      </div> 

  return (
    <>{ component }</>
  ) 
}
export const MemoUserCaseCollectionWrapperComponent = memo(UserCaseCollectionWrapperComponent)

export const UserCasesComponent = ({ templatesData, setTemplatesData, isEdit, type, isMapUserCases, setFocusItemDocument }) => { ///was: theme

  ///state
  const [isShowMain, setIsShowMain] = useState(isEdit) ///note: isEdit is a least disruptive way to introduce new/edit usercase as standalone component (instead of flipcard)
  // [currentImageCounter, setCurrentImageCounter] = useState(0),

  const 
    memoSetIsShowMain = useMemo(() => setIsShowMain),
    memoSetFocusItemDocument = useMemo(() => setFocusItemDocument)

  const userCasesCollections = [[], []] 

  const ChildWrapper = useCallback(({ children }) => <>{ children }</>, [isMapUserCases, isShowMain])

  return (
	  // <Wrapper
    //   name={name} 
    // > /// theme-${theme.theme}
    <div key={"user-cases"} className={`user-cases-wrapper`}>
      <div className={`flip-card`}>
        <div className={`flip-card-inner ${isShowMain ? "flip" : ""}`}>
        <ChildWrapper>
        {userCasesCollections.map((userCaseCollection, i) => ///note: userCasesCollections with relevant userCases was originally computed here, but the computation is moved to MainUserCaseCollectionComponent and EditUserCaseCollectionComponent, respectively => to reduce props/hooks/context dependencies, including for sessionInfo

            <MemoUserCaseCollectionWrapperComponent
              key={i}
              type={type}
              isEdit={isEdit}
              ///userCaseCollection={userCaseCollection}
              isMapUserCases={isMapUserCases}
              isShowMain={isShowMain}
              setIsShowMain={memoSetIsShowMain}
              isMain={i===0}
              templatesData={templatesData} setTemplatesData={setTemplatesData}
              setFocusItemDocument={memoSetFocusItemDocument}
            />
        )}
        </ChildWrapper>
        </div> 
      </div> 
    </div>
    // </Wrapper>
  );
}
UserCasesComponent.displayName = 'UserCasesComponent';

export const UserCasesComponents = ({ templatesData, setTemplatesData, isMapUserCases, setFocusItemDocument }) => { ///was: theme

  const userCaseTypes = ["plan", "experiences", "trip logs", "itinerary"]

  return (
    userCaseTypes.map((type) =>
    <div key={`user-cases-components-${type}`} className={`user-cases-components-wrapper`}>
      <UserCasesComponent
        templatesData={templatesData} setTemplatesData={setTemplatesData}
        type={type}
        isMapUserCases={isMapUserCases}
        setFocusItemDocument={setFocusItemDocument}
      />
    </div>
    )
  );
}
UserCasesComponent.displayName = 'UserCasesComponents';

export const Like = ({ document }) => {

  ///context
  const 
  { userUid } = useContext(userUidContext),
  { sessionInfo, setSessionInfo } = useContext(sessionInfoContext)
  
  ///state
  const 
   // [caseItem, setCaseItem] = useState(null),
    [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    const isSelected = sessionInfo?.documents && sessionInfo.documents.includes(document)
    setIsSelected(isSelected)
  }, [sessionInfo.documents, sessionInfo.userCases])

  const InUserCases = ({}) => {

  const inUserCases = sessionInfo.userCases?.filter(userCase => userCase?.documents && userCase.documents.includes(document))

  if (!inUserCases || inUserCases.length === 0) { return <></> }
  return (
    <div className={`likes-wrapper user-cases-likes`}>
      <span>includes in trips</span>
      <div className={`likes-container`}>
        <div className={`likes`}>
          {inUserCases?.map((userCase) =>
                <div key={userCase.caseId} className={`like-container user-cases-likes`}>
                  <div className={`like`}
                    style={{color: userCase?.color}}
                  >
                      {getUserCaseIcon({ type: [userCase?.type], color: userCase?.color})}
                  </div>
                </div>
          )}
        </div>
      </div>
    </div>
  )
  }

  const UserLike = ({}) => {
    return (
      <div className={`likes-wrapper user-like`}>
        <div className={`like-container`}>
          <div className={`like ${isSelected ? "selected--true" : "selected--false"}`}
            onClick={async() => {
              // setIsSelected(!isSelected)

              if (!userUid) {
                

                setSessionInfo( ///note: set sessionInfo => trigger opening of modal (with info) prior to createUser (slow...)
                  produce((draft) => {
                    draft.document = ""
                    draft.password = ""
                    draft.documents = []
                    draft.type = ""
                    draft.isModal = true
                    })
                  );
                }
              if (!!userUid) { 
                setSessionInfo(
                  produce((draft) => {
                    if (!isSelected) {
                      draft.documents.push(document)
                    }
                    if (isSelected) {
                      const index = draft.documents.findIndex(document_ => document_ === document) 
                      if (index !== -1) draft.documents.splice(index, 1)
                    }
                  })
                );
                
                await NewArtwork_w.updateUserCase({ label: "documents", document: sessionInfo.document, value: document, isAdd: !isSelected })
              }
            }}
          >
{/* 
          <div 
              style={{position: "absolute", bottom: "0.5em", left: "0.5em", zIndex: 1}}
              > */}
                <ViewToggle  ///note: rename to Toogle
                  theme_ = "4"
                  show={true}
                  options={isSelected ? ["Remove", "Remove"] : ["Add To Trip", "Add To Trip"]}
                  //callbackMultiStateButton={( label, value ) => toggleShowOpenCaseCallback({ isShowMain: !isShowMain }) }
              />
              {/* </div>  */}
            {/* { icons.shakaSolidIcon({ fontSize: "1.3em", color: "" })} */}
            
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
    {/* <div className={`like-wrapper`}> */}
      <InUserCases/>
      <UserLike/>
    {/* </div> */}
    </>
  )
}

