import './app.css';
import './styling-standards.css';
import './login-input.css';
import React, { useEffect, useState, useContext, useRef } from "react";
import * as LoginInput_w from './LoginInput_w';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useLocation, useNavigate} from 'react-router-dom'; //v6
import { initialStateOptions } from './StateOptions';
import { userUidContext }from './UserUidProvider';

export default function LoginInput() {

  const { userUid, setUserUid } = useContext(userUidContext);
  const [ emailValidated, setEmailValidated ] = useState(initialStateOptions[2]);
  const [ emailSend, setEmailSend ] = useState(initialStateOptions[2]);

  const emailInputRef = useRef(null)

  const handleEmailInputChange = () => {
    const emailInput = emailInputRef.current.value;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    setEmailSend(false)
    if (!pattern.test(emailInput)) {
      setEmailValidated(false)
    } else {
      setEmailValidated(true)
    }
}

  const handleSubmit = async() => {
    const email = emailInputRef.current.value;
    const emailSend_ = await LoginInput_w.SendSignInLinkToEmail(email)
    setEmailSend(emailSend_)
  }

  const handleStatus = () => {
    LoginInput_w.OnAuthStateChanged()
  }
	
  return (
    <div className="box">
        <form>
          <span className="text-center">Sign in</span>
          <div className="input-container">
            <input 
              type="text" 
              ref={emailInputRef} 
              onChange={() => handleEmailInputChange()}
              required/>
            <label>Email</label>		
          </div>
          {/* <div className="input-container">		
            <input type="mail" required/>
            <label>Password</label>
          </div> */}
       
          <div className="login-input-button-submit-wrapper">
            <button 
              type="button" 
              className={`login-input-button-submit ${emailValidated ? "show fadein-05" : "hide fadeout-01"}`}
              onClick={() => handleSubmit()}
            >submit
            </button>
            <span
              className={`login-input-button-submit-message ${emailSend ? "show fadein-05" : "hide fadeout-01"}`}
            >
              {"An email with link to 'sign in' is sent"}
            </span>
          </div>
 
          {/* <button type="button" className="btn"
            onClick={() => handleStatus()}
          >submit
          </button> */}
        </form>	
      </div>
  );;
}


