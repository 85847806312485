import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; //v6
//import './i18n';

import { firebaseApp } from './Firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
export const functions = getFunctions(firebaseApp);

ReactDOM.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// const messageText = "fuck this shit is awesome"
// const addMessage = httpsCallable(functions, 'addMessage');
// addMessage({ text: messageText })
//   .then((result) => {
//     // Read result of the Cloud Function.
//     /** @type {any} */
//     const data = result.data;
//     const sanitizedMessage = data
//     console.log("sanitizedMessage", sanitizedMessage)
//   });

// const getCrypto = httpsCallable(functions, 'getCrypto');
// getCrypto({ text: "yo" })
// .then((result) => {
//   // Read result of the Cloud Function.
//   /** @type {any} */
//   const data = result.data;
//   console.log(data)
//   const sanitizedMessage = data.text;
// })
// .catch((error) => {
//   // Getting the Error details.
//   const code = error.code;
//   const message = error.message;
//   const details = error.details;
//   // ...
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
