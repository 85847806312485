import './app.css';
import './styling-standards.css';
//import './x.css';
import React from 'react'
import { useState, useEffect, useCallback } from 'react';
import { Wrapper } from './ComponentStandards';
import Dropzone from 'react-dropzone'

export const DropZone = ({ parentCallback, children }) => {
	
  const name = "drop-zone"

  const handleOnDrop = useCallback((acceptedFiles) => {
    parentCallback(acceptedFiles)
  })

  return (
	  <Wrapper
      name={name}
      style={{zIndex: "100"}}
    >
      <div className={`${name}`}>
        <Dropzone onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()}/>
              { children }
            </div>
          </section>
          )}
        </Dropzone>
      </div>
    </Wrapper>
  );
}