import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { userUidContext }from './UserUidProvider';
import { userCasesContext } from './UserCasesProvider';
import produce from "immer";
import { collection, query, doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from './Firebase'; 

export const sessionInfoContext = createContext([,() => {}])

export const SessionInfoProvider = ({ children }) => {

  ///context 
  const
    { userUid } = useContext(userUidContext),
    { userCases, setUserCases } = useContext(userCasesContext)

  const [sessionInfo, setSessionInfo] = useState({})
  const sessionInfoValue = useMemo(
    () => ({ sessionInfo, setSessionInfo }), 
    [sessionInfo]
  );

    ///if userUid change or userCases change (load) => set session info ///note: userUid can change due to any login (like destinaton listing login), but this code will check if (existing) userCase opened and discontue if not.
    useEffect(() => {
      if (
          !!userUid && 
          userCases.length > 0 && ///risk/optimise: if pagination, usercase not found. note: possible solution if pagination => explicit load before set sessionInfo
          userCases.some(userCase => userCase.caseId === userUid)) { ///at new usercase => no match => discontinue (session set state not controlled here ) ///risk: new user case made, logged out, reopen without reaload (download of usercases) => set state not fired
        const 
          document = (userCases.find(userCase => userCase.caseId === userUid)).document,
          documents = (userCases.find(userCase => userCase.caseId === userUid)).documents,
          type = (userCases.find(userCase => userCase.caseId === userUid)).type
  
        setSessionInfo( ///setSessionInfo({ document: sessionDocument, password: password, documents: [document] })
          produce((draft) => {
            draft.document = document
            draft.documents = documents
            draft.type = type
            draft.open = false
            ///note: optimise: password should be set...
            })
        )
      }
      if (!userUid) {
        setSessionInfo( ///setSessionInfo({ document: sessionDocument, password: password, documents: [document] })
          produce((draft) => {
            draft.document = ""
            draft.password = ""
            draft.documents = []
            draft.type = ""
            draft.open = false
          })
        );
      }
    }, [userUid, userCases])

  const handleSetSessionInfo = ({}) => {
    console.log(handleSetSessionInfo)
  }

  useEffect(() => {
    if (!!sessionInfo.document) {
      handleListenToUserCase({ isUnsubscribe: false })
    }
    // if (!sessionInfo.document) {
    //   unsubscribeListenToUserCase({ isUnsubscribe: true })
    // }
  }, [sessionInfo.document]);

  const handleListenToUserCase = ({ isUnsubscribe }) => {
      const document = sessionInfo.document
      const q = doc(db, "userCases", document)
      
      const unsubscribeListenToUserCase =
        onSnapshot(q,
          (doc) => {
            const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            // if (source === "Local") {
            //   return
            // }
      
            const userCase = {...doc.data(), document }
            setUserCases(
              produce((draft) => {
                const caseExist = draft.some(userCase_ => userCase_.document === document)
                if (!caseExist) {
                  draft.push(userCase)
                }
                if (caseExist) {
                  const index = draft.findIndex(userCase_ => userCase_.document === document)
              
                  if (index !== -1) draft[index] = userCase
                }
              })
          )
          },
          (error) => {
            // console.log(error)
          });

      if (isUnsubscribe) {
        //unsubscribeListenToCase()
      }
            // console.log("xtt", sessionInfo.document)
    // const data = NewArtwork_w.listenToCase({ document: sessionInfo.document })
    // console.log("dataxxx", data)
  }

  return (
      <sessionInfoContext.Provider value={sessionInfoValue}> 
          {children(sessionInfo, setSessionInfo, handleSetSessionInfo)}
      </sessionInfoContext.Provider>
  )
}