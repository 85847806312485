import './app.css';
import './styling-standards.css';
import './share-component.css';
import { useState, useEffect } from 'react';
import { Wrapper } from './ComponentStandards';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

export const ShareComponent = ({ url, hashtag }) => {

  const name = "share-component"

  return (
	  <Wrapper
      name={name}
      className={"user-case-sheet-wrapper"}
    >
      <span>Share This Trip</span>
      <div className={"share-component-grid"}>
        <FacebookShareButton url={url} hashtag={hashtag}>
          <FacebookIcon size={32} round={true}>
          </FacebookIcon>
        </FacebookShareButton> 
        <FacebookMessengerShareButton url={url}>
          <FacebookMessengerIcon size={32} round={true}>
          </FacebookMessengerIcon>
        </FacebookMessengerShareButton> 
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={32} round={true}>
          </WhatsappIcon>
        </WhatsappShareButton> 
        <LineShareButton url={url}>
          <LineIcon size={32} round={true}>
          </LineIcon>
        </LineShareButton> 
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true}>
          </TwitterIcon>
        </TwitterShareButton> 
        <TelegramShareButton url={url}>
          <TelegramIcon size={32} round={true}>
          </TelegramIcon>
        </TelegramShareButton> 
        <RedditShareButton url={url}>
          <RedditIcon size={32} round={true}>
          </RedditIcon>
        </RedditShareButton> 
        <EmailShareButton url={url}>
          <EmailIcon size={32} round={true}>
          </EmailIcon>
        </EmailShareButton>
      </div> 
    </Wrapper>
  );
}