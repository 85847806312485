import React, { useEffect, useContext } from 'react';
import * as LoginInput_w from './LoginInput_w';
import { userUidContext }from './UserUidProvider';

export const SignInWithEmailLinkComponent = () => {

  ///context
  const { setUserUid } = useContext(userUidContext)

  ///handle sign in with email
  useEffect(() => {
    handleIsSignInWithEmailLink()
  }, []);

  const handleIsSignInWithEmailLink = async() => {
    const userUid = await LoginInput_w.IsSignInWithEmailLink()
    setUserUid(userUid)
  }

  return (
    <>
    </>
  );
};

export default SignInWithEmailLinkComponent;