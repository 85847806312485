import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import './girl-pearl.css';

export default function GirlPearl({ scale, display }) {

  const girlParl = 
    <div className="canvas">
      <div className="side-blur"></div>
      <div className="blob"></div>
      <div className="blob"></div>
      <div className="blob"></div>
      <div className="blob"></div>
      <div className="band"></div>
      <div className="top-band"></div>
      <div className="top-yellow-band"></div>
      <div className="yellow-band">
          <div className="shadow"></div>
      </div>
      <div className="yellow-drape"></div>
      <div className="yellow-drape-back"></div>
      <div className="band-blur"></div>
      <div className="neck"></div>
      <div className="ear"></div>
      <div className="face">
          <div className="temple"></div>
          <div className="chin-blur"></div>
          <div className="f1"></div>
          <div className="f2"></div>
          <div className="left-cheek">
              <div className="blob"></div>
          </div>
          <div className="lips">
              <div className="top-lip"></div>
              <div className="teeth">
                  <div className="teeth-right"></div>
              </div>
              <div className="bottom-lip">
                  <div className="lip-highlight"></div>
              </div>
          </div>
          <div className="f4"></div>
          <div className="right-cheek">
              <div className="blob"></div>
          </div>
          <div className="fh"></div>
          <div className="left-eye-hollow"><span></span><span></span><span></span>
              <div className="left-eye"><span></span></div>
          </div>
          <div className="noseblob"></div>
          <div className="nose"><span></span><span></span><span></span>
              <div className="philtrum"></div>
          </div>
          <div className="right-eye-hollow"><span></span><span></span>
              <div className="right-eye"><span></span></div>
          </div>
      </div>
      <div className="collar"></div>
      <div className="jacket">
          <div className="jacket-shading"></div>
      </div>
      <div className="earring"></div>
      <div className="side-shadow"></div>
    </div>
	
  return (
	  <div 
        className={"girl-pearl-wrapper"}
        style={{width: "222px", height: "222px"}}
      >
      <div className={"girl-pearl"}
        style={{
            transform: `scale(${scale})`,
            //filter: "grayscale(100%) contrast(0%) brightness(10000%)"
        }}
      >
        {girlParl}
      </div>
    </div>
  );
}