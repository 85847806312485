import { createWorker } from "tesseract.js";

const worker = createWorker({
    logger: (m) => console.log(m),
});

///fileToDataUri not-in-use
// export const fileToDataUrl = async(file) => {
//   const promise = new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       resolve(e.target.result)
//     };
//     reader.readAsDataURL(file);
//   })
//   return promise
// }

///extractTextFromImage
export const extractTextFromImage = async(fileOcr) => { //or imageUrl
	const promise = new Promise(async(resolve, reject) => {
		await worker.load();
		await worker.loadLanguage("eng");
		await worker.initialize("eng");
		const {
				data: {
					text
				},
		} = await worker.recognize(fileOcr); //or imageUrl
		resolve(text)
	})
	return promise
};

//terminateWorker
export const terminateWorker = async() => {
	await worker.terminate();
}



// const handleChangeStatus = ({
//     meta
// }, status) => {
//     if (status === 'headers_received') {
//       alert("Uploaded");
//       // setText("Recognizing...");
// ExtractTextFromImage(meta.url);
//     } else if (status === 'aborted') {
//       alert("Something went wrong")
//     }
// }