import './app.css';
import './styling-standards.css';
import './refine.css';
import ContentEditable from 'react-contenteditable'
import { useState, useEffect, createRef, useRef, useCallback, useContext, memo } from 'react';
import * as generics from './Generics';
import { MultiStateButton } from './MultiStateButton';
import { themeContext } from './ThemeProvider';
import { refineContext, itemsRefinedContext } from './ItemsRefinedComponent';
import produce from "immer";
import { GridColums, SquareIcon, Wrapper } from './ComponentStandards';
import { searchIcon, filterIcon } from './Icons';
import { templatesFilterableAttr, templatesValue, allSearchCategories } from './ItemForm';
// import { templatesDataContext } from './DataProvider';
import { itemsContext } from './ItemsProvider';
import { getFilterOptions } from './GalleryRefined';
import { urlInfoContext } from './UrlInfoProvider';

export const ItemCount = ({ color, activeTemplate, showTemplateName, showOnlySummary, show, fontSize }) => {

  ///context
  const { items, setItems } = useContext(itemsContext);
  const { itemsRefined } = useContext(itemsRefinedContext);

  const 
    name = `item-count`,
    itemsCount = items.filter(item => item.template === activeTemplate).length,
    itemsRefinedCount = showOnlySummary ?
      itemsRefined.length :
      itemsRefined.filter(item => item.template === activeTemplate).length
  let itemCountText
  switch (true) {
    case showOnlySummary:
      itemCountText = `showing ${itemsRefinedCount} results`;
      break;
    // case (itemsCount && itemsRefinedCount) === 0:
    //   itemCountText = "";
    //   break;
    case itemsRefined.length === 1:
      //alert(`test: ${1 === 2 ? 'hello' : 'world'}`);
      itemCountText = `showing ${itemsRefinedCount} ${showTemplateName ? activeTemplate : ""} (of ${itemsCount})`;
      break;
    case itemsCount === itemsRefinedCount:
      itemCountText = `showing all ${showTemplateName ? activeTemplate + "s": ""} (${itemsRefinedCount})`
      break;
    default:
      itemCountText = `showing ${itemsRefinedCount} ${showTemplateName ? activeTemplate + "s" : ""} (of ${itemsCount})`
    break;
  };

  return (   
    <Wrapper
      name={name}
      height={`auto`}
      width={`auto`}
      show={show}
    >
    {/* <div className={`
      item-count-wrapper
      padding-top-2
      padding-bottom-2
    `}> */}
      <div className={`
        ${name}
    
        `}>
        <span className={`
          item-count-text
          no-select
        `}
        style={{color: color}}
          >{itemCountText}
        </span>
      </div>
    {/* </div> */}
    </Wrapper>
  )
}

const SearchField = ({ refine, setRefineCallback }) => {

///ref
const searchRef = useRef("")

///state
const [showSearch, setShowSearch] = useState(false)

useEffect(() => {
  if (showSearch) { ///if => to prevent focus on render (load of page)
    // searchRef.current.focus()
  }
}, [showSearch]);

///TOOGLE SHOW SEARCH
const toogleShowSearch = () => {
  setShowSearch(true)
}

///HANDLE SEARCH
///handle search field change
const handleChange = (e) => {

  const 
    parameter = "lookup",
    action = "lookup-update"
  // if (showSearch) { ///note: condition removed....
    const lookup = generics.removeHtmlTypeStrings(e.target.value).trim()
    searchRef.current = lookup
    setRefineCallback(parameter, action, lookup)
  // } else { /// in case of typing directly after rendering/load, first keystroke reveal search-field (typing deferred)
  //   setShowSearch(true) 
  // }
}

  ///handle search field blur
  const handleBlur = () => {
    ///searchRef.current = "" ///not working
  }

const style = {minWidth: `10vw`}
return (    
  <div className={`search-field-wrapper`}>
    <div 
      className={`
        search-field
        ${showSearch ? "hide-pseudo-after fadeout-05" : "show-pseudo-after fadein-05"}
      `}
      search-icon={`\uf002`}
      onClick={() => toogleShowSearch()} ///optimise: whole-field is clickable. should only be search icon
    >
      <ContentEditable 
        key={"search"}
        placeholder={showSearch ? "search" : ""}
        className={`input input-height-full input-radius-right input-padding-2 input-justify-content-left input-border-1 lowercase`}
        style={style}
        html={refine.lookup} 
        ref={searchRef}
        // innerRef={searchRef}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleBlur(e)}
      />
    </div>
  </div>
)
}

const CategoryOptions = ({ categoryOptions, refine, setRefineCallback }) => {

  ///ref
  const multiStateButtonRef = useRef("")

  ///context
  const { theme } = useContext(themeContext)

  const 
    className = `search-category-options`,
    label = "category"

    ///handle category change
    const callbackMultiStateButton = useCallback((label, value) => {
      const 
        parameter = "category",
        action = "category-update"
      setRefineCallback(parameter, action, value)
    }, []);
  

  return (   
    <>
    <MultiStateButton
      show={true}
      key={label}
      wrapperClassName={`${className}-wrapper`}
      //classNameWrapper={`${className}-wrapper`} ///note: redundant
      className={className}
      classTypes={["classic", "leftSubtle"]}
      //wrapperStyle={{height: "100%"}}
      minHeight={"100%"}
      height={"100%"}
      fontSize={0}
      theme={theme.theme}
      label={label}
      options={categoryOptions} 
      value={refine[label]}
      ref={multiStateButtonRef}
      parentCallback={callbackMultiStateButton}
  ></MultiStateButton>
  </> 
  )
}

const Search = ({ templatesData }) => {

  ///context
  const 
    // { templatesData } = useContext(templatesDataContext),
    { refine, setRefine } = useContext(refineContext)

  ///const from context
  const 
    activeTemplate = templatesData.show.activeTemplates[templatesData.show.activeTemplates.length - 1],
    activeTemplates = templatesData.show.activeTemplates

const 
  activeAttr = [...new Set(activeTemplates.map((activeTemplate => templatesFilterableAttr[activeTemplate])).flat())], ///was: [activeTemplate].sort()  ///optimise: defined in serveral jsx. define once, and geneate generic
  categoryOptions = ["all"].concat(activeAttr),
  tip = generics.arrayToString(categoryOptions.slice(1), ", "),
  showTip = refine.lookup !== "" && refine.category === "all"

    // ///set refine
    const handleSetRefine = (parameter, action, value, index) => { 

      const 
        itemsPrRow = refine.gallery.itemsPrRow,
        period = itemsPrRow + 1
  
      setRefine(
        produce((draft) => {
          switch (action) {
            case "category-update":
            case "lookup-update":
            case "filterOptions-update":
            case "sort-change":
              draft[parameter] = value
              break;
            case "listLength-update":
                generics.isArrayLengthAbove(activeTemplates, 1) ?
                  draft[parameter]["gallery"]["multiple"] += value :
                  draft[parameter]["gallery"][activeTemplate] += value 
              break;	
            case "documentMore-update-from-user-interaction":
              draft[parameter]["document"] = value.document
              draft[parameter]["directLoad"] = value.directLoad ///optimise: get "directLoad" via map over value (inplicit)
              draft[parameter]["documentIndex"] = value.documentIndex
              if (generics.isNumber(value.documentIndex)) { ///optimise: only invoke function if defined, solve above
                const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
                if (index_ !== -1) { ///if already selected => remove index
                  draft[parameter]["documentIndexs"].splice(index_, 1) 
                } 
                else { ///if not selected
                  const 
                    rangeMax = Math.ceil(value.documentIndex/period)*(period) - 2, ///index of last item in row note: minus 2, supposingly due to index - 1 in addition to minus 1 for extra element (the documentmore view)
                    rangeMin = rangeMax - itemsPrRow,
                    indexArray = generics.makeArrayViaRange(rangeMin, rangeMax) ///array with index in row
                  indexArray.map((i_, i) => {
                    const index__ = draft[parameter]["documentIndexs"].findIndex(index => index === i_)
                      if (index__ !== -1) {
                        draft[parameter]["documentIndexs"].splice(index__, 1) 
                      } 
                  })
                  draft[parameter]["documentIndexs"].push(value.documentIndex)
                }
              }
              break;
            case "documentMore-update-from-url-change":
              draft[parameter]["document"] = value.document ///optimise: get "document" via map over value (inplicit)
              draft[parameter]["documentIndex"] = value.documentIndex
              if (generics.isNumber(value.documentIndex)) {
                // const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
                // console.log("url-change", value.documentIndex, index_)
                // index_ !== -1 ? 
                // 	draft[parameter]["documentIndexs"].splice(index_, 1) :
                // 	draft[parameter]["documentIndexs"].push(value.documentIndex)
              }
              break;
            default:
              break;
          };
        })
      ) 
    }

    return (    
      <div className={`search-wrapper`}>
      <div  
        className={`
          search-container
        ${showTip ?
          "tip-below" :
          ""}
        `}
        tip={`searching within: ${tip}`}
        // search-icon={`\uf002`}
        // onClick={() => toogleShowSearch()} ///optimise: whole-field is clickable. should only be search icon
      >
        <GridColums
          className={`
            search 
            ${true ? "show fadeout-05" : "hide--clickable fadein-05"}`} ///alternative: showsearch
          gridTemplateColumns={"5em 1fr"}
        >
            <CategoryOptions
              categoryOptions={categoryOptions}
              refine={refine} 
              setRefineCallback={handleSetRefine}
            />
            <SearchField
              refine={refine} 
              setRefineCallback={handleSetRefine}
            />
        </GridColums> 
      </div>
    </div>
    )
} ///note: usecallback to mitigate loss of focus. optimise: searchfield lose focus on refine.category change, ok or?

export default function Refine({ templatesData }) {

  ///context
	// const 
    // { refine, setRefine } = useContext(refineContext),
    // { urlInfo } = useContext(urlInfoContext),

    // { items } = useContext(itemsContext),
    // { itemsRefined } = useContext(itemsRefinedContext)

  //on refine set => set filteroptions
	// useEffect(() => {
	// 	const parameter = "filterOptions"
	// 	const action = "filterOptions-update"
	// 	const items_ = items.filter(( { template } ) => template === activeTemplate ) ///only get items where template equal activetemplate
	// 	const filterOptions = getFilterOptions(items_, refine.lookup, searchCategories, activeTemplate, templatesValue) ///filtermode toggle: excluding or including. ///note: filtermode with itemsrefined at getfilteroptions removes non-chosen filters ... getfilteroptions(refine.filtermode ? items : itemsrefined) 
	// 	handleSetRefine(parameter, action, filterOptions) //handleSetFilterOptions(parameter, filterOptions)
	// }, [itemsRefined, refine.lookup]); ///question: update to itemsRefine (more often update or?)


  ///const
  // const 
    // show = urlInfo.location.pathname === "/" || generics.stringIncludes(urlInfo.location.pathname, "/gallery/") ? true : false,
    // activeAttr = [...new Set(activeTemplates.map((activeTemplate => templatesFilterableAttr[activeTemplate])).flat())], ///was: [activeTemplate].sort()  ///optimise: defined in serveral jsx. define once, and geneate generic
    // categoryOptions = ["all"].concat(activeAttr),
    // searchCategories = refine["category"] === "all" ? allSearchCategories : [refine["category"]],
    // label = "category",
    // dimension = "5vh"
  //const widthMultiStateButton = {minWidth: `10vw`}


  // useEffect(() => {
  //   if (!generics.arrayEmpty(refine.filterOptions)) { 
  //     if (!generics.arrayEmpty(Object.values(refine.filterOptions[0])[0])) { ///optimise/risk: check only first filter, like artist, if non-empty. implement more robust solution
  //     const show = true /// (activeTemplate !== "live" || activeTemplate !== "special events") ? true : false
  //     const delayFunc = () => toogleShowFilterBox({ show })
  //     setTimeout(delayFunc, 10); ///optimise: timerfunc??
  //     }
  //   }
  // }, [refine.filterOptions]);


  ///toogle show filterbox 
  // const toogleShowFilterBox = ({ show }) => {
  //   const parameter = "show" ///optimise: combine serefine with handlesetrefine
  //   const target = "filterbox"
  //   const value = refine[parameter][target] ? false : true
  //   setRefine(
  //     produce((draft) => {
  //       const item = draft
  //       item[parameter][target] = show; ///value
  //     })
  //   );
  // }

  ///HANDLE SET REFINE
  // const handleSetRefine = useCallback((parameter, lookup) => {
  //   setRefine(
  //     produce((draft) => {
  //       const item = draft
  //       item[parameter] = lookup;
  //     })
  //   );
  // },[]); 




  // const ItemCount = useCallback(() => {
  //   const itemCount = itemsRefined.length === 0 ? "" : `${itemsRefined.length} \uf03e`
  //   return (    
  //     <div className={`item-count-wrapper
  //       ${showSearch ? "item-count-wrapper-pos1" : "item-count-wrapper-pos2"}
  //     `}>
  //       <div className={`item-count`}>
  //         <span className={`
  //           item-count-text 
  //           text-gradient-1
  //           input input-radius input-padding-2
  //         `}
  //           >{itemCount}
  //         </span>
  //       </div>
  //     </div>
  //   )
  // }, [showSearch, itemsRefined])

	
  // const Filtering = () => {
  //   const width = dimension
  //   const focus = refine.show.filterbox
  //   return (   
  //       <SquareIcon
  //         className={`filterbox-toggle`}
  //         focus={focus}
  //         icon={filterIcon}
  //         width={width}
  //         parentCallback={() => toogleShowFilterBox({ show: !refine.show.filterbox })}
  //       >
  //       </SquareIcon>
  //   )
  // }

  return ( /// ${show ? "show fadein-01" : "hide fadeout-05"}
    <div className={`refine-wrapper`}> 
      <GridColums
        className={"refine"}
        gridTemplateColumns={`1fr`} //{`1fr fit-content(100%)`}
        justifyContent="end"
        //justifyItems="end"
        //gridTemplateRows={`5vh`} //{`3fr 0.5fr`}
        // gap={`0px 2vw`}
      > 
        {/* <ItemCount
          itemsRefined={itemsRefined}
          className={`${true ? "item-count-wrapper-pos1" : "item-count-wrapper-pos2"}`} //alternative: showsearch
        /> */}
        <Search
          templatesData={templatesData}
        />
          {/* <Filtering/> */}
        {/* <ItemCount/> */}
      </GridColums>
    </div>
  );
}