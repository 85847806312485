import React, { useState, useEffect, useRef, useCallback } from 'react';
import './app.css';
import './styling-standards.css';
import './selector-provider.css';

export const SelectorProvider = ({ children }) => {

  ///ref
  const selectorRef = useRef(null);

  ///const
  const [selectorId, setSelectorId] = useState(0);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  const handleScroll = useCallback((e) => {
    const isScrolledToTop_ = e.target.scrollTop === 0;
    setIsScrolledToTop(isScrolledToTop_);
  }, []);

  useEffect(() => { ///add (once) listeners for children scroll position
    const selectorElement = selectorRef.current;
    if (!selectorElement) return;

    const selectorChildren = Array.from(selectorElement.children);
    selectorChildren.forEach(child => {
      child.addEventListener('scroll', handleScroll);
      return () => {
        child.removeEventListener('scroll', handleScroll);
      };
    });

    return () => {
      selectorChildren.forEach(child => {
        child.removeEventListener('scroll', handleScroll);
      });
    };
  }, []);

  useEffect(() => { ///set isScrolledToTop after selectorId change
    const isScrolledToTop_ = selectorRef.current.children[selectorId + 1]?.scrollTop === 0; ///note: + 1 as child 0 is menu (currently not selector id/skipped)
    setIsScrolledToTop(isScrolledToTop_);
  }, [selectorId]);

  return (
      <div 
        ref={selectorRef} 
        className={`selector-provider selector-id-${selectorId} ${isScrolledToTop ? "sticky--false" : "sticky--true" }`}> 
          {children(selectorId, setSelectorId)}
      </div>
  )
}

export const Selector = ({ id, selectorId, children }) => {

  return (
      <div 
        className={`selector selector-id-${selectorId} ${selectorId === id ? "show" : "hide"}`}
      > 
          { children }
      </div>
  )
}