import { firebaseApp, db } from './Firebase'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink, signOut } from "firebase/auth";
import { query, where, and, or, orderBy, limit, startAfter, getFirestore, deleteDoc, updateDoc, writeBatch, getDoc, getDocs, setDoc, addDoc, collection, doc, serverTimestamp, increment } from 'firebase/firestore';

const auth = getAuth();

export const SendSignInLinkToEmail = async(email) => {
  const promise = new Promise(async(resolve, reject) => {

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'http://localhost:3000/',
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      //dynamicLinkDomain: 'example.page.link' //removed by RBPN
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        resolve(true)
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.message)        
        reject(false)
        // ...
      });
  })
  return promise
}

export const IsSignInWithEmailLink = async() => {
  const promise = new Promise((resolve, reject) => {
    //const auth = getAuth();
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          const userUid = result.user.uid
          console.log("userUid", userUid)
          resolve(userUid)
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
      }
    })
    return promise
  }

  export const OnAuthStateChanged = () => {
    //const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(`User ${uid}`)
        // ...
      } else {
        console.log("User is signed out")
        // User is signed out
        // ...
      }
    });
}

export const SignOut = async() => {
  const promise = new Promise(async(resolve, reject) => {
    //const auth = getAuth();
    signOut(auth).then(() => {
      console.log("Sign-out successful")
      resolve()
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  })
  return promise
}


export const createUser = async() => {

  let passwordDocRef = doc(collection(db, "userCasesP"))
  
  const 
    password = passwordDocRef.id,
    email = `${password}@yogatube.ai`

    console.log("createUserx", password, email)
  // await addDoc(collection(db, collection_), ///setDoc(doc(db, collection_, caseId), 
  //   { 
  //     caseId: caseId,
  //     caseTitle: "",
  //   })

  //const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword( auth, email, password )

    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error", error)
      // ..
    });
    // .then((userCredential) => {
      // Signed in 
      const 
        user = userCredential.user,
        caseId = user.uid
      return [caseId, password]
    // })
    // createUserWithEmailAndPassword({ email, password })
}

export const signInEmailAndPassword = async({ password }) => {
  const email = `${password}@yogatube.ai`

  const userCredential = await signInWithEmailAndPassword(auth, email, password)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error)
    });
    const user = userCredential.user;
}

