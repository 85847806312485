import './google-map.css';
import React, { useEffect, useContext, useState, useCallback } from "react";
import GoogleMapReact from 'google-map-react';
import * as icons from './Icons';
import { sessionInfoContext } from './SessionInfoProvider';
import { itemsRefinedContext } from './ItemsRefinedComponent';
import { mainColors } from './ItemForm'; 
import { getUserCaseIcon } from './UserCases'; 
import { MultiStateButton } from './MultiStateButton'; 
import { ScrollBox } from './Scroll';
import * as generics from './Generics';
import { itemsContext } from './ItemsProvider';
import { refineContext } from './ItemsRefinedComponent';

const ToogleMapStyles = ({ valuesObj, isShowStyle, setIsShowStyle }) => {
  const options = [false, true]
  return (
    <div className={"toogle-map-styles-container"}>
      <MultiStateButton
        isSelected={isShowStyle}
        show={true}
        classNameWrapper={``} 
        classTypes={["classical", "small"]}
        // fontSize={"08"}
        theme={"map"}
        label={"toogle-pois"}
        options={options} 
        value={valuesObj[isShowStyle]}
        parentCallback={() => setIsShowStyle(!isShowStyle)}
      ></MultiStateButton>
    </div>
  )
}

const getMarkerColors = ({ item, markerType }) => {
  let markerColors, dotColor
    switch (true) {
      case markerType === "refined":
        markerColors = "#f8e4be"
        dotColor = mainColors[item.template] //mainColors[item.template]
        break;
      case markerType === "userCase":
        markerColors = "gold"  ///item.markerColors
        dotColor = mainColors[item.template]
      break;
      case markerType === "local":
        markerColors = mainColors[item.template]  ///item.markerColors
        dotColor = mainColors[item.template]
        break;
      default:
        // markerColors = "#f8e4be"
        // dotColor = "transparent"
      break;
    };
    return { markerColors, dotColor }
  }


  // Linear interpolation function
  const lerp = (start, end, t) => start * (1 - t) + end * t;

  // Calculate the font size based on the zoom level using linear interpolation
  const calculateMarkerSize = ({ zoom }) => {
    const minZoom = 8;
    const maxZoom = 16;
    const minFontSize = 1; // 1em
    const maxFontSize = 2.5; // 2em

    // Ensure that zoom is within the specified range
    const clampedZoom = Math.min(Math.max(zoom, minZoom), maxZoom);

    // Linear interpolation
    return `${lerp(minFontSize, maxFontSize, (clampedZoom - minZoom) / (maxZoom - minZoom))}em`;
  };

const MarkerComponent = ({ zoom, show, item, isFocus, isSelected, markerColors, title, lat, lng }) => {

  // let pieColors
  // if (item.markerType === "userCase") {
  //   pieColors = item.markerColors.map((color_, i) => {
  //       return (
  //         `${i === 0 ? 
  //             `${color_} ${0}%,` : 
  //             `white ${(100 * (i + 1 - 1) / item.markerColors.length) - 4}%,
  //              white ${100 * (i + 1 - 1) / item.markerColors.length}%,
  //              ${color_} ${100 * (i + 1 - 1) / item.markerColors.length}%,
  //             `
  //         }
  //         ${color_} ${(100 * (i + 1) / item.markerColors.length) - 4}%`
  //       )
  //   })
  //     .join(",")
  // }

  const SelectedUserCasesMapComponent = ({ }) => {
    const mapComponent =
      item.selectedUserCasesInfo.map((userCaseInfo) =>
          <div className={"selected-user-cases-map-component-icon-container"}>
            {getUserCaseIcon({ type: userCaseInfo.type, color: userCaseInfo.color })}
          </div>
      )
    return (
      <div className={"selected-user-cases-map-component"}>
        {mapComponent}
      </div>
    )
  }

  return (
    <div
      className={"map-marker-wrapper"}
      style={{zIndex: item.markerType === "local" ? "100000" : "10"}}
    >
      <div
        className={`map-marker-container ${show ? "show fadein-05" : "hide fadeout-05"}`}
        style={{color: markerColors.markerColors}}
        lat={lat}
        lng={lng}
      > 
      {item.markerType === "userCase" &&
        <SelectedUserCasesMapComponent/>
        // <div className={`bar`}
        //   style={{width: `calc(${item.markerColors.length}*50%`, backgroundImage: `linear-gradient(to right, ${pieColors})`}}
        // ></div>
      }
        <div 
          className={`
            map-marker 
            ${isSelected ? "selected--true" : "selected--false"}
            ${isFocus ? "isfocus--true" : "isfocus--false"}
          `}
          style={{fontSize: calculateMarkerSize({ zoom })}}
        >
          <div className={"map-marker-dot"}
           style={{background: `radial-gradient(circle at center, ${markerColors.dotColor} 0%, ${markerColors.dotColor} 40%, transparent 40%)`}}
          >
          </div>
          {icons.mapMarkerIcon}
        </div>
      </div>
    </div>
  )
}

const MapsOverlay = ({ item }) => {

  const scrollBox = !generics.objectIsEmpty(item) ? 
    <ScrollBox
      // templatesData={templatesData} ///note: not used in this context
      boxId={1}
      document={item.document}
      data={item.media} ///{data.media}
      ///mediaCountOffset={isExistSocialMediaData ? socialMediaData.length + 2 : 2} ///note: + 2 due to SocialsComponent and UserCaseIntro
      //geoInfo={""} ///{geoLocation: data.geoLocation}}
      state={{document: item.document, documentIndex: 1}}
      // mapOnlyBoxId={""}
      groupSize={[1, 1]}
    //	mapOnlyBoxId={0} ///default (undefined or "") => map both scrollbox
      to={""}
      overlayContent={[item?.title]}
      social="" ///{data.social} ///data.social
      setup={
          [
            {horizontal: [false, true]},
            {groupSize: [1, 1]},
            {scrollSnap: [true, true]},
            {onHoverPartnerScrollToMe: [true, false]},
            {cancelOnHover: [true, false]},
            {withScrollButtons: [false, false]},
            {animate: [true, false]},
          ]}
      focusItemDocumentCallback={({ document }) => void 0}
      //animate={true}
      // hoverParentCallback={}
      //parentCallback={(elementName, groupName, groupSize, containerId, horizontal) => scrollBoxElementClicked(elementName, groupName, groupSize, containerId, horizontal)}
  >
    </ScrollBox> : null
  return (
    <div className={`maps-overlay-container`}>
      <div className={`maps-overlay`}>
        {/* <span className={`maps-overlay-text`}>{item?.title}</span> */}
        {scrollBox}
      </div>
    </div>
  )
}

const ToogleMapStylesComponent = ({ children }) => {
  return (
    <div className={`toggle-map-styles-wrapper`}>
        <div className={`toggle-map-styles`}>
          { children }
        </div>  
    </div>
  )
}


let isGoogleMapsAPILoaded = false; /// note: flag to track whether the Google Maps API has been loaded

export const SimpleMap = ({ isShow, item, focusItemDocument }) => {

  const 
    { itemsRefined } = useContext(itemsRefinedContext),
    { sessionInfo } = useContext(sessionInfoContext),
    { items } = useContext(itemsContext)
    // { refine } = useContext(refineContext)

  const 
    [center, setCenter] = useState(!!item ?
      [Number(item.geoLocation[0].lat), Number(item.geoLocation[1].lng)] :
      [9.871002521511306, 126.06976208593952]
    ),
    [hoveredItem, setHoveredItem] = useState({}),
    [zoom, setZoom] = useState(10), ///note: map zoom not based on zoom, only zoom is setstate after bounds change, and used to impact marker size
    [isShowPOIs, setIsShowPOIs] = useState(false),
    [isShowIslandLabels, SetIsShowIslandLabels] = useState(false),
    [isShowTownLabels, setIsShowTownLabels] = useState(false)

    // useEffect(() => {
    //   // Check if Google Maps API has already been loaded
    //   if (!isGoogleMapsAPILoaded) {
    //     loadGoogleMapsAPI();
    //   }
  
    //   return () => {
    //     // Cleanup code if necessary
    //   };
    // }, []);
  
    // const loadGoogleMapsAPI = () => {
    //   const script = document.createElement("script");
    //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig&libraries=drawing`;
    //   script.async = true;
    //   script.onload = () => {
    //     isGoogleMapsAPILoaded = true;
    //     // initializeMap(); // note: call the function to initialize the map ///note: not in use, as implemented GoogleMapReact onGoogleApiLoaded =>
    //   };
    //   document.body.appendChild(script);
    // };

  let userCasesItems = []
  const userCasesItems_ = sessionInfo?.userCases && sessionInfo?.userCases?.length > 0 ?
    sessionInfo.userCases.map((userCase) => {
      //const userCase = userCases.find((userCase_) => userCase_.caseId === userCaseId)
      userCase?.documents.map((document) => {
        const item = items.find(item => item.document === document)
        const item_ = { ...item, color: userCase?.color, type: userCase?.type }
        if (!!item) {
          userCasesItems.push(item_)
        }
      })
    }) : []

    const uniqueuserCasesItems = userCasesItems.length > 0 ? 
      userCasesItems.reduce((acc, current) => {
        const existingItem = acc.find(item => item.document === current.document);
        if (existingItem) {
          existingItem.selectedUserCasesInfo = [...existingItem.selectedUserCasesInfo, { color: current.color, type: current.type  }]; ///note: if the document already exists in the accumulator, combine the info
        } else {
          if (!!current?.geoLocation?.[0]?.lat && !!current?.geoLocation?.[1]?.lng) {
            acc.push({ ...current, ["selectedUserCasesInfo"]: [{ color: current.color, type: current.type }], markerType: "userCase"  }); ///note: if the document doesn't exist, add the current object to the accumulator
          }
        }
        return acc;
      }, []) 
    : []

  const geoLocationItemsRefined = 
    itemsRefined
      .filter((item) => !!item?.geoLocation?.[0]?.lat && !!item?.geoLocation?.[1]?.lng)
      .map((item) => { return {...item, markerType: "refined" }}) ///{ return { geoLocation: item.geoLocation, template: item.template } })

  const localItem = !!item ? [{...item, color: "green", markerType: "local" }] : []

  const geoLocationItems = generics.arrayUniqueByKey([...geoLocationItemsRefined, ...uniqueuserCasesItems, ...localItem], "document")

  const defaultProps = {
    zoom: !!item ? 13 : 8.5,
    zoomControl: true,
  };

  const onChildClick = (key, childProps) => {
    setCenter([Number(childProps.lat), Number(childProps.lng)])
    setHoveredItem(childProps.item)
  }

  const onChildCMouseEnter = (key, childProps) => {
    //setHoveredItem(childProps.item)
  }

  const onChildCMouseLeave = (key, childProps) => {
   // setHoveredItem({})
  }

    const handleApiLoaded = ({ map }) => {
      map.setOptions({
        zoomControlOptions: {
          style: window.google.maps.ZoomControlStyle.LARGE,
        },
      });
    }

    const handleChange = ({ zoom }) => {
      setZoom(zoom);
    };

  // if (!isGoogleMapsAPILoaded) return <></>

  return (
    // Important! Always set the container height explicitly
    <div className={`map ${isShow ? "show fadein-05" : "hide fadeout-05"}`} style={{ height: "100%", width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ libraries: 'drawing', key: "AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig" }}
        center={center}
        defaultZoom={defaultProps.zoom}
        gestureHandling={"greedy"}
        onChildClick={(key, childProps) => onChildClick(key, childProps)}
        onChildMouseEnter={(key, childProps) => onChildCMouseEnter(key, childProps)}
        onChildMouseLeave={(key, childProps) => onChildCMouseLeave(key, childProps)}
        onChange={({ zoom }) => handleChange({ zoom })}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded({ map, maps })}
        options = {{
          disableDefaultUI: false,
          panControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          overviewMapControl: true,
          rotateControl: true,
          fullscreenControl: true,
          zoomControl: true,
          zoomControlOptions: {
            position: 7,
          },
          styles: [

            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                    { visibility: isShowPOIs ? "on" : "off" }
              ]
            },
            {
              "featureType": "landscape.natural",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": isShowIslandLabels ? "on" : "off"
                }
              ]
            },
            // {
            //   featureType: "all",
            //   elementType: "labels",
            //   stylers: [
            //         { visibility: isShowIslandLabels ? "on" : "off" }
            //   ]
            // },
            {
              featureType: 'administrative',
              elementType: 'labels',
              stylers: [{ visibility: isShowTownLabels ? "on" : "off" }]
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                  { color: "#018d89" } ,
                  // { invert_lightness: true },
                  // { hue: "#ff003b" },
                  // { saturation: -100 },
                  // { lightness: 100 } /* generates "white" color */
              ]
            }
          ]
        }}
        yesIWantToUseGoogleMapApiInternals
        // minZoom={defaultProps.zoom - 3}
        // maxZoom={defaultProps.zoom + 3}
      >
        {geoLocationItems.map((item) =>
          <MarkerComponent
            zoom={zoom}
            show={isShow}
            isFocus={item.document === focusItemDocument?.document && focusItemDocument?.isMapFocus}
            isSelected={item.document === hoveredItem?.document}
            key={`${item.geoLocation?.[0]?.lat}-${item.geoLocation?.[1]?.lng}`}
            lat={item.geoLocation?.[0]?.lat}
            lng={item.geoLocation?.[1]?.lng}
            markerColors={getMarkerColors({ item, markerType: item.markerType })}
            title={item.title}
            item={item}
          />
        )}
      </GoogleMapReact>
      <MapsOverlay item={hoveredItem}/>
      <ToogleMapStylesComponent>
        <ToogleMapStyles
          valuesObj={{true: "pins", false: "pins"}}
          isShowStyle={isShowPOIs} 
          setIsShowStyle={setIsShowPOIs}
        />
        <ToogleMapStyles
          valuesObj={{true: "islands", false: "islands"}}
          isShowStyle={isShowIslandLabels} 
          setIsShowStyle={SetIsShowIslandLabels}
        />
        <ToogleMapStyles
          valuesObj={{true: "muni.", false: "muni."}}
          isShowStyle={isShowTownLabels} 
          setIsShowStyle={setIsShowTownLabels}
        />
      </ToogleMapStylesComponent>
    </div>
  );
}