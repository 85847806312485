
import './app.css';
import './styling-standards.css';
import './scroll.css';
//import './Scroll';
//import * as Scroll from 'react-scroll';
import { useState, useEffect, useMemo, useContext, useLayoutEffect, memo, useCallback} from 'react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { themeContext } from './ThemeProvider';
import { refineContext } from './ItemsRefinedComponent';
import produce from "immer";
import { angleRightIcon, angleLeftIcon, instagramLogo, googleMapsLogo, facebookLogo } from './Icons';
import { ImageZoom } from './ImageZoom';
import * as generics from './Generics';
import { Wrapper, GridColums, ConditionalElementWrapper, AspectRatio } from './ComponentStandards';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import arrayMove from 'array-move'
import Video from './Video';
import { useLocation } from 'react-router-dom';
import { SocialMediaPost, SocialMediaPostPreview, getSocialMediaTypeInfo, socialsList, socialsIcons } from './SocialMediaPost';
import { SimpleMap } from './GoogleMap';
import * as icons from './Icons';
import { UserComment, MemoUserComment } from './UserComments';
import { colorsLight } from './colors';
import { mainColors, templatesSetup } from './ItemForm';
import { UserComments } from './UserComments';
import ViewToggle from './ViewToggle';
import { urlInfoContext } from './UrlInfoProvider';

export const scrollToName = (name, containerId, horizontal, instant, offset) => {
   scroller.scrollTo(name, { //scroll-to-HyxJobn6B1JhmkeJk22F-true-2
      duration: `${instant ? 0 : 500}`,
      delay: `${instant ? 0 : 0 }`,
      horizontal: horizontal, ///scroll-box-config: rows
      smooth: true,
      containerId: containerId, ///scroll-container-id-HyxJobn6B1JhmkeJk22F-true
      ignoreCancelEvents: true,
      offset: offset
   })
 }

 export const ScrollToTop = ({ children }) => {
  window.onbeforeunload = function () { ///note: to scroll to top on reload
    window.scrollTo(0, 0);
  }

  ///context
  const { urlInfo, setUrlInfo } = useContext(urlInfoContext);

  useEffect(() => {
      //window.scrollTo(0, 0);
  }, [urlInfo]);
  return <>{children}</>
};

export default ScrollToTop;

 export const ScrollButton = memo(({ left, active, parentCallback }) => {
   const 
     name = "scroll-button",
     action = `click-scroll-button-${left}`,
     transformObj = { zIndex: 1, transform: `translate(${left ? "110%" : "-110%"})`} ///note: transform set on div to ensure hover on image-container => correct scroll/navigation
   return (
     <Wrapper
       name={name}
       className={`
         ${active ? "show fadein-05 input-hover" : "fade fadeout-05"}
       `}
       style={transformObj}
       height={"auto"}
       width={"auto"}
       //style={{position: `relative`}}
     >
       <div 
         className={`
           ${name}
           focus-via-scale
         `}
         style={{
          position: "absolute",
          right: left ? "101%" : "",
          left: !left ? "101%" : ""
        }}
         //style={{position: `absolute`}}
         onClick={() => parentCallback(action)}
       >
           {left ? 
             angleLeftIcon :angleRightIcon 
           }
       </div>
     </Wrapper>
   )
 })
 
 export const ScrollBoxImage = memo(({ scrollTo, overlayContent, showDocumentMoreGridViewExtra, state, url, id, elementName, horizontal, boxId, to, interactionParentCallback }) => {
   
  //const { scrollTo, setScrollTo } = useContext(scrollToContext);
 const { theme } = useContext(themeContext);
//  const { refine, setRefine } = useContext(refineContext);
 
 const 
   highlightSlide = 
       !horizontal &&
     (
       elementName === scrollTo.galleryElementScrollBoxPair.boxId_0.toElementName
     ),
   highlightEnlarge = 
       horizontal &&
     (
       //elementName === scrollTo.galleryElementScrollBoxPair.toElementName ||
       elementName === scrollTo.galleryElementScrollBoxPair.boxId_0.toElementName
     )
 
 return (
 <div
  className={` 
  scroll-box-element 
  scroll-box-element-${horizontal}
  input-radius 
 `}
  ///note: input-radius and overflow hidden to ensure round corners at view 1 grid-view 
//  ${highlightSlide ?  ///optimise/delete: removed from className
//   `scroll-box-element-highlight-slide-${horizontal}` : 
//   `scroll-box-element-dehighlight-slide-${horizontal}`
//    }
//  ${highlightEnlarge ? 
//   `scroll-box-element-highlight-enlarge-${horizontal}` : 
//   `scroll-box-element-dehighlight-enlarge-${horizontal}`
//   }
   style={{overflow: "hidden", height: "100%", width: "100%", padding: boxId === 1 && (theme.view === 0 || showDocumentMoreGridViewExtra) ? "0.5em" : ""}}
 >
 <ImageZoom
   // name={name} ///optimise: pass correct name and remove item-image class
   className={` 
      "item-image" 
   ${horizontal ? 
     "input-radius" : 
     "input-radius"}
     `}
   url={url}
   overlayContent={overlayContent}
   style={{
     width: `${boxId === 1 && theme.view === 1 && !showDocumentMoreGridViewExtra ? "100%" : ""}`,
     maxHeight: `${boxId === 1 && theme.view === 1 && !showDocumentMoreGridViewExtra ? "" : "100%"}`, ///was: maxHeight: "100%"
     maxWidth: `${boxId === 1 && theme.view === 1 && !showDocumentMoreGridViewExtra ? "" : "100%"}` ///was: maxWidth: "100%"
   }}
   zoomIt={(boxId === 1 && theme.view === 0) || (boxId === 1 && theme.view === 1 && showDocumentMoreGridViewExtra)}
   linkIt={boxId === 1 && theme.view === 1 && !showDocumentMoreGridViewExtra}
   to={to}
   id={id} ///optimise: id is probably obsolute to pass from parent. exclude?
   state={state}
   interactionParentCallback={(action) => interactionParentCallback(action)}
 ></ImageZoom>
 </div>
 )
 })
  const getName = ( document, boxId, horizontal, index, getPartner, showDocumentMoreGridViewExtra ) => {
     const 
       prefix = `scroll-to`,
       direction = getPartner ? horizontal : horizontal, ///was: !horizontal : horizontal
       boxId_ = getPartner ? (boxId === 0 ? 1 : 0) : boxId,
       name = `${prefix}-${document}-${boxId_}-${direction}-${index}-${showDocumentMoreGridViewExtra}`

     return name
   }
 
   const getId = ( document, boxId, horizontal, getPartner, showDocumentMoreGridViewExtra) => {
   
     const 
       prefix = `scroll-container-id`,
       direction = getPartner ? horizontal : horizontal, ///was: !horizontal : horizontal
       boxId_ = getPartner ? (boxId === 0 ? 1 : 0) : boxId,
       id = `${prefix}-${document}-${boxId_}-${direction}-${showDocumentMoreGridViewExtra}`

     return id
   }

const Count = ({ isRight, count, totalCount }) => {
  ///const [ scrollTo, setScrollTo ] = useState({ galleryElementScrollBoxPair: {boxId_0: {}, boxId_1: {}}, wasRef: {ref: {}, component: "itemform", index: 10, anchorOffset: "", component: ""} }) ///const { scrollTo, setScrollTo } = useContext(scrollToContext);
  const 
    icon = isRight ? icons.mapMarkerIcon : null,
    count_ = isRight ? count : -(count)
  return (
    <div className={"scroll-box-count-wrapper"}>
      <div className={`scroll-box-count-container ${isRight ? "count-right" : "count-left"}`}>
        <span className={`scroll-box-count ${totalCount > 0 && count_ >= 0 && count_ <= totalCount ? "show fadein-05" : "hide fadeout-05"}`}>
          {`${count_ > 0 ? `${count_} of` : ""} ${totalCount} ${count_ > 0 ? `` : ""} ${count_ === 0 && !isRight ? "SoMe" : "" }`}
        </span> 
        { count_ === 0 ? icon : null }
      </div>
    </div>
    )
}
 
 export const ScrollBox = memo(({ templatesData, mediaCountOffset, overlayContent, document, boxId, data, state, setup, mapOnlyBoxId, to, focusItemDocumentCallback }) => {

   ///context
   const [ scrollTo, setScrollTo ] = useState({ galleryElementScrollBoxPair: {boxId_0: {}, boxId_1: {}}, wasRef: {ref: {}, component: "itemform", index: 10, anchorOffset: "", component: ""} }) ///const { scrollTo, setScrollTo } = useContext(scrollToContext);
   const { theme  } = useContext(themeContext);
   const { refine } = useContext(refineContext);

   ///state
   const [mediaCount, setMediaCount] = useState(0)

   ///const
   const 
    documentMore = refine.documentMore.document,
    isDocumentMore = true, ///r documentMore === document,
    showDocumentMoreGridViewExtra = theme.view === 1 && generics.checkRemainder(state.documentIndex + 1, refine.gallery.itemsPrRow + 1, true)

   // const [documentMore, setDocumentMore] = useState("")
 
   ///urlinfo (more/art selected) => animation => 
    // useEffect(() => {
   //   const artworkSelected = generics.stringIncludes(urlInfo.location.pathname, "/gallery/")
   //   const documentMore = artworkSelected ? 
   //     urlInfo.location.state.document : 
   //     ""  
   //     setDocumentMore(documentMore)
   // }, [urlInfo]);
 
   ///const
   //const { document } = data ///optimise: get document from data
   const setupPar = ["", ""].map((x, index) => { ///optimise: this function block ....
     const array = setup.map((ob) => {
       const name = `${Object.keys(ob)}_${index}`
       const obj = { [name]: Object.values(ob)[0][index] }
         return obj
     })
   return array
   })
   
   let mergedObj = generics.mergeArrayWithObjectsIntoObject(setupPar.flat())
 
   const {
     groupSize_0, groupSize_1, horizontal_0, horizontal_1, 
       onHoverPartnerScrollToMe_0, onHoverPartnerScrollToMe_1,
       cancelOnHover_0, cancelOnHover_1,
       scrollSnap_0, scrollSnap_1, withScrollButtons_0, withScrollButtons_1,
       animate_0, animate_1
       } = mergedObj,
     noOfElements = data.length,
     isGrouped_0 = groupSize_0 > 1 ? true : false,
     isGrouped_1 = groupSize_1 > 1 ? true : false,
     noOfGroups_0 = isGrouped_0 ? Math.ceil(data.length / groupSize_0) : 1,
     noOfGroups_1 = isGrouped_1 ? Math.ceil(data.length / groupSize_1) : 1
 
   let 
     toIndex_0, toIndex_1,
     toRealIndex_0, toRealIndex_1,
     toElementName_0, toElementName_1,
     toName_0, toName_1, 
     toContainerId_0, toContainerId_1,
     scroll_0, scroll_1
   
   const 
     name = `scroll-box`,
     horizontal = boxId === 0 ? horizontal_0 : horizontal_1,
     groupSize = boxId === 0 ? groupSize_0 : groupSize_1,
     scrollSnap = boxId === 0 ? scrollSnap_0 : scrollSnap_1,
     withScrollButtons = boxId === 0 ? withScrollButtons_0 : withScrollButtons_1, 
     isGrouped = groupSize > 1 ? true : false,
     noOfGroups = isGrouped ? Math.ceil(data.length / groupSize) : 1
 
   const handleSetScrollTo = ({ action, boxId, realIndex, getPartner }) => {

     let 
       cancel = 
          //was (removed to enable scroll even if only on box mapped, risk?): generics.isNumber(mapOnlyBoxId) || ///if maponlyboxid is specified/explicit
         action === "hover" && boxId === 0 ? cancelOnHover_0 : cancelOnHover_1,
       indexExist = true ///default
     if (cancel) {
       return
     }

     toIndex_0 = isGrouped_0 ? Math.floor(realIndex / groupSize_0) : realIndex
     toIndex_1 = isGrouped_1 ? Math.floor(realIndex / groupSize_1) : realIndex
     toRealIndex_0 = realIndex
     toRealIndex_1 = realIndex
 
     switch (true) {
       case action === "hover":
         scroll_0 = boxId !== 0 && onHoverPartnerScrollToMe_0
         scroll_1 = boxId !== 1 && onHoverPartnerScrollToMe_1 
         break;
       case action === "click": ///scroll partner
         scroll_0 = boxId !== 0 
         scroll_1 = boxId !== 1 
         break;
        case action === "scroll-offset": ///scroll self
          scroll_0 = boxId === 0 
          scroll_1 = boxId === 1
          break;
       case generics.stringIncludes(action, "click-scroll-button"):
         const increment = generics.stringIncludes(action, "true") ? -1 : 1 ///note: false: left, true: right
         const nextRealIndex = !generics.stringUndefined(scrollTo.galleryElementScrollBoxPair.boxId_0.toRealIndex) ?
           scrollTo.galleryElementScrollBoxPair.boxId_0.toRealIndex + increment
           : increment
         toIndex_0 = isGrouped_0 ? Math.floor(nextRealIndex / groupSize_0) : nextRealIndex
         toIndex_1 = isGrouped_1 ? Math.floor(nextRealIndex / groupSize_1) : nextRealIndex
         toRealIndex_0 = nextRealIndex
         toRealIndex_1 = nextRealIndex
         scroll_0 = true 
         scroll_1 = true 
         indexExist = nextRealIndex >= 0 && nextRealIndex < noOfElements
         break;
       default:
         break
     } 
 
     if (!indexExist || cancel) {
       return
     }

     toContainerId_0 = getId(document, boxId, horizontal_0, getPartner, showDocumentMoreGridViewExtra)
     toContainerId_1 = getId(document, boxId, horizontal_1, getPartner, showDocumentMoreGridViewExtra)
     toElementName_0 = getName(document, boxId, horizontal_0, toRealIndex_0, getPartner, showDocumentMoreGridViewExtra) 
     toElementName_1 = getName(document, boxId, horizontal_1, toRealIndex_1, getPartner, showDocumentMoreGridViewExtra) 
     toName_0 = getName(document, boxId, horizontal_0, toIndex_0, getPartner, showDocumentMoreGridViewExtra) 
     toName_1 = getName(document, boxId, horizontal_1, toIndex_1, getPartner, showDocumentMoreGridViewExtra) 



    //  if (1 === 1) {
    //    return
    //  }

     setScrollTo( 
       produce((draft) => {
         const target = "galleryElementScrollBoxPair"
         const a = draft[target]["boxId_0"]
         a["toName"] = toName_0;
         a["toElementName"] = toElementName_0;
         a["toIndex"] = toIndex_0;
         a["toRealIndex"] = toRealIndex_0;
         a["toContainerId"] = toContainerId_0;
         a["toHorizontal"] = horizontal_0;
         a["scroll"] = scroll_0;
       const b = draft[target]["boxId_1"]
         b["toName"] = toName_1;
         b["toElementName"] = toElementName_1;
         b["toIndex"] = toIndex_1;
         b["toRealIndex"] = toRealIndex_1;
         b["toContainerId"] = toContainerId_1;
         b["toHorizontal"] = horizontal_1;
         b["scroll"] = scroll_1;
       })
     )
   }
 
   useEffect(() => {

     if (scrollTo.galleryElementScrollBoxPair.boxId_0.scroll) {
       const { toName, toContainerId, toHorizontal } = scrollTo.galleryElementScrollBoxPair.boxId_0
       scrollToName(toName, toContainerId, toHorizontal, 0)
     }
     if (scrollTo.galleryElementScrollBoxPair.boxId_1.scroll) {
       const { toName, toContainerId, toHorizontal } = scrollTo.galleryElementScrollBoxPair.boxId_1 
       scrollToName(toName, toContainerId, toHorizontal, 0)
     }
   }, [scrollTo.galleryElementScrollBoxPair]);

   const [items, setItems] = useState(data)
 
   const onSortEnd = (oldIndex, newIndex) => {
      setItems((array) => arrayMove(array, oldIndex, newIndex))
    }

   //  const Content2 = useCallback(() => {
   //    const 
   //      elements = generics.groupArrayElementsByFrequency(data, groupSize === 1 ? 1 : groupSize), ///or groupsize equal to 1 pass media.lenght??? 
   //      groupGridTemplateColumns = horizontal ? `1fr` : `1fr`,
   //      groupGridTemplateRows = horizontal ? `1fr` : `repeat(${isGrouped ? 1 : 1}, 100%)`,
   //      gridTracks = (1 / groupSize) * 100,
   //      gridTemplateColumns = horizontal ? `repeat(${data.length}, ${gridTracks}%)` : `1fr`,
   //      gridTemplateRows = horizontal ? `1fr` : `repeat(${data.length}, ${gridTracks}%)`,
   //      scrollSnapType = horizontal ? `x mandatory` : `y mandatory`,
   //      containerId = getId(document, horizontal) 
   //    return (
   //    <GridColums
   //    wrapperStyle={{paddingTop: !horizontal ? "0.5vh" : "0vh", paddingBottom: !horizontal ? "2vh" : "0vh"}}
   //    id={containerId} 
   //    className={`
   //      ${name}
   //    `}
   //    gridTemplateColumns={groupGridTemplateColumns} 
   //    gridTemplateRows={groupGridTemplateRows}
   //    height={"100%"} ///{height}
   //    width={"100%"}
   //    overflow={"overlay"}
   //    scrollSnapType={scrollSnap ? scrollSnapType : ""}
   //  > 
   //    {/* {elements.map(( group, i ) => {
   //      const 
   //        groupIndex = i,
   //        containerKey = `${group[groupIndex][0]["url"]}${i}${horizontal}`,
   //        groupName = getName(document, horizontal, groupIndex, false)
   //    return (
   //    <ConditionalElementWrapper
   //    wrapIt={isGrouped ? true : false}
   //    key={containerKey}
   //    name={groupName} 
   //  > */}
   //        <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
   //    {/* <GridColums
   //    //   className={`
   //    //     ${isGrouped ? "scroll-snap-align-child" : ""}
   //    //     `}
   //      gridTemplateColumns={gridTemplateColumns} 
   //      gridTemplateRows={gridTemplateRows}
   //      height={`100%`}
   //      width={"100%"}
   //    >  */}
   //    {data.map(( media, index ) => {
   //      const { url, id } = media;
   //      //console.log("media", media, url)
   //      const realIndex = index
   //      const elementName = getName(document, horizontal, realIndex, false) //`scroll-to-${document}-${horizontal}-${realIndex}`
   //        return (
   //         <SortableItem key={`${document}${horizontal_0}${url}${realIndex}`}>
   //          <ConditionalElementWrapper
   //            wrapIt={true}
   //            key={`${url}${realIndex}`}
   //            name={elementName}
   //          >
   //            <AspectRatio
   //              //wrapperClassName={isGrouped ? "" : "scroll-snap-align-child"}
   //              aspectRatio={horizontal ? 56.25 : 0}
   //              wrapperMargin={"0.5vw"}  ///optimse: to to marginButton... not pretty. use rowgap?
   //            >
   //            {/* {type === "video" ?
   //              <VideoGroup mediaId={mediaId} url={url}/>
   //              :  */}
               
   //              <ScrollBoxImage 
   //                state={state}
   //                to={to}
   //                horizontal={horizontal}
   //                url={url}
   //                id={id}
   //                boxId={boxId}
   //                elementName={elementName}
   //                interactionParentCallback={(action) => handleSetScrollTo({ action, boxId, realIndex })}
   //              />
   //            </AspectRatio>
   //            </ConditionalElementWrapper>
   //            </SortableItem>
   //        )
   //    })}

   //    {/* </GridColums> */}
   //    </SortableList>
   //    {/* </ConditionalElementWrapper>
   //    )
   //    })} */}
   //  </GridColums>
   //    )
   //  }
   //  , [data])

   useEffect(() => {
    if (mediaCountOffset > 0) {
      // setTimeout(
      handleSetScrollTo({ action: "scroll-offset", boxId: 1, realIndex: mediaCountOffset - 1, getPartner: false }) ///note: - 2 due to SocialsComponent and UserCaseIntro
      // , 500)
    }
   },[])

   const mediaInteraction = ({ action, boxId, realIndex, getPartner }) => {
      //console.log("realIndex", realIndex)

      setMediaCount(realIndex + 1 - mediaCountOffset)
      //if (action === "enter") {
      if (boxId === 0) {
        handleSetScrollTo({ action, boxId, realIndex, getPartner })
      }
        //if (typeof focusItemDocumentCallback === 'function') { ///ensure focusItemDocumentCallback is a function before calling it => mitigate error
      const itemDocument = !!data[realIndex]?.document ? data[realIndex]?.document : ""
      if (!!itemDocument && action === "click") {

        focusItemDocumentCallback({ document: itemDocument, isItemFocus: true });
      }
      if (!!itemDocument && action === "hover") {
       focusItemDocumentCallback({ document: itemDocument, isMapFocus: true });
      }
      //}
   }

   useEffect(() => {
    const realIndex = scrollTo.galleryElementScrollBoxPair.boxId_0.toRealIndex
      if (!!realIndex) {
        setMediaCount(realIndex + 1 - mediaCountOffset)
      }
   }, [scrollTo])
  
     const Content = useCallback(({ data }) => { ///was: mediaCount, totalCount, socialsTotalCount

       const 
         dataSorted = [...data] ///.sort(( a, b ) => { return generics.sortLowToHigh(a, b, "id") } )
         let dataSortedPlusSocial = [...dataSorted]

        //  const mapObj = { url: "", id: dataSorted.length + 1, mediaText: "", mediaType: "map"} ///create media object (to be mapped in scrollbox)
        //   dataSortedPlusSocial.push(mapObj)

        //  if (Array.isArray(social)) { ///if social is included (as an array)
        //   social.map((socialObj, index) => { ///map each socialmedia
        //     if (generics.validUrl(Object.values(socialObj)[0])) { ///if valid url (to social-media, i.e. post,  profile, ..)
        //       const mediaObj = { url: Object.values(socialObj)[0], id: dataSorted.length + 1 + index, mediaText: "", mediaType: Object.keys(socialObj)[0]} ///create media object (to be mapped in scrollbox)
        //     dataSortedPlusSocial.push(mediaObj) ///push it to (the end of) media
        //     }
        //   })
        // }
      const
        elements = generics.groupArrayElementsByFrequency(dataSortedPlusSocial, groupSize === 1 ? 1 : groupSize), ///or groupsize equal to 1 pass media.lenght??? 
        groupGridTemplateColumns = true ? `repeat(${isGrouped ? noOfGroups : dataSortedPlusSocial.length}, 100%)` : `1fr`,
        groupGridTemplateRows = true ? `1fr` : `repeat(${isGrouped ? noOfGroups : dataSortedPlusSocial.length}, 100%)`,
        gridTracks = (1 / groupSize) * 100,
        gridTemplateColumns = true ? `repeat(${groupSize}, ${gridTracks}%)` : `1fr`,
        gridTemplateRows = true ? `1fr` : `repeat(${groupSize}, ${gridTracks}%)`,
        scrollSnapType = true ? (boxId === 0 ? `none` : `x mandatory`) : `y mandatory`,
        containerId = getId(document, boxId, horizontal, false, showDocumentMoreGridViewExtra) 
        //  elements = generics.groupArrayElementsByFrequency(dataSortedPlusSocial, groupSize === 1 ? 1 : groupSize), ///or groupsize equal to 1 pass media.lenght??? 
        //  groupGridTemplateColumns = horizontal ? `repeat(${isGrouped ? noOfGroups : dataSortedPlusSocial.length}, 100%)` : `1fr`,
        //  groupGridTemplateRows = horizontal ? `1fr` : `repeat(${isGrouped ? noOfGroups : dataSortedPlusSocial.length}, 100%)`,
        //  gridTracks = (1 / groupSize) * 100,
        //  gridTemplateColumns = horizontal ? `repeat(${dataSortedPlusSocial.length}, ${gridTracks}%)` : `1fr`,
        //  gridTemplateRows = horizontal ? `1fr` : `repeat(${dataSortedPlusSocial.length}, ${gridTracks}%)`,
        //  scrollSnapType = horizontal ? `x mandatory` : `y mandatory`,
        //  containerId = getId(document, horizontal, false, showDocumentMoreGridViewExtra) 

        return (
          <>
          {/* {boxId === 1 ? <Count isRight={false} count={mediaCount} totalCount={socialsTotalCount}></Count> : null } ///note: moved outside component
          {boxId === 1 ? <Count isRight={true} count={mediaCount} totalCount={totalCount}></Count> : null } */}
         <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
       <GridColums
       wrapperStyle={{height: "100%", paddingTop: !horizontal ? "0.5vh" : "0vh"}}
       id={containerId} 
       className={`
         ${name} ${name}-${boxId}
       `}
       gridTemplateColumns={groupGridTemplateColumns} 
       gridTemplateRows={groupGridTemplateRows}
       height={"100%"} ///{height}
       width={"100%"}
       overflow={"overlay"}
       scrollSnapType={scrollSnap ? scrollSnapType : ""}
     > 
       {elements.map(( group, i ) => {
         const 
           groupIndex = i,
           containerKey = `${group[groupIndex][0]["url"]}${i}${horizontal}`,
           groupName = getName(document, boxId, horizontal, groupIndex, false, showDocumentMoreGridViewExtra)
       return (
       <ConditionalElementWrapper
       wrapIt={true} /// was: {isGrouped ? true : false} note: true implemented to fix scrollto via click on scrollbox 0
       key={`group-${groupName}`} ///was: containerKey
       name={groupName} //{`${groupName} ${showDocumentMoreGridViewExtra ? "more" : ""}`} ///optimise: include "more" in function ///note: implemented to avoid doublicate names (at view 1 and documentmore) and scrollto issues
     >
       <GridColums
         className={`
           ${isGrouped ? "scroll-snap-align-child" : ""}
           `}
           name={`
            scroll-box-scroll-container 
            ${i + 1 === noOfGroups && boxId === 0 ? "scroll-box-child-fixed" : ""}
          `}
         gridTemplateColumns={gridTemplateColumns} ///optimise:risk: seems this is obsolete ...
         gridTemplateRows={gridTemplateRows}
         alignItems={"center"}
         //rowGap={"0em"}
   
         height={`100%`}
         width={"100%"}
         wrapperStyle={{height: "100%"}} ///new
         
       > 
       {group[groupIndex].map(( media, index ) => {
    
         const 
          { url, id, mediaText, mediaType, type, mediaOverlay } = media, ///note: type is currently used for comments...
          realIndex = (groupIndex * groupSize) + index,
          elementName = getName(document, boxId, horizontal, realIndex, false, showDocumentMoreGridViewExtra) //`scroll-to-${document}-${horizontal}-${realIndex}`
        
        let mediaComponent
         switch (true) { ///note: optimise: align use of mediaType and type (user-comments) ///optimise: make case of social media explicit, instead of implicit (currently: mediaType !== "video" && mediaType !== "image") 
          case mediaType === "newUserComment":
          
              mediaComponent = boxId === 0 ?
              <div className={`new-user-comment-button`}>
                <ViewToggle  ///note: rename to Toogle
                  theme_="4"
                  show={true}
                  options={["Add Post"]}
                //callbackMultiStateButton={( label, value ) => toggleShowOpenCaseCallback({ isShowMain: !isShowMain }) }
                />
              </div> 
              :
              <UserComments
                isSocialMediaPreview={false}
                listingDocument={document} 
                listingColor={templatesSetup["stays"].color}
                templatesData={templatesData}
              />
          break
          case !!type: ///implicit = types of userComments
            mediaComponent =
            <UserComment 
              isSocialMediaPreview={boxId === 0} 
              // key={`${0}`} 
              listingDocument={media.listingDocument} 
              listingColor={media.listingColor} 
              userComment={media}
              templatesData={templatesData}
            />
          break;
          case mediaType === "video":
            // mediaComponent =
            //   <Video 
            //     id={id} 
            //     videoUrl={url}
            //     mediaText={mediaText}
            //     light={boxId === 0 ? true : false}
            //   />

              const [mediaPlatform, mediaSubtype] = getSocialMediaTypeInfo({ url: url }) ///consider: upload TypeInfo to backend instead of compute at frontend...
              if (mediaPlatform === "youtube" && mediaSubtype === "video") {
                mediaComponent = 
                <>
                  {boxId === 0 ?
                    <div className={`socialmedia-post-watermark ${mediaPlatform}`}>
                      { icons.youtubeIcon }
                    </div> : 
                  null}
                  <Video 
                    id={id} 
                    videoUrl={url}
                    mediaText={mediaText}
                    light={boxId === 0 ? true : false}
                  />
                </>
              }
              else {
                mediaComponent = 
                  // <div
                  //     style={{height: "100%", width: "100%", position: "relative"}} 
                  //   >  
                  //   <SortableKnob
                  //     className={"color-4"}
                  //   >
                  //     <div style={{width: "2em", height: "2em", position: "absolute", right: "5%", bottom: "5%", zIndex: "100000"}}>
                  //     { icons.handPointerIcon }
                  //     </div>
                  //   </SortableKnob>
                  <>
                      <SocialMediaPostPreview
                        // mediaPlatform={mediaPlatform}
                        // mediaSubtype={mediaSubtype}
                        url={url}
                        isWatermark={boxId === 0}
                    />
                  </>
                  // </div>
              }

            break;
          case mediaType === "image":
            mediaComponent =
              <ScrollBoxImage 
                scrollTo={scrollTo}
                showDocumentMoreGridViewExtra={showDocumentMoreGridViewExtra}
                state={state}
                to={to}
                horizontal={horizontal}
                mediaText={mediaText}
                overlayContent={!!mediaOverlay ? [mediaOverlay] : overlayContent}
                url={url}
                id={id}
                boxId={boxId}
                elementName={elementName} //{`${elementName} ${showDocumentMoreGridViewExtra ? "more" : ""}`} ///optimise: include "more" in function ///note: implemented to avoid doublicate names (at view 1 and documentmore) and scrollto issues
                interactionParentCallback={(action) => handleSetScrollTo({ action, boxId, realIndex, getPartner: true })}
              />
            break;
            case mediaType === "map":
           
              // if (!geoInfo?.geoLocation?.length > 0) { return mediaComponent = <div>map</div> }
              // else {
                mediaComponent = boxId === 0 ?
                  <div 
                    style={{height: "100%", width: "100%", backgroundColor: theme.theme}}
                  > 
                    { googleMapsLogo } 
                  </div> :
                  <SimpleMap 
                    isShow={true} 
                    item={media.item}
                  />
              // }
              break;
              case mediaType !== "video" && mediaType !== "image":
                mediaComponent = media.component
              break
          // case mediaType === "instagram" || mediaType === "facebook":
          //   let socialLogo
          //   switch (true) {
          //     case mediaType === "instagram": 
          //       socialLogo = instagramLogo 
          //       break;
          //     case mediaType === "facebook": 
          //       socialLogo = facebookLogo 
          //       break;
          //     default: 
          //       break;
          //   };
          
          //   mediaComponent = boxId === 0 ?
          //     <div 
          //       style={{height: "100%", width: "100%", backgroundColor: theme.theme}}
          //     > 
          //       { socialLogo } 
          //     </div> : /// optimise: placement and size of logos
          //     <SocialMediaPostPreview
          //       mediaType={mediaType}
          //       url={url}
          //     ></SocialMediaPostPreview>

          // break;
          default:
            mediaComponent = null
          break;
        };

         return (
            <SortableItem 
              key={`element-${elementName}`} ///was: `${url}${realIndex}`}
            >
             <ConditionalElementWrapper
               wrapIt={!isGrouped ? true : false}
               //key={`${url}${realIndex}`}
              name={elementName}
             >
              <AspectRatio
                 wrapperClassName={isGrouped ? "" : "scroll-child-aspect-wrapper scroll-snap-align-child"} ///note: in scss aspect ratio set for user-case
                 aspectRatio={horizontal ? 56.25 : 0}
                 height={!!media.type ? "100%" : "0"}
                 //wrapperMargin={"0.5vw"}  ///optimse: to to marginButton... not pretty. use rowgap?
               >
              {/* <AspectRatio
                 wrapperClassName={`social-media-aspect-ratio ${isGrouped ? "" : "scroll-snap-align-child"}`}
                 aspectRatio={horizontal ? 177 : 177}
              
                 //wrapperMargin={"0.5vw"}  ///optimse: to to marginButton... not pretty. use rowgap?
               > */}
                 <GridColums
                  className={"input-radius"}
                  gridTemplateColumns={"1fr"}
                  gridTemplateRows={`${boxId === 1 ? "20fr 1fr" : "1fr"}`} 
                  wrapperStyle={{
                    height: "100%", 
                    width: "100%", 
                    overflow: "hidden" ///was: //overflowY: `${boxId === 1 ? "hidden" : ""}`}} ///note: to mitigate image is slightly y-scrollable
                  }}
                   
                  height={"100%"}
                  width={"100%"}
                  style={{display: "block"}} ///note: to ensure correct dimensions of video (no overflow)
                  >
                  {/* {mediaType === "video" ? */}
                  
                  <div 
                    className={`
                      media-wrapper 
                      ${!!type || mediaType === "newUserComment" ? "media-wrapper-user-comment" : ""}
                      ${boxId === 0 ? "no-select-pointer-all" : ""}
                    `} 
                    style={{width: "100%", height: "100%", padding: boxId === 0 ? "0.1em" : "", cursor: boxId === 0 ? "pointer" : "grab"}} ///"0.1em"
                    onClick={() => mediaInteraction({ action: "click", boxId, realIndex, getPartner: true })}
                    onMouseEnter={() => mediaInteraction({ action: "hover", boxId, realIndex, getPartner: true })}
                    ///onMouseEnter={() => mediaInteraction({ action: "enter", boxId: 1, realIndex: 3 })}
                  > 
                    {mediaComponent}
                  </div>
                  
                   
                  {boxId === 1 && (theme.view === 0 || showDocumentMoreGridViewExtra) ?
                  <span
                    className={`
                      font-size-0
                      input-padding-3
                      uppercase
                      ${isDocumentMore ? "show fadein-05" : "hide fadeout-05"}
                    `
                    }
                  >{mediaText}</span>
                  : null
                  }
                </GridColums>
               {/* </AspectRatio> */}
               </AspectRatio>
               </ConditionalElementWrapper>       
               </SortableItem>
           )
       })}
       </GridColums>
       </ConditionalElementWrapper>
       )
       })}
     </GridColums>
     </SortableList>
        </>
       )
     }
     , []) ///was: isDocumentMore (why?)
     
   return (
     <Wrapper       
       name={name}
       className={`${""} scrollbar-hide`}
       height={boxId === 0 && theme.view === 1 && !showDocumentMoreGridViewExtra ? "0" : "100%"}
     >
       <GridColums
         gridTemplateColumns={`${boxId === 1 && theme.view === 1 && !showDocumentMoreGridViewExtra ? "0fr 1fr 0fr" : "fit-content(100%) 1fr fit-content(100%)"}`}
         height={"100%"} ///{height}
         wrapperStyle={{height: "100%"}} ///new
         width={"100%"}
         justifyContent={"center"}
         alignItems={"center"} 
         className={`${boxId === 1 || isDocumentMore || showDocumentMoreGridViewExtra ? "show" : "hide"}
  
         ${animate_1 && isDocumentMore ? `scroll-box-wrapper-slide-${theme.view}` : `scroll-box-wrapper-slideback-${theme.view}`}
        `} ///was: scroll-box-wrapper-boxid-${boxId}
       > 
         {/* <div 
           className={` 
             ${boxId === 1 ? "scroll-box-wrapper-slide" : ""}
           `}
           style={{height: "100%", width: "100%"}}
         > */}
         {(withScrollButtons && theme.view === 0 && isDocumentMore) || (withScrollButtons && showDocumentMoreGridViewExtra) ? ///note: use of <p> is as a random placeholder element
           <ScrollButton 
           left={true}
           active={scrollTo.galleryElementScrollBoxPair.boxId_1.toRealIndex > 0}
           parentCallback={(action) => handleSetScrollTo({ action, boxId, realIndex: 0, getPartner: false })} ///note: realIndex set as 0 not used downstream
           ></ScrollButton>
         : <p></p>} 
          {boxId === 1 ? <Count isRight={false} count={mediaCount} totalCount={mediaCountOffset - 3}></Count> : null }
          {boxId === 1 ? <Count isRight={true} count={mediaCount} totalCount={data.length - mediaCountOffset}></Count> : null }
         <Content
          data={data}
          // mediaCount={mediaCount}
          // totalCount={data.length - mediaCountOffset}
          // socialsTotalCount={mediaCountOffset - 3} ///note: -2 due to SocialsComponent and UserCaseIntro
         ></Content>
         {(withScrollButtons && theme.view === 0 && isDocumentMore) || (withScrollButtons && showDocumentMoreGridViewExtra) ?
           <ScrollButton 
           left={false}
           active={scrollTo.galleryElementScrollBoxPair.boxId_1.toRealIndex < data.length - 1}
           parentCallback={(action) => handleSetScrollTo({ action, boxId, realIndex: 0, getPartner: false })} ///note: realIndex set as 0 not used downstream
           ></ScrollButton>
         : <p></p>}
         {/* </div> */}
       </GridColums>
     </Wrapper>
 )})
 export const MemoScrollBox = memo(ScrollBox)
 ScrollBox.displayName = 'ScrollBox';
 MemoScrollBox.displayName = 'MemoScrollBox';
 
 export const ScrollBoxPair = memo(({ templatesData, overlayContent, document, data, groupSize, state, mapOnlyBoxId, to}) => { ///optimise pass setup from parent

   const setup = 
     [
       {horizontal: [true, true]},
       {groupSize: groupSize},
       {scrollSnap: [true, true]},
       {onHoverPartnerScrollToMe: [true, false]},
       {cancelOnHover: [true, false]},
       {withScrollButtons: [false, true]},
       {animate: [true, true]},
     ] 
 
   const scrollBoxPair = useCallback(["", ""].map((box, index) => {
     return (
      !Number.isInteger(mapOnlyBoxId) || ///if maponlyboxid not specified => map both scrollbox 
      mapOnlyBoxId === index ?
         <ScrollBox ///optimise: map into scrollbox via info passed through scrollboxpair  ... refracture
           key={`scrollbox-${document}-${setup[0].horizontal[index]}-${index}`}
           boxId={index}
           document={document}
           data={data}
           state={state}
           mapOnlyBoxId={mapOnlyBoxId}
           setup={setup}
           to={to}
           overlayContent={overlayContent}
           templatesData={templatesData}
           focusItemDocumentCallback={({ document }) => void 0}
           //social={social}
         ></ScrollBox>
          : null
     )
   }), [data])
 
   return (
     <>
       {scrollBoxPair.reverse()}
     </>
   )}
   )
 
 
 