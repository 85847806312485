//https://palmera-demo.squarespace.com/?nochrome=true
import './app.css';
import './styling-standards.css';
import './main-map.css';
import { useContext, useCallback, useState, useEffect } from 'react';
import * as generics from './Generics';
import { themeContext } from './ThemeProvider';
import FilterBox from './FilterBox';
import { SimpleMap } from './GoogleMap';
import { Wrapper } from './ComponentStandards';
import { UserCasesComponent }  from './UserCases';
import { ResizeableGrid } from './ResizeableGrid';
import { Resizable, ResizableBox } from 'react-resizable';
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time

export const MainMap = ({ isShowMap, focusItemDocument, setFocusItemDocument, itemsDownloadStatus }) => {

	///context
	const 
		{ theme } = useContext(themeContext)

	///const
	const 
		{ userCase, chat, userComment, ...dlStatusCategories } = itemsDownloadStatus?.download.overall.status,
    isTrueDlStatusCategories = generics.isAllValuesInObjEquals({obj: dlStatusCategories, value: true })
    // [columnWidths, setColumnWidths] = useState([200, 200, 200])

  const getMainPillarsComponent = useCallback(({ isShowMap, focusItemDocument, setFocusItemDocument }) => {
    return (     
      <div className={
        `main-map-container-wrapper-outer
        ${isShowMap ? "show fadein-05" : "hide fadeout-05"}
        `}
      >
      <Draggable
        cancel={".main-map-container"} ///cancel drag at className => i.e. only drag on outer main-map-container-wrapper, ie. inner div for draggin inside map
      >
      <div className={"main-map-container-wrapper"}>
      <div className={"main-map-container"}>
      <ResizableBox
      width={100} height={100} 
      minConstraints={[50, 50]}
      >
        <>
          <div className={"main-categories-header-container"}>
            <span className={"header"}>Map Explorer</span>
            {/* <div className={"sub-header-container"}> */}
            <span className={`sub-header ${!isTrueDlStatusCategories ? "show fadein-05" : "hide fadeout-05"}`}>Loading...</span>
            {/* </div> */}
          </div>
          <SimpleMap 
            isShow={isTrueDlStatusCategories}
            focusItemDocument={focusItemDocument}
          >
          </SimpleMap>
        </>
      </ResizableBox>
      </div>

      </div>
      </Draggable>
      </div>
    )
}, [itemsDownloadStatus, theme, isShowMap])

const mainPillarsComponent = getMainPillarsComponent({ isShowMap, focusItemDocument, setFocusItemDocument })

	return (
    <>{mainPillarsComponent}</>
	)
}