import './image-zoom.css';
//import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'
import { useState, useEffect, useContext, useCallback, memo } from 'react';
import { WithLink} from './Links';
import { themeContext } from './ThemeProvider';
import * as generics from './Generics';
import { mapText } from './ComponentStandards';


export const ImageZoom = ( { overlayContent, url, state, style, className, zoomIt, linkIt, to, interactionParentCallback } ) => { ///optimise: change name, as this handles link, zoom, and neither...
  //const { imageUrl, title } = data
  ///context
  const { theme, setTheme } = useContext(themeContext);

  const handleInteraction = (action) => {
    interactionParentCallback( action )
  }

  const preTree = "gallery"
  const disable = false

  const fullSizeStyle = { height: "100%", width: "100%" }

  const ConditionalWrapper = useCallback(( { children } ) => {
    let component = <></>
    switch (true) {
      case zoomIt && !linkIt:
        component = 
          <div 
          className={`
            zoom-control
            theme-${theme}
            `} ///optimise zoom control ///note: zoom control => add scale to the zoom button => enables hover on image
          style={fullSizeStyle}
          onMouseOver={() => handleInteraction("hover")} ///note: interaction set on div not img to ensure coordination with scroll-buttons
          onClick={() => handleInteraction("click")}
        >
          <Zoom 
            wrapStyle={fullSizeStyle} 
            zoomMargin={50}
            overlayBgColorStart={"transparent"}
            overlayBgColorEnd={theme.theme === 0 ? "white" : "rgb(26, 26, 26)"}
          > { children } 
          </Zoom> 
      <div className={`overlay-text-container`}>
        <span 
          className={`
            overlay-text 
            font-size-3
          `} 
        >
          {mapText(overlayContent, "block")}
        </span>
      </div>
        </div>
        break
      case !zoomIt && linkIt:
        component = WithLink({to, children, preTree, state, disable})
        break
      case !zoomIt && !linkIt:
        component = 
                  <div 
                      style={{cursor: "pointer", height: "100%"}}
                    >
                      { children } 
                  </div> 
      default:
        break
    }
    return component
  }, [])

    return (
      <ConditionalWrapper>
        <div  
        className={"item-image"} ///optimse: get class via passed and rename
        style={{width: "100%", height: "100%", 
        //backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/simple-easy-gallery.appspot.com/o/attractions%2FPB150069m_1_01.jpg?alt=media&token=8e8f66ab-2a8b-4f53-beb5-784d972c4b72)`
        }}>
        <img
          className={className}
          src={url}
          alt={""}
          draggable={false}
          style={style}
          onMouseOver={() => handleInteraction("hover")}
          onClick={() => handleInteraction("click")}
        />
        </div>
      </ConditionalWrapper>
    )
}