import './app.css';
import './styling-standards.css';
import './main-categories.css';
import { useState, useEffect, useContext, useCallback, useRef, memo } from 'react';
import { GridColums, AspectRatio, PlusButton } from './ComponentStandards';
import  { templatesNames, templatesSetup } from './ItemForm';
import { itemsDownloadStatusContext } from './App';
import { themeContext } from './ThemeProvider';
import produce from "immer";
import { useLongPress } from 'use-long-press';
import * as generics from './Generics';
import { ItemCount } from './Refine';
import { Consume, Experiences, Stay, Sights, Tours, Retreats, Events } from './Icons';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import  { Filtering } from './FilterBox';
// import { templatesDataContext } from './DataProvider';
import { GalleryHeadline, Sticky, Wrapper } from './ComponentStandards';
import { MainSelector } from './MainSelector';
import { refineContext } from './ItemsRefinedComponent';

const MainCategoryWrapper = ({ templatesData, setTemplatesData, itemsDownloadStatus, template }) => {

   ///ref
   const activeTemplatesStateRef = useRef(); ///note: stateref implemented as state not read/update within callback, but stateref is ///see: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback

  ///context
  const 
    { theme } = useContext(themeContext)
    // { templatesData, setTemplatesData } = useContext(templatesDataContext)

  ///const from context
  const 
    activeTemplates = templatesData.show.activeTemplates,
    { userCase, chat, userComment, ...dlStatusCategories } = itemsDownloadStatus?.download.overall.status

    //activeTemplates = templatesData.show.activeTemplates;
    
  const isSelectedMainCategory = activeTemplates.includes(template) 

    ///on longpress =>
    const longPressCallback = useCallback((event, activeTemplate_) => { ///note: callback triggered at longpress true
      const 
        activeTemplate = activeTemplate_["context"],
        longPress = true 
      handleSetActiveTemplate({ activeTemplate, longPress })
    }, []);
  
    ///on mousedown event => call handlelongpress with click or longpress info
    const longPressEvent = useLongPress(true ? longPressCallback : null, {
      onCancel: (event, activeTemplate_) => { ///note: equals onclick event
        const 
          activeTemplate = activeTemplate_["context"],
          longPress = false 
        handleSetActiveTemplate({ activeTemplate, longPress })
      },
      threshold: 150,
    });
  
    ///on mousedown event => on click or longpress => handle set activetemplate
    const handleSetActiveTemplate = ({ activeTemplate, longPress }) => {
      const templateIsIncluded = generics.stringInArray(activeTemplates, activeTemplate) ///clicked template already included in activetemplates
      if (templateIsIncluded && activeTemplates.length === 1) { /// if clicked template is only activetemplate in activetemplates => abort (to mitigate no activetemplate, i.e. no data)
        return
      }
      const removeActiveTemplate = 
        longPress &&
        templateIsIncluded
      const indexOfActiveTemplate = generics.indexInArray(activeTemplates, activeTemplate)
  
      setTemplatesData(
        produce((draft) => {
          switch (true) {
            case longPress && !removeActiveTemplate: ///add template at longpress
              draft.new.activeTemplate = activeTemplate
              draft.show.activeTemplates.push(activeTemplate)
              break;
            case longPress && removeActiveTemplate: ///remove template at longpress
              draft.new.activeTemplate = activeTemplate
              draft.show.activeTemplates.splice(indexOfActiveTemplate, 1)
              break;
            case !longPress && templateIsIncluded: ///if included activetemplate is clicked => remove and add/activate 
              draft.new.activeTemplate = activeTemplate
              draft.update.activeTemplate = activeTemplate
              draft.show.activeTemplates.splice(indexOfActiveTemplate, 1)
              draft.show.activeTemplates.push(activeTemplate)
              break;
            case !longPress && !templateIsIncluded: ///if not included and click => active (and deactive other templates)
              draft.new.activeTemplate = activeTemplate
              draft.update.activeTemplate = activeTemplate
              draft.show.activeTemplates = [activeTemplate]
              break;
            default:
            break;
          };
        })
      );
    }

  const mainCategoryNameComponent =
    <div>
    {isBrowser ? 
    <span className={`main-category-text`} 
      style={{color: templatesSetup[template].color}}
    >
      {template}
    </span> : <></> }
    </div>

const plusButton =
template !== "live" &&
  <PlusButton 
    value={template}
    isPlus={!templatesData.show.activeTemplates.includes(template)}
    show={templatesData.show.activeTemplates[0] !== "live"} /// && !templatesData.show.activeTemplates.includes(template)}
    color={templatesSetup[template].color}
    parentCallback={({ }) => handleSetActiveTemplate({ activeTemplate: template, longPress: true })}
  />

  const mainCategory = 
    <AspectRatio
    wrapperClassName={""}
    aspectRatio={100}
  >
    <div
      {...longPressEvent(template)}
      className={`main-category`}
    > 
      <span 
        className={`main-category-text`}
        style={{color: isSelectedMainCategory ? templatesSetup[template].color : "#1a1a1a"}}
      >{template}
      </span>
      <div className={`main-category-graphic`}>
      {template === "events" &&
      <Events color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Events>
      }
      {template === "attractions" &&
      <Sights color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Sights>
      }
      {template === "experiences" &&
      <Experiences theme={theme.theme} color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Experiences>
      }
      {template === "stays" &&
      <Stay color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Stay>
      }
      {template === "consume" &&
      <Consume theme={theme.theme} color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Consume>
      }
      {template === "tours" &&
      <Tours theme={theme.theme} color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Tours>
      }
      {template === "retreats" &&
      <Retreats isSelectedMainCategory={isSelectedMainCategory} theme={theme.theme} color={isSelectedMainCategory ? templatesSetup[template].color : "#ffdb98"}></Retreats>
      }
      {/* {(template === "live" || template === "attractions" || template === "experiences" || template === "special events" || template === "weekly events" || template === "stays" || template === "consume") &&
      <div 
          className={`main-category-later-span`}>
          <span>should be here soon</span>
      </div> 
      } */}
      </div>
    </div>
    </AspectRatio>
  
  const itemCount = 
  template !== "live" &&
    <GridColums
      gridTemplateColumns={`1fr`} ///config-2 ///gridTemplateColumns={`1fr 1fr`} ///config-1
        justifyItems={"center"}
        alignItems={"center"}
      > 
      <ItemCount 
        activeTemplate={template} 
        showTemplateName={false} 
        showOnlySummary={false}
        // color={templatesSetup[template].color}
        show={
          activeTemplates.length > 0 && 
          generics.stringInArray(activeTemplates, template
          )}
        fontSize={0}
        //color={templatesSetup[template].color}
        // ref={(el) => { ///note: ref used to enable use of !important
        //   if (el) {
        //     generics.stringInArray(templatesData.show.activeTemplates, template) ?
        //       el.style.setProperty('color', templatesSetup[template].color, 'important') :
        //       el.style.setProperty('color', "transparent", 'important')
        //   } 
        // }}
    />
    </GridColums>

  return (
    <div 
      key={template}
      className={`
        main-category-wrapper-outer
        ${dlStatusCategories[template] ? "loaded--true" : "loaded--false"}
        ${isSelectedMainCategory ? "selected--true" : "selected--false"}
      `}>
      <GridColums
        name={"main-category-container"}
        // gridTemplateColumns={`minmax(0, 1fr)`} 
        // gridTemplateRows={`1em 1fr 1.5em`}
        // key={template}
        // rowGap={"0.5em"}
      > 
          {/* {mainCategoryNameComponent} */}
          {mainCategory}
          {plusButton}
          {itemCount}

      </GridColums> 
    </div>
  )
}
const MemoMainCategoryWrapper = memo(MainCategoryWrapper)

export const MainCategories = ({ templatesData, setTemplatesData, itemsDownloadStatus }) => {

  const
    { userCase, chat, userComment, ...dlStatusCategories } = itemsDownloadStatus?.download.overall.status,
    isTrueDlStatusCategories = generics.isAllValuesInObjEquals({obj: dlStatusCategories, value: true }) ///note: also calculated in MainPillars.jsx

  return (
    // <Sticky
    //   show={true}
    //   offsetSticky={"3.1em"}
    // >
    // {(isSticky) => (
      <>
      <div className={`main-categories-wrapper-outer`}>
        {/* <div className={"main-categories-header-container"}>
   
            <div className={"sub-header-container"}>
              <span className={`sub-header select ${isTrueDlStatusCategories ? "show fadein-05" : "hide fadeout-05"}`}>Select Categories</span>
              <span className={`sub-header ${!isTrueDlStatusCategories ? "show fadein-05" : "hide fadeout-05"}`}>Loading...</span>
            </div>
        </div> */}
        <GridColums
            name={"main-categories"}
        > 
          {templatesNames
            .filter((templateName) => templateName !== "userCase" && templateName !== "chat" && templateName !== "userComment") ///note: optimise, implement isMap parameter at templates
            .map((template) => 
              <MemoMainCategoryWrapper
                templatesData={templatesData} setTemplatesData={setTemplatesData}
                key={template}
                itemsDownloadStatus={itemsDownloadStatus}
                template={template}
              />          
          )}      
        </GridColums>

        {/* <Filtering/> */} 
      </div>
      </>
      // )}
      // </Sticky>
)}

// export const Rivers = () => {

//   ///ref
//   const activeTemplatesStateRef = useRef(); ///note: stateref implemented as state not read/update within callback, but stateref is ///see: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback

//   ///context
//   const 
//     { templatesData, setTemplatesData } = useContext(templatesDataContext),
//     { refine, setRefine } = useContext(refineContext),
//     { theme } = useContext(themeContext);
  
//   ///const via context:
//   const activeTemplates = templatesData.show.activeTemplates;

//   ///const
//   const 
//     name = "main-categories"

//   // ///toogle show filterbox ///note: copied from refine.jsx
//   // const toogleShowFilterBox = ({ show }) => {
//   //   const parameter = "show" ///optimise: combine serefine with handlesetrefine
//   //   const target = "filterbox"
//   //   setRefine(
//   //     produce((draft) => {
//   //       const item = draft
//   //       item[parameter][target] = show;
//   //     })
//   //   );
//   // }
  
//   ///on longpress =>
//   const longPressCallback = useCallback((event, activeTemplate_) => { ///note: callback triggered at longpress true
//     const 
//       activeTemplate = activeTemplate_["context"],
//       longPress = true 
//     handleSetActiveTemplate({ activeTemplate, longPress })
//   }, []);

//   ///on mousedown event => call handlelongpress with click or longpress info
//   const longPressEvent = useLongPress(true ? longPressCallback : null, {
//     onCancel: (event, activeTemplate_) => { ///note: equals onclick event
//       const 
//         activeTemplate = activeTemplate_["context"],
//         longPress = false 
//       handleSetActiveTemplate({ activeTemplate, longPress })
//     },
//     threshold: 400,
//   });

//   ///on mousedown event => on click or longpress => handle set activetemplate
//   const handleSetActiveTemplate = ({ activeTemplate, longPress }) => {
//     const templateIsIncluded = generics.stringInArray(activeTemplates, activeTemplate) ///clicked template already included in activetemplates
//     if (templateIsIncluded && activeTemplates.length === 1) { /// if clicked template is only activetemplate in activetemplates => abort (to mitigate no activetemplate, i.e. no data)
//       return
//     }
//     const removeActiveTemplate = 
//       longPress &&
//       templateIsIncluded
//     const indexOfActiveTemplate = generics.indexInArray(activeTemplates, activeTemplate)
//     setTemplatesData(
//       produce((draft) => {
//         switch (true) {
//           case longPress && !removeActiveTemplate: ///add template at longpress
//             draft.new.activeTemplate = activeTemplate
//             draft.show.activeTemplates.push(activeTemplate)
//             break;
//           case longPress && removeActiveTemplate: ///remove template at longpress
//             draft.new.activeTemplate = activeTemplate
//             draft.show.activeTemplates.splice(indexOfActiveTemplate, 1)
//             break;
//           case !longPress && templateIsIncluded: ///if included activetemplate is clicked => remove and add/activate 
//             draft.new.activeTemplate = activeTemplate
//             draft.update.activeTemplate = activeTemplate
//             draft.show.activeTemplates.splice(indexOfActiveTemplate, 1)
//             draft.show.activeTemplates.push(activeTemplate)
//             break;
//           case !longPress && !templateIsIncluded: ///if not included and click => active (and deactive other templates)
//             draft.new.activeTemplate = activeTemplate
//             draft.update.activeTemplate = activeTemplate
//             draft.show.activeTemplates = [activeTemplate]
//             break;
//           default:
//           break;
//         };
//       })
//     );
//   }

//   const noOfColumns = 8

//   return (
// 	  <Wrapper
//       name={name}
//     >
//       <GridColums
//           className={`main-categories-grid ${refine.show.filterbox ? "short" : "long"}`}
//           //gridTemplateColumns={`repeat(${8}, minmax(10%, 100%)`} ///note: optimise, implement isMap parameter at templates (instead of explicit -1)
//           gridAutoFlow={"column"}
//           style={{gridAutoColumns: `calc((100% / ${noOfColumns}) - (${noOfColumns} * 1vw)/${noOfColumns + 1})`}}
//           gridTemplateRows={`1fr`}
//           columnGap={"1vw"}
//           overflow={"scroll"}
          
//       > 
//         {templatesNames
//           .filter((templateName) => templateName !== "userCase") ///note: optimise, implement isMap parameter at templates
//           .map((template, index) => 
//             <GridColums
//               className={"rivers"}
//               gridTemplateColumns={`minmax(0, 1fr)`} 
//               gridTemplateRows={`fit-content(100%)`}
//               key={template}
//             > 
//             <GridColums
//               gridTemplateColumns={`1fr 1fr`}
//               justifyItems={"left"}
//               alignItems={"center"}
//               //gridTemplateRows={`1.5em 1fr fit-content(100%)`}
//             > 
//               <div
//                 className={`main-category-selected-container`}
//               >
//                 <div
//                   className={
//                     `
//                     main-category-selected second
//                     theme-background-${theme.theme} 
//                     ${refine.show.filterbox ? "short" : "long"}
//                     `}
//                   ref={(el) => { ///note: ref used to enable use of !important
//                     if (el) {
//                       generics.stringInArray(templatesData.show.activeTemplates, template) ?
//                         el.style.setProperty('background-color', templatesSetup[template].color, 'important') :
//                         el.style.setProperty('background-color', "transparent", 'important')
//                     } 
//                   }}
//                 >
//                 </div>
//               </div>
//               {/* <ItemCount 
//               activeTemplate={template} 
//               showTemplateName={false} 
//               showOnlySummary={false}
//               show={
//                 activeTemplates.length > 1 && 
//                 generics.stringInArray(activeTemplates, template
//                 )}
//               fontSize={0}
//               color={templatesSetup[template].color}
//               ref={(el) => { ///note: ref used to enable use of !important
//                 if (el) {
//                   generics.stringInArray(templatesData.show.activeTemplates, template) ?
//                     el.style.setProperty('color', templatesSetup[template].color, 'important') :
//                     el.style.setProperty('color', "transparent", 'important')
//                 } 
//               }}
              
//             /> */}
//             </GridColums>
 
//           </GridColums>
//         )} 
//       </GridColums>
//       {/* <div className={`${name}`}>
//         <div className={`${name}-x}`}>
//           Hej
//         </div> 
//       </div> */}
   
//     </Wrapper>
//   );
// }