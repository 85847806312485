import { GridColums, Symbol } from './ComponentStandards';
import { useState, useEffect, useMemo, useContext, createContext, useLayoutEffect, memo, useCallback, useDebugValue} from 'react';
import { MultiOptions } from './MultiOptions';
import { dimensionsInitial, DimensionsOptions } from './DimensionsOptions';
import { UserCaseInfo, OpenUserCase } from './UserCaseInfo';
import { ChooseTripType } from './UserCases';
import * as generics from './Generics';
import produce from "immer";
import * as NewArtwork_w from './NewArtwork_w';
import { itemsContext } from './ItemsProvider';
import { userCasesContext } from './UserCasesProvider';
import { sessionInfoContext } from './SessionInfoProvider';
import { userCommentsContext } from './UserCommentsProvider';
// import { templatesDataContext } from './DataProvider';

export const showContext = createContext([,() => {}])

export const initialValueShow = {  ///optimise: move individual values to specific components? 
    modal: { show: false, modalChild: null }, ///question/optimise: move requester to multioptions?
    multiOptions: { data: [], label: "", requester: "", action: "", dataSelected: [], payloadObjectName: "", payloadInnerPathViaArray: [], preSelectedOptions: [], document: "" }, ///note: payload/value location is ensured either via 1) payloadInnerPathViaArray or 2) document, label
    dimensionsOptions: { data: [], label: "", dataSelected: [] }, ///optimise: look into use... used?
    textualArray: { data: [], document: "", label: "", child: <></>, startUpload: false },
    textualObjects: { data: [], document: "", label: "", child: <></>, startUpload: false },
    openingHours: { data: [], document: "", label: "", child: <></>, startUpload: false },
    sessionInfoModal: { data: [], document: "", label: "", child: <></>, startUpload: false },
    chooseTripTypeModal: { data: [], document: "", label: "", child: <></>, startUpload: false }
  } 

export const Modal = ( { templatesData, userUid, children }) => { ///not used: classNameModalControls

  ///context
  const 
    { sessionInfo, setSessionInfo } = useContext(sessionInfoContext),
    { userCases } = useContext(userCasesContext),
    { items } = useContext(itemsContext),
    { userComments } = useContext(userCommentsContext)
    // { templatesData } = useContext(templatesDataContext)

  const [show, setShow] = useState(initialValueShow) ///consider: to rename to modal or similar
  const showValue = useMemo(
    () => ({ show, setShow }), 
    [show]
  );

  // useEffect(() => {
  //   console.log("userUiduserUid", userUid)
  //   if (!!userUid) {
  //     setShow(initialValueShow); ///reset/close 
  //   }
  // }, [userUid])

  ///options for modal
  const modalOptions = {
    null: null,
    multiOptions: <MultiOptions templatesData={templatesData}/>,
    dimensionsOptions: <DimensionsOptions/>,
    textualArray: <div className="center-by-transform input-padding-3">{show.textualArray.child}</div>, 
    textualObjects: <div className="center-by-transform input-padding-3">{show.textualObjects.child}</div>, ///optimise: consider to include in textualarray with new name "list" or something. only diff is criteria for modalcontrols ... (can save specific code of textualobjects and opening hours if solved differnetly, maybe via passing of a parameter to distingush...)
    openingHours: <div className="center-by-transform input-padding-3">{show.openingHours.child}</div>, ///optimise: openinghours can easly use modalchild type textualobjects (be included in), only is currently a object of objects, not an array of objects and this impact (only) showmodalcontrols (see difference below)
    sessionInfoModal: <UserCaseInfo sessionInfo={sessionInfo}/>,
    chooseTripTypeModal: <ChooseTripType isModal={true} sessionInfo={sessionInfo}/>,
    openUserCaseModal: <OpenUserCase/>
  }
  const payloadObjectMultiOptions = { ///implemented as a passed string fx items only can be interpreted as an object using eval (bad). optimise: make obsolute (so not need to be set)
    itemForm: templatesData.new.values[templatesData.new.activeTemplate], /// was: itemElements
    itemText: items ///optimise/risk/delete - update or delete this?
  }
  const showModalControls = { ///optimise: move values to specific component
    null: null,
    multiOptions: {
      minus: show.multiOptions.dataSelected.length > 0,
      check: ///note: if selected options === state options disable check, else enable.
        !generics.arrayOfObjectsForKeyIsEqual(generics.readViaArrayWithPath(payloadObjectMultiOptions[show.modal.requester], show.multiOptions.payloadInnerPathViaArray), show.multiOptions.dataSelected, show.multiOptions.label) ///optimise: consider to reduce complexity (of reading) .. also user newer arrayofobjectsisequal?
        || !generics.arrayOfObjectsForKeyIsEqual(show.multiOptions.dataSelected, generics.readViaArrayWithPath(payloadObjectMultiOptions[show.modal.requester], show.multiOptions.payloadInnerPathViaArray), show.multiOptions.label),
      times: true,
    },
    dimensionsOptions: {
      minus: true,
      check: true,
      times: false,
    },
    textualArray: {
      minus: false,
      check: 
            !!templatesData.update.document &&
              (templatesData.update.activeTemplate !== "userCase" && templatesData.update.activeTemplate !== "userComment" &&
                !generics.arraysIsEqual(
                  items.find((item) => item.document === templatesData.update.document)?.[templatesData.update.label],
                  templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
                )) ||
              (templatesData.update.activeTemplate === "userCase" &&
                !generics.arraysIsEqual(
                  userCases.find((userCase) => userCase.document === templatesData.update.document)?.[templatesData.update.label],
                  templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
                )) ||
              (templatesData.update.activeTemplate === "userComment" &&
              !generics.arraysIsEqual(
                userComments.find((userComment) => userComment.document === templatesData.update.document)?.[templatesData.update.label],
                templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
              )),
      times: true,
    },
    textualObjects: {
      minus: false,
      check: 
        !!templatesData.update.document &&
        (templatesData.update.activeTemplate !== "userCase" &&
          !generics.arrayOfObjectsIsEqual(
            items.find((item) => item.document === templatesData.update.document)?.[templatesData.update.label],
            templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
          )) ||
        (templatesData.update.activeTemplate === "userCase" &&
          !generics.arrayOfObjectsIsEqual(
            userCases.find((userCase) => userCase.document === templatesData.update.document)?.[templatesData.update.label],
            templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
          )),
        // templatesData.update.document !== "" && 
        // !generics.arrayOfObjectsIsEqual(items.find((item) => item.document === templatesData.update.document)?.[templatesData.update.label],  templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]),
      times: true,
    },
    openingHours: {
      minus: false,
      check: true,
        // templatesData.update.document !== "" && 
        // !generics.arrayOfObjectsIsEqual(generics.outerObjectToArray(items.find((item) => item.document === templatesData.update.document)[templatesData.update.label]), generics.outerObjectToArray(templatesData.update.values[templatesData.update.activeTemplate]?.[templatesData.update.label])), ///note: quickfix: last ? added to solve bug at submit video ...
      times: true,
    },
    sessionInfoModal: {
      minus: false,
      check: false,
      times: true,
    },
    chooseTripTypeModal: {
      minus: false,
      check: false,
      times: true,
    },
    openUserCaseModal: {
      minus: false,
      check: false,
      times: true,
    },
  }
  ///handle modal
  const handleModal = async(symbol) => {
  
    ///optimise: move handleset to specific components
    // console.log("x", items.find((item) => item.document === templatesData.update.document)[templatesData.update.label])
    // console.log("y", templatesData.update.values[templatesData.update.template][templatesData.update.label])
    const 
      actionModal = symbol,
      modalChild = show.modal.modalChild, //show.modal.modalChild,
      requester = show.modal.requester
    let
      child,
      parameter,
      action,
      value
      console.log("checkit")
    if (modalChild === "multiOptions") {
      child = modalChild
      switch (actionModal) {
        case "check":
          parameter = "action"
          action = "action-set"
          value = actionModal
          handleSetShow(child, parameter, action, value)
        break;
        case "times":
          setShow(initialValueShow); ///reset/close 
          break;
        case "minus":
          parameter = "dataSelected"
          action = "option-remove-all"
          value = []
          handleSetShow(child, parameter, action, value)
          return
          break;
        default:
          break;
      };
      //setShow(initialValueShow); ///reset/close 
      return
      }
      // if (modalChild === "dimensionsOptions") { ///optimise: move handlesetrefine to specific component
      //   switch (actionModal) {
      //     case "check": ///accept
      //       setShow(initialValueShow); ///reset/close ///question: move to "times"?
      //       break;
      //     case "times":
      //       ///nothing
      //       break;
      //     case "minus":
      //       const category = "dimensions"
      //       const value = dimensionsInitial
      //       ///handleSetRefine(category, value) ///note: removed for now, to litmit depencencies ///optimise: pass parameters and make function generic
      //       break;
      //     default:
      //       break;
      //   };
      // }
      if (templatesData.update.activeTemplate === "chat") {
        setShow(initialValueShow);
      }
      if (templatesData.update.activeTemplate === "userComment") {
        switch (actionModal) {
          case "check": ///accept 
            setShow(initialValueShow);
            break;
          case "times":
            setShow(initialValueShow); ///reset/close 
            break;
          case "minus":
            ///nothing
            break;
          default:
            break; 
        }; 
      }

      if (templatesData.update.activeTemplate === "userCase") { ///optimise: move handlesetrefine to specific component
     
        switch (actionModal) {
          case "check": ///accept 
            if (modalChild === "textualArray") {
              const value = templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
              await NewArtwork_w.updateUserCase({ label: templatesData.update.label, document: sessionInfo.document, value, isAdd: false }) ///optimise: consider to use startUpload true and move to component
            }
            if (modalChild === "textualObjects") {
              const value = templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label]
              const filteredValue = generics.objectWithArraysWithObjectsFilterArraysIfNoValue({ obj: value })
              await NewArtwork_w.updateUserCase({ label: templatesData.update.label, document: sessionInfo.document, value: filteredValue, isAdd: false }) ///optimise: consider to use startUpload true and move to component
            }
            setShow(initialValueShow);
            // child = modalChild
            // parameter = "startUpload"
            // action = "start-upload" ///note: not used
            // value = true
            // handleSetShow(child, parameter, action, value)
            break;
          case "times":
            setShow(initialValueShow); ///reset/close 
            break;
          case "minus":
            ///nothing
            break;
          default:
            break; 
        };
      }
      if (templatesData.update.activeTemplate !== "userCase" && templatesData.update.activeTemplate !== "userComment" && modalChild === "textualArray" || modalChild === "textualObjects" || modalChild === "openingHours") { ///optimise: move handlesetrefine to specific component
        switch (actionModal) {
          case "check": ///accept 
            child = modalChild
            parameter = "startUpload"
            action = "start-upload" ///note: not used
            value = true
            handleSetShow(child, parameter, action, value)
            break;
          case "times":
            setShow(initialValueShow); ///reset/close 
            break;
          case "minus":
            ///nothing
            break;
          default:
            break; 
        };
      }
      if (modalChild === "chooseTripTypeModal" || modalChild === "openUserCaseModal") { 
        switch (actionModal) {
          case "check":
            setSessionInfo({}) ///reset ///risk: correct?
            setShow(initialValueShow); ///close 
            break
          case "times": 
              setSessionInfo( 
                produce((draft) => {
                  draft.open = false
                  })
              )
              setShow(initialValueShow); ///close 
            break;
          default:
        };
      }
      else {
        switch (actionModal) {
          case "times":
            setShow(initialValueShow); ///reset/close 
            break;
          default:
        };

      }
  }

  const handleSetShow = (child, parameter, action, value, index) => {  

    setShow(
      produce((draft) => {
        draft[child][parameter] = value; ///consider to remove action and update pattern is the same (not dependent on action)
        // switch (action) { 
        //   case "option-remove-all":
        //     draft[child][parameter] = value; ///optimise: find out how to use parameter
        //     break;
        //   case "action-set":
        //     draft[child][parameter] = value; ///optimise: find out how to use parameter
        //     break;
        //   default:
        //   break;
        // };
      })
    )
	}

    ///new session (new userCase)
    useEffect(() => { 
      // setShow(
      //   produce((draft) => { 
      //     draft["modal"]["show"] = true
      //     draft["modal"]["modalChild"] = "chooseTripTypeModal"
      //   })
      // );
      if (sessionInfo.isModal) {
        if (!!sessionInfo.type) {
          setShow(
            produce((draft) => { 
              draft["modal"]["show"] = true
              draft["modal"]["modalChild"] = "sessionInfoModal"
            })
          );
        }
        if (!sessionInfo.type) {
          setShow(
            produce((draft) => { 
              draft["modal"]["show"] = true
              draft["modal"]["modalChild"] = "chooseTripTypeModal"
            })
          );
        }
      }
    }, [sessionInfo.document, sessionInfo.isModal, sessionInfo.type]); 
  
  
    ///note: if open user-case =>  ///optimise: consider to combine with other sessionInfo useEffect
    useEffect(() => {
      if (!sessionInfo.document) {
        if (sessionInfo.open) {
          setShow(
            produce((draft) => { 
              draft["modal"]["show"] = true
              draft["modal"]["modalChild"] = "openUserCaseModal"
            })
          );
        }
      }
    }, [sessionInfo.open])

    useEffect(() => {
      if (!!sessionInfo.document) {
        if (!sessionInfo.open) {
          setShow(initialValueShow); ///reset/close 
        }
      }
    }, [sessionInfo.open])
  

  ///toogle body scroll on modal open/close
  // useEffect(() => {
  //   show.modal.show ? 
  //     document.body.classList.add('modal-open') : 
  //     document.body.classList.remove('modal-open')
  // }, [show.modal.show]);

  // const handleSetRefine = (category, value) => {
  //   setRefine(
  //     produce((draft) => {
  //       draft[category] = value; 
  //     })
  //   )
	// };

  // ///on load => set session id
  // useEffect(() => { ///modal reset in case of itemform => => modal check/accept change => set templatesdata.new => useeffect =>
  //     setSessionInfo("abc1")
  // }, []); 

  ///reset of modal, included after set of items (via modal/multioptions)
  useEffect(() => { ///modal reset in case of itemform => => modal check/accept change => set templatesdata.new => useeffect =>
      setShow(initialValueShow)
  }, [templatesData.new]); 

  // useEffect(() => { ///modal reset in case of itemtext => modal check/accept change => update at backend => set of items (client-side) => useeffect =>
  //   if (show.modal.requester === "itemText") {
  //     setShow(initialValueShow)
  //   }
  // }, [items]);

  // useEffect(() => { ///modal reset in case of itemtext => modal check/accept change => update at backend => set of items (client-side) => useeffect =>
  //   if (show.modal.modalChild === "textualArray" || show.modal.modalChild === "textualObjects" || show.modal.modalChild === "openingHours") {
  //     setShow(
  //       produce((draft) => {
  //         draft.multiOptions = initialValueShow.multiOptions
  //       })
  //     )
  //   }
  // }, [show.modal.modalChild]);

	
  const Controls = () => {
    const symbols = ["minus", "check", "times"]
    return (
      <div className={`modal-controls-wrapper`}>
        <GridColums
          className={`modal-controls`}
          gridTemplateColumns={`3vw 3vw 3vw`} 
        >
          {symbols.map((symbol) => 
            <Symbol
              key={symbol}
              symbol={symbol}
              showControl={showControl}
              parentCallback={() => handleModal(symbol)}
            ></Symbol>
          )}
        </GridColums>
      </div>
    )
  }

  const
    isShow = show.modal.show,
    showControl = show.modal.modalChild !== null && generics.getValueFromKey(showModalControls, show.modal.modalChild),
    memoChildren = modalOptions[show.modal.modalChild] ///generics.getValueFromKey(modalOptions, show.modal.modalChild)

  // const
  //   modalWidth = getModalWidth({ modalChildName: modal.modalChildName }),
  //   memoChildren = useMemo(() =>  modalOptions[modal.modalChildName], [modal, templatesData, templatesData.new.activeTemplate, templatesData.new.values]), ///note: templatesData.new.activeTemplate added to ensure update of UI at template change. note: templatesData.new.values added to ensure update of UI at values change
  //   memoShowControl = useMemo(() =>  !!modal.modalChildName && showModalControls[modal.modalChildName], [modal.modalChildName, modal.isAcceptEnabl, modal.isClearAllEnabl]  )

  return (
    <showContext.Provider value={showValue}> 
    <>
      <dialog className={`
        modal-wrapper
        ${isShow ? "show fadein-05" : "hide fadeout-05"}
      `}
      >
        <div className={`modal
        input-radius input-border-default
        ${isShow ? "show slide-up-into-place" : "hide slide-down-out-of-place"}
        `}
        >
          <Controls/>
          { memoChildren }
 
        </div>
      </dialog>
      { children(show, setShow) }
      </>
    </showContext.Provider>
  );
}

export const ModalHeader = ( { content } ) => {
  return (
    <div className={`modal-controls-headline-wrapper`}>
      <div className={`modal-controls-headline`}>
        <span className={`modal-controls-headline-content`}>
          {content}
        </span>
      </div>
    </div>
  )
}