import React, { createContext, useState, useMemo, useEffect, useContext, useCallback } from 'react';
import * as generics from './Generics';
import { getItemsRefined, sortItemsRefined, getFilterOptions, sorting } from './GalleryRefined';
import { UserPresent }from './UserUidProvider';
// import { templatesDataContext } from './DataProvider';
import { usePrevious } from './StateOptions';
import { templates } from './ItemForm';
import { dimensionsInitial } from './DimensionsOptions';
import { itemsContext } from './ItemsProvider';
import { sessionInfoContext } from './SessionInfoProvider';
import { urlInfoContext } from './UrlInfoProvider';
import produce from "immer";

export const itemsRefinedContext = createContext([,() => {}])
export const refineContext = createContext([,() => {}])

export const ItemsRefinedComponent = ({ templatesData, itemsDownloadStatus, focusItemDocument, children }) => {

  ///context
  const
    { sessionInfo } = useContext(sessionInfoContext),
    { items } = useContext(itemsContext),
    // { templatesData } =  useContext(templatesDataContext),
    { urlInfo } =  useContext(urlInfoContext) 

  ///const from context
  const
    activeTemplates = templatesData.show.activeTemplates,
    activeTemplate = activeTemplates[activeTemplates.length - 1]

    

  ///state
  const [itemsRefined, setItemsRefined] = useState(items)
  const itemsRefinedValue = useMemo(
    () => ({ itemsRefined, setItemsRefined }), 
    [itemsRefined]
  );
  const initialStateRefine = 
    {
      gallery: {itemsPrRow: 5 },
      lookup: "", category: "all", show: { filterbox: false },
      listLength: { gallery: {...{multiple: 6}, ...generics.objMap(templates, (x) => 6, false) }},
      filterOptions: [], filterMode: true, filters: generics.objMap(templates, (x) => [], false), activeFilterAttr: generics.objMap(templates, (x) => Object.keys(x)[0], false),  ///note: show filterbox null used as default, due to zero-to-full-height animation (prevent animation on render). optimise: rename filters to filtersSelected. note: filterMode set to false="exlude mode"
      dimensions: dimensionsInitial, // { height: 0, width: 0, height_o: 0, width_o: 0, heightInterval: 0, widthInterval: 0, forceGalleyRefined: false}
      documentMore: { document: "", directLoad: true, documentIndex: null, documentIndexs: [] }, ///optimise: strickly documentmore is not dependent on refine criteria, but is does accept what information is rendered ...
      sort: ""
    }
  const [refine, setRefine] = useState(initialStateRefine) 
  const refineValue = useMemo(
    () => ({ refine, setRefine }), 
    [refine]
  );

  let userPresent = UserPresent()
  const prevItemsRefined = usePrevious(itemsRefined);

  const
    { userCase, chat, userComment, ...dlStatusCategories } = itemsDownloadStatus?.download.overall.status,
    isTrueDlStatusCategories = generics.isAllValuesInObjEquals({obj: dlStatusCategories, value: true }) ///note: also calculated in MainPillars.jsx

	///on refine or focusItemDocument change => 
	useEffect(() => {
    if (isTrueDlStatusCategories) { ///note: only calc itemsrefined if isTrueDlStatusCategories
    handleGetItemsRefined({ items })
    }
	}, [isTrueDlStatusCategories, activeTemplate, activeTemplates, refine.dimensions, refine.sort, refine.category, refine.lookup, refine.filters, sessionInfo.isOnlyHeart, sessionInfo.userCases, sessionInfo.isOnlyRedHeart, sessionInfo.documents, focusItemDocument]);


  function measureExecutionTime(func) {
    // Record the current time before the function runs
    const startTime = performance.now();
  
    // Execute the function
    func();
  
    // Record the current time after the function runs
    const endTime = performance.now();
  
    // Calculate the difference to get the execution time in milliseconds
    const executionTime = endTime - startTime;
  
    console.log(`Function execution time: ${executionTime} milliseconds`);
  }

  
  const handleGetItemsRefined = useCallback( async ({ items }) => {
    let itemsRefined = await getItemsRefined( userPresent, items, refine, urlInfo, prevItemsRefined, activeTemplates, sessionInfo.isOnlyHeart, sessionInfo.userCases, sessionInfo.isOnlyRedHeart, sessionInfo.documents )
    if (!!refine.sort) { ///note: if sort is defined => sort
      const sortValue = Object.values(refine.sort)[0]
      itemsRefined = await sortItemsRefined(itemsRefined, sortValue)
    }

    if (!!focusItemDocument.document && focusItemDocument.isMapFocus) {

        const index = itemsRefined.findIndex(item => item.document === focusItemDocument.document); ///find the index of the item
        if (index !== -1) { ///move the item to the front if it exists in the array

          const focusItem = itemsRefined.splice(index, 1); ///remove the item from its current position

          itemsRefined.unshift(focusItem[0]); /// insert the item at the front of the array
        }
    }
    setItemsRefined(itemsRefined)
  }, [activeTemplate, activeTemplates, refine.dimensions, refine.sort, refine.category, refine.lookup, refine.filters, sessionInfo.isOnlyHeart, sessionInfo.userCases, sessionInfo.isOnlyRedHeart, sessionInfo.documents, focusItemDocument])


  const handleSetItemsRefined = ({}) => {
    console.log("handleSetItemsRefined")
  }

  const handleSetRefine = ({ parameter, action, value }) => { ///note: currenly only callback is from Home component (i.e. listLength-update) 
			console.log("handleSetRefine", parameter, action, value )
    const 
      itemsPrRow = refine.gallery.itemsPrRow,
      period = itemsPrRow + 1

    setRefine(
      produce((draft) => {
        switch (action) {
          case "filterOptions-update":
            draft[parameter] = value
            break;
          case "listLength-update":
              generics.isArrayLengthAbove(activeTemplates, 1) ?
                draft[parameter]["gallery"]["multiple"] += value :
                draft[parameter]["gallery"][activeTemplate] += value 
            break;	
          case "documentMore-update-from-user-interaction":
            draft[parameter]["document"] = value.document
            draft[parameter]["directLoad"] = value.directLoad ///optimise: get "directLoad" via map over value (inplicit)
            draft[parameter]["documentIndex"] = value.documentIndex
            if (generics.isNumber(value.documentIndex)) { ///optimise: only invoke function if defined, solve above
              const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
              if (index_ !== -1) { ///if already selected => remove index
                draft[parameter]["documentIndexs"].splice(index_, 1) 
              } 
              else { ///if not selected
                const 
                  rangeMax = Math.ceil(value.documentIndex/period)*(period) - 2, ///index of last item in row note: minus 2, supposingly due to index - 1 in addition to minus 1 for extra element (the documentmore view)
                  rangeMin = rangeMax - itemsPrRow,
                  indexArray = generics.makeArrayViaRange(rangeMin, rangeMax) ///array with index in row
                indexArray.map((i_, i) => {
                  const index__ = draft[parameter]["documentIndexs"].findIndex(index => index === i_)
                    if (index__ !== -1) {
                      draft[parameter]["documentIndexs"].splice(index__, 1) 
                    } 
                })
                draft[parameter]["documentIndexs"].push(value.documentIndex)
              }
            }
            break;
          case "documentMore-update-from-url-change":
            draft[parameter]["document"] = value.document ///optimise: get "document" via map over value (inplicit)
            draft[parameter]["documentIndex"] = value.documentIndex
            if (generics.isNumber(value.documentIndex)) {
              const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
              index_ !== -1 ? 
                draft[parameter]["documentIndexs"].splice(index_, 1) :
                draft[parameter]["documentIndexs"].push(value.documentIndex)
            }
            break;
          // case "sort-change":
          // 	draft[parameter] = value
          // 	break;
          default:
            break;
        };
      })
    ) 
  };


  const prevRefine = usePrevious(refine.show.filterbox)

  return (
    <itemsRefinedContext.Provider value={itemsRefinedValue}> 
    <refineContext.Provider value={refineValue}>
      {children(
        itemsRefined, setItemsRefined, handleSetItemsRefined,
        refine, prevRefine, setRefine, handleSetRefine
      )}
    </refineContext.Provider>
    </itemsRefinedContext.Provider>
  )
}