import './app.css';
import './styling-standards.css';
//import './instagram-embed.css';
import { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { Wrapper } from './ComponentStandards';
import * as icons from './Icons';
import { InstagramEmbed, FacebookEmbed, TikTokEmbed, TwitterEmbed } from 'react-social-media-embed';

export const socialsList = ["instagram", "facebook", "tiktok", "twitter"]
export const socialsIcons = [icons.instagramIcon, icons.facebookIcon, icons.tiktokIcon, icons.twitterIcon]

export const getSocialMediaTypeInfo = ({ url }) => {

  let mediaPlatform, mediaSubtype
  switch (true) {
    case url.includes("instagram"):
      mediaPlatform = "instagram";
      mediaSubtype = ""
      break;
    case url.includes("facebook") || url.includes("fb.watch"):
      mediaPlatform = "facebook";
      mediaSubtype = url.includes("reel") ? "reel" : "video"
      break;
    case url.includes("tiktok"):
      mediaPlatform = "tiktok";
      mediaSubtype = ""
      break;
    case url.includes("youtu"): ///incl youtube and youtu.be
      mediaPlatform = "youtube";
      mediaSubtype = url.includes("reel") ? "reel" : "video"
      break;
    case url.includes("twitter") || url.includes("x.com"): ///incl youtube and youtu.be
      mediaPlatform = "twitter";
      mediaSubtype = ""
    break;
    default:
    break;
  };
  return [mediaPlatform, mediaSubtype]
}

export const SocialMediaPostPreview = ({ url, isWatermark, className }) => {
  const [mediaPlatform, mediaSubtype] = getSocialMediaTypeInfo({ url })

  const
    memoMediaPlatform = mediaPlatform, ///useMemo(() => mediaPlatform)
    memoMediaSubtype = mediaSubtype, ///useMemo(() => mediaSubtype)
    memoUrl = url ///useMemo(() => url)

  const socialMediaPost = 
    <MemoSocialMediaPost
    mediaPlatform={memoMediaPlatform}
    mediaSubtype={memoMediaSubtype}
    url={memoUrl}
  >
  </MemoSocialMediaPost> 

  return (
    <div className={`socialmedia-post-preview-wrapper ${className} ${mediaPlatform} ${mediaSubtype}`}>
    {isWatermark ?
      <div className={`socialmedia-post-watermark ${mediaPlatform}`}>
        { socialsIcons[socialsList.findIndex(social => social === mediaPlatform)] }
      </div> : 
    null}
      {socialMediaPost}
    </div>
  )
}

const MemoInstagramEmbed = memo(InstagramEmbed)
const MemoFacebookEmbed = memo(FacebookEmbed)
const MemoTikTokEmbed = memo(TikTokEmbed)

export const SocialMediaPost = memo(({ mediaPlatform, mediaSubtype, url }) => {

  const memoUrl = useMemo(() => url)

  const getMediaComponent = useCallback(({ url }) => {
    let mediaComponent = <div/>
    switch (true) {
      case mediaPlatform === "instagram":
        mediaComponent = 
          <MemoInstagramEmbed 
            url={memoUrl}
            width={328}
            //captioned 
          />
        break;
      case mediaPlatform === "facebook":
        mediaComponent =
        <MemoFacebookEmbed 
          url={url}
          width={450} 
        />
        break;
      case mediaPlatform === "tiktok":
        mediaComponent =
        <MemoTikTokEmbed 
          url={url}
          width={"auto"} 
        />
        break;
      // case mediaPlatform === "twitter":
      //   mediaComponent =
      //   <TwitterEmbed 
      //     url={url}
      //     width={"auto"} 
      //   />
      //   break;
      default:
      break;
    }
    return mediaComponent
  }, [url])

  const mediaComponent = getMediaComponent({ url })

  const MediaComponent = useCallback(() => 
    <div className={`socialmedia-post-wrapper ${mediaPlatform}`}>
      <div className={`socialmedia-post-container ${mediaPlatform} ${mediaSubtype}`}>
        <div className={`socialmedia-post ${mediaPlatform} ${mediaSubtype}`}>
          { mediaComponent }
        </div>
      </div>
    </div>, [url])

  return (
    <MediaComponent/>
  );
})
const MemoSocialMediaPost = memo(SocialMediaPost)


