
import './app.css';
import './styling-standards.css';
import './rating.css';
import { useState, useEffect } from 'react';
import { Wrapper } from './ComponentStandards';
import * as icons from './Icons';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export const Rating = ({ isNew, type, isReviewTagChosen, rating, isEditEnabled, ratingCallback }) => {
	
  const name = "rating"

  const 
    [hoverRating, setHoverRating] = useState(0),
    [isShow, setIsShow] = useState(false)

  useEffect(() => {
    let isShow = false
    switch (true) {
      case isNew:
        isShow = isReviewTagChosen || hoverRating > 0 || rating > 0
        
        break;
      case !isNew:
        isShow = rating > 0

        break;
      default:
      break;
    };
    setIsShow(isShow)
  }, [isNew, rating, isReviewTagChosen, hoverRating])

///isReviewTagChosen && userComment_?.new || newRating > 0 || !userComment_?.new && userComment.rating > 0

  const 
    solidIcon = type === "plan" ? icons.shakaSolidIcon({ color: "gold "}) : icons.starSolidIcon({ color: "gold "}),
    regularIcon = type === "plan" ? icons.shakaRegularIcon({ color: "gold "}) : icons.starRegularIcon({ color: "gold "}),
    stars = Array(5).fill("x").map((fill, index) => {
      const star = 
        ((isShow || hoverRating > 0) && 
        isEditEnabled && 
        hoverRating !== 0 ? 
          index < hoverRating : 
          isShow ? index < rating : false)  ? solidIcon : regularIcon 
      return star
    }) 

  return (
	  // <Wrapper
    //   name={name}
    // >
      <div className={`${name}-container`}>
        <div className={`${name}`}>
          <div className={`stars-container`}
      
          >
            { !isShow ? <span className="tooltiptext">{`want to review? select 'review'`}</span> : null }
            <div className={`stars
            ${isShow ? "show fadein-05" : "fade fadeout-05"}
            `}
            style={{cursor: isShow && isEditEnabled ? "pointer" : ""}} ///was: not-allowed
            >
              {stars.map((star, i) => 
                 <div className={`star`}
                    key={i}
                    onMouseEnter={() => setHoverRating(i + 1)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => {
                      if (isEditEnabled) {
                        const newRating = i + 1 === rating ? 0 :  i + 1 ///note: enable reset of rating (no rating)
                        ratingCallback({ rating: newRating })
                      }
                    }}
                 >
                    {star}
                 </div>
              )}
            </div>
          </div>
        </div>
      </div>
    // </Wrapper>
  );
}
