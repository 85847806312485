import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import * as Gallery_r from './Gallery_r';

export const userCasesContext = createContext([,() => {}])

export const UserCasesProvider = ({ templatesData, children }) => { ///listening to open usercase at !!sessionInfo moved to sessionInfoProvider

  ///state
  const [userCases, setUserCases] = useState([])
	const userCasesValue = useMemo(
    () => ({ userCases, setUserCases }), 
    [userCases]
  );

  ///download user-cases
  useEffect(() => {
    getUserCases()
  }, [])

  const getUserCases = async() => {
    const templateName = "userCases"
    let userCases_ = await Gallery_r.getDataGallery({ templateName }) 
    userCases_ = [...userCases, ...userCases_]
    setUserCases(userCases_) ///set user-cases
  }

  const handleSetUserCases = () => {
    console.log("handleSetUserCases")
  }

    return (
      <userCasesContext.Provider value={userCasesValue}> 
          {children(userCases, setUserCases, handleSetUserCases)}
      </userCasesContext.Provider>
    )
}