import { itemsContext, refineContext, urlInfoContext } from './App';

import * as generics from './Generics';
import { useState, useEffect, useContext, useRef, useCallback, createContext, useMemo } from 'react';
import { SortableItem } from 'react-easy-sort';
import { multiOptionsValues, templatesType, templatesFilterableAttr } from './ItemForm';



export const getItemsRefined = (userPresent, items, refine, urlInfo, prevItemsRefined, activeTemplates, isOnlyHeart, userCases, isOnlyRedHeart, documents) => {
  const startTime = performance.now();
  const directLoadArtwork = refine.documentMore.directLoad //urlInfo["directload"]["artwork"]
  const documentParams = refine.documentMore.document //urlInfo["directload"]["documentParams"]
  const activeAttr = [...new Set(activeTemplates.map((activeTemplate => templatesFilterableAttr[activeTemplate])).flat())] ///was: activeTemplate].sort()
  const categories = refine["category"] === "all" ? activeAttr : [refine["category"]]
  // const filterCategories = Object.keys(Object.assign({}, ...refine.filterOptions)).sort() ///const
  // const filtersByCategory = filterCategories.map(category => { ///optimise: move to generic
  //   let filters = {
  //   [category]: refine.filters[activeTemplates[0]]
  //     .filter((filter => filter[category] !== undefined))
  //     .map((filter => filter[category])) 
  //   }
  //   return filters
  // })
  // const filtersByCategoryNonEmpty = filtersByCategory.filter(object => Object.values(object)[0].length > 0) ///object including only categories where filters have been selected
  
  const organiseFilters = (filterObjPrTemplate) => { ///organises filters as template.category.filterarray
    let filterCategories = [] ///note: filtercategories (pr. template)
    Object.values(filterObjPrTemplate) ///collect filtercategories pr. template via map
      .map((v) => {
        Object.entries(v).map(([k_, v_]) => {
          if (!generics.stringInArray(filterCategories, k_)) {
            filterCategories.push(k_)
            }
        })
      })
      return filterCategories.map((category) => { ///map each filtercategory, and popuplate array with filters
        const valueArray = []
        Object.values(filterObjPrTemplate)
          .map((v) => {
            Object.entries(v).map(([k_, v_]) => {
              if (k_ === category) {
                valueArray.push(v_)
              }
            })
          })
      return { [category]: valueArray}
    })
  }

  const organisedFilters = generics.objMap(refine.filters, organiseFilters, false)

  let itemsRefined = []
  itemsRefined = 
    items
      .filter(( item => isOnlyHeart ? 
        generics.isExistValueInArrayInsideArrayOfObjects(userCases, "documents", item.document)
        : true ))
      .filter(( item => isOnlyRedHeart ? 
          documents.some(document => document === item.document)
        : true ))
      //.filter(( item => !item.hasOwnProperty("caseId")))
      //.filter( ( { publish } ) => !userPresent ? (publish || !publish) : publish )
      .filter(( { template } ) => generics.stringInArray(activeTemplates, template) )
      //.filter( ( { document } ) => directLoadArtwork ? generics.stringsAreIdentical(document, documentParams, false) : document !== null) ///at directloadartwork => only load specific item
      .filter(item => {
        const templateFilterableAttr = templatesFilterableAttr[item.template] ///filterable attributes for that item via template
        if (generics.arrayContainsValueInArray(templateFilterableAttr, categories)) { ///if item attr is included in search categories, return item if match
          return generics.partialMatchFromStart(refine["lookup"], generics.valuesToStringViaKeys(item, categories, " "))
        } else {
          return item ///if item attr is not included in search categories, return item (no filtering)
        }
        }) ///search: join relevant information and filter for matches in joined information
      .filter(item => { ///filtering
        if (generics.arrayEmpty(organisedFilters[item.template])) { ///if no filters selected for item template => return item (no filtering)
          return item
        }
        // if (generics.objectIsEmpty(organisedFilters)) {///was: (filtersByCategoryNonEmpty.length === 0) { ///case: no filter selected (fx on load)
        //   return item
        // }
        const check = ///check for each category with a filter selected that item option is identical to at least one filter in that category, output a array with a boolean pr. non-empty category
        organisedFilters[item.template] ///was: filtersByCategoryNonEmpty ///array with objects, each object a category with given filters: [{artist: ['francisco toledo', 'frida kahlo']}, {title: ...}]
            .map(object => { // map over each object/category: artist: ['francisco toledo', 'frida kahlo']
              const category = Object.keys(object) ///array: ['artist']
              const itemContentPrCategory = templatesType[activeTemplates[0]][category] === "multiOptions" ? ///was: generics.keyExist(multiOptionsValues, category) ? ///array with item content for the given category
                item[category].map(a => a[category]) : ///array: ['tag', 'tagit'] 
                [item[category]] ///array: ['francisco toledo']
              const filtersSelectedPrCategory = generics.valueByKeyInArrayOfObjects(organisedFilters[item.template], category) ///array: ['francisco toledo', 'frida kahlo']
              const itemOptionEqualASelectedFilterPrCategory = itemContentPrCategory.map((itemOption) => { ///array with boolean; true if a item option is included in the selected filters
                return generics.stringInArray(filtersSelectedPrCategory, itemOption)  
              })
              return generics.stringInArray(itemOptionEqualASelectedFilterPrCategory, true) ///array with boolean; true if true found just once, else false: [true]
            })
        const include = 
          // refine.filterMode ? 
          // generics.stringInArray(check, true) : ///array with boolean; true if value true found just once 
          generics.valuesIdenticalInArray(check, true) ///array with boolean; true if all values are true
        if ( include ) {
          return item
        }
      })
      ///includeagain:
      // .filter(( item ) => { ///filter dimensions (height and width) 
      //   const { dimensions_g_cm } = refine.dimensions.dim 
      //   const { dimensionsInterval_cm } = refine.dimensions.int
      //   const height = Number(dimensions_g_cm.height) ///optimise: generic.round should return number, so number() is obsolute
      //   const width = Number(dimensions_g_cm.width)
      //   const heightInterval = Number(dimensionsInterval_cm.height) //refine.dimensions.heightInterval
      //   const widthInterval = Number(dimensionsInterval_cm.width) //refine.dimensions.widthInterval
      //   const itemHeight = item.dimensions[0]
      //   const itemWidth = item.dimensions[1]
      //   if (height === 0 && width === 0) { ///case: no filter selected
      //     return item
      //   }
      //     return (
      //       itemHeight >= height - heightInterval &&
      //       itemHeight <= height + heightInterval &&
      //       itemWidth >= width - widthInterval && 
      //       itemWidth <= width + widthInterval 
      //     )
      // })
  if ( ///return previtemsrefined if previtemsrefined equal itemrefined => to mitigate re-render of items (at search with no change in items)
    refine.search ==! "" && ///optimise: consider to optimise .... note: criteria added to ensure that update of items (fx change of text) => update of itemsrefined. risk that search is not empty at update...
    items.length !== itemsRefined.length && ///items.length !== itemsRefined.length to return itemsRefined on load (note: prevItemsRefined.length result in error at load..?)
    generics.arrayOfObjectsForKeyIsEqual(itemsRefined, prevItemsRefined, "document") && ///case: search add a letter
    generics.arrayOfObjectsForKeyIsEqual(prevItemsRefined, itemsRefined, "document")) ///case: search delete a letter
    {
    return prevItemsRefined
  }
  const endTime = performance.now();
  const executionTime = endTime - startTime;
  // console.log(`Function execution time: ${executionTime} milliseconds`);
  return itemsRefined
}

export const sorting = [ ///optimise: rename to sortarray or something
  {id: 0, value: "Newest", parameter: "created"},
  {id: 1, value: "Title", parameter: "title"},
  // {id: 1, value: "Price: Low To High", parameter: "price"},
  // {id: 2, value: "Price: High To Low", parameter: "price"},
  // {id: 3, value: "Artist: A to Z", parameter: "artist"},
]

export const sortItemsRefined = (itemsRefined, sortValue) => {
  const id = sorting.filter((obj) => obj.value === sortValue).map((obj) => obj.id)[0]
  const parameter = sorting.filter((obj) => obj.value === sortValue).map((obj) => obj.parameter)[0]
  const sortedItems = itemsRefined.sort(( a, b ) => {
    switch (true) { ///optimse: use parameter instead of id and exclude id? optimise: make function lookup in sorting (sortarray), i.e. define there, not here in switch statement
      case id === 0:
        return generics.sortDate(a, b, parameter)
        break;
      // case id === 1:
      //   return generics.sortLowToHigh(a, b, parameter) 
      //   break;
      // case id === 2:
      //   return generics.sortHighToLow(a, b, parameter) 
      //   break;
      case id === 1:
        return generics.sortAbsending(a, b, parameter) 
      break;
      default:
        break;
    } 
  })

  return sortedItems
}
    
export const getFilterOptions = (items, lookup, categories, activeTemplate, templatesValue) => {

  const filterOptions = templatesFilterableAttr[activeTemplate] 
    .map((category) => {
      const options = 
        items
          .filter(item => generics.partialMatchFromStart(lookup, generics.valuesToStringViaKeys(item, categories, " "))) ///exclude filters on search
          .filter(item => !generics.stringEmpty(item[category]) && !generics.arrayEmpty(item[category])) ///note: to exclude no filter value (empty strings and one-dim empty arrays). optimise: consider to include empty multi-dim arrays, like dimensions. not working: item[category] !== templatesValue[activeTemplate][category], and would also falsely exlude default value, if any optimise: consider to include no filter, and style via css as "no filter" so users can filter for no filter for that category
          .map((item) => {
            
            // const itemContentPrCategory = generics.keyExist(multiOptionsValues, category) ? ///array with item content for the given category
            // item[category].map(a => a[category]) : ///array: ['francisco toledo']
            // [item[category]] ///array: ['tag', 'tagit']
            // //console.log("d", itemContentPrCategory)
            // const x = itemContentPrCategory.map((filter) => {
            //   //console.log("hejsa", filter) // generics.partialMatchFromStart(lookup, filter))
            // })
            //console.log("item cat", itemContentPrCategory)
            return item[category]
          })
          .sort();

        const object = {}
        object[category] = options
        ///console.log("options", options)
        return object
    })
  return filterOptions
}

// export const getFilterOptions = (items, lookup, categories, activeTemplate) => {
//   const filterOptions = templatesFilterableAttr[activeTemplate].map((data) => {
//     const options = items.map((item) => item[data]).sort();
//     const object = {}
//     object[data] = options
//     return object
//   })
//   return filterOptions
// }