import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import './theme-provider.css';

export const themeContext = createContext([,() => {}])

export const ThemeProvider = ({ children }) => {

  ///state
  const [theme, setTheme] = useState({ theme: 0, view: 1 })
  const themeValue = useMemo(
    () => ({ theme, setTheme }), 
    [theme]
  );

    ///change in theme.theme => set --color themes ///optimise: consider to place in switchtheme, but with root as scope (using $color-theme and $color-theme-flipped enables control from switchtheme, but root is not targeted properly)
    useEffect(() => {
      const 
        colors = ["#ffffff", "#414141"],
        newColor = theme.theme === 0 ? colors[0] : colors[1],   
        newColorFlipped = theme.theme === 1 ? colors[0] : colors[1]
      setColor( "--color-theme", newColor )
      setColor( "--color-theme-flipped", newColorFlipped )
    }, [theme.theme]);
  
    function setColor ( variable, newColor ) {
      document.documentElement.style.setProperty(variable, newColor); ///note: const color = getComputedStyle(document.documentElement).getPropertyValue(variable);
    }
   
  return (
      <themeContext.Provider value={themeValue}> 
        <div className={`theme theme-${theme.theme}`}>
         {children(theme, setTheme)}
        </div>
      </themeContext.Provider>
  )
}