import './app.css';
import './styling-standards.css';
import './user-case-info.css';
import { useState, useEffect, useRef } from 'react';
import { Wrapper, mapText } from './ComponentStandards';
import Bookmark from 'react-bookmark';
import ContentEditable from 'react-contenteditable'
import { coconutTree } from './Icons';
import { ContentEditableDefault } from './ItemForm';
import * as LoginInput_w from './LoginInput_w';
import * as generics from './Generics';
import produce from "immer";


export const UserCaseInfo = ({ sessionInfo }) => {
	
  const name = "user-case-info"

  const 
    firstMessage = [
      `Dear traveller, you started on a new ${sessionInfo.type}`
    ],
    tagline = [
      "travel planning & sharing experiences!"
    ],
    secondMessage = [
      `It's public for all to see`,
      "But off cause only you and friends of your choice can make changes",
      "The password to open your trip is"
    ],
    thirdMessage = [
      `Please keep the password`,
    ],
    bookmarkInfo = <Bookmark className="bookmark" href={`/pw/${sessionInfo.password}`} title="" />, //.replace("to add this page to your bookmarks.", "")
    fourthMessage = [
      `Copy it to your notes`,
      bookmarkInfo
    ],
    fifthMessage = [
      `Next`
    ],
    sixthMessage = [
      "Keep adding to your trip, perhaps sort it",
      "Add public or private comments",
      "Add list info, incl. links to your social media or stay anonymous",
      "Share it with friends"
  ] 

  return (
	  <Wrapper
      name={name}
    >
      <div className={`${name}`}>
        <div className={"user-case-info-background"}>
            {coconutTree}
        </div>
        <div className={"user-case-info-information"}>
            <h1>{`Your New Trip`}</h1>
            {/* {mapText(tagline, "block", "", false, "center", 900, "", "uppercase")} */}
            {mapText(firstMessage, "block", "", false, "center", 900)}
            {mapText(secondMessage, "block", "", true, "center")}
            <span className={"password"} style={{cursor: !!sessionInfo.password ? "copy" : ""}}
              onClick={() => !!sessionInfo.password && navigator.clipboard.writeText(sessionInfo.password)}
              >
                {`${!!sessionInfo.password ? sessionInfo.password : "getting password..."}`}
            </span>
            {mapText(thirdMessage, "block", "", false, "center", 900)}
            {mapText(fourthMessage, "block", "", true, "center")}
            {mapText(fifthMessage, "block", "", false, "center", 900)}
            {mapText(sixthMessage, "block", "", true, "center")}
        </div>
      </div>
    </Wrapper>
  );
}

const OpenCase = () => {

  ///ref
  const openCaseRef = useRef("")

  const handleOnChange = (e) => {
    openCaseRef.current = generics.removeHtmlTypeStrings(e.target.value);
    console.log("hik", openCaseRef.current, openCaseRef.current.length )
    if (openCaseRef.current.length === 20) {

      LoginInput_w.signInEmailAndPassword({ password: openCaseRef.current })

    }
  }

  const style = {minWidth: `20vw`, background: "white"}
  return (    
    <div className={`open-case-input-wrapper`}>
      <div 
        className={`open-case-input`}
        search-icon={`\uf002`}

      >
        <ContentEditable ///optimsie: implement contenteditabledefault with input restrictions
          key={"open-case"}
          placeholder={"trip id"}
          className={`input input-height-full input-radius input-padding-2 input-justify-content-left lowercase`}
          style={style}
          html={openCaseRef.current} 
          //innerRef={openCaseRef}
          onChange={(e) => handleOnChange(e)}//handleChange(e)}
          onBlur={(e) => console.log("fuck")} //handleBlur(e)}
        />
      </div>
    </div>
  )
}

export const OpenUserCase = () => {
  return (
    <div className="open-case-wrapper">
      <div className="open-case">
      <div className={"open-case-background"}>
          {coconutTree}
      </div>
        <OpenCase></OpenCase>

      </div>
    </div>
  )
}