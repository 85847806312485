import './drop-down.css';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import { useContext } from 'react';
import { Wrapper } from './ComponentStandards';
import { themeContext } from './ThemeProvider';
import * as generics from './Generics';

export const DropDown = ({ theme_, highlightWeekday, disabled, height, className, options, passedValue, parentCallback } ) => {

  ///context
  const { theme, setTheme } = useContext(themeContext);
	
  ///const
  const 
    name = "drop-down",
    defaultOption = options[0],
    buttonTheme = highlightWeekday ? 
      theme.theme === 0 ? 1 : 0
      : theme.theme
  return (
	  <Wrapper
      name={name}
      height={height}
    >
      {/* <div className={`${name}`}> */}
        <Dropdown 
          disabled={disabled}
          className={`
            ${className}
            theme-${!!theme_ ? theme : buttonTheme}
            input-radius
          `}
          controlClassName={`btn-subtle theme-${theme_}`}
          menuClassName={`btn-like`}
          arrowClassName={'Dropdown-arrow'}
          arrowClosed={<span className="arrow-closed" />}
          arrowOpen={<span className="arrow-open" />}
          // placeholderClassName={``}
          options={options} 
          value={!generics.stringUndefined(passedValue) ? passedValue : defaultOption} 
          placeholder={`Select an option`}
          onChange={(value) => {
            const template = value.value
            parentCallback({ template })
          }}
        />
      {/* </div> */}
    </Wrapper>
  );
}
