import { db } from './Firebase';
import { doc, getDoc, collection as collection_, query, where, onSnapshot , getDocs, collectionGroup as collectionGroup_, updateDoc } from "firebase/firestore";
import sizeof from 'firestore-size'

export const getDataGallery = async({ templateName }) => {
	const promise = new Promise(async(resolve, reject) => {
		//const q = query(collection(db, templateName)) //, where("artist", "==", "Mone"));
		let galleryItems = [];
		const querySnapshot = await getDocs(collection_(db, templateName));
		querySnapshot.forEach((doc) => {
		  const	document = Object.assign({ document: doc.id }, doc.data())
				// const bytes = sizeof(document);
				// console.log("bytes", document, bytes)
				galleryItems.push(document);
		});

		// const unsubscribe = onSnapshot(q, (querySnapshot) => {
		// let galleryItems = [];
		// querySnapshot.forEach((doc) => {
		// 	  const	document = Object.assign({ document: doc.id }, doc.data())
		// 		// const bytes = sizeof(document);
		// 		// console.log("bytes", document, bytes)
		// 		galleryItems.push(document);
		//})

		resolve(galleryItems)
		//});
  })
	return promise
}

export const updateItemText = async({ label, value, document, template }) => { ///optimise: move to write file

	//const promise = new Promise(async(resolve, reject) => {
		const ItemRef = doc(db, template, document);
		const element = {};
		element[label] = value; 
		await updateDoc(ItemRef, element);
		return
	//	resolve() //optimise: error-handling
  //})
//	return promise
}

export const updateItemSortId = async(sortId_Timestamp, document) => { ///optimise: move to write file
	// const promise = new Promise((resolve, reject) => {
		const ItemRef = doc(db, "gallery", document);
		await updateDoc(ItemRef, {sortId: sortId_Timestamp}); //question await?
		return
		//resolve() //optimise: error-handling
  // })
	  // return promise
}
// export const getGallery = async() => {
//     const docRef = doc(db, "blogpostdeterminator", "Bt5Y7C0KvGLCHcfK6ioG");
//     const docSnap = await getDoc(docRef);

//     const promise = new Promise((resolve, reject) => {
//         if (docSnap.exists()) {
//         console.log("Document data:", docSnap.data());
//         resolve(docSnap.data())
//         } else {
//         console.log("No such document!");
//         }
//     })
//     return promise
// }

export const getUserComments = async({ collectionGroup, collection, isPublic, document }) => {

	const promise = new Promise(async(resolve, reject) => {
		const q = isPublic ?
			query(collectionGroup_(db, collectionGroup), where('isPublic', '==', isPublic)) :
			query(collection_(db, collection, document, "comments"), where('isPublic', '==', isPublic))
		const querySnapshot = await getDocs(q);
		let data = []
		querySnapshot.forEach((doc) => {
				const	document = Object.assign({ document: doc.id }, doc.data())
				data.push(document)

		});

		resolve(data)
	})
	return promise
}