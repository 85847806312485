import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import produce from "immer";
import { initialStateOptions } from './StateOptions';
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const userUidContext = createContext([,() => {}])

///state
export const UserPresent = () => {
  const { userUid, setUserUid } = useContext(userUidContext);
  return true //!!userUid 
}

export const UserUidProvider = ({ children }) => {

  ///context

  ///state
  const [userUid, setUserUid] = useState(initialStateOptions[0])
  const userUidValue = useMemo(
    () => ({ userUid, setUserUid }), 
    [userUid]
  );

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      setUserUid(uid)
      console.log("signed in")
    } else {
      setUserUid("")
      console.log("signed out")
    }
  }, [auth]);

  const handleSetUserUid = ({}) => {
    console.log(handleSetUserUid)
  }

  return (
      <userUidContext.Provider value={userUidValue}> 
         {children(userUid, setUserUid, handleSetUserUid)}
      </userUidContext.Provider>
  )
}