import './app.css';
import './styling-standards.css';
import './sort.css';
import { useState, useEffect } from 'react';
import { Wrapper } from './ComponentStandards';
import { DropDown } from './DropDown';

export const Sort = ({ options, parentCallback }) => {
	
  const name = "sort"

  return (
	  <Wrapper
      name={name}
      // height={`100%`}
      width={"15vw"}
      //paddingAndMargin={`margin-standard`}
    >
      <DropDown
        className={`font-size-1`}
        options={options}
        parentCallback={(value) => parentCallback(value)}
      >
      </DropDown>
      {/* <div className={`${name}`}>
        <div className={`${name}-x}`}>
        </div> 
      </div> */}
    </Wrapper>
  );
}