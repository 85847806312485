import './app.css';
import './styling-standards.css';
import './login.css';
import LoginInput from './LoginInput';
import { useState, useEffect } from 'react';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useLocation, useNavigate} from 'react-router-dom'; //v6

export default function Login() {
	
  return (
	  <div className={"login-wrapper"}>
      <div className={"login"}>
        <div className={"login-input-wrapper"}>
          <LoginInput></LoginInput>
        </div> 
      </div>
    </div>
  );;
}