import './app.css';
import './styling-standards.css';
import './dimensions-options.css';
import './react-resizable.css';
import './mona-lisa.css';
import React, { useState, useCallback, useEffect, useContext, forwardRef } from 'react';
import { Resizable, ResizableBox } from 'react-resizable';
import GirlPearl from './GirlPearl';
import { ModalHeader } from './Modal';
import produce from "immer";
import * as generics from './Generics';
import { refineContext } from './ItemsRefinedComponent';

///state const
const 
dimensions_n = { height: 0, width: 0 },
dimensions_o = { height: 222, width: 222 },
dimensionsUnits = { height: "cm", width: "cm" },
dimensionsInterval = { height: 5, width: 5 }
export const dimensionsInitial = { 
  dim : {
  dimensions_u_px : dimensions_o , ///u: user interaction
  dimensions_g_cm: dimensions_n , ///g: gradient
  dimensions_g_ln: dimensions_n , ///g: gradient
  dimensions_a: { ///adjusted units (cm and m)
    dimensions_a_cm_m: dimensions_n, 
    dimensionsUnits_a_cm_m: dimensionsUnits 
    }, 
  },
  int : {
    dimensionsInterval_cm: dimensionsInterval
  },
  resetReady: false,
}
 
export function DimensionsOptions() {

  ///context 
  const { refine, setRefine } = useContext(refineContext);
  //const { itemsRefined, setItemsRefined } = useContext(itemsRefinedContext);

  ///state
  const [ dimensions, setDimensions] = useState( dimensionsInitial )

  ///const
  const { dimensions_u_px, dimensions_g_cm, dimensions_g_ln, dimensions_a } = dimensions.dim
  const { dimensions_a_cm_m, dimensionsUnits_a_cm_m } = dimensions_a
  const { dimensionsInterval_cm } = dimensions.int
  const dimensionsFactor = 1000
  // const height_i = 222
  // const width_i = 222
  const intervalOptions = ["0", "1", "2", "3", "4", "5", "10", "20", "30"]

  ///on load/opening of modal =>
	useEffect(async() => { ///optimise: update to avoid flickering of shown item at modal open
    const x = "" ///set dimensions from context
    let node = {
      ["size"]: refine.dimensions.dim.dimensions_u_px 
    };
    handleDimensions(x, node)

    const parameter = "int" ///set interval from context
    const dimensionsInterval = refine.dimensions.int.dimensionsInterval_cm
    Object.keys(dimensionsInterval).map((k) => 
      handleSetDimensions(parameter, dimensionsInterval[k], k)
    );
  }, [refine.dimensions.resetReady]);

  ///on interaction: on resize => calc and set dimensions and units
  const handleDimensions = async(event, node) => {
    const dimensions_u_px = node.size
    const dimensionsFunctionLinearGradient = (x) => generics.round(generics.roundHalf(x / (dimensionsFactor / x)), 0) ///ref2, note: use digits 1 to round to 0.5cm
    let dimensions_g_cm = generics.objMap(dimensions_u_px, dimensionsFunctionLinearGradient, true);
    const dimensionsFunctionUnitAdjustment = (x) => x >= 100 ? generics.round(x / 100, 2) : x ///for x equal to or larger than 100cm convert to 1m+, and use two digits for m
    let dimensions_a_cm_m = generics.objMap(dimensions_g_cm, dimensionsFunctionUnitAdjustment, true);
    
    const dimensionsFunctionGetUnits = (x) => x >= 100 ? "m" : "cm"
    let dimensionsUnits_a_cm_m = generics.objMap(dimensions_g_cm, dimensionsFunctionGetUnits, true);

    const dimensionsFunctionDimensionsUs = (x) => generics.round( x / 2.54 , 1)
    let dimensions_g_ln = generics.objMap(dimensions_g_cm, dimensionsFunctionDimensionsUs, true);
    
    const parameter = "dim"
    const dim = {
      dimensions_u_px : dimensions_u_px, ///u: user interaction
      dimensions_g_cm: dimensions_g_cm, ///g: gradient
      dimensions_g_ln: dimensions_g_ln, ///g: gradient
      dimensions_a: { dimensions_a_cm_m, dimensionsUnits_a_cm_m }, ///adjusted cm and m
    }  
    handleSetDimensions(parameter, dim)
  }

  ///user interaction: click => change dimension intervals
  const changeInterval = (dimension) => { ///note: function copied from `multistatebutton`
    const value = dimensionsInterval_cm[dimension]
    const index = generics.indexInArray(intervalOptions, value)
    const nextIndex = (index + 1) % intervalOptions.length
    const nextValue = intervalOptions[nextIndex]
    const parameter = "int"
    handleSetDimensions(parameter, nextValue, dimension)
  };

  ///on resize or change interval =>
  const handleSetDimensions = (parameter, value, dimension) => {
    setDimensions(
      produce((draft) => {
        switch (parameter) {
          case "dim":
            draft[parameter] = value;
            draft["resetReady"] = true
            break;
          case "int":
            draft[parameter]["dimensionsInterval_cm"][dimension] = value;
            draft["resetReady"] = true
            break;
          default: 
            break
        }
      })
    )
  };

  ///on change of interval  =>
	useEffect(() => {
    handleSetRefine()
  }, [dimensions.int, dimensions.dim]);

  //on resize stop or change of interval via effet => 
  const handleSetRefine = () => {
    if (dimensions_u_px.height === dimensions_o.height && dimensions_a_cm_m.height ) { ///defer setrefine on load
      return
    }
    const category = "dimensions"
    setRefine(
      produce((draft) => {
        draft[category] = dimensions
      })
    )
	};

  const Intervals = () => {
    const dimensionsProperties = [
      "width",
      "height"
    ]
    const dimensionsPropertiesInfo = (index) => {
      const info = 
      [
        `${dimensions_g_ln[dimensionsProperties[index]]}ln`,
        `${generics.round(generics.cmToLn(dimensionsInterval_cm[dimensionsProperties[index]]), 1)}ln`,
        `${dimensions_a_cm_m[dimensionsProperties[index]]}${dimensionsUnits_a_cm_m[dimensionsProperties[index]]}`,
        `${dimensionsInterval_cm[dimensionsProperties[index]]}cm`,
      ]
      return info
    }
    return (
      <>
        {dimensionsProperties.map((dimensionsProperty, index) => {
          return (
          <div className={`
            dimensions-options-box-interval-wrapper
            dimensions-options-box-interval-wrapper-${dimensionsProperty}
          `}>
            <div 
              key={index}
              className={`
                dimensions-options-box-interval
                dimensions-options-box-interval-${dimensionsProperty}
              `}>
              {dimensionsPropertiesInfo(index).map((dimensionProperty, index) => {
                return (
                  <span
                    key={index}
                    className={`
                      ${(index === 2 || index === 3) ? "dimensions" : "interval"}
                      ${refine.dimensions.resetReady ? "show fadein-05" : (index === 1 || index === 3) && "hide"}
                      ${index % 2  == 0 ? "even" : "odd"}
                      ${!refine.dimensions.resetReady ? "disabled" : "active"}
                    `}
                  >{dimensionProperty}
                  </span>
                )
              })}
              <div className={`
                dimensions-options-box-interval-button
                ${refine.dimensions.resetReady ? "show fadein-05" : "hide fadeout-05"} 
              `} //optimise: fade not working, probably due to re-rendering of whole component ...
                onClick={() => changeInterval(dimensionsProperty)}
              ></div>
            </div>
          </div>
          )
        })}
      </>
    )
  }

  const headerContent = "Select dimensions"

  return (
    <div className="dimensions-options-wrapper">
      <ModalHeader
        content={headerContent}
      />
      <div className="dimensions-options-box-wrapper"
          height={`${dimensions_g_ln.height} in`}
          width={`${dimensions_g_ln.width} in`}
      >
        {/* <div className="dimensions-options-box-frame"> */}
        {/* <div className="dimensions-options-box-interval"> */}
          <Intervals/>
          <div className="dimensions-options-box"
            height={`${dimensions_a_cm_m.height} ${dimensionsUnits_a_cm_m.height}`}
            width={`${dimensions_a_cm_m.width} ${dimensionsUnits_a_cm_m.width}`}
          >
            <ResizableBox 
              className={`
                dimensions-options 
                input-border-default
                `}
              width={refine.dimensions.dim.dimensions_u_px.width} 
              height={refine.dimensions.dim.dimensions_u_px.height} 
              minConstraints={[100, 100]} 
              maxConstraints={[500, 500]}
              onResize={(event, node) => handleDimensions(event, node)}
              onResizeStop={() => handleSetRefine()}
            >
              <div className={`dimensions-options-artwork-wrapper`}>
                <div className={`dimensions-options-artwork`}>
                  <GirlPearl/>
                </div>
              </div>
            </ResizableBox>
          </div>
        </div>
        {/* </div> */}
      {/* </div> */}
      {/* <ItemCount
        className={""}
        showSearch={false} 
        itemsRefined={itemsRefined}
      /> */}
    </div>
  )
}

export function DimensionsOptionsLight({ parentCallback }) { ///optimise: delete superfleous code

  // ///context
  const { refine } = useContext(refineContext);
  //const { itemsRefined, setItemsRefined } = useContext(itemsRefinedContext);

  ///state
  const [ dimensions, setDimensions] = useState( dimensionsInitial )

  //const
  const { dimensions_u_px, dimensions_g_cm, dimensions_g_ln, dimensions_a } = refine.dimensions.dim
  const { dimensions_a_cm_m, dimensionsUnits_a_cm_m } = dimensions_a
  const { dimensionsInterval_cm } = refine.dimensions.int

  const Intervals = () => {
    const dimensionsProperties = [
      "width",
      "height"
    ]
    const dimensionsPropertiesInfo = (index) => {
      const info = 
      [
        `${dimensions_g_ln[dimensionsProperties[index]]}ln`,
        `${generics.round(generics.cmToLn(dimensionsInterval_cm[dimensionsProperties[index]]), 1)}ln`,
        `${dimensions_a_cm_m[dimensionsProperties[index]]}${dimensionsUnits_a_cm_m[dimensionsProperties[index]]}`,
        `${dimensionsInterval_cm[dimensionsProperties[index]]}cm`,
      ]
      return info
    }
    return (
      <>
        {dimensionsProperties.map((dimensionsProperty, index) => {
          return (
          <div className={`
            dimensions-options-box-interval-wrapper
            dimensions-options-box-interval-wrapper-${dimensionsProperty}
          `}>
            <div 
              key={index}
              className={`
                dimensions-options-box-interval
                dimensions-options-box-interval-${dimensionsProperty}
              `}>
              {dimensionsPropertiesInfo(index).map((dimensionProperty, index) => {
                return (
                  <span
                    key={index}
                    className={`${(index === 2 || index === 3) ? "dimensions" : "interval"}
                    ${index % 2  == 0 ? "even" : "odd"}
                    `}
                  >{dimensionProperty}
                  </span>
                )
              })}
              <div className={`dimensions-options-box-interval-button`}
              ></div>
            </div>
          </div>
          )
        })}
      </>
    )
  }

  return (
    <div className={`
      dimensions-options-wrapper 
      animation-fadein-1
      `}
      onClick={() => parentCallback()}
    >
      <div className="dimensions-options-box-wrapper"
          // height={`${dimensions_g_ln.height} in`}
          // width={`${dimensions_g_ln.width} in`}
          style={{transform: "scale(0.3)"}}
      >
        {/* <div className="dimensions-options-box-frame"> */}
        {/* <div className="dimensions-options-box-interval"> */}
          <Intervals/>
          <div className="dimensions-options-box"
            // height={`${dimensions_a_cm_m.height} ${dimensionsUnits_a_cm_m.height}`}
            // width={`${dimensions_a_cm_m.width} ${dimensionsUnits_a_cm_m.width}`}
          >
            <ResizableBox 
              className={`
                dimensions-options 
                input-border-default
                `}
              width={refine.dimensions.dim.dimensions_u_px.width} 
              height={refine.dimensions.dim.dimensions_u_px.height} 
              minConstraints={[100, 100]} 
              maxConstraints={[500, 500]}
            >
              <div className={`dimensions-options-artwork-wrapper`}>
                <div className={`dimensions-options-artwork`}>
                  <GirlPearl/>
                </div>
              </div>
            </ResizableBox>
          </div>
        </div>
    </div>
  )
}
