import './app.css';
import './styling-standards.css';
import './social-media.css';
import { useState, useEffect } from 'react';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useLocation, useNavigate} from 'react-router-dom'; //v6
import * as icons from './Icons';

export default function Header({className}) {

  const instagramLink = "https://www.instagram.com/jesus.kobe/"
	
  return (
	  <div className={`social-media-wrapper ${className}`}>
			<div className={"social-media"}>
				<div className={"instagram-wrapper"}>
					<a href={instagramLink} target={"_blank"}>
						<div className={"instagram"}>
						</div> 
					</a> 
				</div> 
			</div>
    </div>
  );;
}

export const socials = ["instagram", "facebook", "twitter", "tiktok"]
export const Socials = ({ value }) => {

	const socialsIcons = [icons.instagramIcon, icons.facebookIcon, icons.twitterIcon, icons.tiktokIcon]

		return (
			<div className={"user-case-social-container"}
				style={{display: "grid", gridAutoFlow: "column", columnGap: "0.5em"}}
			>
				{socials
					.filter((social) => !!value[value.findIndex(value_ => Object.keys(value_)[0] === social)]?.[social])
					.map((social, index) => 
				<div key={social} className={"user-case-social"}
					// onClick={() => setSocial(social)}
				>
					<a 
						style={{pointerEvents: "all", height: "100%", width: "100%"}}
						href={`/${value[value.findIndex(value_ => Object.keys(value_)[0] === social)]?.[social]}`}
						target={"_blank"}>
					</a>
						{socialsIcons[socials.findIndex(social_ => social_ === social)]} 
				</div>
				)}
		</div>
		)
}