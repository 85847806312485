//https://palmera-demo.squarespace.com/?nochrome=true
import './gallery.css';
import { useState, useEffect, Fragment, useContext, useLayoutEffect, memo, useRef, useCallback, createContext, useMemo } from 'react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import produce from "immer";
import * as Gallery_r from './Gallery_r';
import { usePrevious} from './StateOptions';
import arrayMove from 'array-move'
import { Timestamp } from "firebase/firestore";
import { db } from './Firebase';
import * as generics from './Generics';
import { Outlet } from 'react-router-dom'; //v6
import { themeContext } from './ThemeProvider';
import { refineContext } from './ItemsRefinedComponent';
import { itemsRefinedContext } from './ItemsRefinedComponent';
import { GridColums, Wrapper} from './ComponentStandards';
import { GalleryItem } from './GalleryItem';
import { templatesSetup, allSearchCategories } from './ItemForm';
// import { templatesDataContext } from './DataProvider';
import { userCommentsContext } from './UserCommentsProvider';
import { itemsContext } from './ItemsProvider';
import { urlInfoContext } from './UrlInfoProvider';
import { Reel } from './EmbedSocialWidget';


import GalleryAdmin from './GalleryAdmin';
export const unitLengthIndexContext = createContext([,() => {}])
export const unitLength = {
	cm: 1,
	ln: 2.54
}

export const Gallery = function Gallery({ templatesData, setTemplatesData, setFocusItemDocument }) { ///note: was memo
	///context
	const 
		{ items, setItems } = useContext(itemsContext), ///items from react-easy-sort
		{ itemsRefined } = useContext(itemsRefinedContext),
	 	{ theme } = useContext(themeContext),
		{ refine, setRefine } = useContext(refineContext),
		// { templatesData } = useContext(templatesDataContext),
		{ userComments } = useContext(userCommentsContext),
		{ urlInfo } = useContext(urlInfoContext)

	///const based on context
	const activeTemplates = templatesData.show.activeTemplates
	const activeTemplate = activeTemplates[activeTemplates.length - 1] ///templatesData.update.activeTemplate
  
	// ///ref
	// const scrollCallback = () => { ///note: moved to Home component as home div is not the scrollable div
	// 	console.log("scrolllisteninger")
	// 	const
	// 		parameter = "listLength",
	// 		action = "listLength-update",
	// 		value = 12
	// 	handleSetRefine(parameter, action, value)
	// }

	// const scrollRef = useBottomScrollListener(scrollCallback, { ///note: moved to Home component as home div is not the scrollable div
	// 	offset: 100,
	// 	debounce: 1000,
	// })

	// const [unitLengthIndex, setUnitLengthIndex] = useState(0)
	// const unitLengthIndexValue = useMemo(
  //   () => ({ unitLengthIndex, setUnitLengthIndex }), 
  //   [unitLengthIndex]
  // );
	const [index, setIndex] = useState([]);
	const [noSearchMatch, setNoSearchMatch] = useState(false);
	const prevItems = usePrevious(items);
	// const prevItemsRefined = usePrevious(itemsRefined);
	// const prevActiveTemplate = usePrevious(activeTemplate) 
	const prevIndex = usePrevious(index);
	const templatesSetupEntries = Object.entries(templatesSetup)

	///const sortValues = generics.arrayOfObjectGetValuesByKey(sorting, "value")
	const searchCategories = refine["category"] === "all" ? allSearchCategories : [refine["category"]]
	//const [scrollTo, setScrollTo] = useState({}); ///index of media for a given artwork
	//const [documentParams, setDocumentParams] = useState("");
	//const [documentMore, setDocumentMore] = useState("");
	//const [directLoadArtwork, setDirectLoadArtwork] = useState(false);
	
	// let userPresent = UserPresent()
	const 
		name = "gallery",
		itemsPrRow = refine.gallery.itemsPrRow,
		period = itemsPrRow + 1,
		seperatorParams = "-"
		
		// noSearchMatchText = [
		// 	`We're sorry.`,
		// 	`We where not able to find any artworks for`,
		// 	`${refine["lookup"]}.`,
		// 	`Try again maybe?`
		// ]

	////GET DATA GALLERY was here
	////ON REFINE (note: most moved to App!)

	///no search result - removed OK?
	useEffect(() => {
		const matches = items.filter(item => generics.partialMatchFromStart(refine["lookup"], generics.valuesToStringViaKeys(item, searchCategories, " "))) ///optimise: only compute once, outside render...
		const noSearchMatch = refine["lookup"] !== "" && generics.arrayEmpty(matches)
		///setNoSearchMatch(noSearchMatch ? true : false)
	}, [refine]);

	///deselect artwork (remove more info), if any artwork selected - removed OK?
	useEffect(() => {
		const value = {document: "", directLoad: false} ///quesstion/bug: directload was true, but i changed to false - correct?
		handleSetRefine("documentMore", "documentMore-update-from-url-change", value, index)
		handleSetRefine("documentMore", "documentMore-update-from-user-interaction", value, index)
	}, [itemsRefined]);

		///set refine
		const handleSetRefine = (parameter, action, value, index) => { 
			
			const 
				itemsPrRow = refine.gallery.itemsPrRow,
				period = itemsPrRow + 1

			setRefine(
				produce((draft) => {
					switch (action) {
						case "filterOptions-update":
							draft[parameter] = value
							break;
						case "listLength-update":
								generics.isArrayLengthAbove(activeTemplates, 1) ?
									draft[parameter]["gallery"]["multiple"] += value :
									draft[parameter]["gallery"][activeTemplate] += value 
							break;	
						case "documentMore-update-from-user-interaction":
							draft[parameter]["document"] = value.document
							draft[parameter]["directLoad"] = value.directLoad ///optimise: get "directLoad" via map over value (inplicit)
							draft[parameter]["documentIndex"] = value.documentIndex
							if (generics.isNumber(value.documentIndex)) { ///optimise: only invoke function if defined, solve above
								const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
								if (index_ !== -1) { ///if already selected => remove index
									draft[parameter]["documentIndexs"].splice(index_, 1) 
								} 
								else { ///if not selected
									const 
										rangeMax = Math.ceil(value.documentIndex/period)*(period) - 2, ///index of last item in row note: minus 2, supposingly due to index - 1 in addition to minus 1 for extra element (the documentmore view)
										rangeMin = rangeMax - itemsPrRow,
										indexArray = generics.makeArrayViaRange(rangeMin, rangeMax) ///array with index in row
									indexArray.map((i_, i) => {
										const index__ = draft[parameter]["documentIndexs"].findIndex(index => index === i_)
											if (index__ !== -1) {
												draft[parameter]["documentIndexs"].splice(index__, 1) 
											} 
									})
									draft[parameter]["documentIndexs"].push(value.documentIndex)
								}
							}
							break;
						case "documentMore-update-from-url-change":
							draft[parameter]["document"] = value.document ///optimise: get "document" via map over value (inplicit)
							draft[parameter]["documentIndex"] = value.documentIndex
							if (generics.isNumber(value.documentIndex)) {
								const index_ = draft[parameter]["documentIndexs"].findIndex(index => index === value.documentIndex)
								index_ !== -1 ? 
									draft[parameter]["documentIndexs"].splice(index_, 1) :
									draft[parameter]["documentIndexs"].push(value.documentIndex)
							}
							break;
						// case "sort-change":
						// 	draft[parameter] = value
						// 	break;
						default:
							break;
					};
				})
			) 
		};

	///url change => handle documentmore - removed OK?
	useEffect(() => {
		const 
			artworkSelected = generics.stringIncludes(urlInfo.location.pathname, "/gallery/"),
			documentMore = artworkSelected ? 
				urlInfo.location.state.document : 
				"",
			documentIndex = artworkSelected ? 
			urlInfo.location.state.documentIndex : 
			""

			const index_ = refine["documentMore"]["documentIndexs"].findIndex(index => index === documentIndex)
			if (index_ !== -1 ) { ///if 
				return 
			}
		// if (documentMore !== "") {
			const value = {document: documentMore, documentIndex: documentIndex, directLoad: "*not-used*"}
			handleSetRefine("documentMore", "documentMore-update-from-url-change", value, index)
		// }
	}, [urlInfo]);

	///HANDLING SORT ITEM
	///interaction: drag and drop => on sort end => 
	const onSortEnd = (oldIndex, newIndex) => { ///note: from react-easy-sort 
		setIndex([oldIndex, newIndex]) ///set index
	}

	///useeffect at index => 
	useEffect(() => {
		const [oldIndex, newIndex] = index
		setItems(() => arrayMove([...items], oldIndex, newIndex)) ///set items. note: from 'array-move' ///optimise: the move of the function to effects from onsortend cause rerender => flickering in gallery. moved due to corruption if two setstate within parent function.
	}, [index])

	///useeffect at items if =>
	useEffect(() => {
		if (generics.eachArrayNotEmpty([items, index]) && ///if items loaded and sorting is done (at least once)
				!generics.isEqualArrays(items, prevItems)) { ///optimise: update function name, perhaps pass document ...? ///if sorted
			handleSortId()
		}
  }, [items]);

	///useffect at items if ... => handle sortid =>
	const handleSortId = async() => {
		const [, newIndex] = index
		const document = items[newIndex].document
		let [sortId_seconds, sortId_nanoseconds] = await calcSortId(newIndex) //calc sortid
		const sortId_Timestamp = new Timestamp(sortId_seconds, sortId_nanoseconds)
		await Gallery_r.updateItemSortId(sortId_Timestamp, document) //update sortid
		setItemSortId(sortId_seconds, sortId_nanoseconds, newIndex) //set sortid
	}

	///calc sortid
	const calcSortId = async(newIndex) => {
		const [prevIndex, nextIndex, addedSeconds]  = await generics.getSortIdInput(items, newIndex)
		const sortId_c = [prevIndex, nextIndex].map(( nearestIndex, index) => { //output: sortid for prevIndex and nextIndex as [ , ]
			if ( nearestIndex != null) { ///note: == null at firstitem and lastitem
				let { sortId: { seconds: seconds, nanoseconds: nanoseconds }} = items[nearestIndex]
				return +`${seconds+addedSeconds}.${nanoseconds}` 			
			}
		})
		const sortId = sortId_c.filter((x => x !== undefined)).reduce((a, b) => a + b, 0) / sortId_c.filter((x => x !== undefined)).length //sortid equal avg value of sortid_c

		let [sortId_seconds, sortId_nanoseconds] = sortId.toString().split(".").map(string => parseInt(string));
		sortId_nanoseconds = sortId_nanoseconds === undefined ? 0 : sortId_nanoseconds
		return [sortId_seconds, sortId_nanoseconds]
	}

	///set sortid
	const setItemSortId = useCallback((newItemSortId_seconds, newItemSortId_nanoseconds, newIndex) => {
		setItems(
			produce((draft) => { //note: update do not cause render
				const item = draft[newIndex];
				item.sortId.seconds = newItemSortId_seconds;
				item.sortId.nanoseconds = newItemSortId_nanoseconds;
			})
		);
	},[]);

	const GalleryComponent = useCallback(({ templatesData, setTemplatesData }) => {

		const galleryStyle = [
			{
				gridTemplateColumns: "1fr", ///view-0
				gridTemplateRows: `repeat(${itemsRefined.length}, minmax(40vh, fit-content(100%)))`,
				rowGap: `15vh`,
				display: "grid" ///optimise: only define once
			},
			{
				gridTemplateColumns: `repeat(${itemsPrRow}, 1fr)`, ///view-1
				gridTemplateRows: "1fr",
				rowGap: "5vh",
				columnGap: "5vh",
				display: "grid",
			},
		]
		
	 return (
		<SortableList  
		key={"gallery"}
		className={`
			gallery-sortable-list 
			gallery-sortable-list-${theme.view}
		`}
		style={galleryStyle[theme.view]}
		draggedItemClassName="dragged"
		allowDrag={true} // {!userPresent}
		onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)} 
		// ref={scrollRef} ///note: moved to Home component as home div is not the scrollable div
	>
		<GalleryComponents
			templatesData={templatesData} setTemplatesData={setTemplatesData}
		/>
	</SortableList>
	 )
	}, [itemsRefined, theme.view, refine.documentMore, refine.listLength]) ///Q: add templatesData and setTemplatesData?

	const GalleryComponents = useCallback(({ templatesData, setTemplatesData }) => {

		let itemsMap = [...itemsRefined]

		// itemsMap = itemsMap.flatMap(element => ///note: test element
		// 	Array(5).fill(element)
		// );

		//generics.insertAt(itemsMap, 2, "placeholder");
		const documentIndexs = theme.view === 0 ? [] : refine.documentMore.documentIndexs ///note: only map item/document selection at view 1
		
		itemsMap = generics.addToArrayByPeriodExtra(itemsMap, period, "placeholder", documentIndexs) 
	
		// const documentMoreElement = [...itemsMap].splice(refine.documentMore.documentIndex, 1) ///note/risk: at ..documentIndex === null (default) documentmoreelement is still defined, probably equal to first element
		if (!!refine.documentMore.document) { ///note: if item/document selected duplicate element
			//itemsMap.splice(refine.documentMore.documentIndex + 1, 1, ...documentMoreElement)
		}

		const	itemsMap_ = ///note: only map if array not only dummy
			itemsMap
				// .slice(0, 
				// 		generics.isArrayLengthAbove(activeTemplates, 1) ?
				// 			refine.listLength.gallery["multiple"] :
				// 			refine.listLength.gallery[activeTemplate]
				// 	) ///note: only map according to listlength (first x items). disabled due to causing crash ..
				.map( ( data , index ) => { 
					let { document } = data
					const 
						{ title, imageUrl, created  } = data,
						to = `${document}${seperatorParams}${seperatorParams}${title}`
						let component = null
						switch (true) {
							case data === "placeholder":
								document = `placeholder-${index}`
								component = theme.view === 1 ?
									<div
										style={{gridColumn: `span ${itemsPrRow}`}}
									></div> 
									:
									null
								break;
							case data === "placefiller": ///note: currently at load pure placefiller items are mapped, but concluded ok (no impact) ...
								document = `placefiller-${index}`
								component = theme.view === 1 ?
									<div
									></div> 
									:
									null
								break;
							default:
								component = 
									<SortableItem //gallery-item-animation-fadein
									>
										<GalleryItem 
											templatesData={templatesData} setTemplatesData={setTemplatesData}
											//showDocumentMore={showDocumentMore}
											//fadeArtwork={fadeArtwork}
											userComments={[...userComments]
												// .sort((a, b) => generics.sortDate(b, a, "created"))
												// .sort((a, b, index) => {
												// 	if (a["isPublic"] === true && a["userCaseDocument"] === openUserCase.document) return -1;
												// 	if (b["isPublic"] === true && b["userCaseDocument"] === openUserCase.document) return 1;
												// 	return a - b;
												// })
												.filter(userComment => userComment.listingDocument === document)}
											gridColumn={`span ${generics.checkRemainder(index + 1, period, true) && theme.view === 1 ? itemsPrRow : 1}`}
											to={to}
											data={data}
											document={document}
											index={index}
											parentCallback={(parameter, action, value) =>  handleSetRefine(parameter, action, value)}
										>
										</GalleryItem>
									</SortableItem>
							break;
						};
					return (
						<Fragment 
							key={`${document}-documentmore--${refine.documentMore.document === document}`} ///`${!!created ? `${created.seconds}${created.nanoseconds}-${index}` : `${index}`}`
						>
							{component}
						</Fragment>
						)
					}) 

		return (
			<> {itemsMap_} </>
				// <SortableList  
				// 	key={"gallery"}
				// 	className={`
				// 		gallery-sortable-list 
				// 		gallery-sortable-list-${theme.view}
				// 	`}
				// 	style={galleryStyle[theme.view]}
				// 	draggedItemClassName="dragged"
				// 	allowDrag={true} // {!userPresent}
				// 	onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)} 
				// 	// ref={scrollRef} ///note: moved to Home component as home div is not the scrollable div
				// >
				// {itemsMap ///note: only map if array not only dummy
				// 	// .slice(0, 
				// 	// 		generics.isArrayLengthAbove(activeTemplates, 1) ?
				// 	// 			refine.listLength.gallery["multiple"] :
				// 	// 			refine.listLength.gallery[activeTemplate]
				// 	// 	) ///note: only map according to listlength (first x items). disabled due to causing crash ..
				// 	.map( ( data , index ) => { 
				// 		const 
				// 			{ title, document, imageUrl, created  } = data,
				// 			to = `${document}${seperatorParams}${seperatorParams}${title}`
				// 			let component = null
				// 			switch (true) {
				// 				case data === "placeholder":
				// 					component = theme.view === 1 ?
				// 						<div
				// 							style={{gridColumn: `span ${itemsPrRow}`}}
				// 						></div> 
				// 						:
				// 						null
				// 					break;
				// 				case data === "placefiller":
				// 					component = theme.view === 1 ?
				// 						<div
				// 						></div> 
				// 						:
				// 						null
				// 					break;
				// 				default:
				// 					component = 
				// 						<SortableItem //gallery-item-animation-fadein
				// 						>
				// 							<GalleryItem 
				// 								//showDocumentMore={showDocumentMore}
				// 								//fadeArtwork={fadeArtwork}
				// 								userComments={[...userComments]
				// 									// .sort((a, b) => generics.sortDate(b, a, "created"))
				// 									// .sort((a, b, index) => {
				// 									// 	if (a["isPublic"] === true && a["userCaseDocument"] === openUserCase.document) return -1;
				// 									// 	if (b["isPublic"] === true && b["userCaseDocument"] === openUserCase.document) return 1;
				// 									// 	return a - b;
				// 									// })
				// 									.filter(userComment => userComment.listingDocument === document)}
				// 								gridColumn={`span ${generics.checkRemainder(index + 1, period, true) && theme.view === 1 ? itemsPrRow : 1}`}
				// 								to={to}
				// 								data={data}
				// 								document={document}
				// 								index={index}
				// 								parentCallback={(parameter, action, value) =>  handleSetRefine(parameter, action, value)}
				// 							>
				// 							</GalleryItem>
				// 						</SortableItem>
				// 				break;
				// 			};
				// 		return (
				// 			<Fragment 
				// 				key={`${document}-${index}`} ///`${!!created ? `${created.seconds}${created.nanoseconds}-${index}` : `${index}`}`
				// 			>
				// 				{component}
				// 			</Fragment>
				// 			)
				// 		}) 
				// 	}
				// </SortableList>
		)
	}, [userComments, itemsRefined, theme.view, refine.documentMore, refine.listLength]) ///Q: add templatesData and setTemplatesData? ///was: theme activeTemplate, refine.documentMore ///risk/bug/temporary: refine.listLength was removed to prevent scroll on render, but list will not update then, fix  ///optimise: implement refine.documentmore.document but with correct rendering...

  return (
	///<unitLengthIndexContext.Provider value={unitLengthIndexValue}>
		<Wrapper
			name={name}
			className={`
				gallery-wrapper 
				theme theme-${theme.theme}
			${noSearchMatch ? 
				"no-search-match" : 
				""
				}
			`}
			height={"fit-content"}
			paddingAndMargin={`padding-extra-horizontal`}
			show={true}
			// no-search-match={noSearchMatchText}
		>
			<>
		{templatesSetupEntries ///optimise: attempted to implement as <reelcomponent index={index}/>, but causes issues with lifecycle and render. fix
			.filter(([template, v]) => !v.isGallery)
			.sort(function([a], [b]) { ///note: sorts widgets so most recent added widget/template is on top (in the view), and so forth
				return activeTemplates.indexOf(b) - activeTemplates.indexOf(a); ///bug/risk: shared stories not working after new sort, while non-shared stories does???
			})
			.map(([template, v], i) => 
					//  {activeTemplates
					// .filter((template) => !templatesSetup[template].isGallery)
					//  .map((template, index) => 
						// return (
						// <div >
						<GridColums
							key={template}
							gridAutoFlow={"column"}
						>
						{v.mapWidgetNames
							.map((widgetName, i_) => 
							<Fragment
								key={`${widgetName}-${i}`}
							>
							<Reel
								// key={widgetName}
								// show={true}
								// height={"100%"}
								// widgetName={template} ///note: ensure 
								// headerBackgroundColor={templatesSetup[activeTemplate].color}
								// showHeader={true}
								// headerContent={templatesSetup[template].headerContent}
								show={generics.stringInArray(activeTemplates, template)}
								height={generics.stringInArray(activeTemplates, template) ? "100%" : "0"}
								widgetName={widgetName} ///note: ensure defined in embedsocialwidget.jsx
								headerBackgroundColor={templatesSetup[template].color}
								showHeader={generics.stringInArray(activeTemplates, template) && i !== 0} ///note: only map widgetheader if not widget on top (as the top widget already have the default header define in gallery)
								headerContent={templatesSetup[template].headerContent} //{v.headerContent}
							></Reel> 
							</Fragment>
						)}
					</GridColums>
					// </div>
						// )
					// }
			)}
					<GalleryComponent
					 templatesData={templatesData} setTemplatesData={setTemplatesData}
					/> 
				</>
			<Outlet/>
		</Wrapper>
	///</unitLengthIndexContext.Provider>
  );
}

