import './app.css';
import './styling-standards.css';
import './switch-theme.css';
import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Switch from "react-switch";
import { themeContext } from './ThemeProvider';
import * as generics from './Generics';
import produce from "immer";

export default function SwitchTheme({ className }) {

  ///context
  const { theme, setTheme } = useContext(themeContext);

  ///state
  const [checked, setChecked] = useState(false)
  const [showSwitchTheme, setShowSwitchTheme] = useState(true)

  let { pathname } = useLocation(); //optimise: consider using to route statements, i.e. app.js, and use of props
  useEffect(() => {
    setShowSwitchTheme(pathname === "/" || generics.stringIncludes(pathname, "/gallery/") ? true : false)
  }, );
  
  const handleChange = () => {
    setChecked(checked ? false : true)
    //const randomInt = generics.randomIntFromInterval(1, 10)
    const toogle = (theme.theme + 1) % 2 //wrandomInt === 10 ? 2 : (theme.theme + 1) % 2
    handleSetTheme(toogle)
	}

  const handleSetTheme = (value) => {
    const parameter = "theme"
    setTheme(
      produce((draft) => {
        draft[parameter] = value;
      })
    )
  }



  return (
	  <div className={`switch-theme-wrapper ${className} ${showSwitchTheme ? "show fadein-05" : "hide fadeout-05"}`}>
      <div className={"switch-theme"}>
        <label>
          <Switch 
            onChange={() => handleChange()} 
            checked={checked} 
            offColor={"#141414"}
            onColor={"#141414"}
            checkedIcon={
              // <div 
              // style={{
              //   // height: 20,
              //   // width: 28,
              //   // position: "relative",
              //   // opacity: 0,
              //   // pointerEvents: "none",
              //   // WebkitTransition: "opacity 0.25s",
              //   // MozTransition: "opacity 0.25s",
              //   // transition: "opacity 0.25s"
              // }}>
                <svg 
                stroke="currentColor" 
                fill="currentColor" 
                strokeWidth="0" 
                viewBox="0 0 512 512" 
                focusable="false" 
                className="switch-theme-svg svg-sun" 
                height="1em" 
                width="1em" 
                xmlns="http://www.w3.org/2000/svg">
                  <path d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z">
                  </path>
                </svg>
              // </div>
            }
            uncheckedIcon={
              <svg 
              stroke="currentColor" 
              fill="currentColor" 
              strokeWidth="0" 
              viewBox="0 0 512 512" 
              focusable="false" 
              className="switch-theme-svg svg-moon" 
              height="1em" 
              width="1em" 
              xmlns="http://www.w3.org/2000/svg">
                <path d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z">
                </path>
              </svg>      
            }
            />
        </label>
      </div>
    </div>
  );;
}


// {/* <div class="react-switch-bg" 
//   style="height:20px;width:45px;margin:0;position:relative;background:#1F1F1F;border-radius:10px;cursor:pointer;-webkit-transition:background 0.25s;-moz-transition:background 0.25s;transition:background 0.25s">
//   {/* <div 
//     style="height:20px;width:28px;position:relative;opacity:0;pointer-events:none;-webkit-transition:opacity 0.25s;-moz-transition:opacity 0.25s;transition:opacity 0.25s"> */}
//       <svg 
//         stroke="currentColor" 
//         fill="currentColor" 
//         stroke-width="0" 
//         viewBox="0 0 512 512" 
//         focusable="false" 
//         className="chakra-icon css-1f0unps" 
//         height="1em" 
//         width="1em" 
//         xmlns="http://www.w3.org/2000/svg">
//           <path d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z">
//           </path>
//         </svg>
//       {/* </div> */}
//     <div style="height:20px;width:28px;position:absolute;opacity:1;right:0;top:0;pointer-events:none;-webkit-transition:opacity 0.25s;-moz-transition:opacity 0.25s;transition:opacity 0.25s">
//       <svg 
//         stroke="currentColor" 
//         fill="currentColor" 
//         stroke-width="0" 
//         viewBox="0 0 512 512" 
//         focusable="false" 
//         className="chakra-icon css-1qo0qa6" 
//         height="1em" 
//         width="1em" 
//         xmlns="http://www.w3.org/2000/svg">
//           <path d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z">
//           </path>
//         </svg>
//       </div>
//     </div> */}