import React, { useEffect, useState, useContext } from "react";
import * as RecogniseText_f from './RecogniseText_f';
import { FileOcrContext } from './NewArtwork';
import { OcrTextContext } from './NewArtwork';

export function RecogniseText() {
		const fileOcr = useContext(FileOcrContext);
		const { ocrText, setOcrText } = useContext(OcrTextContext);
    
		///from 'newartwork' interaction => set file ocr =>
    useEffect(() => {
			if (fileOcr !== null) {
				handleOcr(fileOcr)
				}
		}, [fileOcr]);

		///useeffect at fileocr => handle ocr
		const handleOcr = async (fileOcr) => {
			const text = await RecogniseText_f.extractTextFromImage(fileOcr) ///or call with imageUrl, i.e. 'const imageUrl = await RecogniseText_f.fileToDataUrl(fileOcr)' ///note: if used, include 'const [imageUrl] = useState(null);'
			setOcrText(text) //set orc text
		}

		///useeffect at orctext
		useEffect(() => {
			if (ocrText !== null) {
				RecogniseText_f.terminateWorker() ///terminate worker
				}
		}, [ocrText]);

    return (
      <main style={{ padding: "1rem" }}>
        {/* <div className = "container text-center pt-5" >
        Written text {"hi"}
        </div>  */}
      </main>
    );;
  }

	



