//https://palmera-demo.squarespace.com/?nochrome=true
import './app.css';
import './styling-standards.css';
import './gallery-controls.css';
import { useContext } from 'react';
import produce from "immer";
import * as generics from './Generics';
import { themeContext } from './ThemeProvider';
import { refineContext } from './ItemsRefinedComponent';
import { itemsRefinedContext } from './ItemsRefinedComponent';
import Refine, { ItemCount } from './Refine';
import { sorting } from './GalleryRefined';
import { GridColums, Wrapper} from './ComponentStandards';
import ViewToggle from './ViewToggle';
import { Sort } from './Sort';
import { templatesSetup  } from './ItemForm';
import { squareListIcon, gripIcon } from './Icons';
// import { templatesDataContext } from './DataProvider';
import { Filtering }  from './FilterBox';
import { ToggleUnion }  from './UserCases';

export const GalleryControls = ({ itemsRefined, setTheme, templatesData, setRefine }) => {

	// const 
	// { itemsRefined } = useContext(itemsRefinedContext),
	// { theme, setTheme } = useContext(themeContext),
	// { templatesData } = useContext(templatesDataContext),
	// { setRefine } = useContext(refineContext)
		
	///const based on context
	const 
		activeTemplates = templatesData.show.activeTemplates,
		activeTemplate = activeTemplates[activeTemplates.length - 1] ///templatesData.update.activeTemplate

	///const
	const
		// itemsPrRow = refine.gallery.itemsPrRow,
		// //period = itemsPrRow + 1,
		sortValues = generics.arrayOfObjectGetValuesByKey(sorting, "value")

	const show = templatesSetup[activeTemplate].isGallery


	const handleSetRefine = (parameter, action, value, index) => { 
			setRefine(
				produce((draft) => {
					switch (action) {
						case "sort-change":
							draft[parameter] = value
							break;
						default:
							break;
					};
				})
			) 
	};

	return (
		<Wrapper
		show={show}
		animate={true}
		wrapperClass
		className={`
			gallery-controls-outer
			`}
		// paddingAndMargin={`padding-standard-vertical`}
		height={"fit-content"}
		>
		<GridColums 
			className={`gallery-controls`}
			gridTemplateColumns={`fit-content(100%) 1fr fit-content(100%) fit-content(100%) fit-content(100%) fit-content(100%)  fit-content(100%)`} //optimse: this is a hacky attemt to center viewtoggle (dependent on itemcount)
			//gridTemplateRows={""}
			//justifyContent="start"
			justifyItems="right"
			alignItems={"center"}
			columnGap={"2vw"}
		>
			<ItemCount 
				activeTemplate={activeTemplates.length > 1 ? "" : activeTemplates[0]} 
				showTemplateName={true}
				showOnlySummary={activeTemplates.length > 1} 
				show={itemsRefined.length !== 0 && templatesSetup[activeTemplate].isGallery} ///bug: not showing on first render of a category, fix
				fontSize={1}
			/>		
			<Refine
				templatesData={templatesData}
			/>
			<ToggleUnion
				type={"isOnlyRedHeart"}
			/>
			<ToggleUnion
				type={"isOnlyHeart"}
			/>
			<Filtering
				show={templatesData.show.activeTemplates[0] !== "live" && templatesData.show.activeTemplates.length > 0} 
			/>
			<Sort 
				options={sortValues}
				parentCallback={(value) => handleSetRefine("sort", "sort-change", value)}
			/>
			<ViewToggle 
				show={true}
				options={[<><i>{gripIcon}</i><span>Use GridView</span></>, <><i>{squareListIcon}</i><span>Use ListView</span></>]}
				callbackMultiStateButton={( label, value ) => {
						setTheme(
							produce((draft) => {
								draft["view"] = value.props.id;
							})
						)
				}}
			/>
		</GridColums>
		</Wrapper>
	)
}