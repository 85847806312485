import './app.css';
import './styling-standards.css';
import './multi-option.css';
import { useState, useEffect, useContext, useCallback, memo } from 'react';
import { itemElementsContext } from './App';
import { CloseSymbol } from './ComponentStandards';
import { templates } from './ItemForm';
import produce from "immer";
import * as generics from './Generics';
import { GridColums, ZeroToFullHeight, Button } from './ComponentStandards';
import { usePrevious} from './StateOptions';
// import { templatesDataContext } from './DataProvider';
import { checkIcon } from './Icons';
import { showContext } from './Modal';


///notes:
///multioptionbutton: 
///=> setshow: 1) show modal and 2) modalchild equal to multioption, including data options
///multioptions:
///=> multioption component, including options
///=> useeffect at load: handleoption with preselected options from "itemelements"
///=> handle option: prepare add/remove with limit => setshow add/remove option
///app: 
///=> handle modal, including action for modal controls 1) accept => set "itemelements" 2) minus => setshow reset for modalchild 3) close => reset

export const MultiOptions = ({ templatesData }) => { ///optimise: combine filterbox and multioption

  ///context
  const { show, setShow } = useContext(showContext);
  // const { templatesData, setTemplatesData } = useContext(templatesDataContext);

  ///const based on context
	const activeTemplate = templatesData.new.activeTemplate

  // const toggleMultiOptionsShow = (label) => { //////optimise: duplicaete function in itemform. reduce
  //   const ///reset parameters
  //     component = "modal", 
  //     action = "show", showValue = false,
  //     child = "modalChild", childValue = null
  //   const ///reset parameters
  //     modalChild = "multiOptions",
  //     parameter = "data", dataValue = []

  //   setShow(
	// 		produce((draft) => { 
  //       draft[component][action] = showValue
  //       draft[component][child] = childValue
  //       draft[modalChild][parameter] = dataValue
	// 		})
	// 	);
  // }

  ///on load => add preselected options to selected options
  useEffect(() => {

    const preSelectedOptions = show.multiOptions.preSelectedOptions
    preSelectedOptions.map(option =>
      handleOption(Object.values(option)[0])
    )
  }, []);

  ///const
  const category_a = show["multiOptions"]["label"]
  const options = show["multiOptions"]["data"][category_a]
  const theme = 0

  ///select and deselect option
  const handleOption = (option) => { ///optimise: consider to cleanup variables ...
    const parameter = ["multiOptions"]["dataSelected"]
    const optionSelected = generics.valueExistInArrayOfObjectsViaKey(show["multiOptions"]["dataSelected"], category_a, option)
    const singleSelection = templates[activeTemplate][category_a].limit === 1
    const atLimit = show.multiOptions.dataSelected.length >= templates[activeTemplate][category_a].limit
    let action
    switch (true) {
      case !singleSelection && !optionSelected && !atLimit ||
        singleSelection && !atLimit:
        action = "option-add"
        break;
      case singleSelection && atLimit:
        action = "option-remove-all-add"
        break;
      case singleSelection && optionSelected ||
        !singleSelection && optionSelected:
        action = "option-remove"
        break;
      default:
      break;
    };

    // const action = optionSelected ? "option-remove" : "option-add";
    // if (action === "option-add" && show.multiOptions.dataSelected.length >= multiOptionsLimit[category_a] ) { ///limit on number of options selected
    //   return
    // }
    const index = optionSelected ? generics.indexOfObjectInArray(show["multiOptions"]["dataSelected"], category_a, option) : null
    const optionObj={}
    optionObj[category_a] = option
    handleSetShow(parameter, action, optionObj, index)
  }

	const handleSetShow = useCallback((parameter, action, value, index) => {  
    //console.log(parameter, action, value)
    setShow(
      produce((draft) => {
        let item = draft["multiOptions"]["dataSelected"];
        switch (action) {
          case "option-add":
            item.push(value);
            break;
          case "option-remove":
            item.splice(index, 1);
            break;
          case "option-remove-all-add":
            draft["multiOptions"]["dataSelected"] = [value];
            break;
          default:
          break;
        };
      })
    )
	},[]);

  const Category = () => {
    let text

    switch (true) {
      case templates[activeTemplate][category_a].limit === 1:
        text = "select 1 option"
        break;
      case templates[activeTemplate][category_a].limit > 1:
          text = `select up to ${templates[activeTemplate][category_a].limit} options`
        break;
        case templates[activeTemplate][category_a].limit === undefined:
          text = "select options"
        break;
      default:
        break;
    } 
    return (
      <GridColums
        height={`100%`}
        className={"filter-box-categories multi-option-categories"}
        gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${1}, max(100%/${1}))))`} 
      >
          <div 
            className={`filter-box-category center-by-grid`} ///${category === category_a ? `theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ""}`}
            // onClick={() => handleCategory(category)}
          >
            <span className={"filter-box-category-text"}
            >
               <span className={"filter-box-category-text uppercase"}>{`${category_a}:`}</span>
               <span className={"filter-box-category-text lowercase"}>{` ${text}`}</span>
            </span>
          </div>   
      </GridColums>
    )
  }

  // const Options = useCallback(() => {
  //   const parameter = "options"
  //   return (
  //     <div className={"filter-box-filters multi-options-option padding-top-3 flex-centered"}>
  //       {options.map((option) => {
  //         const isSelected = generics.valueExistInArrayOfObjectsViaKey(show.multiOptions.dataSelected, category_a, option)
  //         return (
  //           <Button
  //             show={true}
  //             key={option}
  //             classTypes={["classical", "min"]}
  //             className={`
  //               filter-box-filter 
  //             ${isSelected ?
  //               "theme-0" : "" }
  //             `}
  //             fontSize={1}
  //             // height={"auto"}
  //             // width={"100%"}
  //             isSelected={isSelected}
  //             parentCallback={() => handleOption(option)}
  //           >
  //             {/* <span className={`filter-box-filter-item-count 
  //             text-gradient-1
  //             input-radius input-padding-2
  //             `}
  //             >
  //             </span> */}
  //             <span className={"filter-box-filter-text"}
  //             >
  //               {option}
  //             </span>
  //           </Button> 
  //           )
  //         }
  //       )}
  //     </div>
  //   )
  // }, [show.multiOptions]) ///question: ///was itemElements[category_a], include something instead?

  const Options = useCallback(() => {
    console.log("optionxs", options)
    return (
      <div className={"filter-box-filters multi-options-option padding-top-3 flex-centered"}>
        {options.map((option) => {
          const 
            isSelected = generics.valueExistInArrayOfObjectsViaKey(show.multiOptions.dataSelected, category_a, option),
            isColor = generics.isValidColor(option)
          let text
          switch (true) {
            case 
              !isColor:
              text = option
              break;
            case isColor && !isSelected:
              text = <span>&nbsp;</span> ///note: insert space to ensure homogenous height
              break;
            case isColor && isSelected:
              text = checkIcon ///note: if selected => checkmark
              break;
            default:
            break;
          };
          return (
            <Button
              show={true}
              wrapperHeight={"auto"}
              wrapperWidth={"auto"}
              key={option}
              classTypes={["classical", "min"]}
              paddingAndMargin={"input-padding-1"}
              height={"fit-content"}
              className={`
                filter-box-filter
              ${isSelected ?
                "theme-0" : "" }
              `}
              fontSize={1}
              // height={"auto"}
              // width={"100%"}
              isSelected={isSelected}
              parentCallback={() => handleOption(option)}
              style={{background: isColor ? option : ""}}
            >
              {/* <span className={`filter-box-filter-item-count 
              text-gradient-1
              input-radius input-padding-2
              `}
              >
              </span> */}
              <span className={"filter-box-filter-text"}
              >
                {text}
              </span>
            </Button> 
            )
          }
        )}
      </div>
    )
  }, [show.multiOptions, show.multiOptions.dataSelected]) 

  // const FiltersRemove = () => {
  //   const
  //     parameter = "filters",
  //     action = "filters-remove"
  //   return (
  //     <div className={`
  //       filters-remove-wrapper
  //       ${refine.filters.length > 0 ? "show fadein-05" : "hide fadeout-05"}
  //     `}>
  //       <div className={`
  //         filters-remove
  //         input input-radius input-padding-2
  //         theme input-border-1 theme-color-${theme}
  //       `}>
  //         <i className="fas fa-filter filters-remove-icon"
  //           onClick={() => handleSetRefine(parameter, action)}
  //         ></i>
  //       </div>
  //     </div>
  //   )
  // }

  // const FilterBoxButtons = () => {
  //   return (
  //     <div className={"filter-box-buttons-wrapper"}>
  //       <GridColums
  //         className={"filter-box-buttons"}
  //         gridTemplateColumns={`2em fit-content(50%)`} 
  //         justifyContent={"end"}
  //       >
  //         {/* <FiltersRemove/> */}
  //         <FilterModeToggle/>
  //       </GridColums>
  //     </div>
  //   )
  // }

  const Box = memo(() => {

    const BoxContent = useCallback(() => {
      return (
        <div className={`multi-option-wrapper`}>
        <GridColums
          className={`multi-options`} ///optimise: scrollability of options is ensured via class multi-options and flex-centered with explicit height (random value). but priduces a larger empty space below. optimise
          gridTemplateRows={"5vh fit-content(100%)"}
          overflow={"scroll"}
        >
        {/* <div className={`
            multi-options vertical-gridbox 
            ${show ? "show fadein-05" : "hide fadeout-01"}
            `}
            > */}
            <Category/>
            <Options/>
            {/* <FilterBoxButtons/> */}
        {/* </div> */}
        </GridColums>
      </div>
      )
    }, [show.modal])

    return (
      <BoxContent/>
    )
  })
	
  return (
      <div className={`multi-option-prompt-wrapper`}>
          <Box/>
      </div>
  ); 
}

export const MultiOptionButton = ({ className, disabled, requester, payloadObjectName, payloadInnerPathViaArray, preSelectedOptions, document, label, classTypes, optionCountText, multiOptionsValues, text, children, parentCallback}) => {

  const { show, setShow } = useContext(showContext)

  ///interaction: handle multioption 
  const toggleMultiOptionsShow = () => { ///optimise: move most to modal in app
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", 
      childValue = "multiOptions"
    const 
      modalChild = "multiOptions",
      parameter = "label", labelValue = label,
      data = "data", dataValue = { [label]: multiOptionsValues }
    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[component]["requester"] = requester
        draft[modalChild][parameter] = labelValue
        draft[modalChild][data] = dataValue
        draft[modalChild]["payloadObjectName"] = payloadObjectName
        draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
        draft[modalChild]["preSelectedOptions"] = preSelectedOptions
        draft[modalChild]["document"] = document
      })
    );
  }

  return (
    <Button
      wrapperHeight={"auto"}
      wrapperWidth={"auto"}
      disabled={disabled}
      selectOff={disabled}
      hoverOff={disabled}
      show={true}
      paddingAndMargin={1}
      wrapperClassName={`multi-option-button-wrapper`}
      className={`${className} multi-option-button`}
      classTypes={classTypes}
      theme={0}
      height={"100%"}
      //minHeight={`inherit`}
      //minWidth={minWidthButton}
      fontSize={1}
      message={optionCountText}
      parentCallback={() => !disabled && toggleMultiOptionsShow(label) || disabled && parentCallback()}
  >
    { text } { children }
  </Button> 
  )
}


//   ///state
//   const category = "a"
//   ///const prevRefine = usePrevious(refine.show.filterbox) 

//   ///select and deselect option
//   const handleOption = (option) => { ///optimise: consider to cleanup variables ...
//     const parameter = "childData"
//     const optionSelected = generics.valueExistInArrayOfObjectsViaKey(modal[parameter], category, option)
//     const action = optionSelected ? "option-remove" : "option-add";
//     const index = optionSelected ? generics.indexOfObjectInArray(modal[parameter], category, option) : null
//     const optionObj={}
//     optionObj[category] = option
//     handleSetShow(parameter, action, optionObj, index)
//   }

// 	const handleSetShow = useCallback((parameter, action, value, index) => {  
//     console.log(parameter, action, value)
//     setShow(
//       produce((draft) => {
//         let item = draft[parameter];
//         switch (action) {
//           case "option-add":
//             item.push(value);
//             break;
//           case "option-remove":
//             item.splice(index, 1);
//             break;
//           default:
//           break;
//         };
//       })
//     )
// 	},[]);

//   const Categories = () => {
//     return (
//       <GridColums
//         className={"filter-box-categories"}
//         gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${1}, max(100%/${1}))))`} 
//       >
//         {category.map((category) => 
//           <div 
//             key={category}
//             className={`filter-box-category center-by-grid ${category === category_a ? `theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ""}`}
//             onClick={() => handleCategory(category)}
//           >
//             <span className={"filter-box-category-text"}
//             >
//               {category}
//             </span>
//           </div>   
//         )}
//       </GridColums>
//     )
//   }

//   const Options = useCallback(() => {
//     const parameter = "options"
//     return (
//       <div className={"filter-box-filters padding-1 flex-left"}>
//         {filterOptions.map((filter) => {
//           return (
//             <div 
//             key={option}
//               className={`filter-box-filter 
//               ${generics.valueExistInArrayOfObjectsViaKey(refine[parameter], category_a, filter) ? 
//                 `fadein-05 theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ``}
//               theme theme-border-${theme}
//               input input-padding-2 input-margin-2 input-radius`}
//               onClick={() => handleOption(option)}
//             >
//               <span className={`filter-box-filter-item-count 
//               text-gradient-1
//               input-radius input-padding-2
//               `}
//               >
//               </span>
//               <span className={"filter-box-filter-text"}
//               >
//                 {option}
//               </span>
//             </div> 
//             )
//           }
//         )}
//       </div>
//     )
//   }, [show.modal.childData])

//   const FiltersRemove = () => {
//     const
//       parameter = "filters",
//       action = "filters-remove"
//     return (
//       <div className={`
//         filters-remove-wrapper
//         ${refine.filters.length > 0 ? "show fadein-05" : "hide fadeout-05"}
//       `}>
//         <div className={`
//           filters-remove
//           input input-radius input-padding-2
//           theme input-border-1 theme-color-${theme}
//         `}>
//           <i className="fas fa-filter filters-remove-icon"
//             onClick={() => handleSetRefine(parameter, action)}
//           ></i>
//         </div>
//       </div>
//     )
//   }

//   // const FilterBoxButtons = () => {
//   //   return (
//   //     <div className={"filter-box-buttons-wrapper"}>
//   //       <GridColums
//   //         className={"filter-box-buttons"}
//   //         gridTemplateColumns={`2em fit-content(50%)`} 
//   //         justifyContent={"end"}
//   //       >
//   //         {/* <FiltersRemove/> */}
//   //         <FilterModeToggle/>
//   //       </GridColums>
//   //     </div>
//   //   )
//   // }

//   const Box = memo(() => {

//     const BoxContent = useCallback(() => {
//       return (
//         <div className={`filter-box-wrapper`}>
//         <div className={`
//             filter-box vertical-gridbox 
//             theme theme-border-${theme} theme-color-${theme} input-radius
//             ${show ? "show fadein-05" : "hide fadeout-01"}
//             `}>
//             <Categories/>
//             <Options/>
//             {/* <FilterBoxButtons/> */}
//         </div>
//       </div>
//       )
//     }, [show.modal])

//     return (
//       <BoxContent/>
//     )
//   })

//   return (
//     <Box/>
//   );

// export default memo(FilterBox)