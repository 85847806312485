import './app.css';
import './styling-standards.css';
import './chat.css';
import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {publicIp, publicIpv4, publicIpv6} from 'public-ip';
import * as generics from './Generics';
import { ContentEditableDefault } from './ItemForm';
import ContentEditable from 'react-contenteditable'
import { SocialsModalChildComponent } from './UserCases';
import { SquareIcon, Wrapper, mapText, Button, PlusButton } from './ComponentStandards';
import { initialValueShow } from './App';
// import { templatesDataContext } from './DataProvider';
import { showContext } from './Modal';
import produce from "immer";

export const Chat = ({ setTemplatesData }) => {

  const name = "chat"

  ///const contentEditableRef = useRef([])
  const contentEditable = useRef(null)

  ///context
  const 
    { show, setShow } = useContext(showContext)
    // { templatesData, setTemplatesData } = useContext(templatesDataContext)

  const 
    [ip, setIp] = useState(""),
    [color, setColor] = useState(""),
    [message, setMessage] = useState(""),
    [socials, setSocials] = useState({}),
    [peopleLength, setPeopleLength] = useState(1)

  const getIp = async({}) => await publicIpv4()

  const handleSetTemplatesData = useCallback(({ template, trigger, document, label, index, value, startUpload, reset }) => {

    // if (trigger === "item-text--updated") {
    //   handleSetItems({ label, value })
    // }
    //setIsUpdating(true) ///note: setisupdating implemented to ensure that statedata is copied to templatesdata update. if not [] would be copied ///optimise: way to make obsolute?
    const tempObj = { ///optimise: make specification implicit via map
      document: reset ? "" : document, ///optimse: pass document instead? ///note: if reset document is "", else document
      action: "update", 
      template: template, 
      label: label, 
      index: index, 
      value: value, 
      startUpload: startUpload, 
      reset: reset 
    } 
    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  }, [])

  useEffect(() => {
    // const ip = getIp({})
    // setIp(ip)
  }, [])

  useEffect(() => {
  
    const filteredSocials =  generics.sliceObjectAtIndexs({ obj: socials, indexStart: 0, indexEnd: 0 }) ///note: use only socials corresponding to chosen peopleLength
    ///handleSetTemplatesData({ template: "chat", trigger: "text--change", document: "", label: "socials", index: 0, value: socials, startUpload: false, reset: false }) 
  }, [socials]) ///peopleLength

  const chats = [
    { userCaseId: "11", publishedAt: new Date("2023-10-25"), message: "afgfg", user: "", name: "rico", color: "red", isOwner: true },
    { userCaseId: "11", publishedAt: new Date("2023-10-15"), message: "bfgfg", user: "", name: "bibber", color: "blue", isOwner: false },
    { userCaseId: "11", publishedAt: new Date(), message: "cfgfg", user: "", name: "ricoxxxx", color: "red", isOwner: true },
    { userCaseId: "11", publishedAt: new Date(), message: "dfgfg", user: "", name: "ricxxxxxxxxo", color: "red", isOwner: true },
  ]

  const toggleModalShow = ({ label, modalChildComponent, modalChildName }) => { ///optimise: move most to modal in app
	
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", 
      childValue = modalChildName ///note/risk: not correct - should be dynamic, based on who calls (textialarray or textualobjecs)
    const 
      modalChild = modalChildName,
      parameter = "label", labelValue = label
      //data = "data", dataValue = { ["bib"]: "hi" }

    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[component]["requester"] = "userCase"
        draft[modalChild][parameter] = labelValue
        //draft[modalChild][data] = dataValue
        draft[modalChild]["child"] = modalChildComponent
        // draft[modalChild]["payloadObjectName"] = payloadObjectName
        // draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
        // draft[modalChild]["preSelectedOptions"] = preSelectedOptions
        draft[modalChild]["document"] = ""
      })
    );
  }

  const handleSetSocials = useCallback(({ index, value, peopleLength }) => {
    setSocials(
      produce((draft) => {
      draft[index] = value
      })
    )
    ///setPeopleLength(peopleLength)
  }, [])

  const chatsMap = chats.map((chat, i) => {
  
    const timeAgo = generics.timeAgo( chat.publishedAt )
    return ( 
      <div key={`${chat.publishedAt}-${i}`} className={`chat-message-container ${chat.isOwner ? "owner--true" : "owner--false"}`}>
        <span className={`chat-message-header`}>{chat.name} {timeAgo}</span>
        <span className={`chat-message`}>{chat.message}</span>
      </div>
    )
  })

  const socialMediaProfilesButton =
    <div className={`social-media-profiles-button`}>
      <Button
            classTypes={["classical", "all"]}
            isSelected={false}
            height={"100%"}
            width={"100%"}
            fontSize={0}
            show={true}
            theme={`${0} theme-social-media`}
            parentCallback={() => {
              // sessionInfo.document === userCase.document && 
              // !isLocked && 
              toggleModalShow({ label: "socials", 
                modalChildComponent: 
                  <SocialsModalChildComponent 
                    template={"chat"}
                    isChangePeopleLength={false}
                    userCase={{}} 
                    setSocialsCallback={({ index, value, peopleLength }) => handleSetSocials({ index, value, peopleLength })}
                    setPeopleLengthCallback={({ peopleLength }) => setPeopleLength(peopleLength)}
                  />, 
                modalChildName: "textualObjects"})
              } 
            }
            >Add SoMe profiles
      </Button>
    </div>


  return (
	  // <Wrapper
    //   name={name}
    // >
      <div className={`chat-container`}>
        <div className={`chat`}>
          <div className={`chat-messages-container`}>
            <div className={`chat-messages`}>
              {chatsMap}
            </div> 
          </div>
          { socialMediaProfilesButton }
          <div className={`chat-input-container`}>
            <div className={`chat-input`}>
              <ContentEditable
                innerRef={contentEditable}
                html={message} // innerHTML of the editable div
                disabled={false}       // use true to disable editing
                onChange={() => setMessage(message)} // handle innerHTML change
              />
              {/* <ContentEditableDefault
                label={"chat"}
                key={0}
                placeholder={"bib"}
                className={"className"}
                editableContent={message}
                index={0}
                refIndex={0}
                currentRefs={contentEditableRef.current}
                setStateCallback={(label, value, index) => setMessage(value)} //handleSetTemplatesData({ label, value, index })}
              /> */}
            </div>
          </div>
        </div>
      </div>
      //</Wrapper>

  );
}