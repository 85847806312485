import './app.css';
import './styling-standards.css';
import './main-selector.css';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Wrapper } from './ComponentStandards';
import produce from "immer";
import { sessionInfoContext } from './SessionInfoProvider';
import * as LoginInput_w from './LoginInput_w';

export const MainSelector = ({ selectorId, setSelectorId }) => {

  ///context
  const { sessionInfo, setSessionInfo } = useContext(sessionInfoContext)

  const handleSelect = useCallback(({ index }) => {
    setSelectorId(index)
    if (
      index === selectorMap.length - 1 
      ){
      handleSessionInfo()
    }
  }, [sessionInfo.document, window.scrollY])

  const handleSessionInfo = () => { 
   
    if (!sessionInfo.document) {

      setSessionInfo(
        produce((draft) => {
          draft["open"] = true ///!sessionInfo.open;
        })
      )
    }
    if (!!sessionInfo.document) {
      LoginInput_w.SignOut()
    }
	}

  const name = "main-selector"

  const 
    mainSelectors = ["destinations", "trips", "map", "faq"],
    menuSelectors = [`${!sessionInfo.document ? "new trip" : "my trip"}`, `${!sessionInfo.document ? "open trip" : "close trip"}`],
    selectorMap = [...mainSelectors, ...menuSelectors],
    gridAutoColumns = ([...selectorMap, ""].map((selector, index) => index === mainSelectors.length ? "1fr" : "fit-content(100%)")).join(" ")

  const MainSelectors = 
    selectorMap.map((mainSelector, index) =>
      <div 
        key={mainSelector}
        className={`
        ${name}-wrapper ${mainSelector.replace(" ", "-")}
        ${index > mainSelectors.length - 1 ? "menu-selector" : "main-selector"} 
        ${selectorId === index ? "selected--true" : "selected--false"}
      `}
        style={{justifySelf: index > mainSelectors.length - 1 ? "end" : "start"}}
      >
        <div className={`${name}-container`}
          onClick={() => handleSelect({index})}
        >
          <div className={`${name}`}>
            {mainSelector}
          </div>
        </div>
      </div>
    )

  return (
      <div className={`${name}-component-wrapper`}>
        <div className={`${name}-component`}
          style={{gridAutoColumns: gridAutoColumns}}
        >
         {MainSelectors}
        </div> 
      </div>
  );
}