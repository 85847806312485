import './header.css';
import { useState, useEffect, useContext } from 'react';
import SwitchTheme from './SwitchTheme';
import { HeaderLinks} from './Links';
import logo from './siargao-live-logo.png';
import { GridColums, Logo, Switch, Wrapper } from './ComponentStandards';
import GoogleTranslate from './GoogleTranslate';
  // import ViewToggle from './ViewToggle';
import { themeContext } from './ThemeProvider';
import * as generics from './Generics';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import * as icons from './Icons'
import { urlInfoContext } from './UrlInfoProvider';

export default function Header() {

  ///context
  const 
    { urlInfo } = useContext(urlInfoContext),
    { theme} = useContext(themeContext);

  
  ///state
  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(urlInfo.location.pathname === "/" || generics.stringIncludes(urlInfo.location.pathname, "/gallery/") ? true : false)
  }, [urlInfo]);

  const top =
      <GridColums 
      pos={`top`} 
      style={{}}
      className={"header"}
      //gridTemplateColumns={`fit-content(100%) 1fr 1fr 10vw 10vw`} ///fit-content(100%) 10em ///optimise: width identical to min width of googletranslate - base it on shared variable
      gridAutoFlow={"column"}
      //gridTemplateColumns={`minmax(0, 1fr)`}
      //gridTemplateRows={""}
      //justifyContent="start"
      justifyItems="center"
      alignItems={"center"}
      columnGap={"2vw"}
      width={"100%"}
    >
      <Logo 
        src={logo} 
      />
      <div></div>
      {/* <Refine 
        // pos={`bottom`} 
        // style={{gridColumn: "1 / span 2"}} //justifySelf: "center", 
      /> */}
      <HeaderLinks/>
      <SwitchTheme/>
      {/* <ViewToggle/> */}
      {/* <GoogleTranslate/> */}
      {/* <Switch language={"español"}/> */}
    </GridColums>
  const bottom = 
      <GridColums 
        pos={`bottom`} 
        className={"header"}
        gridTemplateColumns={`1fr`}
        gridTemplateRows={""}
        justifyContent="end"
        justifyItems="center"
        alignItems={"center"}
        //columnGap={"2vw"}
      >
        {/* <HeaderLinks activeTemplate={activeTemplate}/> */}
        {/* <SocialMedia/> */}
        <Wrapper
         name={`tagline`}
         show={show}
         animate={true}
        >
          <span className={`header-tagline`}>{`True Discovery`}</span>
          {/* <span className={`header-tagline`}>{`A `}</span>{icons.shakaSolidIcon({ color: "black", fontSize: "1.5em"})}
          <span className={`header-tagline`}>{` Choice for Discovering Siargao`}</span> */}
        </Wrapper>
      </GridColums>

  const elements = [top] ///[top, bottom]

  return (
	  <div className={`header-wrapper theme theme-${theme.theme}`}>
      <GridColums
        className={`header`}
        gridTemplateColumns={`1fr`}
        gridTemplateRows={`2fr`} ///gridTemplateRows={`2fr ${isBrowser ? "4fr" : "0fr"}`}
        alignItems={"center"}
        height={"100%"}
      >   
        {elements.map((element) => 
          <div 
            key={element.props.pos}
            className={`header-${element.props.pos}`}
            style={element.props.style}
          >
            {element}
          </div>
        )}
      </GridColums>
    </div>
  );
}