
import './app.css';
import './styling-standards.css';
import './user-comments.css';
import { useState, useEffect, memo, useContext, useRef, useCallback, forwardRef } from 'react';
import { Wrapper, Button } from './ComponentStandards';
import { themeContext } from './ThemeProvider';
import ContentEditable from 'react-contenteditable'
import * as generics from './Generics';
import Dropdown from 'react-dropdown';
import { UserCaseFilters } from './UserCases';
import { Rating } from './Rating';
import produce from "immer";
import * as icons from './Icons';
import { Socials, socials } from './SocialMedia';
import * as NewArtwork_w from './NewArtwork_w';
import { openStdin } from 'process';
import { keyframes } from 'styled-components';
import { usePrevious} from './StateOptions';
import { SocialMediaPostsModalChildComponent, getUserCaseIcon } from './UserCases';
import { SocialMediaPostPreview } from './SocialMediaPost';
import { DropDown} from './DropDown';
import { templates } from './ItemForm';
import { showContext } from './Modal';
// import { templatesDataContext } from './DataProvider';
import { userCasesContext } from './UserCasesProvider';
import { sessionInfoContext } from './SessionInfoProvider';
import { userCommentsContext } from './UserCommentsProvider';

const messages = {
  review: "Share and rate your experience! Remember positive vibes!",
  ["pro tip"]: "Got any pro-tip? We might include your tip in the listing, thank you!",
  ["feedback to business"]: "Something the business should know? Remember positive vibes!",
  ["correction"]: "Found inaccurate information? We care to get it right!",

  excitement: "Planning tip: Rate below to indicate 'how excited you are about experiencing this listing'"
}


const NewComment = ({ listingColor, edit, isNew, existingSocialMediaPosts, existingComment, existignRating, existingUserCaseFilters, isReset, listingDocument, openUserCase, isPublic, newRating, newCommentUserCaseFiltersCallback, userCaseFiltersHoverCallback, isValidatedCallback }) => {

  const contentEditableRef = useRef([]);

  const 
    { theme } = useContext(themeContext)

  const 
    [userCaseFilters, setUserCaseFilters] = useState([]),
    [newData, setNewData] = useState({}),
    [isValidated, setIsValidated] = useState(false)

  //const openUserCase = userCases.find(userCase => userCase.document === sessionInfo.document) 

  const { by, type, document } = openUserCase
  const userCaseDocument = document

  const handleSetNewData = ({ label, value }) => {
    setNewData(
      produce((draft) => {
        draft[label] = value
      }
    ))
  }

  useEffect(() => {
    if (isReset) {
    setNewData({})
    contentEditableRef.current[0] = ""
    contentEditableRef.current[1] = ""
    setUserCaseFilters([])
    }
  }, [isReset])

  useEffect(() => {
    if (newRating > 0) {
      if (!userCaseFilters.includes("review")) {
          handleUserCaseFilters({ filter: "review" })
      }
    }
    if (newRating === 0) {
      if (userCaseFilters.includes("review")) {
        handleUserCaseFilters({ filter: "review" })
      }
    }
  }, [newRating])

  useEffect(() => {   
    let isValidated
      if (isNew) {
        switch (true) {
          case type === "plan":
            isValidated = !!newData?.comment || newRating > 0
            break;
          case type === "experiences":
            if (userCaseFilters.includes("review")) {
              isValidated = newRating > 0
            } else {
              isValidated = !!newData?.comment
            }
            break;
          default:
          break;
        };
      }
      if (!isNew) {
        switch (true) {
          case type === "plan":
        
            isValidated = 
              (!!newData?.comment || newRating > 0) &&
              existingComment !== newData?.comment
              ||
              !generics.isEqualArrays(existingUserCaseFilters, userCaseFilters)
              ||
              newRating !== existignRating
            break;
          case type === "experiences":
            if (userCaseFilters.includes("review")) {
              isValidated = 
                newRating > 0 &&
                !!newData?.comment &&
                existingComment !== newData?.comment 
                ||
                !generics.isEqualArrays(existingUserCaseFilters, userCaseFilters)
                ||
                newRating !== existignRating
            } else {
              isValidated = 
                !!newData?.comment &&
                existingComment !== newData?.comment
                ||
                !generics.isEqualArrays(existingUserCaseFilters, userCaseFilters)
                ||
                newRating !== existignRating
            }
            break;
          default:
          break;
        };
      }
    setIsValidated(isValidated)
  }, [newData, newRating, userCaseFilters, existingComment, existignRating, existingUserCaseFilters])

  useEffect(() => {   
    isValidatedCallback({ isValidated })
  }, [isValidated])



    let useCaseFilters = []
    switch (true) {
      case type === "plan":
        useCaseFilters = ["excitement", "activities & itinerary", "date, timing & duration", "food & dining", "budget & cost", "transportation", "packing & gear", "cultural & language", "booking & cancellation", "safety & health", "travel documentation", "other"]
        break;
      case type === "experiences":
        useCaseFilters = ["review", "pro tip", "feedback to business", "correction"]
      break;
      case type === "itin":
        useCaseFilters = ["review", "pro tip", "feedback to business", "correction"]
        break;
      default:
      break;
    };
    const commentTags = 
    <UserCaseFilters 
      backgroundColor={isPublic ? "gold" : listingColor} 
      userCaseFiltersMap={useCaseFilters}
      theme={theme} 
      userCaseFilters={userCaseFilters} 
      userCaseFiltersHoverCallback={userCaseFiltersHoverCallback} //({ filter, isHover }) => handleUserCaseFiltersHover({ filter, isHover })}
      userCaseFiltersParentCallback={({ filter }) => handleUserCaseFilters({ filter })}
    />

    const Editable = useCallback(({ label, placeholder, refIndex, editableCallback }) => { ///note: callback used to mitigate re-render of contenteditable (=> loss of html value and focus)

      const handleOnChange = (e) => { 
        const value = generics.containsHTML(e.target.value) ?
          generics.removeHtmlTypeStrings(e.target.value) :
          e.target.value

        contentEditableRef.current[refIndex] = value

        editableCallback({ label, value })
      }

      return (
         <ContentEditable ///optimise: implement contenteditable default
          key={refIndex}
          //className={`${templateClassName}`}
          //tagName={htmlTag}
          placeholder={placeholder}
          // className={className}
          html={!!contentEditableRef.current[refIndex] ? contentEditableRef.current[refIndex] : ""} ///note: !!... to mitigate undefined at first mount/render ///optimise: consider to optimise
          //innerRef={(el) => (contentEditableRef.current[refIndex] = el)}
          // placeholder={userPresent ? `add ${label}?` : null}
          //style={contentStyle}
          // disabled={!userPresent}
          //innerRef={contentEditableRef} ///(el) => (contentEditableRef.current[refIndex] = el)}
          onChange={(e) => handleOnChange(e)}//handleChange(e)}
          //onClick={() => !userPresent && handleTextClicked(label)} ///reactivate
          // onKeyDown={(e) => {
          //   const gotoNext = false
          //   contentEditableKeyDown(e, label, contentEditableRef.current, refIndex, gotoNext)} ///note: refindex due to nested data like dimensions
          // } 
          // onPaste={(e) => {
          //   const anchorOffset = window.document.getSelection()["anchorOffset"]
          //   const value = contentEditablePaste(e, label, contentEditableRef.current, refIndex, anchorOffset) ///if text is not restricted => return text, else empty string
          //   handleSetItems(label, document, value, s) ///note: handlecontenteditableblur not used: to mitigate upgrade at backend directly after paste. instead re-focus via setscrollto/wasref ensures update at blur
          //   const component = "itemtext"
          //   handleSetScrollTo(contentEditableRef.current[refIndex], refIndex, anchorOffset, component)
          //   }} 
          // ///note: onchange not used => to ensure correct focus/blur at custom keydown events
      //     onBlur={async() => {
      //       //await NewArtwork_w.updateUserCase({ document: sessionInfo.document, label: label, value: contentEditableRef.current[refIndex]?.innerHTML, isAdd: null })
      //       // setUserCases(
      //       //   produce((draft) => {
      //       //     const index = draft.findIndex(userCase_ => userCase_.document === sessionInfo.document)
      //       //     if (index !== -1) draft[index][label] = contentEditableRef.current[refIndex]?.innerHTML
      //       //   })
      //       // )
      // }}
      > 
    </ContentEditable>
      )
    }, [contentEditableRef, existingComment, existignRating, existingUserCaseFilters])

  const comment = 
    <Editable 
      label={"comment"}
      refIndex={0}
      placeholder={"comment?"}
      editableCallback={handleSetNewData}
      >
    </Editable>

  const by_ = type === "plan" && !isPublic ?  
    <Editable 
      label={"by"}
      refIndex={1}
      placeholder={`${!!by ? `${by} or?` : "by?"}`}
      editableCallback={handleSetNewData}
      >
    </Editable> :
    !!by ? by : "anonymous" 

    useEffect(() => {   
      newCommentUserCaseFiltersCallback({ type, userCaseFilters })
  }, [userCaseFilters])


  useEffect(() => {
    if (!isNew) {
      contentEditableRef.current[0] = existingComment
      setUserCaseFilters(existingUserCaseFilters)
      // contentEditableRef.current[1] = rating
    } 
  }, [existingComment, existignRating, existingUserCaseFilters])

//   useEffect(() => {   
//     console.log("contentEditableRef", contentEditableRef)
// }, [contentEditableRef])

  // const handleOnChange = (e, refIndex) => { ///note: target is undefined, blur blur capture the correct value! (why??)
  //   if (generics.containsHTML(e.target.value)) {
  //     contentEditableRef.current[refIndex] = generics.removeHtmlTypeStrings(e.target.value)
  //   }
  // }


  const handleUserCaseFilters = ({ filter }) => {

    const isSelected = userCaseFilters.includes(filter)
    setUserCaseFilters(
      produce((draft) => {
        if (!isSelected) {
          draft.push(filter)
        }
        if (isSelected) {
          const index = draft.findIndex(filter_ => filter_ === filter) 
          if (index !== -1) draft.splice(index, 1)
        }
      })
    );
  }

  return (
    {
    // document: "6SDd5IoDObxe8o8bXUsK",
    comment: comment,
    header: isPublic ? `public message` : `new private note`,
    by: by_,
    new: true,
    edit: edit,
    type: type,
    tags: commentTags,
    isPublic: isPublic,
    ["social media posts"]: existingSocialMediaPosts,
    newData: {
      comment: newData?.comment,
      by: !!newData?.by ? newData?.by : by_,
      tags: userCaseFilters,
      userCaseDocument: userCaseDocument,
      listingDocument: listingDocument,
      type: type,
      isPublic: isPublic,
      rating: newRating,
    }
  }
  )
}

export const UserComment = ({ templatesData, isSocialMediaPreview, listingDocument, listingColor, userComment, isPublicPost, setIsPublicPost }) => {


  const 
    { theme } = useContext(themeContext),
    { userCases } = useContext(userCasesContext),
    { sessionInfo } = useContext(sessionInfoContext),
    { setShow } = useContext(showContext) ///note: optimise by moving elsewhere (reduce impact of change => re-render),
    // { templatesData } = useContext(templatesDataContext)
    
  const 
    [isNewCommentStarted, setIsNewCommentStarted] = useState(false),
    [isReviewTagChosen, setIsReviewTagChosen] = useState(false),
    [newRating, setNewRating] = useState(0),
    [userCaseFiltersHoverInfo, setUserCaseFiltersHoverInfo] = useState({}),
    [isValidated, setIsValidated] = useState(false),
    [isReset, setIsReset] = useState(false),
    [changedBinary, setChangedBinary] = useState(0) ///note: used to trigger animation on update of public message

  const 
    isOpenUserCase = 
      !!sessionInfo?.document && 
      userCases.some(userCase => userCase?.document === sessionInfo.document) ///note: to ensure new userCase is loaded and setstate
  const
    openUserCase = isOpenUserCase && userCases.find(userCase => userCase?.document === sessionInfo.document),
    isCommentFromOpenUserCase = isOpenUserCase && openUserCase?.document === userComment?.userCaseDocument,
    isCommentPublicFromOpenUserCase = isCommentFromOpenUserCase && userComment.isPublic,
    isUserCaseSelected = sessionInfo.userCases?.some(userCase_ => userCase_.document === userComment.userCaseDocument),
    color = (userCases.find(userCase => userCase?.document === userComment.userCaseDocument))?.color

useEffect(() => {
  if (isReset) { ///note: after upload => reset true at usercomment (isReset true and newRating 0) => pass reset to new usercomment + useEffect reset to false in usercomment
    setIsReset(false)
  }
}, [isReset])


// const contentEditableRef = useRef([]);

const handleNewCommentUserCaseFiltersChange = ({ type, userCaseFilters }) => {
  setIsReviewTagChosen(!!userCaseFilters?.includes("review"))
}

const handleUserCaseFiltersHover = ({ filter, isHover }) => {
  setUserCaseFiltersHoverInfo({ isHover: isHover, filter: filter })
}

const handleSetIsValidated = ({ isValidated }) => {
  setIsValidated(isValidated)
}

const handleRating = ({ rating }) => {
  setNewRating(rating)
 }

 let userComment_ = {}
 switch (true) {
  case userComment?.new && !userComment?.edit &&
    isOpenUserCase: ///note: added to ensure userCase at new usercase is downloaded and setstate
    userComment_ = NewComment({ 
      listingColor, edit: userComment?.edit, isNew: true, isReset, listingDocument: listingDocument, isPublic: userComment.isPublic, newRating, isNewCommentStarted, openUserCase, label: "comment", newCommentUserCaseFiltersCallback: handleNewCommentUserCaseFiltersChange, userCaseFiltersHoverCallback:  handleUserCaseFiltersHover, isValidatedCallback: handleSetIsValidated 
      }) 
    break;
  case userComment?.new && userComment?.edit: ///was: isCommentPublicFromOpenUserCase

    userComment_ = 
      NewComment({ 
          listingColor, edit: userComment?.edit, isNew: false, existingSocialMediaPosts: userComment["social media posts"], existingComment: userComment.comment, existignRating: userComment.rating, existingUserCaseFilters: userComment.tags,
          isReset, listingDocument: listingDocument, isPublic: userComment.isPublic, newRating, isNewCommentStarted, openUserCase, label: "comment", newCommentUserCaseFiltersCallback: handleNewCommentUserCaseFiltersChange, userCaseFiltersHoverCallback:  handleUserCaseFiltersHover, isValidatedCallback: handleSetIsValidated 
        }) 
    break; 
  default:
    userComment_ = userComment
  break;
};

//  let userComment_ = userComment?.new ?
//     NewComment({ isReset, listingDocument: listingDocument ,isPublic: userComment.isPublic, newRating, isNewCommentStarted, openUserCase, label: "comment", newCommentUserCaseFiltersCallback: handleNewCommentUserCaseFiltersChange, userCaseFiltersHoverCallback:  handleUserCaseFiltersHover, isValidatedCallback: handleSetIsValidated }) :
//     userComment

 userComment_ = isCommentPublicFromOpenUserCase ?
    {...userComment_, header: `edit public message` } :
    userComment_

  const socialsMap = socials.map((social => { return {[social]: openUserCase?.[social] } }))

  const timeAgo = generics.timeAgo(userComment_.created)

  useEffect(() => {
    setNewRating(!!userComment.rating ? userComment.rating : 0) ///optimise: setstate and dependencies made to work, but not optimised (consider checking cascade)
  }, [isCommentPublicFromOpenUserCase, userComment.rating])

  const toggleModalShow = ({ label, modalChildComponent, modalChildName }) => { ///optimise: move most to modal in app
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", 
      childValue = modalChildName ///note/risk: not correct - should be dynamic, based on who calls (textialarray or textualobjecs)
    const 
      modalChild = modalChildName,
      parameter = "label", labelValue = label
      //data = "data", dataValue = { ["bib"]: "hi" }

    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[component]["requester"] = "userCase"
        draft[modalChild][parameter] = labelValue
        //draft[modalChild][data] = dataValue
        draft[modalChild]["child"] = modalChildComponent
        // draft[modalChild]["payloadObjectName"] = payloadObjectName
        // draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
        // draft[modalChild]["preSelectedOptions"] = preSelectedOptions
        draft[modalChild]["document"] = ""
      })
    );
  }

    // //multioptions via modal action => 
    // useEffect(() => {
    //   if (show.modal.requester === "userComment") {
    //     if (show.multiOptions.action === "check") {
    //       console.log("yoyo", show.multiOptions.dataSelected)
    //       //handleUpdateUserComment({ document: show.multiOptions.document, label: show.multiOptions.label, value: show.multiOptions.dataSelected, isAdd: null })
    //     }
    //   }	
    // }, [show.multiOptions.action]);
  
    // const handleUpdateUserComment = async({ document, label, value, isAdd }) => {
    //   console.log("fuckitit", document, label, value, isAdd)
    //   //await NewArtwork_w.updateUserCase({ document, label, value, isAdd })
    //   // await NewArtwork_w.updateSubcollectionDoc({ collection: "userCases", document: openUserCase.document, subCollection: "comments", subDocument: userComment.document, 
    //   // payload: { comment: userComment_.newData.comment, rating: userComment_.newData.rating, tags: userComment_.newData.tags }
    //   // })
    //   //setShow(initialValueShow)
    // }        
  

  const addSocialMediaPostsButton = ({ isShow }) => {
    const text = isCommentPublicFromOpenUserCase ? 
      !!userComment_["social media posts"]?.[0] ? "Edit SoMe" : "Add SoMe" :
      !!templatesData.update.values.userComment["social media posts"]?.[0] ? "Edit SoMe" : "Add SoMe" 
    const button = 
    <div className={`social-media-button ${isShow ? "show fadein-05" : "hide fadeout-05"}`}>
      <Button
            classTypes={["classical", "min"]}
            wrapperHeight={"auto"}
            wrapperWidth={"auto"}
            isSelected={false}
            height={"100%"}
            width={"100%"}
            fontSize={0}
            show={true}
            theme={`${theme.theme}`}
            parentCallback={() => {
     
                toggleModalShow({ label: "social media posts", 
                  modalChildComponent: 
                    <SocialMediaPostsModalChildComponent
                    template={"userComment"}
                    item={isCommentPublicFromOpenUserCase ? 
                      userComment_ :
                      templatesData.update.values.userComment
                    } 
                    document={userComment.document}
                    />, 
                  modalChildName: "textualArray"})}
            }
            >{text}
      </Button>
    </div>
    return <>{button}</>
  }

  return ( ///note:  user-comment-container "socialmedia-view" and "new" had !userComment_?.new at small new preview design ///&& user-comment-container "new" had userComment.isPublic
  <div className={`user-comment-wrapper 
    ${userComment_?.new ? `new` : "preview"}
    ${userComment_.isPublic ? "ispublic--true" : `ispublic--false`}
    ${isCommentFromOpenUserCase ? "comment-from-open-usercase" : ``}
    ${isValidated ? "validated" : ""}
    ${isSocialMediaPreview ? "height--fixed" : "height--full" }
    ${!isSocialMediaPreview && userComment_.isPublic ? "move-right" : "" }
    `}
      // style={{boxShadow: !userComment_.isPublic && userComment_?.new ? `0px -1.5em ${listingColor}` : ""}}
    key={`${userComment_.document}`}
    > 
    <div className={` 
      user-comment-container
      ${userComment_?.new  ? `new` : ``} 
      ${!isSocialMediaPreview ? `center` : ``}
      ${!isSocialMediaPreview && !!userComment_["social media posts"]?.[0]  ? `socialmedia-view` : ``}
    `}
    > 
  <div className={`user-comment-container-inner`}>
      {/* <div className={`user-comment-inner-wrapper`}> */}
      <div className={`user-comment theme-${theme.theme}
        ${userComment_?.new && !isCommentPublicFromOpenUserCase ? `new` : ""}
        ${userComment_.isPublic ? "ispublic--true" : `ispublic--false`}
        ${isCommentFromOpenUserCase ? "comment-from-open-usercase" : ``}
        ${isValidated ? "validated" : ""}
        change-${changedBinary} 
        ${isUserCaseSelected ? "user-case-selected--true" : "user-case-selected--false"}
      `}
        style={{border: !userComment_.isPublic ? `1px solid ${listingColor}` : "", boxShadow: !userComment_.isPublic && userComment_?.new ? `0px -1.5em ${listingColor}` : ""}}
        onClick={() => userComment_?.new  && setIsNewCommentStarted(true)}
      >
        <div className={`user-comment-content-container`}>
        {/* <div className={`user-comment-span user-comment-header`}> */}
          
        <div className={`user-comment-header-container`}>
        <div className={`user-comment-header`}>
          {/* <span style={{pointerEvents: "none"}} 
            className={`user-comment-span user-comment-header`}> */}
          { userComment_?.new ? ///was: || isCommentPublicFromOpenUserCase
            <div className={`user-comment-header-text`}>
              <DropDown 
                theme_={"transparent"}
                options={
                  isPublicPost ? 
                  ["new private post"] : ["public post"]
                 ///["public post", "new private post"]
                } 
                passedValue={isPublicPost ? 
                  isCommentFromOpenUserCase ?
                    "edit public post" :
                    "new public post"
                  : "new private post"} 
                placeholder={`Select an option`}
                parentCallback={({ template }) => {
                  const isPublicPost = template.includes("public post")
                  setIsPublicPost(isPublicPost)
                }}
              />
              {/* <span  className={`user-comment-header-text`}>{userComment_.header}</span> 
              {userComment_?.new ? 
              <span className={`${isNewCommentStarted ? "hide fadeout-05" : "show fadein-05"}`}>
                  {"? "}
              </span> : null} */}
            </div> 
            
            : null
        }
            {userComment_?.new ? 
              <span 
            
                onClick={async() => {
                  if (!isValidated) { return }
                  if (userComment_?.new && !isCommentPublicFromOpenUserCase) {
                    let newData 
                    newData = generics.isString(userComment_.newData.by) ? ///note: newData.by is contenteditable object if no explicit value, thus test is isString..
                      userComment_.newData : 
                        !!openUserCase.by ? 
                          {...userComment_.newData, by: openUserCase.by } : 
                          {...userComment_.newData, by: "anonymous" }
                    newData = generics.isString(userComment_.newData.comment) ? ///optimise: ensure value in component itself
                      newData :
                      {...newData, comment: "" }
                    newData = !!templatesData.update.values.userComment["social media posts"]?.[0] ?
                      {...newData, ["social media posts"]: templatesData.update.values.userComment["social media posts"]} :
                      {...newData, ["social media posts"]: []} 

                    await NewArtwork_w.WriteArtwork(newData, "userCases", openUserCase.document, "comments")

                    setIsReset(true)
                    setNewRating(0)
                  }

                  if (userComment_?.new && isCommentPublicFromOpenUserCase) {
                    console.log("userComment_.newData", userComment_.newData)
                    await NewArtwork_w.updateSubcollectionDoc({ collection: "userCases", document: openUserCase.document, subCollection: "comments", subDocument: userComment.document, 
                      payload: 
                        { 
                          ...(!!userComment_.newData.comment ? { comment: userComment_.newData.comment } : {}),
                          ...(!!userComment_.newData.rating ? { rating: userComment_.newData.rating } : {}),
                          ...(!!userComment_.newData.tags ? { tags: userComment_.newData.tags } : {}),
                          ...(!!templatesData.update.values.userComment["social media posts"]?.[0] ? {  ["social media posts"]: templatesData.update.values.userComment["social media posts"] } : {}),                    
                        }
                      
                      // !!templatesData.update.values.userComment["social media posts"]?.[0] ?
                      //   { comment: !!userComment_.newData.comment ? userComment_.newData.comment : userComment_.comment, rating: userComment_.newData.rating, tags: userComment_.newData.tags, ["social media posts"]: templatesData.update.values.userComment["social media posts"]} :
                      //   { comment: !!userComment_.newData.comment ? userComment_.newData.comment : userComment_.comment, rating: userComment_.newData.rating, tags: userComment_.newData.tags }
                    })
                    setChangedBinary(changedBinary === 0 ? 1 : 0)
                    // setIsReset(true)
                    // setNewRating(0)
                  }
                }}
                className={`paper-plane ${isValidated ? "show active fadein-05" : "fade fadeout-05"}`}>
              {icons.paperPlanIcon}
              </span> : ""}
          {/* </span> */}
          </div>
        </div>
        <div className={`user-comment-subheader-container`}>
            <div className={`type-container`}>
              <span className={`user-comment-span type`}>{getUserCaseIcon({ type: [userComment_?.type], color: isUserCaseSelected ? color : "rgb(207, 176, 0)"})}</span> 
              <span className={`user-comment-span tags`}>
              
                { userComment_?.new ?
                userComment_.tags :
                <UserCaseFilters 
                  backgroundColor={userComment_.isPublic ? "gold" : listingColor} //userComment_?.isPublic ? "#FF6961" :  listingColor}
                  userCaseFiltersMap={userComment_.tags} //[userComment_.type, ...userComment_.tags]}
                  theme={theme} 
                  userCaseFilters={[]} 
                  hoverOff={true}
                  //disabled={true}
                  // userCaseFiltersHoverCallback //({ filter, isHover }) => handleUserCaseFiltersHover({ filter, isHover })}
                  // userCaseFiltersParentCallback
              />}
              </span>
            </div>
        </div>
        <div className={`user-comment-content`}>

        {!userComment_?.new &&
        <div className={`user-comment-socialmedia-wrapper ${isSocialMediaPreview ? "preview" : ""}`}>
          <div className={`user-comment-socialmedia-container`}>
            {/* {addSocialMediaPostsButton} */}
            {/* {!userComment_?.new && */}
            {userComment.isPublic && isSocialMediaPreview && !!userComment_["social media posts"]?.[0] && ///note: temp => remove userComment.isPublic and include critiera if SoMe exist 
            <SocialMediaPostPreview
                url={userComment["social media posts"][0]}
                isWatermark={false}
            />
            }
            {/* } */}
          </div>
        </div>
      }
          <div className={`user-comment-span-container`}>

            <div className={`user-comment-span-by-container`}>
              <span className={`user-comment-span by`}>{userComment_.by}</span>
              <Socials value={socialsMap}/>
              { (!userComment_.new && !isCommentPublicFromOpenUserCase) ? ///note: isCommentPublicFromOpenUserCase comment got userComment_.new true, consider to optimise...
                <span className={`timeago`}>{timeAgo}</span> : null
              }
            </div>

            <div className={`user-comment-span comment-container`}>
              <div className={`user-comment-span message ${userComment_?.new && userCaseFiltersHoverInfo?.isHover ? "show fadein-05" : "hide fadeout-05"}`}>
                {messages[userCaseFiltersHoverInfo?.filter]}
              </div>
              <div className={`user-comment-span comment ${userComment_?.new && userCaseFiltersHoverInfo?.isHover ? "hide fadeout-05" : "show fadein-05 "}`} >
                {userComment_.comment}
              </div>
            </div>
            {userComment_?.new || userComment.rating > 0 ? 
            <Rating 
              isNew={userComment_?.new} 
              type={userComment_.type} 
              rating={userComment_?.new || isCommentPublicFromOpenUserCase ? newRating : userComment_.rating} 
              isReviewTagChosen={isReviewTagChosen}
              isEditEnabled={userComment_?.new} ratingCallback={handleRating} ///|| isCommentPublicFromOpenUserCase
            />
              : null
            }
          </div>
          </div>
        </div>
      </div>
      {userComment_?.new && addSocialMediaPostsButton({ isShow: userComment.isPublic })}
    </div>

      {/* </div> */}
      
      {(userComment_?.new && userComment.isPublic || !userComment_?.new && userComment.isPublic && !isSocialMediaPreview) ?  ///note: temp => remove userComment.isPublic and include critiera if SoMe exist 
        <div className={``}>
          
          {(
            (!userComment_?.new && !!userComment_["social media posts"]?.[0]) || 
            (userComment_?.new && !userComment_?.edit && !!templatesData.update.values.userComment["social media posts"]?.[0]) ||
            (userComment_?.new && userComment_?.edit && !!templatesData.update.values.userComment["social media posts"]?.[0]) ||
            (userComment_?.new && userComment_?.edit && !!userComment_["social media posts"]?.[0])
          ) && 
          <SocialMediaPostPreview
              url={userComment_?.new && !!templatesData.update.values.userComment["social media posts"]?.[0] ?
                templatesData.update.values.userComment["social media posts"]?.[0] :
                userComment_["social media posts"][0]
              }
              isWatermark={false}
          /> 
          }
        </div> :
          <></>
      }

    </div>
  </div>
  )
}
export const MemoUserComment = memo(UserComment)
MemoUserComment.displayName = 'MemoUserComment';

export const UserComments = ({ templatesData, isSocialMediaPreview, listingDocument, listingColor }) => {

    ///context
	const 
    { userCases } = useContext(userCasesContext),
    { sessionInfo } = useContext(sessionInfoContext),
    { userComments } = useContext(userCommentsContext)
    
  const 
    [isNewCommentAdded, setIsNewCommentAdded] = useState(false),
    [isPublicPost, setIsPublicPost] = useState(true)

  useEffect(() => {

    // const func = () => {
 
    // }
    // if (isNewCommentAdded) {
    //   setTimeout(
    //   func(),
    //   10000)
  //   // }
    if (isNewCommentAdded) {
      setIsNewCommentAdded(!isNewCommentAdded)
    }
  // function myGreeting() {

  // }

  }, [isNewCommentAdded])


  const 
    isOpenUserCase =  ///was: !!sessionInfo?.document && userCases.length > 0,
      !!sessionInfo?.document && 
      userCases.some(userCase => userCase?.document === sessionInfo.document), ///note: to ensure new userCase is loaded and setstate
    openUserCase = isOpenUserCase && userCases.find(userCase => userCase?.document === sessionInfo.document),
    isPublicCommentExistForOpenUserCase = userComments.some(comment => comment.listingDocument === listingDocument && comment.isPublic && comment.userCaseDocument === openUserCase?.document), // && openUserCase?.document === userComment?.userCaseDocument &&),
    openCasePublicComment = isPublicCommentExistForOpenUserCase ?
      userComments.find(comment => comment.listingDocument === listingDocument && comment.isPublic && comment.userCaseDocument === openUserCase?.document) : 
      [],
    // openUserCase = isOpenUserCase ? userCases.find(userCase => userCase.document === sessionInfo.document) : null,
    newComments = isOpenUserCase ?
        isPublicPost ?
          isPublicCommentExistForOpenUserCase ?
            [{...openCasePublicComment, new: true, edit: true }] :
            [{ new: true, isPublic: true, listingDocument, document: 1 }] 
          :
        [{ new: true, isPublic: false, listingDocument, document: 1 }] 
      // isPublicCommentExistForOpenUserCase ? 
      //     [{ new: true, isPublic: false, listingDocument, document: 1 }] :
      //     [{ new: true, isPublic: true, listingDocument, document: 2 }, { new: true, isPublic: false, listingDocument, document: 1}]
      : [],
    listingUserComments = [], /// comments.filter(userComment => userComment.listingDocument === listingDocument), //|| (userComment?.new && sessionInfo?.documents.includes(listingDocument)))
    userCommentsMap = isOpenUserCase ? [...newComments, ...listingUserComments] : [...listingUserComments],
    userCommentsDummyMap = [...Array.from({ length: userCommentsMap.length + 1 })]
    const gridTemplateColumnsStyle = (
        userCommentsDummyMap
          .map((userComment, index) => {
            const 
              userCommentWidth = "15vw", ///note: coordinate with scss $user-comment-width
              userCommentHeight = "20vh" ///note: coordinate with scss $user-comment-height
            if (index === 0) {
              return `calc(${userCommentWidth} + ${parseFloat(userCommentHeight) * (9/16)}vh)`
            }
            if (index === 2|| (index === 3 && isNewCommentAdded)) {
              return "0%"
            }
            else {
              return userCommentWidth
            }
          })).join(" ") 
    
    const prevListingUserComments = usePrevious(listingUserComments) 

    useEffect(() => {
      if (listingUserComments.length > prevListingUserComments?.length) {
        setIsNewCommentAdded(true)
      }
    }, [listingUserComments.length])

  const userCommentsMapSorted = 
  userCommentsMap
      .sort((a, b) => generics.sortDate(b, a, "created"))
      .sort((a, b, index) => {
        if (a["isPublic"] === true && a["userCaseDocument"] === openUserCase.document) return -1;
        if (b["isPublic"] === true && b["userCaseDocument"] === openUserCase.document) return 1;
        return a - b;
      })

  return (
    <div className={"user-comments-container-wrapper"}
      // style={{borderRight: `1px solid ${listingColor}`}}
    >
      <div className={"user-comments-container"}>
      {/* <div className={"user-comments-container-scroll"}> */}
        <div className={`user-comments`}
          style={{transition: !isNewCommentAdded ? "ease-in-out 1s" : "0s", gridTemplateColumns: `${"1fr"}`}} ///`${gridTemplateColumnsStyle}`
        >
          {userCommentsMapSorted
            .map((userComment) => {
              if (userComment === "dummy") { return <div/> }
              return (
                <UserComment 
                  templatesData={templatesData}
                  isSocialMediaPreview={isSocialMediaPreview}
                  key={`${userComment.document}`} 
                  listingDocument={listingDocument} 
                  listingColor={listingColor} 
                  userComment={userComment}
                  isPublicPost={isPublicPost} 
                  setIsPublicPost={setIsPublicPost}
                />
              )
            }
            )}
          </div>
      {/* </div> */}
      </div>
    </div>
  )
}

