//solve: more uploads in a row
import './new-artwork.css';
import './input-group.css';
import './specialities.css';
import React, { useState, useEffect, useContext, createContext, useMemo, useRef, useCallback, memo, createRef} from 'react';
import ItemForm, { templatesNames, templatesSetup } from './ItemForm';
import { RecogniseText } from './RecogniseText';
import * as NewArtwork_w from './NewArtwork_w';
import { initialStateOptions } from './StateOptions';
import * as generics from './Generics';
import produce from "immer";
import Page from './Page';
import { DropDown } from './DropDown';
// import { templatesDataContext } from './DataProvider';

///context
export const FileOcrContext = createContext([[],() => {}])
export const OcrTextContext = createContext([,() => {}])

export const uploadMedia = async({ mediaData, activeTemplate }) => {
  let mediaArray = []
  await Promise.all(mediaData.map(async(item, index) => {
    const { mediaType, url, mediaText, file, nameId } = item
    let mediaUrl 
    if (mediaType === "image" && generics.isFile(file)) { 
      const metadata = await NewArtwork_w.prepareImage(file) //prepare img
      mediaUrl = await NewArtwork_w.writeImage(file, metadata, activeTemplate) //write img
    } else { //mediaType = "video" or preveously uploaded mediatype = "image" with url
      mediaUrl = url
    }
    let mediaObj = {
      id: index,
      nameId: nameId, ///note: used to establish getfilelistunique => alternative to using two parameters, namely file (new image) and url (video and image)
      file: "", ///note: not explicit used
      mediaType: mediaType,
      url: mediaUrl,
      mediaText: mediaText
    };
    mediaArray.push(mediaObj)
    }
  ))
  return mediaArray //was: setMedia(mediaArray) 
}

const NewArtwork = ({ templatesData, setTemplatesData }) => {
  ///refs
  const inputOcrFileRef = useRef()
  const inputImageFileRef = useRef()

  ///context
  // const { templatesData, setTemplatesData } = useContext(templatesDataContext)

  ///const from context    
  const activeTemplate = templatesData.new.activeTemplate

  ///state
  const [fileOcr, setFileOcr] = useState(initialStateOptions[0]) //const [dataUri, setDataUri] = useState(null)
  const [ocrText, setOcrText] = useState(initialStateOptions[0])
  const ocrTextValue = useMemo(
    () => ({ ocrText, setOcrText }), 
    [ocrText]
  )

  ///HANDLE OCR TEXT
  ///interaction: selected image with text =>
  const handleOcrText = async(file) => {
    setFileOcr(file) //set file ocr => to 'recognisetext' useeffect
  }

  ///from 'recognisetext' 
  useEffect(() => {
    if (ocrText != null) {
      decipherOcrText() ///decipher ocr text
      }
  }, [ocrText]);

  ///useeffect at ocrtext =>
  const decipherOcrText = () => {
    const seperators = Object.keys(activeTemplate).map(seperator => `${seperator}:`) //seperator from template
    const a = generics.splitMulti(generics.removeLinebreaks(ocrText), seperators) //split by seperator => object
    const b = generics.trimArrayOfArrays(generics.splitStringsInArray(a, ":")) //split element into label and value => array of arrays
    const c = generics.objectFromArrayOfArrays(b) 
    //const dimensionsSplit = { dimensions: c["dimensions"].split(" ") }
    const name = "dimensions"
    const {[name]: removedProperty, ... d} = c //remove dimensions
    const e = { ...d, [name]: c["dimensions"].split(" ") } //add dimensions split
    const textElements = e
    setTemplatesData(textElements) ///risk: not tested ///was: setItemElements(textElements)
  }

  ///UPLOAD IMAGE - see itemform
  ///HANDLE TEXT CHANGE - see itemform
  ///HANDLE MULTIBUTTON CLICKED - see itemform and multistatebutton

  ///UPLOAD ITEM
  ///interaction: selected upload => upload media and set media
  const handleUploadMedia = async({ anyMedia, mediaData }) => {
    const 
      label = "media",
      startUpload = true,
      reset = false
    let mediaArray = []
    switch (true) {
      case anyMedia:
        mediaArray = await uploadMedia({ mediaData, activeTemplate })
        break;
      case !anyMedia:
        mediaArray = []
        break;
      default:
      break;
    };
    const value = mediaArray
    handleSetTemplatesData({ label, value, startUpload, reset })

      // anyMedia = !generics.arrayEmpty(mediaData)
      // const mediaArray = anyMedia ?
      //   await uploadMedia({ mediaData, activeTemplate }) :
      //   [],
      // // action = "new",
      // label = "media",
      // value = mediaArray
     // handleSetTemplatesData({ label, value, startUpload })
      //tempObj = { action: action, label: label, value: value, startUpload: startUpload } 
        //   anyMedia ?
        // { action: action, label: label, value: value, startUpload: startUpload } : ///optimise: make specification implicit via map
        // { startUpload: startUpload }
    // setTemplatesData( ///was: setMedia(mediaArray) 
    //   produce((draft) => { 
    //     draft.temp = tempObj
    //   })
    // );
  }

  const handleSetTemplatesData = ({ template, label, value, index, startUpload, reset }) => { ///optimise: is index used?
    const 
      template_ = generics.stringUndefined(template) ? activeTemplate : template,
      action = "new",
      tempObj = { template: template_, action: action, label: label, value: value, index: index, startUpload: startUpload, reset: reset } ///was/risk: document: document, but excluded because no doc right? ///optimise: make specification implicit via map

    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  };

  ///selected upload => media uploaded and set (incl startupload true) => upload item
  useEffect( async() => {
    // if (generics.arrayEmpty(media)) {
    //   return ///if media change due to reset abort. optimise/bug: if removed, circuluar call. also, can not upload text without media. fix
    // }
    if (!templatesData.new.startUpload) {
      return
    }
    // const mediaObj = {media: media}
    // let { image, ...item } = templatesData.new.values[activeTemplate] ///note: removes image from itemelements ///optimise: specify parameters to upload in variable and use here
    // item = { ... item, ... mediaObj }
    let { video, ...item } = templatesData.new.values[activeTemplate] ///note: removes video from item ///optimise: specify parameters to upload in variable and use here
    await NewArtwork_w.WriteArtwork(item, activeTemplate)
    resetComponent()
  }, [templatesData.new.startUpload]); ///was: media

  ///item uploaded => reset component
  const resetComponent = () => {
    const reset = true
    handleSetTemplatesData({ reset })
    resetState()
    resetInput()
  }

    const resetState = () => { ///optimse:map, perhaps use callback
      setFileOcr(initialStateOptions[0])
      setOcrText(initialStateOptions[0])
      ///setMedia([]) ///update
      ///setItemElements(templet)
    }

    const resetInput = () => {
      inputImageFileRef.current.value = null;
      inputOcrFileRef.current.value = null;
    }

    // const handleSetTemplateData = (templet) => ///note: delete - implemnted in handleSetTemplatesData instead
    //   setTemplatesData(
    //     produce((draft) => {
    //       draft.new.activeTemplate = templet
    //     })
    //   );

  ///text
  const header = `New ${activeTemplate}`
  const pageIntro = "Enter information or use "
  const ocr = "image to text converstion"

  return ( ///question: ok set to set value without memo and stuff?
    <FileOcrContext.Provider value={fileOcr}>
    <OcrTextContext.Provider value={ocrTextValue}> 
    <Page>
    <div>
      <h1 className="page-header">
        {/* {header} */}
      </h1>        
        <DropDown
            height={"2em"}
          className={`font-size-3`}
          options={templatesNames}
          passedValue={templatesData.new.activeTemplate}
          parentCallback={({ template }) => {
            const reset = false
            handleSetTemplatesData({ template, reset })
          }}
        >
        </DropDown>
      <div className="page-intro">
        <span>
            {pageIntro}
          <label 
            className="ocr input input-radius input-padding-3 uppercase input-border-default input-shadow"
            ref={inputOcrFileRef}
            onChange={(e) => handleOcrText(e.target.files[0] || null)}>
                {ocr}
                <input type="file" hidden/>
          </label>
        </span>
      </div>
      <ItemForm
        templatesData={templatesData} setTemplatesData={setTemplatesData}
        inputImageFileRef={inputImageFileRef}
        parentCallback={() => {
          const 
            // startUpload = true,
            // reset = false
            anyMedia =  
              generics.keyExist(templatesData.new.values[activeTemplate], "media") && ///check if label media is present. optimise: maybe check templates instead?
              !generics.arrayEmpty(templatesData.new.values[activeTemplate]["media"]),
            mediaData = anyMedia ? 
                templatesData.new.values[activeTemplate]["media"] :
                []
          handleUploadMedia({ anyMedia, mediaData }) 
            // was: anyMedia ?
            // handleUploadMedia({ mediaData, startUpload }) :
            // handleSetTemplatesData({ startUpload, reset })
         // console.log("anyMedia", anyMedia)
        }}
      />
     {/* <input type="file" ref={inputImageFileRef} onChange={(e) => handleUploadImage(e.target.files[0] || null)} /> */}
        <RecogniseText/>
      <div className="item-ocr-text">
        {/* <input type="text" className="item-text-std item-text-title">{ocrTextArray[0]}</input>
        <input type="text" className="item-text-std item-text-artist">{ocrTextArray[1]}</input> */}
      </div>
    </div>
    </Page>
    </OcrTextContext.Provider>
    </FileOcrContext.Provider>
  );
}

export default NewArtwork