import './app.css';
import './styling-standards.css';
import './more-media.css';
import { useState, useEffect, memo, createContext, useContext, useMemo, useCallback } from 'react';
import Video from './Video';
import { AspectRatio, Play, ZeroToFullHeight } from './ComponentStandards';
import * as generics from './Generics';
import { refineContext } from './App';

export const mediaIdContext = createContext([,() => {}])
export const videoPlayContext = createContext([,() => {}])

function MoreMedia({ imageUrl, document_m }) {

  // const { refine, setRefine } = useContext(refineContext);
  const [mediaId_a, setMediaId_a] = useState(null);;
  const mediaId_aValue = useMemo(
    () => ({ mediaId_a, setMediaId_a }), 
    [mediaId_a]
  );
  const [videoPlay, setVideoPlay] = useState(false)
  const videoPlayValue = useMemo(
    () => ({ videoPlay, setVideoPlay }), 
    [videoPlay]
  );
  
  const media = [
    {
      url: "https://www.youtube.com/embed/Msx1CXFezRU",
      type: "video",
      category: "Making of",
      mediaId: 1,
    },
    {
      url: "https://www.youtube.com/embed/hqEgBmccwCM",
      type: "video",
      category: "Making of",
      mediaId: 2,
    },
    {
      url: "https://www.iucn.org/sites/dev/files/content/images/2020/shutterstock_1458128810.jpg",
      type: "image",
      category: "Making of",
      mediaId: 3,
    }, 
    {
      url: "https://www.iucn.org/sites/dev/files/content/images/2020/shutterstock_1458128810.jpg",
      type: "image",
      category: "Making of",
      mediaId: 4,
    }, 
    {
      url: "https://www.iucn.org/sites/dev/files/content/images/2020/shutterstock_1458128810.jpg",
      type: "image",
      category: "Making of",
      mediaId: 5,
    }, 
    {
      url: "https://www.iucn.org/sites/dev/files/content/images/2020/shutterstock_1458128810.jpg",
      type: "image",
      category: "Making of",
      mediaId: 6,
    }, 
  ]

  const VideoGroup = ({ url, mediaId }) => {

    const video_i = generics.stringsAreIdentical(mediaId, mediaId_a) ///true when video entered
    const hideImage = video_i && videoPlay ///hide image when entered and video playing
    const hideText = video_i ///hide text when entered

    return (
    <>
      <div className={`more-media-image-overlay-wrapper ${hideImage ? "hide fadeout-05" : "show fadeins-05"}`}
      >
        <span className={`more-media-image-overlay-wrapper-text ${hideText ? "hide fadeout-05" : "show fadeins-05"}`}
        >THE PROCESS</span>
        <Play
          mediaId={mediaId}
          backgroundColor={"transparent"} 
          playColor={"white"}
          pointerEvents={video_i ? "none" : "all"} ///on entered => disable (desktop). else enable playbutton (mobile)
        ></Play>
        <img
          className={"more-media-image-overlay"}
          src={imageUrl}
        ></img>
      </div>
      <Video
        videoUrl={url}
        mediaId={mediaId}
      >
      </Video>
    </>
    )
  }

  const Image = ({ url }) =>
    <img
      className={"more-media-image"}
      src={url}
    ></img>

  return (
    <mediaIdContext.Provider value={mediaId_aValue}>
    <videoPlayContext.Provider value={videoPlayValue}>
          <ZeroToFullHeight
            classNameOuter={"more-media-wrapper"}
            classNameInner={"more-media flex-centered"}
            animateForwards={true}
          >
              {media.map((media, index) => {
                const { url, type, mediaId } = media;
                return (
                  <div 
                    key={mediaId}
                    className={`flex-row-limit`}>
                    <AspectRatio
                      wrapperClassName={"more-media-media"}
                      aspectRatio={56.25}
                      marginTop={9}
                      marginLeft={16} 
                      marginFactor={2}
                      key={index}
                    >
                      {type === "video" ?
                          <VideoGroup mediaId={mediaId} url={url}/>
                          : 
                          <Image url={url}/>
                          }
                    </AspectRatio>
                  </div>
                  )
                })}
          </ZeroToFullHeight>
    </videoPlayContext.Provider>
    </mediaIdContext.Provider>
  );
}

export default MoreMedia