//web version 9 (modular)
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage, ref } from "firebase/storage";
//import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig",
  authDomain: "simple-easy-gallery.firebaseapp.com",
  projectId: "simple-easy-gallery",
  storageBucket: "simple-easy-gallery.appspot.com",
  messagingSenderId: "902650130669",
  appId: "1:902650130669:web:7aadd0c33725e008c45ce4",
  measurementId: "G-LETSRT30KY"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const db = getFirestore();
export const storage = getStorage(firebaseApp);
export const storageRef = ref(storage);
// export const functions = getFunctions(firebaseApp);
