import './app.css';
import './css-reset.css';
import './styling-standards.css';
import React, { Suspense, useState, useEffect, createContext, useMemo, useRef, useCallback, memo } from 'react';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useParams, useLocation, useNavigate} from 'react-router-dom'; //v6
import Home from './Home';
import Login from './Login';
import { Gallery } from './Gallery';
import { GalleryControls } from './GalleryControls';
import { MainPillars } from './MainPillars';
import { UrlInfoProvider, UseNavigate } from './UrlInfoProvider';
import NewArtwork from './NewArtwork';
import { ScrollToTop } from './Scroll'; 
import { DataProvider } from './DataProvider';
import { ItemsProvider } from './ItemsProvider';
import { Modal } from './Modal';
import { SessionInfoProvider } from './SessionInfoProvider';
import { SignInWithEmailLinkComponent } from './SignInWithEmailLinkComponent';
import { ItemsRefinedComponent } from './ItemsRefinedComponent';
import { UserCasesProvider } from './UserCasesProvider';
import { UserCommentsProvider } from './UserCommentsProvider';
import { UserUidProvider } from './UserUidProvider';
import { MainCategories } from './MainCategories';
import { ThemeProvider } from './ThemeProvider';
import { MainSelector } from './MainSelector';
import { GalleryHeadline, Sticky } from './ComponentStandards';
import { SelectorProvider, Selector } from './SelectorProvider';
import { UserCasesComponent, UserCasesComponents }  from './UserCases';
import { MainMap }  from './MainMap';
import { FilterBox }  from './FilterBox';
import { usePrevious} from './StateOptions';
import { StickyMenu} from './StickyMenu';
import Header from './Header';

// import { Rivers }  from './MainCategories';



const FocusDestinationWrapper = ({ children }) => {
 
  ///const
  const [focusItemDocument, setFocusItemDocument] = useState({ document: "", isMapFocus: false, isItemFocus: false })
  return (
    <>{children(focusItemDocument, setFocusItemDocument)}</>
  )
}

///context
export const scrollToContext = createContext([,() => {}])

export default function App() {

  // const [scrollTo, setScrollTo] = useState({ galleryElementScrollBoxPair: {boxId_0: {}, boxId_1: {}}, wasRef: {ref: {}, component: "itemform", index: 10, anchorOffset: "", component: ""} }) ///optimise: rename to moveto
  // const scrollToValue = useMemo(
  //   () => ({ scrollTo, setScrollTo }), 
  //   [scrollTo]
  // );

  return ( 
    // <Suspense fallback="loading">   /// i18n translations might still be loaded by the http backend. use react's Suspense 
    <ThemeProvider>
      {(theme, setTheme) => (  
    <UserUidProvider>
    {(userUid, setUserUid, handleSetUserUid) => (  
    <DataProvider>
      {(templatesData, setTemplatesData, handleSetTemplatesData) => (  
    <ItemsProvider 
      templatesData={templatesData}>
      {(itemsDownloadStatus, items) => (  
    // <scrollToContext.Provider value={scrollToValue}>
    <UserCasesProvider> 
      {(userCases, setUserCases, handleSetUserCases) => (
    <SessionInfoProvider>
    {(sessionInfo, setSessionInfo, handleSetSessionInfo) => (
    <UserCommentsProvider>
      {(userComments, setUserComments, handleSetUserComments) => (
    <FocusDestinationWrapper>
    {(focusItemDocument, setFocusItemDocument) => (
    <ItemsRefinedComponent
      templatesData={templatesData}
      itemsDownloadStatus={itemsDownloadStatus}
      focusItemDocument={focusItemDocument}
    >
      {(itemsRefined, setItemsRefined, handleSetItemsRefined, refine, prevRefine, setRefine, handleSetRefine) => (
    <Modal ///note: preveisoly modal was not used as wrapper, thus it implementation design is impacted by this legacy
      templatesData={templatesData}
      userUid={userUid}
    >
      {(show, setShow, handleSetShow) => (
      <UrlInfoProvider> 
        {(urlInfo, setUrlInfo, handleSetUrlInfo) => ( ///note: at below div impl of theme-${theme.theme} is not working ...!?
        <div className={`App ${!!sessionInfo.document ? "session--true" : "session--false"}`}> 
        <ScrollToTop/>
        <UseNavigate/>
          <Routes>
            <Route 
              path="/"
              element={
                <>
                <Home
                  handleSetRefineCallback={handleSetRefine}
                >
                <SelectorProvider>
                {(selectorId, setSelectorId) => (
                  ///<div className={`selector-container selector-id-${selectorId}`}>
                    <> 
                    {/* <Sticky>
                      {(isSticky) => ( */}
                        <div className={`main-wrapper`}>
                        	<Header />
                          <div className={"main-container"}>
                            <MainSelector
                              selectorId={selectorId}
                              setSelectorId={setSelectorId}
                            />
                            <MainCategories 
                              templatesData={templatesData} setTemplatesData={setTemplatesData}
                              itemsDownloadStatus={itemsDownloadStatus}
                            />               
                            <FilterBox
                              refine={refine} 
                              prevRefine={prevRefine}
                              setRefine={setRefine}
                              theme={theme}
                              items={items}
                              itemsRefined={itemsRefined}
                              templatesData={templatesData}
                              setShow={setShow} 
                            />
                            <GalleryControls
                              itemsRefined={itemsRefined}
                              setTheme={setTheme}
                              templatesData={templatesData}
                              setRefine={setRefine}
                            /> 
                          </div>
                        </div>
                     {/* )} 
                    </Sticky> */}
                    <Selector
                          id={0}
                          selectorId={selectorId}
                    >
              
                      <Gallery
                        templatesData={templatesData}
                        setTemplatesData={setTemplatesData}
                      />
                    </Selector>
                    <Selector
                          id={1}
                          selectorId={selectorId}
                    >
                      <UserCasesComponents
                        isMapUserCases={true} ///temp: isTrueDlStatusCategories 
                        theme={theme}
                        templatesData={templatesData} setTemplatesData={setTemplatesData}
                        setFocusItemDocument={setFocusItemDocument}
                      />
                    </Selector>

                      <MainMap
                        isShowMap={sessionInfo?.userCases?.length > 0 || itemsRefined?.length > 0 || selectorId === 2} ///note: show map if usercase selected or itemsrefined
                        itemsDownloadStatus={itemsDownloadStatus}
                        focusItemDocument={focusItemDocument}
                        setFocusItemDocument={setFocusItemDocument}
                      />
                    <Selector
                          id={4}
                          selectorId={selectorId}
                    >
                      <UserCasesComponent
                        type={sessionInfo.type}
                        isMapUserCases={true}
                        templatesData={templatesData} setTemplatesData={setTemplatesData}
                        setFocusItemDocument={setFocusItemDocument}
                        isEdit={true}
                      />
                    </Selector>
                    {/* </Selector> */}
                    </>
                    // </div>
                )}
                </SelectorProvider>
                </Home>
                </>
              }>  
              {/* <Route //index route ///question: keep or?
                      index
              /> */}
              <Route path="gallery/:artworkId" element={<></>} />  
            </Route>
            <Route path="/__/auth/action" element={<SignInWithEmailLinkComponent/>} />

              {true &&
              <Route path="new_destination" element={
                <NewArtwork 
                  templatesData={templatesData} setTemplatesData={setTemplatesData}
                />
              } />
              }
              <Route path="l" element={<Login />} />
          </Routes>
          </div>  
      )}
    </UrlInfoProvider>
      )}
    </Modal>
      )}
    </ItemsRefinedComponent>
      )}              
    </FocusDestinationWrapper>
      )}
    </UserCommentsProvider>
      )}
    </SessionInfoProvider>
    // </scrollToContext.Provider>
      )}
    </UserCasesProvider>
      )}
    </ItemsProvider> 
      )}
    </DataProvider> 
      )}
    </UserUidProvider>
      )}
    </ThemeProvider>
    // </Suspense>
  );
}
