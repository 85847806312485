import React, { useState, useEffect, useContext, useCallback, createContext, useMemo, useRef } from 'react';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useParams, useLocation, useNavigate} from 'react-router-dom'; //v6
import { refineContext } from './ItemsRefinedComponent';
import produce from "immer";
import * as generics from './Generics';
import { usePrevious} from './StateOptions';

export const urlInfoContext = createContext([,() => {}])

export const UrlInfoProvider = ({ children }) => {

  const [urlInfo, setUrlInfo] = useState({location: {pathname: "", state: {document: ""}}, params: {}}) //, directload: {artwork: false, documentParms: ""} })
  const urlInfoValue = useMemo(
    () => ({ urlInfo, setUrlInfo }), 
    [urlInfo]
  );

  let location = useLocation()
	let params = useParams() ///bug: params is always empty, why??
  //const seperatorParams = "-"
  const prevLocation = usePrevious(urlInfo.location.pathname) 
  useEffect(() => {
    if (location.pathname === prevLocation) { ///abort if pathname no change
      return
    }
    // getUrlInfo()
    // const artworkSelected = generics.stringIncludes(location.pathname, "/gallery/")
    // const documentParams = artworkSelected ? location.state.document : "" // artworkSelected ? location.state.document : "" ///params.artworkId.split(seperatorParams) : ""
    setUrlInfo(
      produce((draft) => {
        const urlInfo = draft
        urlInfo.location = location
        urlInfo.params = params
        // urlInfo.directload.artwork = directLoadArtwork
        // urlInfo.directload.documentParms = documentParams
      })
    );
  }, [location]);

  const handleSetUrlInfo = ({}) => {
    console.log(handleSetUrlInfo)
  }
	
  return (
    <urlInfoContext.Provider value={urlInfoValue}>
       {children(urlInfo, setUrlInfo, handleSetUrlInfo)}
    </urlInfoContext.Provider>
  );;
}

export function UseNavigate() {

  const { urlInfo, setUrlInfo } = useContext(urlInfoContext);
  const { refine } = useContext(refineContext);

  let navigate = useNavigate();

  // useEffect(() => { ///temp disabled due to error on load ...
  //   if (refine?.documentMore.document === "" && !refine?.documentMore.directLoad) {
  //     navigate(`/${""}`)
  //   }
  // }, [refine.documentMore.document]);
	
  return (
    <></>
  );
}