import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import * as generics from './Generics';
import produce from "immer";
import { templatesType, templates, templatesNames, handleMediaAddition, templatesValue } from './ItemForm';

// export const templatesDataContext = createContext([,() => {}])

export const DataProvider = ({ children }) => {

  const initialStateTemplatesData = {
    new: { values: templatesValue, activeTemplate: [templatesNames[0]], startUpload: false },
    update: { values: templatesValue, activeTemplate: "", label: "", document: "", startUpload: false }
   } ///optimise/risk: activeTemplate cant be "" (reset to ""), but at reset it will now be potentially wrong (ie. activeTemplate[0]). consider to solve. it should not be a problem, because before read for a purpose its set again, correctly ... right?
  

  const [templatesData, setTemplatesData] = useState({ 
    temp: {}, ///note: temp is implemented to allow component to initiate set templatesdata locally (shorthand), while the setstate of new and update is done centrally (here) based on temp changes (detected via useeffect)
    new: initialStateTemplatesData.new,
    update: initialStateTemplatesData.update, //{ values: templatesValue, activeTemplate: "", label: "", document: "", startUpload: false }, ///note: it seems better to pass along template, than set and read activetemplate here... ///note: this centralised utilisiation of activetemplate (or renamed to template), document and label in update => only able to handle a single update at a time fx media OR textualarray via modal, i.e. update of these element must be finalised or aborted before initiation of new update 
    show: { activeTemplates: [templatesNames[0]] } 
  }) 
  // const templatesDataValue = useMemo(
  //   () => ({ templatesData, setTemplatesData }), 
  //   [templatesData]
  // );

    ///change at templatesdata temp => handle settemplatesdata 
    useEffect(() => {
      const { document, action, label, value, index, startUpload, reset, template } = templatesData.temp

      if (generics.stringUndefined(reset)) { ///at load => no reset set => abort
        return
      }
      const 
        type = templatesType[template][label], ///optimise: pass from function instead?
        isColumns = 
          generics.keyExist(templates[template], label) && ///note: keyexits due to use of label mediatext not in template (at this nesting-level)
          generics.isArrayLengthAbove(templates[template][label].value, 0) 
      setTemplatesData( 
        produce((draft) => { 
          draft[action].activeTemplate = template  
          draft[action].startUpload = startUpload  
          draft[action].label = label 
          if (action === "update" && reset === false && templatesData.update.document !== document) { ///note: not set for action new as no document
            draft[action].document = document 
          }
            switch (true) {
              case reset === true:
                if (action === "update") {
                  draft[action] = initialStateTemplatesData[action] 
                } else {
                  draft[action].values[template] = initialStateTemplatesData[action].values[template] ///note: only reset activetemplate (if input in other template(s) it remains (no reset), i.e. work on another new item can continue
                  draft[action].startUpload = initialStateTemplatesData[action].startUpload
                }
                //const resetValue = templatesValue[activeTemplate]
                //draft["update"].values[activeTemplate] = resetValue
                //const resetValue = templatesValue[activeTemplate] 
                break;
              case generics.stringUndefined(value): ///if no value => abort (from here)
                break;
              case label === "media_is_video":
  
                const 
                  stateMedia = [],
                  parameter = "url",
                  mediaType = "video",
                  mediaArray = handleMediaAddition(stateMedia, value, parameter, mediaType)
                draft[action].values[template]["video"] = "";
                draft[action].values[template]["media"].push(mediaArray[0]) ///[0] to get obj in array
                break;
              case label === "mediaText":
                draft[action].values[template]["media"][index]["mediaText"] = value
                break;
              case type === "textualObjects":
                draft[action].values[template][label] = value
                // show.modal.modalChild === "multiOptions" ?
                //   draft[action].values[template][label].push(value) :
                //   draft[action].values[template][label] = value
                break;
              case isColumns:
                draft[action].values[template][label][index] = value
                break;
              case !isColumns:
                draft[action].values[template][label] = value
                break;
              default:
              break;
            };
        })
      );
    }, [templatesData.temp]);

  const handleSetTemplatesData = () => {
    console.log("handleSetTemplatesData")
  }

  const component = useCallback(() => 
    // <templatesDataContext.Provider value={templatesDataValue}> 
      <>
      { children(templatesData, setTemplatesData, handleSetTemplatesData) }
      </>
    // </templatesDataContext.Provider>
    ,[templatesData.new, templatesData.update, templatesData.show.activeTemplates]) /// note: dependency array excludes templatesData.temp to limit rendering (of children)

    return (
      <>{component()}</>
    )
}