import './app.css';
import './styling-standards.css';
import './component-standards.css';
import { useState, useEffect, useMemo, useContext, useRef, useLayoutEffect, memo, useCallback} from 'react';
import { mediaIdContext } from './MoreMedia';
import * as generics from './Generics';
import GirlPearl from './GirlPearl';
import { HomeLink } from './Links';
import * as icons from './Icons';

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export function Wrapper({ id, name, className, height, display, width, position, style, show, paddingAndMargin, overflow, animate, marginTop, marginBottom, margin, children }) {
  ///
  let classShow
  switch (true) {
    case generics.stringUndefined(show) && generics.stringUndefined(animate):
      classShow = "show"
      break;
    case show && animate:
      classShow = "show fadein-05"
      break;
    case show && !animate:
      classShow = "show"
      break;
    case !show && animate:
      classShow = "hide fadeout-01"
      break;
    case !show && !animate:
      classShow = "hide"
      break;
    default:
    break;
  };

  const dimSizeObj = {
    height: !!height ? height : "100%", 
    width: !!width ? width : "100%", 
    }
  const marginObj = {
    // marginTop: !generics.stringUndefined(marginTop) ? marginTop : "0%", 
    // marginBottom: !generics.stringUndefined(marginBottom) ? marginBottom : "0%",  
    } 
  const overflowObj = {overflow: overflow}
  const positionObj = {position: position}
  const displayObj = {display: display}
  const wrapperStyle = {...style, ...dimSizeObj, ...marginObj, ...overflowObj, ...positionObj, ...displayObj}

  return (
    <div 
      id={id}
      className={`
      ${name}-wrapper 
      wrapper
      ${paddingAndMargin}
      ${className}
      ${classShow}
      `}
      style={wrapperStyle}
    >
        {/* <div 
          className={`
          ${name}
          ${className}
          `}
          style={{style}}
        > */}
          { children }
      {/* </div> */}
    </div>
  );
}

export function AspectRatio( { height, children, wrapperClassName, wrapperMargin, aspectRatio, marginTop, marginLeft, marginFactor, scrollSnapAlign } ) {

  const styleWrapper = {
    paddingTop: `${aspectRatio}%`, /* 16:9 Aspect Ratio */
    marginBottom: wrapperMargin,
    scrollSnapAlign: scrollSnapAlign,
    //position: "relative",
    height: height, ///optimise: implement padding instead of height, probably via positon relative and ???. note: at horizontal = true, aspectratio = 0, groupsize = 1, height > 0% ensures image is visible
  }

  const styleWrapped = {
    marginTop: `calc(${marginTop}% / ${marginFactor})`, ///optimse: test use of padding instead of margin, and no transform
    marginLeft: `calc(${marginLeft}% / ${marginFactor})`,
    transform: `translateX(calc(${- marginLeft}% / ${marginFactor}))`,
  }

  return (
	  <div 
      className={`aspect-ratio-wrapper ${wrapperClassName}`}
      style={styleWrapper}
      >
      <div className={`
        aspect-ratio-wrapped
      `}
        style={styleWrapped}
      >
         { children }
        </div> 
      </div>
  );
}

export const SquareIcon = ( { className, icon, focus, width, border, overflow, display, children, parentCallback } ) => {
   return (
     <div 
      className={`${className} square-icon-wrapper-wrapper`}
      style={{width: width, overflow: overflow, display: display}}
     >
        <AspectRatio
          wrapperClassName={`square-icon-wrapper`} //{`${name} square-icon-wrapper`}
          aspectRatio={100}
          // marginTop={100}
          // marginLeft={100} 
          // marginFactor={1}
          >
            <div
              className={`
                square-icon
                ${border ? "input-border-default" : ""}
                ${focus ? "focus fadein-05" : ""}
                `}
            onClick={() => parentCallback()}
           >
            { icon }
            {/* <i
              className={icon}
              >
            </i> */}
            { children }
          </div>
        </AspectRatio>
      </div>
    )
}

export const SvgComponent = ( { width, icon, alt, children } ) => {

  const svgImg = 
    <img
      alt={alt} 
      src={icon}
    >
    </img>
  return (
    <div
      style={{position: "relative"}}
    >
      <SquareIcon
        className={`svg-container`}
        style={{position: "absolute"}}
        icon={svgImg}
        width={width}
      > { children }
      </SquareIcon>
    </div>
   )
}

export const ButtonWithLogo = ({ isOneColumn, disabled, href, buttonHeight, theme, iconScaleFactor, icon, text, parentCallback }) => {
    return (

    <Button
      width={"100%"}
      wrapperHeight={"auto"}
      wrapperWidth={"auto"}
      height={buttonHeight}
      show={true}
      classTypes={["classical", ""]}
      theme={theme}
      disabled={disabled}
      selectOff={disabled}
      wrapperDisplay={"grid"}
      parentCallback={(e) => parentCallback(e)}
    >
    <a 
      aria-label="Chat on Facebook Messenger" 
      href={href}
      target={"_blank"}
      style={{width: "100%", height: "100%", textDecoration: "none", pointerEvents: `${disabled ? "none" : ""}`}}
    >
      <GridColums
        gridTemplateColumns={`${isOneColumn ? "1fr" : "1fr 1fr"}`}
        gridTemplateRows={"1fr"}
        alignItems={"center"}
        justifyItems={"center"}
        columnGap={"0em"}
        wrapperStyle={{height: "100%", width: "100%"}}
        height={"100%"}
        width={"100%"}

      >
        <span>{text}</span>
        <AspectRatio
          aspectRatio={1}
        >
          <div
            className={"center-by-transform"}
            style={{position: "relative", height: `calc(100% * ${iconScaleFactor})`, width: "100%"}}
          >
          {icon}
          </div>
        </AspectRatio>

        {/* <SvgComponent
          alt="Chat on Facebook Messenger" 
          icon={<>{GlobeLogo}</>}
          width={iconWidth}
        >
        </SvgComponent> */}
        
      </GridColums>
      </a> 
    </Button>

    )
}

export function Button( { wrapperHeight, wrapperWidth, prefix, wrapperDisplay, overflowOff, selectOff, hoverOff, style, innerRef, show, wrapperClassName, wrapperStyle, position, positions, className, classTypes, paddingAndMargin, minHeight, minWidth, height, width, text, fontSize, theme, isSelected, suffix, message, disabled, children, parentCallback } ) { ///optimise: control of height of button and width

  ///state
  const [entered, setEntered] = useState(false)

  ///const
  const 
    name = "btn",
    classes = {
      1: { ///overall look and feel
        default: "btn-classical",
        classical: "btn-classical",
      },
      2: { ///additional styling
        default: "btn-all",
        all: "btn-mini",
        all: "btn-all",
        small: "btn-small",
        filter: "btn-filter",
        left: "btn-left",
        right: "btn-right",
        customborder: "btn-customborder ",
        min: "btn-min",
        subtle: "btn-subtle",
        subtleExtra: "btn-subtle-extra",
        subtleSuper: "btn-subtle-super",
        select: "btn-select",
        selectRight: "btn-select-right",
        selectLeft: "btn-select-left",
        link: "btn-link",
        linkSubtle: "btn-link-subtle",
        leftSubtle: "btn-left-subtle"
      }
    }

  const classes1 = generics.getClassesFromType(classes, 1, classTypes) ///optimise: combine classes1 and classes 2...
  const classes2 = generics.getClassesFromType(classes, 2, classTypes)

  const handleMouseMovement = (entered) => {
      setEntered(entered)
  }

  const highlight = (isSelected || entered) && !hoverOff

  const positionObj = Array.isArray(positions) ?
      generics.getPosition(positions) : 
      {}
  //const heightObj = {height: height}
  const wrapperStyleObj = {...wrapperStyle, ...positionObj}

  const btnText = 
    <span className={`${name}-text`}>
      {text}
    </span>

const isColor = generics.isValidColor(message)

const colorStyle = {
  background: `${message}`,
  display: "block",
  margin: "10px",
  width: "50%",
  height: "50%",
  borderRadius: "10%",
}

  return (
    <Wrapper
      name={name} ///optimise - use name in button
      className={`
        ${name}-wrapper
        ${wrapperClassName}
        ${show ? "show fadein-05" : "hide fadeout-05"}
      `}
      paddingAndMargin={paddingAndMargin}
      height={wrapperHeight}
      width={wrapperWidth}
      show={true}
      style={wrapperStyleObj}
      display={wrapperDisplay}
      position={Array.isArray(positions) ? "absolute" : position}
    >
      <div ///note: if classname is used => consider to imeplemt in classes
        className={` 
          ${name}
          ${className}
          ${classes1}
          ${classes2}
          ${`font-size-${fontSize}`}
          ${`theme-${theme}`}
        ${disabled ? 
          `btn-disabled` : 
          ``
        }
        ${hoverOff ? "hover-off" : "hover-on"}
        ${highlight? 
          `highlight` :
          "" }
        `}
        style={
          {...style, ...{
           minHeight: minHeight, 
           minWidth: minWidth, 
           height: height,
           width: width, 
           cursor: `${selectOff ? "" : "pointer"}`,
           overflow: overflowOff ? "hidden" : "visible",
           //display: "flex", ///note: to center children (text)
          }}}
        prefix={prefix}
        suffix={suffix}
        //message={message}
        onMouseEnter={() => !disabled && handleMouseMovement(true)}
        onMouseLeave={() => !disabled && handleMouseMovement(false)}
        onClick={(e) => !disabled && !selectOff && parentCallback(e)}
        ref={innerRef}
        >
          { children } { btnText } 

          <span ///optimise/risk: can overflow width, mitigate ...
          className={"btn-message"}
          style={isColor ? colorStyle : null}
          >
            {!isColor ? message : ""}
        </span>
      </div> 
    </Wrapper>
  );
}


export function PreLoader({ show }) {
  return (
      <div className={`loading-wrapper ${show ? "" : "hide fadeout-01"}`}>
        <div className="preloader">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
  );
}

export const Play = memo(( {mediaId, backgroundColor, playColor, pointerEvents} ) => {

const { mediaId_a, setMediaId_a } = useContext(mediaIdContext);

const backgroundStyle = {
  background: backgroundColor,
  pointerEvents: pointerEvents,
}

const playStyle = {
  color: playColor,
}

const handleClicked = (mediaId) => {
  setMediaId_a(mediaId)
}

  return (
    <div className={`play-container`}>
      <div 
        onClick={() => handleClicked(mediaId)}
        className={`play`}
        style={backgroundStyle}
      >
        <i 
          className="fa fa-play" 
          style={playStyle}
          aria-hidden="true"
        ></i>  
      </div>
    </div>
  );
})

export const FlexBox = ( { className, children } ) => {

  const style = {
    display: "flex",
    flexWrap: "wrap",
  }

   return (
     <div className={`flexbox-wrapper`}>
      <div className={`
        flexbox
        ${className}
      `}
        style={style}
      >
        { children }
        </div>
      </div>
    )
}

export const GridColums = ( { wrapperId, style, id, border, wrapperStyle, gridTemplateColumns, gridTemplateRows, justifyContent, hideScrollBars, justifyItems, alignItems, gridAutoFlow, scrollSnapAlignChildren, name, gap, rowGap, columnGap, height, width, overflow, scrollSnapType, paddingBottom, className, children } ) => {
  ///repeat(auto-fit, minmax(0, min(100%/3, max(100%/${columns})))) ///min and max defines number of elements in rows
  const style_ = {
    ...style,
    gridTemplateColumns: gridTemplateColumns,
    gridTemplateRows: gridTemplateRows,
    justifyContent: justifyContent,
    justifyItems: justifyItems,
    alignItems: alignItems,
    gap: gap,
    rowGap: rowGap,
    columnGap: columnGap,
    height: height,
    width: width,
    overflow: overflow,
    scrollSnapType: scrollSnapType,
    paddingBottom: paddingBottom,
    gridAutoFlow: gridAutoFlow,
    border: border
  }

  const heightObj = {height: `100%`} ///note/risk: removed due to the height of fixed and scrollable textelement, impact elsewhere? 
  const wrapperStyleObj = wrapperStyle ///{...wrapperStyle, ...heightObj}  ///optimise/bug/risk: needed?? removed due to height of mediaupload in galleryitem

  return (
     <div 
     id={wrapperId}
     className={`
     ${name}-wrapper
     ${className}
      grid-columns-wrapper
      ${hideScrollBars ? "scrollbar-hide" : ""}
    `}
      style={wrapperStyleObj}
     >
      <div className={`
        ${name}
        grid-columns
        scroll-snap-align-children-${scrollSnapAlignChildren}
      `}
        style={style_}
        id={id}
      >
      { children }
     </div>
     </div>
   )
}


export const ZeroToFullHeight = memo(( { height, classNameOuter, classNameInner, noShow, justShow, animateForwards, animateReverse, children } ) => {
  ///optimise: full-height is set via max-height. not ideal...
  ///note: noshow, justshow, animateforwards, animateeverse instead of simply show => to mitigate animation on re-render

  const durationHeightForwards = 0.8
  const durationHeightReverse = 0.3
  const durationOpacityForwards = 0.5
  const durationOpacityReverse = 0.3
  const delayOpacity = durationHeightForwards - (durationOpacityForwards / 2)

   const Component = useCallback(() => {
    return (
    <div 
    key={"a"} 
    className={`${classNameOuter} zero-to-full-height-outer
      ${noShow ? "zero-to-full-height-outer-noshow" : ""}
      ${justShow ? "zero-to-full-height-outer-justshow" : ""}
      ${animateForwards ? "zero-to-full-height-outer-animation-forwards" : ""}
      ${animateReverse ? "zero-to-full-height-outer-animation-reverse" : ""}
    `}
    style={{
      animationTimingFunction: "ease-in-out", ///note: animationame is heightanimation
      animationDuration: `${animateForwards ? durationHeightForwards : durationHeightReverse}s`,
      animationDirection: `${animateForwards ? "normal" : "reverse"}`,
      animationFillMode: "forwards",
    }}
  >
      <div 
        key={"b"} 
        className={`${classNameInner} zero-to-full-height-inner
        ${justShow ? "zero-to-full-height-inner-justshow" : ""}
        ${animateForwards ? "zero-to-full-height-inner-animation-forwards" : ""}
        ${animateReverse ? "zero-to-full-height-inner-animation-reverse" : ""}
        `}
        style={{
          animationTimingFunction: "ease-in-out", ///note: animationame is opacityanimation
          animationDuration: `${animateForwards ? durationOpacityForwards : durationOpacityReverse}s`,
          animationDirection: `${animateForwards ? "normal" : "reverse"}`,
          animationFillMode: "forwards",
          animationDelay: `${delayOpacity}s`,
          opacity: "0",
        }}
        >
        { children }
      </div>
    </div>
    )
  },[noShow, justShow, animateForwards, animateReverse])

  return (
   <Component/>
   )
})

///symbol
export const Symbol = ({ symbol, showControl, parentCallback }) => { ///optimse: combine x-symbol in one
  const show = generics.getValueFromKey(showControl, symbol)
  return (
      <div className={`symbol-wrapper`}>
        <div className={`
          symbol
          input input-square input-radius input-border-default input-padding-2
          ${show ? "show fadein-05 input-hover" : "fade fadeout-05"}
          `}
          onClick={() => show && parentCallback(symbol)}
          >
          <i className={`fas fa-${symbol}`}></i>  
        </div>
      </div>
  );
}

export const ItemCount = memo(( { itemsRefined, className } ) => {
  const itemCount = itemsRefined.length === 0 ? "" : `${itemsRefined.length}`
  return (    
    <div className={`item-count-wrapper ${className}`}>
      <div className={`item-count`}>
        <span className={`
          item-count-text 
          text-gradient-1
          input input-radius input-padding-2
        `}
          >{itemCount}
        </span>
      </div>
    </div>
  )
})

export const ContentMapped = ({ contentArray }) => {
  return (  
    <div className={`content-mapped-wrapper`}>
      <div className={`content-mapped`}>
        {contentArray.map((text, index) => 
          <span
            key={index}
            className={`content-mapped-content`}
          > {text}
          </span>
        )}
      </div>
    </div>
  )
}

export const NoMatch = ({ show, content }) => {
  const name = `no-match`
  return (  
    <Wrapper
      name={name}
      paddingAndMargin={`margin-standard-vertical`}
      show={show}
      display={show ? "block" : "none"}
      overflow={`hidden`}
      width={`100%`}
      height={`auto`}
    >
      {/* <div className={`
        no-match-wrapper
        
        `}> */}
        <div className={name}>
          <div className={`
            input-radius
            theme-color-1
          `}>
              <ContentMapped contentArray={content}/>
            </div>
          <div className={`no-match-graphics`}>
            <GirlPearl/>
          </div>
        </div>
    </Wrapper>
  )
}

export const Logo = ({ className, src }) => 
<div className={`logo-wrapper ${className}`}>
  <div className={"logo"}>
    <HomeLink>
      <img className={"logo-img"}
        src={src}
      ></img>
    </HomeLink>
  </div>
</div>

export const Switch = ({ className, language }) => 
<div className={`switch-wrapper ${className}`}>
  <div className={"switch"}>
    <span>{language}</span>
  </div>
</div>

export const ConditionalElementWrapper = ( { wrapIt, name, children } ) => 
wrapIt ? WithElement({ name, children }) : <>{ children }</>;

export const WithElement = ({ name, children }) => 
  <Element
    name={name} 
  > 
    { children }
  </Element>




///component
export const mapText = (textArray, display, delimiter, isBulletList, textAlign, fontWeight, color, textTransform, className) => { ///optimised: not used. also, include dynamic classes

  const content = Array.isArray(textArray) ?
    textArray.map((text, i) =>
      <span className={`map-tex ${className}`}
        key={i}
        style={{display: display, textAlign: textAlign, fontWeight: fontWeight, color: color, textTransform: textTransform}}
      >
        {isBulletList ? '\u25CF' : ""} {text} {delimiter}
      </span>
    ) 
    :  null
  return (
    <div>{content}</div>
  );
}

export const PlusButton = ({ value, show, color, isPlus, parentCallback }) => {
  const symbol = isPlus ? icons.plusIcon : icons.minusIcon
  return (
    //<div style={{position: "absolute", top: 0, width: "100%", height: "100%", zIndex: 0}}>
      <Button
        show={show}
        wrapperHeight={"100%"}
        wrapperWidth={"2em"}
        classTypes={["classical", "customborder"]}
        wrapperClassName={`plus-button-container`}
        className={`plus-button ${isPlus ? `${value}-background-hover` : ""} input-padding-2 ${isPlus ? "selected--false" : "selected--true"}`} /// ${show ? "show fadein-01" : "hide fadeout-05"}`}
        theme={"plus-button"}
        style={{bottom: "0.2em", left: "0.2em", height: "fit-content", width: "fit-content", background: isPlus ? "#fcf0d9" : color, border: !isPlus ? `1px solid #fcf0d9` : `1px solid ${"#ffdb98"}`}} ///or color
        fontSize={1}
        text={symbol}
        parentCallback={() => parentCallback({ })}
      />
    //</div>
  )
}

// import React, { memo, useState, useEffect, useRef } from 'react';

const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) =>
        setIsSticky(
          entry.boundingClientRect.bottom <= entry.intersectionRect.bottom &&
            entry.intersectionRatio < 1
        ),
      { threshold: 1 }
    );
    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return [isSticky, ref, setIsSticky];
};

export const Sticky = memo(({ className, offsetSticky, isFade, fadeSticky, children }) => {
  const [isSticky, ref] = useDetectSticky();

  useEffect(() => {
    if (!fadeSticky) return;
    const elements = Array.from(document.querySelectorAll(`.${className}.sticky--true`));

    if (elements.length > 0) {
      elements.forEach((element, i) => {
        if (i === 0) {
          element.classList.add('first-sticky--true');
        } else {
          element.classList.remove('first-sticky--true');
        }

        if (i === elements.length - 1) {
          element.classList.add('last-child--true');
        } else {
          element.classList.remove('last-child--true');
        }
      });
    }
  }, [isSticky, fadeSticky, className]);

  return (
    <>
      <div
        className={`sticky-c`}
        style={{
          top: !!offsetSticky ? -offsetSticky : '-10px',
          paddingTop: !!offsetSticky ? offsetSticky : '10px',
        }}
        ref={ref}
      >
        <div
          className={`sticky ${
            isFade ? 'select--false-blur' : ''
          } ${fadeSticky ? 'sticy-fade--true' : ''} ${className} ${
            isSticky ? 'sticky--true' : 'sticky--false'
          }`}
        >
          {children(isSticky)}
        </div>
      </div>
    </>
  );
});

// export const Sticky = memo(({ className, offsetSticky, isFade, fadeSticky, children }) => { ///note: old sticky before refrac
//   // https://github.com/yairEO/react-hooks/blob/master/hooks/useDetectSticky.js
//   /**
//    * detects when a (CSS) sticky element changes "sticky" state
//    * @param {object} ref optional react ref. if not provided, a new one will be used instead.
//   // */
//   // const [isStickyText, setIsStickyText] = useState("bb")
  
//   const useDetectSticky = (ref, observerSettings = {threshold: [1]}) => {
//     const [isSticky, setIsSticky] = useState(false)
    
//     const newRef = useRef()
//     ref ||= newRef;
    
//      // mount 
//     useEffect(()=>{
//       const cachedRef = ref.current,
//             observer = new IntersectionObserver(
//               ([e]) => 
//                 setIsSticky(
//                   e.boundingClientRect.bottom <= e.intersectionRect.bottom && ///note: condition to exclude at viewport border 
//                   e.intersectionRatio < 1
//                   ),
//                 {threshold: 1}
            
//             )
//       observer.observe(cachedRef)
      
//       // unmount
//       return () => {
//         observer.unobserve(cachedRef)
//       }
//     }, [])
  
//     return [isSticky, ref, setIsSticky];
//   }
  
//   const Header = ({ sticky=false, ...rest }) => {
//     const [isSticky, ref, setIsSticky] = useDetectSticky()
  
//     const elementRef = useRef(null);
  
//     useEffect(() => {
//       if (!fadeSticky) return
//       const elements = Array.from(document.querySelectorAll(`.${className}.sticky--true`));
//       //if (elements.length === 1 && i !== 0) return
//       if (elements.length > 0) {
//         elements.map((element, i) => { ///slice(0, -1)
//           elementRef.current = element
//           i === 0 ? 
//             elementRef.current.classList.add('first-sticky--true') : ///note: subtle impact on animation
//             elementRef.current.classList.remove('first-sticky--true');
//           i === elements.length - 1 ? 
//             elementRef.current.classList.add('last-child--true') :
//             elementRef.current.classList.remove('last-child--true')
//         })
//       }
  
//       // const innerText = elements.length > 0 ?
//       //   elements.pop().innerText : ""
//       //stickyCallback({ innerText: innerText })
  
//     }, [isSticky]);
  
//     return (
//       // <div className={`sticky-w
//       //  ${isFade ? "fade-out" : "fade-in"}
//       // `}>
//       <>
//         {/* <div className={`
//           sticky-background
//           ${isSticky ? " sticky--true" : ""}
//           ${isSticky && !isFade ? "fade-in" : "fade-out"}
//           `}>
//         </div> */}
//         <div 
//           className={`
//             sticky-c
//           `} 
//           style={{
//             top: !!offsetSticky ? -offsetSticky : "-10px", 
//             paddingTop: !!offsetSticky ? offsetSticky : "10px", 
//           }}
//           ref={ref} 
//           {...rest}
//         >
//             <div className={`sticky
//                     ${isFade ? "select--false-blur" : ""}
//                     ${fadeSticky ? "sticy-fade--true" : ""}
//                     ${className} ${isSticky ? "sticky--true" : "sticky--false"}
//             `}
//               >
//                 { children(isSticky) } 
//           </div>
//         </div>
//       {/* </div> */}
//       </>
//     )
//   }
//     return (
//       <>
//         <Header 
//           //stickyCallback={({ innerText }) => setIsStickyText(innerText)}
//         >
//             { children }
//         </Header>         
//         {/* <StaticHeader headerText={isStickyText}/> */}
//       </>
//     )
//   })
  

  // export const StaticHeader = ({ text }) => {
  //   return (
  //     <div ///   
  //     className={`
  //       static-header-c 
      
  //     `}>
  //       <div
  //         className={`
  //           static-header
  //       `}>
  //         {/* {text} */}
  //       </div>
  //     </div>
  //   )
  // }
  
  export const GalleryHeadline = ({ show, headline, subline }) => {
  
    //const headlineRef = useRef(null)
  
    return (
      <Sticky
        fadeSticky={false}
        //stickyCallback={() => console.log("bib")}
      >
        {(isSticky) => (
        <div className={
          `gallery-headline-c
          ${show ? "fade-in delay" : "fade-out"}
          `}
      >
          {/* <div className={`screen`}>
  
          </div> */}
          <span className={`
            gallery-headline
            ${isSticky ? "hide fade-out" : "fade-in" }
          `}>
            {headline}
          </span>
          <span className={`gallery-headline-subline`}>
            {subline}
          </span>
        </div>
        )}
      </Sticky>
    )
  }
  