import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import * as generics from './Generics';
import produce from "immer";
import { templatesNames } from './ItemForm';
import { usePrevious} from './StateOptions';
import * as Gallery_r from './Gallery_r';

//const itemsAPIContext = createContext([,() => {}])
//export const itemsDownloadStatusContext = createContext([,() => {}])
export const itemsContext = createContext([,() => {}])

export const ItemsProvider = ({ templatesData, children }) => {

  ///state
  const [itemsDownloadStatus, setItemsDownloadStatus] = useState({
    values: [], 
    download: {
      active: {
        templateNames: [],
        isFinalised: true, 
      },
      overall: {
        status: generics.mapArrayToObjectWithKeysAndSetValue(templatesNames, false)
      }
    }
  })
  const [items, setItems] = useState([])
  const itemsValue = useMemo(
    () => ({ items, setItems }), 
    [items]
  );

  ///GET DATA GALLERY
	///on load => useeffect =>
  const
    activeTemplates = templatesData.show.activeTemplates,
    activeTemplate = activeTemplates[activeTemplates.length - 1], ///templatesData.update.activeTemplate
    prevActiveTemplate = usePrevious(activeTemplate)
  // searchCategories = refine["category"] === "all" ? allSearchCategories : [refine["category"]]

  useEffect(() => {
    const 
      templateSelected = !generics.stringsAreIdentical(prevActiveTemplate, activeTemplate, false), ///template selectd (by user) => true
      activeDownloads = !generics.arrayEmpty(itemsDownloadStatus.download.active.templateNames) ///if array templatenames is not empty => true
    if (
      !templateSelected && ///note: only allow starting multiple (parallel) download, if true templateselected (by user), else => abort
      activeDownloads ///note: see above
      ){
        return
    }
    const templateName = templateSelected ?
      activeTemplate : ///note: use clicked template (i.e. activetemplate)
      generics.getKeyFromValue(itemsDownloadStatus.download.overall.status, false) ///note: or use first templatename with statusupload false (not yet uploaded)
    if (
      generics.stringInArray(itemsDownloadStatus.download.active.templateNames, templateName) || ///if download of template (data) already started => abort
      itemsDownloadStatus.download.overall.status[templateName] || ///if template already downloaded => abort
      generics.stringUndefined(templateName) || ///if no templatename left with (download) status false (i.e all is downloaded) => abort
      templateName === "chat" || templateName === "userCase" || templateName === "userComment"  ///exclude from download 
      // templateName !== "attractions"
      ) { 
      return 
    }
    const action = "start-download"
    handleSetDownload({ action, templateName })
  }, [activeTemplate, itemsDownloadStatus.download.active.templateNames]);

    const handleSetDownload = ({ action, templateName }) => {
      setItemsDownloadStatus( 
        produce((draft) => {
          switch (true) {
            case action === "start-download":
              draft.download.active.templateNames.push(templateName) 
              break;
            case action === "finalised-download":
              const index = draft => draft.download.active.templateNames.findIndex(templateName_ => templateName_ === templateName)
              if (index !== -1) draft.download.active.templateNames.splice(index, 1)
              draft.download.overall.status[templateName] = true
              break;
            default:
            break;
          };
        })
      )
    }

  useEffect(() => {
    const activeDownloads = !generics.arrayEmpty(itemsDownloadStatus.download.active.templateNames)
    if (!activeDownloads) { ///if no active downloads => abort
      return
    }
    const templateName = itemsDownloadStatus.download.active.templateNames[0]
    handleGetDataGallery({ templateName }) ///call to get gallery data
  }, [itemsDownloadStatus.download.active.templateNames]);

  ///on load => useeffect => handle set gallery data =>
  const handleGetDataGallery = async({ templateName }) => {
    let items_ = await Gallery_r.getDataGallery({ templateName }) ///get gallery data
    const items__ = [...items, ...items_]
    // let items_s = [...items].sort( (a, b) => ///sort 
    // 	parseFloat(`${b.sortId.seconds}.${b.sortId.nanoseconds}`) - parseFloat(`${a.sortId.seconds}.${a.sortId.nanoseconds}`)) //( (a, b) => b.created.seconds > a.created.seconds ? 1 : -1)
    setItems(items__) ///set gallery data
    const action = "finalised-download"
    handleSetDownload({ action, templateName})
  }

  const handleSetItems = () => {
    console.log("handleSetItems")
  }

  // const getComponent = 
  //  useCallback(() => ///note: limited dependency (without explicit prop) array prevents prior provider props to be updated correctly in children..
  //   <itemsContext.Provider value={itemsValue}> 
  //     {children(itemsDownloadStatus, items)}
  //   </itemsContext.Provider>
  // , [items, itemsDownloadStatus])

  // const componenet = getComponent()

  return (
      <itemsContext.Provider value={itemsValue}> 
        {children(itemsDownloadStatus, items)}
      </itemsContext.Provider>
      // <>{componenet}</>
  )
}

// export const ItemsProvider = ({ templatesData, children }) => {

//   const Provider = ({ children }) => { 
//     const [items, setItems] = useState([])
//       ///state
//     const [itemsDownloadStatus, setItemsDownloadStatus] = useState({
//       values: [], 
//       download: {
//         active: {
//           templateNames: [],
//           isFinalised: true, 
//         },
//         overall: {
//           status: generics.mapArrayToObjectWithKeysAndSetValue(templatesNames, false)
//         }
//       }
//     })
  
//     const handleSetItems = useCallback((items) => {
//       setItems(
//           produce((draft) => {
//             draft = draft.concat(items)
//           })
//         );
//     }, [])

//     const handleSetItemsDownloadStatus = useCallback(({ action, templateName }) => {
//       setItemsDownloadStatus( 
//         produce((draft) => {
//           switch (true) {
//             case action === "start-download":
//               draft.download.active.templateNames.push(templateName) 
//               break;
//             case action === "finalised-download":
//               const index = draft => draft.download.active.templateNames.findIndex(templateName_ => templateName_ === templateName)
//               if (index !== -1) draft.download.active.templateNames.splice(index, 1)
//               draft.download.overall.status[templateName] = true
//               break;
//             default:
//             break;
//           };
//         })
//       )
//     }, [])

//       return (
//           <itemsContext.Provider value={{ items }}>
//               <itemsAPIContext.Provider value={{ data: itemsDownloadStatus, set: {handleSetItems, handleSetItemsDownloadStatus} }}>
//                 { children }
//               </itemsAPIContext.Provider>
//           </itemsContext.Provider>
//       );
//   }

//   const useApi = () => useContext(itemsAPIContext);
//   const APIChild = ({ }) => {
 
//       const 
//         api = useApi(), ///note: setter function
//         handleSetItems = api.set.handleSetItems,
//         handleSetItemsDownloadStatus = api.set.handleSetItemsDownloadStatus,
//         itemsDownloadStatus = api.data

//       ///GET DATA GALLERY
//       ///on load => useeffect =>
//       const
//         activeTemplates = templatesData.show.activeTemplates,
//         activeTemplate = activeTemplates[activeTemplates.length - 1], ///templatesData.update.activeTemplate
//         prevActiveTemplate = usePrevious(activeTemplate)
//         // searchCategories = refine["category"] === "all" ? allSearchCategories : [refine["category"]]

//       useEffect(() => {
//         const 
//           templateSelected = !generics.stringsAreIdentical(prevActiveTemplate, activeTemplate, false), ///template selectd (by user) => true
//           activeDownloads = !generics.arrayEmpty(itemsDownloadStatus.download.active.templateNames) ///if array templatenames is not empty => true
//         if (
//           !templateSelected && ///note: only allow starting multiple (parallel) download, if true templateselected (by user), else => abort
//           activeDownloads ///note: see above
//           ){
//             return
//         }
//         const templateName = templateSelected ?
//           activeTemplate : ///note: use clicked template (i.e. activetemplate)
//           generics.getKeyFromValue(itemsDownloadStatus.download.overall.status, false) ///note: or use first templatename with statusupload false (not yet uploaded)
//         if (
//           generics.stringInArray(itemsDownloadStatus.download.active.templateNames, templateName) || ///if download of template (data) already started => abort
//           itemsDownloadStatus.download.overall.status[templateName] || ///if template already downloaded => abort
//           generics.stringUndefined(templateName) || ///if no templatename left with (download) status false (i.e all is downloaded) => abort
//           templateName === "chat" || templateName === "userCase"  ///exclude from download 
//           // templateName !== "attractions"
//           ) { 
//           return
//         }
//         const action = "start-download"
//         handleSetItemsDownloadStatus({ action, templateName })
//       }, [activeTemplate, itemsDownloadStatus.download.active.templateNames]);

//         // const handleSetDownload = ({ action, templateName }) => {
//         //   setItemsDownloadStatus( 
//         //     produce((draft) => {
//         //       switch (true) {
//         //         case action === "start-download":
//         //           draft.download.active.templateNames.push(templateName) 
//         //           break;
//         //         case action === "finalised-download":
//         //           const index = draft => draft.download.active.templateNames.findIndex(templateName_ => templateName_ === templateName)
//         //           if (index !== -1) draft.download.active.templateNames.splice(index, 1)
//         //           draft.download.overall.status[templateName] = true
//         //           break;
//         //         default:
//         //         break;
//         //       };
//         //     })
//         //   )
//         // }

//       useEffect(() => {
//         const activeDownloads = !generics.arrayEmpty(itemsDownloadStatus.download.active.templateNames)
//         if (!activeDownloads) { ///if no active downloads => abort
//           return
//         }
//         const templateName = itemsDownloadStatus.download.active.templateNames[0]
//         handleGetDataGallery({ templateName }) ///call to get gallery data
//       }, [itemsDownloadStatus.download.active.templateNames]);

//       ///on load => useeffect => handle set gallery data =>
//       const handleGetDataGallery = async({ templateName }) => {
//         let items_ = await Gallery_r.getDataGallery({ templateName }) ///get gallery data
//         // let items_s = [...items].sort( (a, b) => ///sort 
//         // 	parseFloat(`${b.sortId.seconds}.${b.sortId.nanoseconds}`) - parseFloat(`${a.sortId.seconds}.${a.sortId.nanoseconds}`)) //( (a, b) => b.created.seconds > a.created.seconds ? 1 : -1)
//         //setItems(items_) ///set gallery data was: items__ (const items__ = [...items, ...items_])
//         handleSetItems(items_)
//         const action = "finalised-download"
//         handleSetItemsDownloadStatus({ action, templateName})
//       }
//   }

//   return (
//       <Provider>
//           <APIChild/>
//               { children }
//       </Provider>
//   )
// }

