export const artistData = [
    {
      firstName: "Francisco",
      lastName: "Toledo",
      alias: "The Miestro 1",
      cv: "bla bla 1"
    },
    {
      firstName: "Francisco 2",
      lastName: "Toledo 2",
      alias: "The Miestro 2",
      cv: "bla bla 1"
    },
    {
      firstName: "Francisco 1",
      lastName: "Toledo 1",
      alias: "The Miestro 1",
      cv: "bla bla 1"
    }
  ];

  export function getArtist(firstName) {
    return artistData.find(
      artist => artist.firstName.toLowerCase() === firstName
    );
  }

  export function existArtist(firstName) {
    return artistData.some(
      artist => artist.firstName.toLowerCase() === firstName);

  }


