import './app.css';
import './styling-standards.css';
import './item-form.css';
import './specialities.css';
import { useState, useEffect, forwardRef, useMemo, memo, useCallback, useReducer, useRef, createRef, useContext} from 'react';
import ContentEditable from 'react-contenteditable'
import { MultiStateButton } from './MultiStateButton';
import { MultiOptionButton } from './MultiOptions';
import * as generics from './Generics';
import { AspectRatio, Button, GridColums } from './ComponentStandards';
import { PreviewImages } from './PreviewImages';
import produce from "immer";
import { DropZone } from './DropZone';
import { scrollToContext } from './App';
// import { templatesDataContext } from './DataProvider';
//import { FileOcrContext } from './NewArtwork';
// import { showContext } from './App';
//import mergeFileLists from "merge-file-lists";
//import e from 'cors';
import { pesoIcon, burstIcon, clockIcon, personWalkingIcon, lightbulb, proSign, locationPin } from './Icons';
//import { linkWithPhoneNumber } from 'firebase/auth';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import arrayMove from 'array-move'
import { trashIcon, handPointerIcon } from './Icons'
import { DropDown } from './DropDown';
import { UserPresent }from './UserUidProvider';
import { colorsLight } from './colors';
import { SocialMediaPost, SocialMediaPostPreview } from './SocialMediaPost';
import validUrl from 'valid-url';
import { showContext } from './Modal';


export { 
  templates,
  templatesNames,
  templatesType,
  templatesValue,
  templatesShortcut,
  templatesIsFixed,
  templatesMapping,
  templatesClassName,
  templatesVertical,
  templatesFilterableAttr,
  templatesSetup,
  contentRestrictions,
  contentEditableKeyDown,
  contentEditablePaste,
  weekdays,
  hoursTypes,
  hoursOptionsOpening, 
  hoursOptionsClosing
};

const templateParam = [
  "type", ///string
  "value", ///incl. default value and input value(s)
  "filter", ///boolean
  "optional", ///boolean (optional or mandatory)
  "mapping", ///0: "view 0 default, 1: view 0 more, 2: view 1
  "className", ///like font-size-x and no-translate
  "vertical", ///vertical or horizontal arrangement of text (relevant for mapping of arrays)
  "isfixed", ///at more-view fixed (at top) or scrollable (below)
  "shortcut", ///at more-view if scrollable shortcut true => included in scrolltomenu. elements not mapped 
  "placeholder", ///string or array
  "options", ///array - applies to: multistate, multioptions
  "limit" ///integer - applies to: multioptions
]

// const templateParam2 = [
//   "value"
// ]

export const mainColors = {
  live: "white", 
  events: "white",
  attractions: "#07d4ae",
  experiences: "#a52eff",
  stays: "#03bbe6", //038cc5
  consume: "#8abb3b", //f8e32d
  tours: "#fd5d90",
  retreats: "#ff9333", //f87621,
  ["ngo's"]: "white",
  artists: "white",
}

const templatesSetup = { ///note: mapwidgets unique for widgets
  "live": {
    isGallery: false,  
    mapWidgetNames: ["live"],
    color: mainColors["live"],
    headerContent: "Whats happening right now!"
  },
  "events": {
    isGallery: true,
    mapWidgetNames: ["special events"],
    color: mainColors["events"],
    headerContent: "One off and occasional events"
  },
  // "weekly events": {
  //   isGallery: false,
  //   mapWidgetNames: ["monday", "tuesday"],
  //   color: "#FFD9B7", //"#FDC453",
  //   headerContent: "Those cyclic events"
  // },
  "attractions": {
    isGallery: true,
    color: mainColors["attractions"], //"#D2E9E9", ///"#5ab6ca",
    headerContent: "Where to go - beaches, natural pools, view points and ... hidden gems?"
  },
  "tours": {
    isGallery: true,
    color: mainColors["tours"], ///"repeating-conic-gradient( at 100% 100%, #9ED2BE 0 calc(2.5*25%/4), #DFDD6C 0 calc(4*25%/4) )",
    headerContent: "tour the island"
  },
  "stays": {
    isGallery: true,
    color: mainColors["stays"], ///
    headerContent: "Get a roof over your head, and what about a swimming pool or ...?"
  },
  "consume": {
    isGallery: true,
    color: mainColors["consume"],
    headerContent: "Eat, drink, love"
  },
  "experiences": {
    isGallery: true,
    color: mainColors["experiences"], ///"#F886A8",
    headerContent: "What to do - be part of a yoga class, get a massage, join a organised trip or ...?"
  },
  "retreats": {
    isGallery: true,
    color: mainColors["retreats"], ///"repeating-conic-gradient( at 100% 100%, #9ED2BE 0 calc(25%/4),    #DBCDF0 0 calc(2*25%/4),    #EFB495 0 calc(3*25%/4),    #DFDD6C 0 calc(4*25%/4) )",
    headerContent: "ultimative experiences"
  },
  "ngo's": {
    isGallery: true,
    color: mainColors["ngo's"], //"#EFB495", ///
    headerContent: "ultimative experiences"
  },
  "artists": {
    isGallery: true,
    color: mainColors["artists"], //"#EFB495", ///
    headerContent: "ultimative experiences"
  },
  "userCase": { ///optimise: remove ...
    isGallery: true,
    color: mainColors["mainColors"], //"#DFDD6C",
    headerContent: ""
  },
  "chat": { ///optimise: remove ...
    isGallery: true,
    color: mainColors["mainColors"], //"#DFDD6C",
    headerContent: ""
  },
  "userComment": { ///optimise: remove ...
    isGallery: true,
    color: mainColors["mainColors"], //"#DFDD6C",
    headerContent: ""
  },
}

const templatesContentSetup = { ///risk: attributes not in data-set will result in error in itemtext - fix!
  "live": {
    dfe:        ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "events": {
    dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  // "weekly events": {
  //   dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
  //   tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
  //   a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
  //   b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
  //   publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
  //   media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
  //   video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  // },
  "attractions": {
    social:               ["textualObjects", [{"instagram": ""}, {"facebook": ""}], false, false, [true, true, false], "font-size-1", true, true, [false, null], "", [], 1],
    title:                ["textual", "", true, true, [true, true, true], "font-size-3", false, true, [false, null]],
    "opening hours":      ["openingHours", "", false, true, [true, true, true], "font-size-1", false, true, [false, null]],
    highlights:           ["textualArray", [], false, false, [false, true, false], "font-size-1", true, false, [true, "highlights"]], 
    "what to do":         ["textualObjects", [], false, false, [false, true, false], "font-size-1", true, false, [true, "what to do"], "", ["swimming", "snorkeling", "cliff jumping", "consumption", "touring", "exploring"], 1],
    "protip":             ["textualObjects", [], false, false, [false, true, false], "font-size-1", true, false, [true, "PRO"], "", ["swimming", "snorkeling", "cliff jumping", "consumption", "touring", "exploring"], 1],
    info:                 ["textual", "", false, false, [false, true, false], "font-size-1", false, false,[false, null]],
     "time to visit":      ["textual", "", false, false, [false, true, false], "font-size-1", false, false, [false, null], "MEX"],
     "how to get there":   ["textual", "", false, false, [false, true, false], "font-size-1", false, false,[true, personWalkingIcon]],
     fees:               ["textualObjects", [{"parking": ""}, {"entry": ""}, {"environmental": ""}, {"other": ""}], false, false, [false, true, false], "font-size-1", false, false, [true, "fees"], "", [], 1],
    practical:            ["textual", "", false, false, [false, true, false], "font-size-1", false, false, [true, lightbulb]],
    address:              ["textual", "", false, false, [false, true, false], "font-size-1", false, false, [true, lightbulb]],
    contact:              ["textualObjects", [{"text | smart": ""}, {"text | globe": ""}, {"call | smart": ""}, {"call | globe": ""}, {"whatsapp": "+63"},  {"messenger": ""}, {"instagram": ""}, {"email": ""}], false, false, [false, true, false], "font-size-1 full-width", false, false, [true, "contact"], "", [], 1],
    location:             ["multiOptions", [], true, true, [false, true, true], "font-size-1", false, false, [false, null], "", ["catagnan", "pilar"], 1],
    geoLocation:          ["textualObjects", [{"lat": ""}, {"lng": ""}], false, false, [false, false, false], "font-size-1", true, false, [true, "null"], "", [], 1],
    phone:                ["textual", "", false, false, [false, true, false], "font-size-1", false, false, [false, null]],
    webpage:              ["textual", "", false, false, [false, true, false], "font-size-1", false, false, [false, null]],
    "open for business":  ["multiState", "true", false, true, [false, true, false], false, "font-size-1", false, [true, pesoIcon], "", ["true", "false"]],  
    type:                 ["multiOptions", [], true, true, [false, true, true], "font-size-1", false, false, [false, null], "", ["pool", "pool, natural", "pool, rock", "cliff", "beach"], 6],
    activities:           ["multiOptions", [], true, true, [false, true, false], "font-size-1", false, false, [false, null], "", ["swimming", "snorkeling", "cliff jumping", "consumption", "touring", "exploring"], 6],
    emotions:             ["multiOptions", [], true, true, [false, true, false], "font-size-1", false, false, [false, null], "", ["amusement", "exploration", "relaxation", "chill"], 6],
    media:                ["media", [], false, false, [false, false, false], "font-size-1", false, true, [false, null]], ///note: isfixed is set to true because of scrollable false, note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:                ["video", "", false, true, [false, false, false], "font-size-1", false, true, [false, null]], ///note: isfixed is set to true because of scrollable false
    ///nearby stops
  },

  // "type", ///string
  // "value", ///incl. default value and input value(s)
  // "filter", ///boolean
  // "optional", ///boolean (optional or mandatory)
  // "mapping", ///0: "view 0 default, 1: view 0 more, 2: view 1
  // "className", ///like font-size-x and no-translate
  // "vertical", ///vertical or horizontal arrangement of text (relevant for mapping of arrays)
  // "isfixed",
  // "shortcut",
  // "placeholder", ///string or array
  // "options", ///array - applies to: multistate, multioptions
  // "limit"
  "tours": {
    dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "stays": {
    x:          ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "consume": {
    xxxx:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "experiences": {
    title:      ["textual", "", true, true, [true, true, true], "font-size-2", false, true, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, true, [false, null], "", Array.from(Array(400).keys()), 1],
    "opening hours":   
                ["openingHours", "", false, true, [true, true, true], "font-size-1", false, true, [false, null]],
    textualArrayHi: ["textualArray", [], true, false, [false, true, false], "font-size-1", true, false, [true, pesoIcon]], 
    x:          ["textual", "", true, true, [false, true, false], "font-size-1", false, false, [true, pesoIcon]],
    publish:    ["multiState", "true", false, false, [false, true, false], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [false, true, false], "font-size-1", false, false, [false, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [false, true, false], "font-size-1", false, false, [false, null]],
    background:  ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false, [false, null], "", colorsLight({isRadical: false}), 1],
  },
  "retreats": {
    dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "ngo's": {
    dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "artists": {
    dfe:       ["textual", "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
    tags:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
    a:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    b:          ["textual", "", true, true, [true, true, true], "font-size-1", false, false, [true, null]],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    media:      ["media", [], false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
    video:      ["video", "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
  },
  "userCase": {
    caseTitle:      ["textual", "", true, true, [true, true, true], "font-size-2", false, true, [false, null]],
    by:       ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, true, [false, null], "", Array.from(Array(400).keys()), 1],
    background:  ["multiOptions", [], true, true, [true, true, true], "font-size-1", false, false, [false, null], "", colorsLight({isRadical: false}), 1],
    publish:    ["multiState", "true", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["true", "false"]],
    instagrams: ["textualArray", [], false, false, [false, true, false], "font-size-1", true, false, [false, null]], 
    instagramPosts: ["textualArray", [], false, false, [false, true, false], "font-size-1", true, false, [false, null]], 
    socials:     ["textualObjects", [{"instagram": ""}, {"facebook": ""}, {"tiktok": ""}, {"twitter": ""}], false, false, [true, true, false], "font-size-1", true, true, [false, null], "", [], 1],
    "social media posts": ["textualArray", [], false, false, [false, true, false], "font-size-1", true, false, [false, null]], 
  },
  "chat": {
    socials:     ["textualObjects", [{"instagram": ""}, {"facebook": ""}, {"tiktok": ""}, {"twitter": ""}], false, false, [true, true, false], "font-size-1", true, true, [false, null], "", [], 1],
  },
  "userComment": {
    "social media posts": ["textualArray", [], false, false, [false, true, false], "font-size-1", true, false, [false, null]], 
  },
  // "art&crafts": {
  //   xxx:       ["textual", "", true, true, [true, true, true], false, [false, null]],
  //   tags:       ["multiOptions", [], true, true, [true, true, true], false,[false, null], "", Array.from(Array(400).keys()), 1],
  //   a:          ["textual", "", true, true, [true, true, true], false, [true, null]],
  //   b:          ["textual", "", true, true, [true, true, true], false, [true, null]],
  //   publish:    ["multiState", "true", false, false, [true, true, true],  false, [true, null], "", ["true", "false"]],
  //   media:      ["media", [], false, false, [true, true, true], false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
  //   video:      ["video", "", false, true, [true, true, true],false, [true, null]],
  // },
  // "events!": {
  //   dfe:       ["textual", "", true, true, [true, true, true], false, [false, null]],
  //   tags:       ["multiOptions", [], true, true, [true, true, true], false,[false, null], "", Array.from(Array(400).keys()), 1],
  //   a:          ["textual", "", true, true, [true, true, true], false, [true, null]],
  //   b:          ["textual", "", true, true, [true, true, true], false, [true, null]],
  //   publish:    ["multiState", "true", false, false, [true, true, true],  false, [true, null], "", ["true", "false"]],
  //   media:      ["media", [], false, false, [true, true, true], false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
  //   video:      ["video", "", false, true, [true, true, true],false, [true, null]],
  // }
}

const ///note: templatescontentsetup is mapped into an object - for each templateparam - via objMap functions. ///note: templatessetup, in contrast, is read as is. ///optimise: consider to discontinue use of objmap for specifc params and just pass and read templates
  templates = generics.objMapTemplates(templatesContentSetup, templateParam),
  templatesNames = Object.keys(templatesContentSetup),
  templatesType = generics.objMapTemplatesX(templatesContentSetup, templateParam, "type"),
  templatesMapping = generics.objMapTemplatesX(templatesContentSetup, templateParam, "mapping"),
  templatesClassName = generics.objMapTemplatesX(templatesContentSetup, templateParam, "className"),
  templatesVertical = generics.objMapTemplatesX(templatesContentSetup, templateParam, "vertical"),
  templatesValue = generics.objMapTemplatesX(templatesContentSetup, templateParam, "value"), ///note: select one key, and flatten obj /// was: generics.objMapTemplates(templatesContentSetup, templateParam, ["value"]),
  templatesFilter = generics.objMapTemplatesX(templatesContentSetup, templateParam, "filter"),
  templatesIsFixed = generics.objMapTemplatesX(templatesContentSetup, templateParam, "isFixed"),
  templatesShortcut = generics.objMapTemplatesX(templatesContentSetup, templateParam, "shortcut"),
  templatesFilterableAttr = generics.objWithObjectsMapKeysToArrayIfValue(templatesFilter, true),
  templatesOptional = generics.objMapTemplatesX(templatesContentSetup, templateParam, "optional")
  //colorsTemplates = generics.objMapTemplatesX(templatesSetup, templateParam2, "value")


  // const colors = colorsLight({ isRadial : false})

export const allSearchCategories = ["artist", "title", "description", "tags"] 

const 
  weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
  hoursTypes = ["opening", "closing"],
  hoursOptionsOpening = ["closed", "6:00", "6:30", "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30"],
  hoursOptionsClosing = ["15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "3:30", "04:00", "04:30", "05:00", "05:30", "6:00", "6:30", "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30"]
///content restrictions
const contentRestrictions = (input, label) => { ///note: input is e or text from paste
  const keydown = input.type === "keydown"
  ///test
  if (keydown && !generics.isAllowedGeneral(input)) { ///general restrictions 
    return 
  }
  ///test
  let restrictions = { ///specific restrictions
    dimensions: keydown ? generics.inputKeyIsNumber(input) : generics.isNumber(input),
    price: keydown ? generics.inputKeyIsNumber(input) : generics.isNumber(input),
  }
  return generics.keyExist(restrictions, label) ? restrictions[label] : true ///optimise: consider other structure... 
}

///on key down =>
const contentEditableKeyDown = (e, label, currentRefs, refIndex, gotoNext) => { ///optimise: consider to move all contentediable functions to generics or to a seperate file
  if (
    e.key === "Enter" ||
    e.key === "Tab" 
    ) {
      e.preventDefault()
      currentRefs[refIndex].blur(); ///blur
      if (gotoNext) {
        setTimeout(function() { ///optimise:risk: does not work properly. null ref are created continlously on render. impacts this function. prevent how?
            const focusIndex = refIndex <= currentRefs.length - 1 ? ///next ref => circle refs, plus mitigate focus on non-existing ref/dom element
              refIndex + 1 : 
              currentRefs.length
            currentRefs[focusIndex].focus() ///note: focus (next) ///bug/optimise: on copy into video, every tab/enter in other fields focus video. why? mitigate? hint: its indendent on this codeblock...
        }, 100);
      }
      return
    }
  const contentAllowed = contentRestrictions(e, label)
  if (!contentAllowed) { e.preventDefault() }
}

const contentEditablePaste = (e, label, currentRef, refIndex, anchorOffset) => {
  e.preventDefault()
  const pastedText = generics.getPasteText(e).trim() ///get pasted text
  const pastedTextToNumber = parseInt(pastedText) ///convert to number or "nan" ///optimise: consider to allow/implement floating points
  const pastedTextModified = generics.isNumber(pastedTextToNumber) ?  ///if pasted is number (can be sucessfully converted to a integer)
    pastedTextToNumber : ///number
    pastedText ///text
  const contentAllowed = contentRestrictions(pastedTextModified, label) ///check if pasted text (modified to number if relevant) is allowed
  const existingText = currentRef[refIndex].innerText ///get existing text in field
  const combinedText = generics.isNumber(pastedTextToNumber) ? ///if pasted is number (can be sucessfully converted to a integer)
    generics.addStr(existingText, anchorOffset, pastedText, "").trim() : ///premise: is number, and must return a integer (with no spaces)
    generics.addStr(existingText, anchorOffset, pastedText, " "); ///optimise: at end of text + a space + paste => content inserted with an additional space => consdier to mitigate
  const emptyString = ""
  if (!contentAllowed) { return emptyString }
  const result = existingText === "" ? pastedText : combinedText ///return pasted text or combined string (including use of cursur position at paste)
  return result
}

export const ContentEditableDefault = ({blurOnMouseOut, label, currentRefs, refIndex, placeholder, className, editableContent, valueTemplet, index, inputChangedCallback, setStateCallback }) => {
  ///context
  // const { scrollTo, setScrollTo } = useContext(scrollToContext) ///note: utilise wasref at scrolltocontext
  
  // const handleSetScrollTo = ({wasRef, wasRefIndex, anchorOffset, component}) => {

  //   setScrollTo( 
  //     produce((draft) => {
  //       const item = draft["wasRef"]
  //       item["ref"] = wasRef
  //       item["index"] = wasRefIndex
  //       item["anchorOffset"] = anchorOffset
  //       item["component"] = component
  //     })
  //   )
  // }

  // useEffect(() => { ///temp removed - ok?
  //   const { index, anchorOffset, component } = scrollTo.wasRef
  //   if (component !== "itemform") { return }
  //   setTimeout(function() {
  //     currentRefs[index].focus() ///note: scrollto.wasref.ref does not work => also, thus: each component must have their own version of this useeffect
  //     //contentEditableRef.current[index].selectionStart = anchorOffset ///optimise: not working
  //   }, 50);

  // }, [scrollTo]);

  return (
    <ContentEditable 
    //key={``}
    placeholder={placeholder}
    className={`form-field ${className}`}
    html={editableContent != undefined ? editableContent : valueTemplet} 
    innerRef={(el) => currentRefs[refIndex] = el}
    onKeyDown={(e) => {
      const gotoNext = true
      //const value = currentRefs[refIndex].innerText
      //inputChangedCallback(label, value, index)
      // const value = e.target.innerText
      // console.log("e", e, e.target.innerHTML, "value", value)
      // inputChangedCallback(label, value, index)
      contentEditableKeyDown(e, label, currentRefs, refIndex, gotoNext)} ///note: refindex due to nested data like dimensions
     } 
    onMouseOut={() => blurOnMouseOut && currentRefs[refIndex].blur()}
    onPaste={(e) => { ///optimise: cursor positon after paste
      const anchorOffset = window.document.getSelection()["anchorOffset"]
      const value = contentEditablePaste(e, label, currentRefs, refIndex, anchorOffset) ///if text is not restricted => return text, else empty string
      //inputChangedCallback(label, value, index)
      setStateCallback( label, value, index ) ///question: text !== "" && (if text not empty string => set item elements)
      const component = "itemform"
      ///note. disabled - not working ///handleSetScrollTo(currentRefs[refIndex], refIndex, anchorOffset, component)
      }}
    //  onChange={(e) => {
    //     const value = currentRefs[refIndex].innerText
    //     inputChangedCallback(label, value, index)
    //   }} ///note: use of onchange causes unexpected behavour
    ///note: onchange not used => to ensure correct focus/blur at custom keydown events
    onBlur={() => {
      const value = currentRefs[refIndex].innerText

      setStateCallback(label, value, index)
      }
    }
    />
  )
}

export const OpeningHours = ({ stateData, label, setStateCallback }) => {
  const hoursInitialState = weekdays.reduce((o, key) => ({ ...o, [key]: {opening: "opening", closing: "closing"}}), {}) ///optimise: include in generic as array to object with set value

    ///state
    const [hours, setHours] = useState(
      generics.stringEmpty(stateData) ? ///if statedata not set (yet), use hoursinitialstate, after set use statedata 
        hoursInitialState : stateData)
    const [isValidated, setIsValidated] = useState(false)
    const userPresent = UserPresent()

 useEffect(() => {
    const value = hours
    
    setStateCallback({ label, value })

    const validationArray =
    weekdays.map((weekday) =>
      hoursTypes.map((hoursType) =>
        hours[weekday][hoursType] === "opening" || hours[weekday][hoursType] === "closing"
      )
    )
    const validated = generics.valuesIdenticalInArray(validationArray.flat(), false)
    setIsValidated(validated)
  }, [hours])

    const handleSetHours = ({ weekday, hoursType, template }) => {
      let autoFillMapArray = []
      switch (true) {
        case weekday === "monday" && hoursType === "closing" && template !== "closed":
          autoFillMapArray = ["tuesday", "wednesday", "thursday", "friday"]
          break;
        case weekday === "tuesday" && hoursType === "closing" && template !== "closed":
          autoFillMapArray = ["wednesday", "thursday", "friday"]
          break;
        default:
        break;
      };

      setHours(
        produce((draft) => {
          draft[weekday][hoursType] = template
        })
      )
      if (autoFillMapArray.length > 0) { ///autofill weekdays according to autofillmaparray
          autoFillMapArray.map((weekday_) =>
            hours[weekday_].opening === "opening" && hours[weekday_].closing === "closing" ? ///autofill if both opening and closing hours are not already altered
              setHours(
                produce((draft) => {
                  draft[weekday_].opening = hours[weekday].opening
                  draft[weekday_].closing = template
                })
            ) : null )
        }
      }

    const HoursPickers = ({weekday}) =>
      <GridColums
        gridTemplateRows={"1fr 1fr"}
        gridTemplateColumns={"minmax(0, 1fr)"}
        columnGap={"0.5em"}
        rowGap={"0.5em"}
      > 
        {hoursTypes.map((hoursType, i) => {  
          const 
            disabled = 
              !userPresent ||
              (hoursType === "closing" && 
              hours[weekday]["opening"] === "closed"),
            currentWeekdayIndex = new Date().getDay() - 1
          ///optimise: pass classname or button type at disabled to showcase to user
          return (  
            <DropDown
              highlightWeekday={!userPresent && weekday === weekdays[currentWeekdayIndex]} ///optimise: styling of dropdown, border at highlight not ok
              key={`${weekday}-${hoursType}-${i}`}
              disabled={disabled}
              height={"2em"}
              className={`
                font-size-1
              `}
              options={hoursType === "opening" ? hoursOptionsOpening : hoursOptionsClosing}
              passedValue={hours[weekday]["opening"] === "closed" ? "closed" : hours[weekday][hoursType]}
              parentCallback={({ template }) => { ///note: rename to value
                handleSetHours({ weekday, hoursType, template })
              }}
            >
            </DropDown>
          )  
        } 
      )}
    </GridColums>


  return ( 
    <GridColums
      gridTemplateColumns={"1fr"}
      gridTemplateRows={"1fr"}
      width={"100%"}
      
      wrapperStyle={{width: "100%"}}
    >  
      <GridColums
        gridTemplateColumns={"repeat(7, minmax(50px, 1fr))"}
        gridTemplateRows={"fit-content(100%) 2fr"}
        width={"100%"}
        columnGap={"0.5em"}
        rowGap={"0.5em"}
      >
      {weekdays.map((weekday, index) => 
        <span
          key={weekday}
        >{weekday}</span>
      )}
      {weekdays.map((weekday, index) => 
        <span
          key={weekday}
        > 
          <HoursPickers
            weekday={weekday} ///note: or use/pass index
          >
          </HoursPickers>
        </span>
      )}
    </GridColums>
    </GridColums>
  )
}

export const TextualObjects = ({ requester, isPredefined, activeTemplate, placeholder, stateData, className, refIndex, currentRefs, label, value, setTemplatesDataCallback }) => {
  const [items, setItems] = useState(stateData)
  // const [items, setItems] = useState(
  //   generics.arrayEmpty(stateData) ? ///if statedata not set (yet), use hoursinitialstate, after set use statedata 
  //     preDefinedContent : stateData)
  const 
    bin = ["bin"], 
    mapBin = items.length > 0 && !isPredefined,
    mapArray = mapBin ? [...bin, ...items] : [...items],
    selectedMultiOptionsValues = items.map((obj) => Object.keys(obj)[0]),
    multiOptionsValues = templates[activeTemplate][label].options,
    remainingMultiOptionsValues = generics.arrayExcludeIfInArray(multiOptionsValues, selectedMultiOptionsValues)

  useEffect(() => {
    const value = items
    setTemplatesDataCallback({ label, value })
  }, [items]);

  const handleSetTextualArray = ({action, label, value, i}) => {
    const index = isPredefined ? i : i - 1
    setItems(
      produce((draft) => {
        switch (true) {
          case action === "update": 
              draft[index][Object.keys(items[index])] = value  ///note: object.keys to specify unknown key ///note: keynote: - 1 due to bin (mapbin true)
            break;
          default:
          break;
        };
      })
    )
  } 

    ///on sort end
    const onSortEnd = (oldIndex, newIndex) => {
      setItems((array) => { 
        let newArray = array
        if (newIndex === 0) { ///if newindex is first index (bin)
          newArray = array.filter((media, index) => index !== oldIndex - 1) ///note: at drag to bin, exclude dragged item (equals in "array" oldindex - 1, due to bin)  
        } 
          return arrayMove(newArray, oldIndex - 1, newIndex - 1) ///note: -1 due to map (mapbin true)
      })
    }

  return (
    <GridColums
      gridAutoFlow={"row"}
      wrapperStyle={{width: "100%"}}
    >
    <>
    <SortableList  
      className={`xxx-list`} 
      //style={{padding: `${items.length > 0 ? "1vw" : ""}`}}
      onSortEnd={onSortEnd} 
      allowDrag={!isPredefined}
      draggedItemClassName="dragged"
    >
    {mapArray.map((entry, i) => {
      const isTrash = 
        !isPredefined && 
        i === 0
      let component = null
      switch (true) {   
        case !isTrash:
          component =
          <div>
            <GridColums
            gridTemplateColumns={"1fr 3fr"}
            gridTemplateRows={"1fr"}
            width={"100%"}
            wrapperStyle={{width: "100%"}}
            justifyContent={"center"}
            alignItems={"center"}
          >
        <MultiOptionButton
        disabled={true} ///optimise: consider to implement non-disabled (ie. change of category via button instead of delete => new ... was: ispredefined. 
        label={label} 
        document={""} ///note: not used here
        requester={requester} ///optimise: use name
        preSelectedOptions={[]} ///was; templatesData.new.values[activeTemplate][label]
        payloadObjectName={`templatesData.new.values`} ///was: itemElements
        payloadInnerPathViaArray={label.split(",")}
        optionCountText={""}
        multiOptionsValues={remainingMultiOptionsValues} ///optimise: consider to rename to options
        classTypes={["classical", "selectRight"]}
        text={Object.keys(entry)}
      ></MultiOptionButton>
        <ContentEditableDefault
        //key={`${label}-${i}`} ///${i}${index}
        label={label}
        // value={entry.html}
        ///placeholder={placeholder}
        className={className}
        editableContent={Object.values(entry)[0]} //Object.values(entry)[0]}
        currentRefs={currentRefs} //{`${currentRefs}-${i}`}
        //valueTemplet={valueTemplet}
        //index={index}
        //blurOnMouseOut={true}
        refIndex={`${refIndex}-${i}`}
        setStateCallback={( label, value ) => {
          const action = "update"
          handleSetTextualArray({action, label, value, i})
        }}
      >
      </ContentEditableDefault>
      {isPredefined ?
        null :
        <SortableKnob
              className={"color-4"} ///optimise: size and position (width and height) note: not able to drag is placed outside/right of grid, fix?
              >
              <div style={{width: "2em", height: "2em", right: "0.2em", position: "absolute", zIndex: "1000"}}> 
              { handPointerIcon } 
              </div>
        </SortableKnob>
      }
      </GridColums>
      </div>
        break;
        case isTrash:  
          component =               
          <span 
            className={`
            color-4
            `}
            style={{lineHeight: "10vw"}}
            >
              { trashIcon }
          </span>
        break;
        default: 
        break;
      };
      return (
        // <div
        //   key={`${label}-${i}`}
        //   index={`${label}-${i}`}
        // >
    <SortableItem 
      key={`${label}-${i}`}
      >
    {/* <div> */}
        { component }
  {/* </div> */}
  </SortableItem>
  // </div>
)}) }
  </SortableList>
  </>
  {isPredefined ? null :
    <GridColums
        gridTemplateColumns={"1fr"}
        wrapperStyle={{width: "100%"}}
    >
      <MultiOptionButton
        disabled={remainingMultiOptionsValues.length === 0}
        label={label} 
        document={""} ///note: not used here
        requester={requester} ///optimise: use name
        preSelectedOptions={[]} ///was; templatesData.new.values[activeTemplate][label]
        payloadObjectName={`templatesData.new.values`} ///was: itemElements
        payloadInnerPathViaArray={label.split(",")}
        optionCountText={""}
        multiOptionsValues={remainingMultiOptionsValues} ///optimise: consider to rename to options
        classTypes={["classical", "selectRight"]}
        text={`Add`}
      ></MultiOptionButton>
    </GridColums>
  }
  </GridColums>
  )
}

export const TextualArray = ({ isSocialMediaPreview, placeholder, stateData, className, refIndex, currentRefs, label, value, setTemplatesDataCallback }) => {

  const [textualArrayInput, setTextualArrayInput] = useState("")
  //const [textualArray, setTextualArray] = useState(stateData)
  const [items, setItems] = useState(stateData)
  const 
    bin = ["bin"], 
    mapBin = items.length > 0,
    mapArray = mapBin ? [...bin, ...items] : []

  const handleSetTextualArrayInput = ({label, value}) => {
    setTextualArrayInput(value)
  } 

  useEffect(() => {
    const value = items
    setTemplatesDataCallback({ label, value })
  }, [items]);

  const handleSetTextualArray = ({action, label, value, i}) => {
    setItems(
      produce((draft) => {
        const item = draft
        switch (true) {
          case action === "submit":
            item.push(value);
            break;
          case action === "update": 
              item[i - 1] = value  ///note: - 1 due to bin (mapbin true)
            break;
          default:
          break;
        };
      })
    )
    setTextualArrayInput("") ///reset submit-input field after submit
  } 

    ///on sort end
    const onSortEnd = (oldIndex, newIndex) => {
      setItems((array) => { 
        let newArray = array
        if (newIndex === 0) { ///if newindex is first index (bin)
          newArray = array.filter((media, index) => index !== oldIndex - 1) ///note: at drag to bin, exclude dragged item (equals in "array" oldindex - 1, due to bin)  
        } 
          return arrayMove(newArray, oldIndex - 1, newIndex - 1) ///note: -1 due to map (mapbin true)
      })
    }

  return (
    <GridColums
      gridAutoFlow={"row"}
      wrapperStyle={{width: "100%", paddingBottom: "20vh"}}
    >
    <>
    <SortableList  
      className={`xxx-list`} 
      //style={{padding: `${items.length > 0 ? "1vw" : ""}`}}
      onSortEnd={onSortEnd} 
      allowDrag
      draggedItemClassName="dragged"
    >
    {mapArray.map((entry, i) => {
      const isTrash = i === 0

      const socialMediaPreview = isSocialMediaPreview ? 
        SocialMediaPostPreview({ url: entry }) : null
 
      let component = null
      switch (true) {   
        case !isTrash:
          component =
          <div>
          <GridColums
          gridTemplateColumns={isSocialMediaPreview ? "minmax(15vw, 15vw) 1fr fit-content(100%)" :  "1fr fit-content(100%)"}
          gridTemplateRows={"1fr"}
          width={"100%"}
          wrapperStyle={{width: "100%"}}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {socialMediaPreview}

        <ContentEditableDefault
        //key={`${label}-${i}`} ///${i}${index}
        label={label}
        // value={entry.html}
        ///placeholder={placeholder}
        className={className}
        editableContent={entry}
        currentRefs={currentRefs} //{`${currentRefs}-${i}`}
        //valueTemplet={valueTemplet}
        //index={index}
        //blurOnMouseOut={true}
        refIndex={`${refIndex}-${i}`}
        setStateCallback={(label, value) => {
          const action = "update"
          handleSetTextualArray({ action, label, value, i })
        }}
      > 
      </ContentEditableDefault>
      <SortableKnob
            className={"color-4"} ///optimise: size and position (width and height) note: not able to drag is placed outside/right of grid, fix?
            >
            <div style={{width: "2em", height: "2em", right: "0.2em", position: "absolute", zIndex: "1000"}}> 
            { handPointerIcon } 
            </div>
      </SortableKnob>
    
      </GridColums>
      </div>
        break;
        case isTrash:  
          component =               
          <span 
            className={`
            color-4
            `}
            style={{lineHeight: "10vw"}}
            >
              { trashIcon }
          </span>
        break;
        default: 
        break;
      };
      return (
        // <div
        //   key={`${label}-${i}`}
        //   index={`${label}-${i}`}
        // >
    <SortableItem 
      key={`${label}-${i}`}
      >
    {/* <div> */}
        { component }
  {/* </div> */}
  </SortableItem>
  // </div>
)}) }
  </SortableList>
  </>
  <ArrayOfStrings
    placeholder={placeholder}
    label={label} 
    stateMedia={[]}
    editableContent={textualArrayInput}
    currentRefs={currentRefs}
    refIndex={refIndex}
    setStateCallback={(label, value, index) => handleSetTextualArrayInput({label, value})}//handleSetTemplatesData({ label, value, index })}
    submitCallback={(label, value) => {
      const action = "submit"
      handleSetTextualArray({action, label, value})
    }} 
  ></ArrayOfStrings>
  </GridColums>
  )
}

export const ArrayOfStrings = ({ stateMedia, label, currentRefs, refIndex, placeholder, className, editableContent, valueTemplet, index, setStateCallback, submitCallback }) => {
  
  //const [inputFieldEmpty, setInputFieldEmpty] = useState(true)

  const handleInputChanged = ({ label, value, index }) => {
    // console.log("handleInputChanged", value)
    // const isInputFieldEmpty = value === ""
    // setInputFieldEmpty(isInputFieldEmpty)
  }

  const submit = (label) => {
    if (editableContent === "") { ///note: no bubbling if no data ///note: superflous if button disabled
      return
    }

    if (label === "video") { ///optimise: implement generic solutions as well
        const url = editableContent ///stateData[label] ///url
        if (!validUrl.isUri(url)) { ///!generics.validUrl(url)) {
          alert("Ups, is this an URL?")
          return
        }
        // const label_ = "media"
        if (generics.valueExistInArrayOfObjectsViaKey(stateMedia, "url", url)) {
          alert("Ups, this video is already added")
          return
        }
        const specialLabel = "media_is_video"
        submitCallback(specialLabel, url)
    } else { ///if label not video

      submitCallback(label, editableContent)
    }
  }
  return (
    <GridColums
      gridTemplateColumns={"1fr fit-content(100%)"}
      gridTemplateRows={"1fr"}
      width={"100%"}
      wrapperStyle={{width: "100%"}}
    >
      <ContentEditableDefault
        key={`${label}${index}`}
        label={label}
        placeholder={placeholder}
        className={className}
        editableContent={editableContent}
        //valueTemplet={valueTemplet}
        index={index}
        currentRefs={currentRefs}
        refIndex={refIndex}
        blurOnMouseOut={true}
        //inputChangedCallback={(label, value, index) => handleInputChanged({label, value, index})}
        setStateCallback={(label, value, index) => setStateCallback(label, value, index)}
      ></ContentEditableDefault>
      <Button
        wrapperHeight={"auto"}
        wrapperWidth={"auto"}
        show={true}
        text={"add"}
        classTypes={["classic", "right"]}
        parentCallback={() => submit(label)}
        theme={0}
        // disabled={inputFieldEmpty}
        // selectOff={inputFieldEmpty}
        // hoverOff={true}
      >
      </Button>
    </GridColums>
  )
}

export const getFileListUnique = ({ stateMedia, newMediaArray }) => {
  const mergedMediaArray = [...stateMedia, ...newMediaArray]
  const uniqueMediaArray = generics.arrayUniqueByKey(mergedMediaArray, "nameId") // [...new Set(fileListMerged.filter(file => file.url).map(file => file))];
  if (mergedMediaArray.length !== uniqueMediaArray.length) {
    const 
      duplicates = generics.arrayDuplicatesByKey(mergedMediaArray, "nameId"),
      uniqueDuplicates = generics.arrayUniqueByKey(duplicates, "nameId"),
      names = uniqueDuplicates.map((item) => item.nameId)
    alert(`Ups, image file with name "${names}" is already added`);
  }
  return uniqueMediaArray
}

export const handleMediaAddition = ( stateMedia, newMedia, parameter, mediaType ) => {

  const mediaArray = Array.isArray(newMedia) ? newMedia : [newMedia]
  const obj = {id: 0, mediaType: "", file: [], url: "", mediaText: "", nameId: ""}
  const mediaObject = { ...obj, mediaType: mediaType } ///optimise: get obj from ...templet.media
  const newMediaArray = generics.mapArrayIntoArrayWithObjectsAndSetValueViaKey(mediaArray, mediaObject, parameter, mediaType) //////note: usage of nameid based on mediatype makes the function non-generic
  const uniqueMediaArray =  getFileListUnique({ stateMedia, newMediaArray })
  return uniqueMediaArray
}

export const MediaUpload = memo(({ stateMedia, label, minWidthButton, setDataParentCallback, setTextCallback, children }) => {

  const uploadImage_t = "Drop or Select Image(s)"
  return <GridColums
    gridAutoFlow={"row"}
    height={"auto"}
    wrapperStyle={{zIndex: 100}}
    style={{backgroundColor: "white"}}
  >
    <DropZone
      parentCallback={(fileList) => {
    
        const   
          parameter = "file",
          mediaType = "image",
          mediaArray = handleMediaAddition(stateMedia, fileList, parameter, mediaType)
        setDataParentCallback(label, mediaArray)
      }}
    >
    <Button
      show={true}
      wrapperHeight={"auto"}
      wrapperWidth={"auto"}
      wrapperClassName={`image-upload-button-wrapper-${label}`}
      //wrapperStyle={{maxWidth: "40vw"}}
      className={`image-upload-button-${label}`}
      classTypes={["classical", "selectRight"]}
      minWidth={"100%"} //{minWidthButton}
      theme={0}
    >
      <label 
        className={`placeholder-text`}
        //ref={inputImageFileRef}
        onChange={(e) => {
          const
            fileList = e.target.files,
            parameter = "file",
            mediaType = "image",
            mediaArray = handleMediaAddition(stateMedia, fileList, parameter, mediaType)
            setDataParentCallback(label, mediaArray)
        }}
      >
          <span>{uploadImage_t}</span>
          {/* <input type="file" multiple={true} hidden/> */}
      </label>
    </Button>
    </DropZone>
    <PreviewImages
      fileListArray={stateMedia}
      maxWidth={minWidthButton}
      parentCallback={(label, items) => setDataParentCallback(label, items)}
      setTextCallback={(label, value, index) => setTextCallback(label, value, index)}
    ></PreviewImages>
      { children } 
      {/* ///note: children included due to galleryitem with arrayofstring as child of mediaupload  */}
  </GridColums>
})

const ItemForm = ( { templatesData, setTemplatesData, inputImageFileRef, parentCallback }) => {
  ///refs
  const 
    contentEditableRef = useRef([]),
    multiStateButtonRef = useRef(null);
  
  ///context 
  const 
    // { templatesData, setTemplatesData } = useContext(templatesDataContext),
    { show, setShow } = useContext(showContext)

  ///const from context    
  const activeTemplate = templatesData.new.activeTemplate

  ///state
  const [verify, setVerify] = useState(templates[activeTemplate])

  ///const
  const templetEntries = Object.entries(templatesData.new.values[activeTemplate]) /// was: Object.entries(templet)
  const verifiedAll = generics.valuesIdenticalInArray(Object.values(verify), true)

  const stylingClasses = {
  }

  ///compute refindex
  const getRefIndex = (function(n) { ///optimise: move to generics. note: did try, but not working as expected, try to solve
    return function() {
      n += 1;
      return n;
    }
  }(-1));

  ///handle multi-option change
  const callbackMultiStateButton = (label, value) => {
    handleSetTemplatesData({ label, value })
  }

  ///interaction: handle multioption 
  const toggleMultiOptionsShow = (label) => {
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", childValue = "multiOptions"
    const 
      modalChild = "multiOptions",
      parameter = "label", labelValue = label,
      data = "data", dataValue = { [label]: templates[activeTemplate][label].options } ///was: multiOptionsValues[label] } ///note: multiOptionsValues gone, get instead from templatestype...
    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[modalChild][parameter] = labelValue
        draft[modalChild][data] = dataValue
      })
    );
  }

  ///text, multistatebutton or multioption change => set templatesdata temp
  const handleSetTemplatesData = ({trigger, label, index, value, startUpload, reset }) => { ///optimise: move function up and combine in one in newartwork?
  
    if (templatesData.temp.startUpload) { ///if startupload is true => abort. implemented to mitigate reset of startupload (to false) due to rerender and call of handleitemtemplatesdata
      return
    }

    const tempObj = { ///optimise: make specification implicit via map
      document: null, ///note: no document for a new item
      action: "new", 
      template: activeTemplate, 
      label: label, 
      index: index, 
      value: value, 
      startUpload: false, 
      reset: false 
      } 
      ///action = "new"
      ///tempObj = { action: action, label: label, value: value, index: index, startUpload: false} ///optimise: make specification implicit via map
    setTemplatesData(
      produce((draft) => { 
        draft.temp = tempObj
      })
    );
  };

  ///VERIFY READY TO UPLOAD
  useEffect(() => {
    const verifyArray = generics.verify(templatesValue[activeTemplate], templatesData.new.values[activeTemplate], templatesOptional[activeTemplate])
    setVerify(verifyArray)
  }, [templatesData.new.values]);

  // const handleSetScrollTo = (wasRef, wasRefIndex, anchorOffset, component) => {
  //   setScrollTo( 
  //     produce((draft) => {
  //       const item = draft["wasRef"]
  //       item["ref"] = wasRef
  //       item["index"] = wasRefIndex
  //       item["anchorOffset"] = anchorOffset
  //       item["component"] = component
  //     })
  //   )
  // }

  // useEffect(() => {
  //   const { index, anchorOffset, component } = scrollTo.wasRef
  //   if (component !== "itemform") { return }
  //   setTimeout(function() {
  //     contentEditableRef.current[index].focus() ///note: scrollto.wasref.ref does not work => also, thus: each component must have their own version of this useeffect
  //     //contentEditableRef.current[index].selectionStart = anchorOffset ///optimise: not working
  //   }, 50);

  // }, [scrollTo]);

  ///multioptions via modal action => 
  useEffect(() => {
    if (show.modal.requester === "itemForm") {
      if (show.multiOptions.action === "check") {
        const 
          //path = show.multiOptions.payloadInnerPathViaArray, ///question: use document instead?
          label = show.multiOptions.label,
          value = templatesType[activeTemplate][label] === "textualObjects" ? [...templatesData.new.values[activeTemplate][label], ...[{[show.multiOptions.dataSelected[0][label]]: ""}]] : show.multiOptions.dataSelected ///note: make generics .. maybe update to use type ... note: tried to just pass single value, but due to behavour with refiring of item-text changed at item-text and updatae of textualarray after choising to add a new object, it was chosen to avoud push, and just pass the whole array with objects, like here ...
          handleSetTemplatesData({ label, value })
        //handleSetItems2(path, value)
      }
    }	
  }, [show.multiOptions.action]); 

  const MultiState = useCallback(({ label, value }) => {
    return (
      <MultiStateButton
      show={true}
      key={label}
      wrapperClassName={`multi-state-button-wrapper-${label}`}
      className={`multi-state-button-${label}`}
      classTypes={["classical", "right"]}
      //minWidth={minWidthButton}
      theme={0}
      //style={{minWidth: minWidthButton}}
      label={label}
      options={templates[activeTemplate][label].options} ///was {options[label]} 
      value={value}
      ref={multiStateButtonRef}
      parentCallback={(label, value) => callbackMultiStateButton(label, value)}
    ></MultiStateButton>
  )
}, [templatesData.new])

  // const MultiOptionButton = useCallback(({label, optionCountText}) => {
  //   return (
  //     <Button
  //     wrapperClassName={`multi-option-button-wrapper`}
  //     className={`multi-option-button`}
  //     classTypes={["classical", "selectRight"]}
  //     theme={0}
  //     height={"auto"}
  //     //minHeight={`inherit`}
  //     //minWidth={minWidthButton}
  //     fontSize={1}
  //     message={optionCountText}
  //     parentCallback={() => toggleMultiOptionsShow(label)}
  //   >
  //     {"Select"}
  //   </Button> 
  //   )
  // }, [itemElements])
  
  ///populate templet
  const PopulateTemplet = useCallback(({ label, value, i }) => { ///was: value was valueTemplet, but not used!??
    const 
      type = templates[activeTemplate][label].type,
      // value = !generics.objectIsEmpty(itemElements) ? ///get text from itemelements, if exist ///was this => legacy => delete
      //   generics.getValueFromKey(itemElements, label) : "",
      isColumns = 
        generics.keyExist(templates[activeTemplate], label) && ///note: keyexits due to use of label mediatext not in template (at this nesting-level)
        generics.isArrayLengthAbove(templates[activeTemplate][label].value, 0),
      minWidthButton = `49vw`
    let row = null
    switch(true) {
      case type === "textualObjects": ///was: case generics.stringInArray(multiStateButtons, label):
        row = <TextualObjects
                isPredefined={!generics.arrayEmpty(templatesValue[activeTemplate][label])} ///if true => predefined entries/objects, fixed lenght, and non-sortable
                activeTemplate={activeTemplate}
                placeholder={`Enter ${label}`}
                requester={"itemForm"}
                label={label}
                value={value}
                currentRefs={contentEditableRef.current}
                refIndex={getRefIndex()}
                stateData={templatesData.new.values[activeTemplate][label]}
                setTemplatesDataCallback={({ label, value }) => handleSetTemplatesData({ label, value })}
              ></TextualObjects>
        break;
      case type === "textualArray": ///was: case generics.stringInArray(multiStateButtons, label):
        row = <TextualArray
                placeholder={`Enter ${label}`}
                label={label}
                value={value}
                currentRefs={contentEditableRef.current}
                refIndex={getRefIndex()}
                stateData={templatesData.new.values[activeTemplate][label]}
                setTemplatesDataCallback={({ label, value }) => handleSetTemplatesData({ label, value })}
              ></TextualArray>
        break;
      case type === "multiState": ///was: case generics.stringInArray(multiStateButtons, label):
        row = <MultiState
                label={label}
                value={value}
              ></MultiState>
        break;
        case type === "media": ///was: case label === "media":
          row = <MediaUpload
                  stateMedia={templatesData.new.values[activeTemplate][label]}
                  label={label} 
                  minWidthButton={minWidthButton}
                  setDataParentCallback={(label, files) => {
                    const value = files
                    handleSetTemplatesData({ label, value })
                  }}
                  setTextCallback={(label, value, index) => handleSetTemplatesData({ label, value, index })}
                ></MediaUpload>
        break;
      case type === "multiOptions": ///was: generics.stringInArray(multiOptions, label):
        const optionCountValue = templatesData.new.values[activeTemplate][label].length
        let optionCountText
        switch (true) {
          case optionCountValue === 0:
            optionCountText = ""
            break
            case optionCountValue === 1 && templates[templatesData.new.activeTemplate][label].limit === 1: ///was: multiOptionsLimit[label] === 1:
              const value = generics.valueByKeyInArrayOfObjects(templatesData.new.values[templatesData.new.activeTemplate][label], label)
              const isColor = generics.isValidColor(value)
              optionCountText = !isColor ? `you chose '${value}'` : value
            break
          case optionCountValue >= 1:
            optionCountText = `you chosen ${optionCountValue} ${optionCountValue === 1 ? "tag" : "tags"}`
            break
          default:
            break
        }
        row = <MultiOptionButton

                label={label} 
                document={""} ///note: not used here
                requester={`itemForm`} ///optimise: use name
                preSelectedOptions={templatesData.new.values[activeTemplate][label]}
                payloadObjectName={`templatesData.new.values`} ///was: itemElements
                payloadInnerPathViaArray={label.split(",")}
                optionCountText={optionCountText}
                multiOptionsValues={templates[activeTemplate][label].options} ///optimise: consider to rename to options
                classTypes={["classical", "selectRight"]}
                text={"select"}
              ></MultiOptionButton>
        break;
      case type === "video": ///was:label === "video":
        const editableContent = value;
        const refIndex = getRefIndex()
        row = <ArrayOfStrings
                placeholder={"URL of video from Youtube, Facebook, Vimeo, Twitch or Soundcloud"}
                label={label} 
                stateMedia={templatesData.new.values[activeTemplate]["media"]}
                editableContent={editableContent}
                currentRefs={contentEditableRef.current}
                refIndex={refIndex}
                setStateCallback={(label, value, index) => handleSetTemplatesData({ label, value, index })}
                submitCallback={(label, value) => handleSetTemplatesData({ label, value })}
              ></ArrayOfStrings>
              // console.log("value", value)
              // const obj = {id: 0, mediaType: "", file: [], url: "", mediaText: ""}
              // const mediaObject = { ...obj, mediaType: "video" } ///optimise: get obj from ...templet.media
              // const mediaArray = generics.mapArrayIntoArrayWithObjectsAndSetValueViaKey([value], mediaObject, "url")
        break;
      case type === "openingHours":
        row = 
          <OpeningHours
            stateData={templatesData.new.values[activeTemplate][label]}
            label={label}
            setStateCallback={({ label, value }) => {
              const trigger = "opening-hours--change"
              handleSetTemplatesData({ trigger, label, value })
            }}
          ></OpeningHours>
        break;
      case type === "textual": ///was: generics.stringInArray(textualContent, label):
      let columns = [];
        columns = isColumns ? templates[activeTemplate][label].value : ["one"];
        row =
          columns.map((column, index) => {
            const placeholder = isColumns ? templates[activeTemplate][label].placeholder[index] : templates[activeTemplate][label].pladeholder //was templetPlaceholders[label][index] : templetPlaceholders[label];
            const editableContent = isColumns ? value[index] : value;
            const className = stylingClasses[label]
            const refIndex = getRefIndex() ///was: const refIndex = getRefIndex()
              return (
                <ContentEditableDefault
                  key={`${label}${i}${index}`}
                  label={label}
                  placeholder={placeholder}
                  className={className}
                  editableContent={editableContent}
                  //valueTemplet={valueTemplet}
                  index={index}
                  currentRefs={contentEditableRef.current}
                  refIndex={refIndex}
                  setStateCallback={(label, value, index) => handleSetTemplatesData({ label, value, index })}
                ></ContentEditableDefault>
              )
            })
        break;
      default:
    }    
      return (
        <>
        {row}
        </>
      )
    }, [templatesData.new])

  return (
    <div className={"new-artwork-container"}>
      <div className={"new-artwork"}>
      {templetEntries.map(
        ([k, v], i) => {
          const 
            optional = templates[activeTemplate][k].optional,
            verified = verify[k] && !optional, // !generics.stringInArray(optional, k) ///verified if verify true and not optional (optional is verified as true, but rendered as optional without verificaiton status)
            mandatory = optional ? "" : `*` ///if mandatory render *
            return (
              <div  
                key={i}
                className={`
                  form-group
                  ${verified ? `form-group-verified` : `form-group-mandatory`}
                `}
                verified={verified ? `\uf00c` : mandatory} ///render verified true else render mandatory("*"")/optional("")
              >
                  {/* <form> */}
                    {/* <div className={`form-label-wrapper`}> */}
                      <span
                        className="form-label"
                      >{k}</span>
                    {/* </div> */}
                    <PopulateTemplet
                      label={k}
                      value={v}
                      //valueTemplet={v}
                      i={i}
                    ></PopulateTemplet>
              </div>
           
            )
          }
        )}
      </div>
        <Button
          wrapperHeight={"auto"}
          wrapperWidth={"auto"}
          show={true}
          theme={0}
          paddingAndMargin={"padding-standard"}
          parentCallback={() => parentCallback()}
          disabled={!verifiedAll}
        > Upload
        </Button>
    </div>
  )
}

export default memo(ItemForm) ///note: was export default memo(forwardRef(ItemForm))
