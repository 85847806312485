//import "./styles.css";
import './app.css';
import './styling-standards.css';
import './embed-social-widget.css';
import React, { useEffect } from "react";
import { GridColums } from "./ComponentStandards";
import * as generics from './Generics';

const embedSocialSetup = {
  "live": { 
    className: "embedsocial-stories",
    refId: "62665c1c33351555206318cf40afda89a17339ea",
    jsSrc: "https://embedsocial.com/embedscript/st.js",
    embedScript: "EmbedSocialStoriesScript",
  },
  "special events": { 
    className: "embedsocial-hashtag",
    refId: "21e33f04633909f22446847bf992ba1762755b7e",
    jsSrc: "https://embedsocial.com/cdn/ht.js",
    embedScript: "EmbedSocialHashtagScript",
  },
  // "weekly events": { 
  //   className: "embedsocial-stories",
  //   refId: "92e243b9f9ebc7424f24bf947e3a7a1610264eeb",
  //   jsSrc: "https://embedsocial.com/cdn/ht.js",
  //   embedScript: "EmbedSocialStoriesScript",
  // },
  "monday" : {
    className: "embedsocial-stories",
    refId: "92e243b9f9ebc7424f24bf947e3a7a1610264eeb",
    jsSrc: "https://embedsocial.com/cdn/ht.js",
    embedScript: "EmbedSocialStoriesScript",
  },
  "tuesday" : {
    className: "embedsocial-stories",
    refId: "2a3dc37203919a6f98f573e1c280f8638facd047",
    jsSrc: "https://embedsocial.com/cdn/ht.js",
    embedScript: "EmbedSocialStoriesScript",
  }
}

const EmbedSocialWidget = ({ widgetName }) => { ///optimise: make into stateless function
  
  const { className, refId, jsSrc, embedScript } = embedSocialSetup[widgetName] /// generics.stringUndefined(embedSocialSetup[widgetName]) ? [] : embedSocialSetup[widgetName]

  useEffect(() => {
    const id_ = embedScript
    if (document.getElementById(id_) === null) { ///if script is not already appended
      (function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = jsSrc; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", embedScript))
    }
  }, []);

      return (
        <div 
          className={className} 
          data-ref={refId}
         // style={{position: "absolute", width: "100%"}}
          >
        </div> 
      );
}

export const Reel = ( { widgetName, height, show, headerContent, showHeader, headerBackgroundColor }) => {
  return ( 
    <div 
      className={`
        input-radius 
        ${show ? "show fadein-05 input-hover" : "hide fadeout-05"}
      `}  
      style={{height: height}}
    >
      <GridColums
        rowGap={"2vh"}
      >
        <div
          className={`input-radius input-padding-3 capitalize`}
          style={{backgroundColor: headerBackgroundColor, display: `${showHeader ? "" : "none"}`}}
        > 
          <span
             className={`uppercase`}
          > {headerContent}
          </span>
        </div>
        <EmbedSocialWidget 
          widgetName={widgetName} 
        /> 
      </GridColums>
    </div> 
  ); 
}

//<div class="embedsocial-hashtag" data-ref="3b7c6211c0b60dc2c2aef7f48057ae2e3fdb4c9a"></div> <script> (function(d, s, id) { var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js); }(document, "script", "EmbedSocialHashtagScript")); </script>

