import './home.css';
import { Outlet, Link } from "react-router-dom"; //v6
import Layout from './Layout';
import { useBottomScrollListener, BottomScrollListener } from 'react-bottom-scroll-listener';
import React, { Suspense, useState, useEffect, createContext, useMemo, useRef, useCallback, memo } from 'react';

export default function Home({ children }) {

	// const scrollCallback = () => {
	// 	const
	// 		parameter = "listLength",
	// 		action = "listLength-update",
	// 		value = 12
  //   handleSetRefineCallback({ parameter, action, value })
	// }

	// const scrollRef = useBottomScrollListener(scrollCallback, {
	// 	offset: 0,
	// 	debounce: 1000,
  //   // triggerOnNoScroll: true
	// })

    return (
        <Layout>
        {/* <BottomScrollListener onBottom={scrollCallback}>
        {(scrollRef) =>  */}
          <main className={`home`}
          // ref={scrollRef} ///optimise: solve ... disabled, due to => crash of site. to reimplement height of home div must be reset (and menu pos solved) as well as filter on map of gallery items..
          >
              { children }
            {/* <nav>  */}
              <Outlet />
            {/* </nav> */}
          </main>
          {/* }
        </BottomScrollListener> */}
        </Layout>
    );
  }
  
  